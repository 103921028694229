<template>
  <div
    v-loading="isUpdatingTask || isOutputCheck || isBatchBujianLoading"
    :element-loading-text="
      isOutputCheck
        ? translateLang('arrangedPage.checkData')
        : translateLang(loadingPrompts)
    "
    :class="['draw-parts', isForbidUserSelect ? 'forbid-select' : '']"
  >
    <!-- <g-task-loading v-if="isShowTask" :task-list="taskList"></g-task-loading> -->
    <!-- <g-task-loading
      v-if="isDownloadingNc"
      :task-list="ncTaskList"
      :isNcLoading="true"
      :isNowTimeOut="isTimeOut"
      :isWebToQt="isWebToQt"
    ></g-task-loading> -->
    <!-- // todo 暂时关闭原来的加载框 -->
    <loading
      :loading="isDownloadingNc || isShowTask"
      :isTimeOut="isTimeOut"
      type="2"
      :text="loadingText"
      :isWebToQt="isWebToQt"
      :task-list="isDownloadingNc ? ncTaskList : taskList"
      :finish_percent="finishPercent"
      :loadingTitle="
        isDownloadingNc
          ? translateLang('arrangedPage.downloadNC')
          : translateLang('arrangedPage.sendToFactory.title')
      "
    ></loading>
    <loading
      :loading="isRefresh"
      :isTimeOut="isTimeOut"
      type="1"
      :text="loadingText"
      :isWebToQt="isWebToQt"
      :loadingTitle="translateLang('common.startArrange')"
    >
      <template slot="text">
        <span> {{ $t('materialPage.arrangeLoading') }}</span>
        <span class="fs18 ml5 mr5" style="color: #18a8c7">{{
          recordDrawPlankDataLength
        }}</span>
        {{ $t('materialPage.arrangeLoading2') }}
      </template></loading
    >
    <div class="loading-box" v-if="showLoading && !isRefresh">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 60px" spin />
      </a-spin>
    </div>
    <div class="draw-parts-main">
      <div class="left-operation">
        <div
          class="flex paiban-top-container flex-main--justify pb13"
          :style="{ minHeight: '160px' }"
        >
          <!-- :class="{
              'normal-top': !isShowConflictPrompts && !isDepthError,
              'oneErr-top':
                (!isShowConflictPrompts && isDepthError) ||
                (isShowConflictPrompts && !isDepthError),
            }" -->
          <div class="paiban-top w100">
            <!-- <a-tooltip
              placement="topLeft"
              :title="orderInfo.order_address"
              overlayClassName="more-title"
            >
              <div class="order-title">
                {{ orderInfo.order_address }}
              </div>
            </a-tooltip> -->

            <!-- <div class="paiban-info">
              <span class="paiban-info-label"
                >整体优化率：<span class="paiban-info-value">{{
                  totalUsedRate
                    ? totalUsedRate >= 1
                      ? `${(100).toFixed(2)}%`
                      : `${(totalUsedRate * 100).toFixed(2)}%`
                    : '0%'
                }}</span></span
              >
              <span class="paiban-info-label"
                >大板数量：<span class="paiban-info-value">{{
                  totalBigpartCounts
                }}</span></span
              >
              <span class="paiban-info-label"
                >翻板次数：<span class="paiban-info-value">{{
                  totalBigpartFanban
                }}</span></span
              >
              <span class="paiban-info-label"
                >生产线：<span class="paiban-info-value">{{
                  $store.state.recodrProductionLineChoose ?? ''
                }}</span></span
              >
            </div> -->
            <!-- 头部信息位置调整 -->
            <div class="paiban-info">
              <a-tooltip
                placement="bottomLeft"
                :title="orderInfo.order_address"
                overlayClassName="more-title"
                :overlayStyle="{
                  maxWidth: '600px !important',
                }"
              >
                <div class="order-title">
                  {{ orderInfo.order_address }}
                </div>
              </a-tooltip>

              <div class="flex flex-main--justify flex-cross--center">
                <div>
                  <span class="paiban-info-label"
                    >{{ $t('arrangedPage.totalRate') }}：<span
                      class="paiban-info-value"
                      >{{
                        totalUsedRate
                          ? totalUsedRate >= 1
                            ? `${(100).toFixed(2)}%`
                            : `${(totalUsedRate * 100).toFixed(2)}%`
                          : '0%'
                      }}</span
                    ></span
                  >
                  <a-tooltip
                    :getPopupContainer="getContainer"
                    overlayClassName="useMaterial-tooltip-card"
                    placement="bottom"
                  >
                    <template slot="title">
                      <div class="tip">
                        {{ materialData && materialData[0] }}
                      </div>
                      <a-button type="link" @click="handleCopyData"
                        >复制大板用量为文本</a-button
                      >
                    </template>
                    <span class="paiban-info-label cursor"
                      >{{ $t('arrangedPage.plankCount') }}：<span
                        class="paiban-info-value"
                        >{{ totalBigpartCounts }}</span
                      ></span
                    >
                  </a-tooltip>
                  <span class="paiban-info-label"
                    >{{ $t('arrangedPage.rollCount') }}：<span
                      class="paiban-info-value"
                      >{{ totalBigpartFanban }}</span
                    ></span
                  >
                  <span class="paiban-info-label"
                    >{{ $t('common.productionLine') }}：
                    <div class="inline-block" style="color: rgba(0, 0, 0, 0.9)">
                      {{
                        $store.state.ncSetting.isPreLayout
                          ? $t('preLayoutSetting.preWayEmpty')
                          : $store.state.recodrProductionLineChoose
                      }}
                      <a-button
                        v-if="
                          $store.state.ncSetting.cuttingEquipment == 'engraving'
                        "
                        @click="handleSelectProLine"
                        size="small"
                        :disabled="hasEmtryPlank"
                      >
                        <span
                          class="fs14"
                          :class="[hasEmtryPlank ? 'color-c' : 'color-3']"
                          >{{
                            $store.state.ncSetting.isPreLayout
                              ? $t('preLayoutSetting.selectLine')
                              : $t('preLayoutSetting.changeProline')
                          }}</span
                        >
                      </a-button>
                    </div>
                    <span v-if="false" class="paiban-info-value">{{
                      $store.state.recodrProductionLineChoose ?? ''
                    }}</span>
                  </span>
                </div>
                <div style="margin-left: 10px">
                  <div
                    class="display flex flex-main--justify"
                    v-if="isShowConflictPrompts"
                  >
                    <span class="prompts"
                      >{{ $t('arrangedPage.holeSlotConflict') }}!</span
                    ><a-button
                      id="modal_see_conflict_detail_btn"
                      class="see-details bg-white flex flex flex-cross--center flex-main--center ml10"
                      @click="handleSeeDetails"
                      :disabled="isOnlyShowSurplus"
                      type="link"
                      :style="{ opacity: isOnlyShowSurplus ? 0.3 : 1 }"
                    >
                      {{ $t('arrangedPage.checkDetail') }}
                    </a-button>
                  </div>
                  <div
                    class="display flex flex-main--justify"
                    v-if="isDepthError()"
                  >
                    <span class="prompts">{{
                      $t('arrangedPage.holeSlotErr')
                    }}</span
                    ><a-button
                      id="modal_see_depthError_detail_btn"
                      class="see-details bg-white flex flex flex-cross--center flex-main--center ml10"
                      @click="handleSeeDepthError"
                      :disabled="isOnlyShowSurplus"
                      type="link"
                      :style="{ opacity: isOnlyShowSurplus ? 0.3 : 1 }"
                    >
                      {{ $t('arrangedPage.checkDetail') }}
                    </a-button>
                  </div>
                  <div
                    class="display flex flex-main--justify flex-across--center"
                    v-if="
                      allKnivesErrInfos.length && showNotFoundKnivesErrInfoBtn
                    "
                  >
                    <span class="prompts">{{
                      $t('arrangedPage.knivesErr')
                    }}</span
                    ><a-button
                      id="modal_see_depthError_detail_btn"
                      class="see-details bg-white flex flex flex-cross--center flex-main--center ml10"
                      @click="handleOpenErrDetailInfoDrawer"
                      :disabled="isOnlyShowSurplus"
                      type="link"
                      :style="{ opacity: isOnlyShowSurplus ? 0.3 : 1 }"
                    >
                      {{ $t('arrangedPage.checkDetail') }}
                    </a-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="paiban-actions">
              <div class="tag-nc flex-cross--center w100" v-if="hasLblAuth">
                <a-button
                  class="down-nc"
                  @click="handleDownloadNC(false)"
                  :loading="isNCBtnLoading"
                  id="paiban_downloadNC_btn"
                  :disabled="hasEmtryPlank && ncSetting.isPreLayout"
                  :class="{
                    'disable-btn': hasEmtryPlank && ncSetting.isPreLayout,
                  }"
                  :style="{
                    border:
                      hasEmtryPlank && ncSetting.isPreLayout
                        ? '1px solid #ddd'
                        : 'none',
                  }"
                  >{{ $t('arrangedPage.downloadNC') }}</a-button
                >
                <a-dropdown
                  class="more-setting ml8"
                  :trigger="['click']"
                  :visible="isMoreSetting"
                  id="paiban_export_setting_dropdown"
                >
                  <template #overlay>
                    <a-menu id="paiban_export_setting_menu">
                      <a-menu-item key="3"
                        ><a-button
                          class="action-btn ml8"
                          style="
                            height: unset;
                            padding: 5px 15px;
                            text-align: left;
                          "
                          v-if="!isWebToQt"
                        >
                          <div
                            class="follow-export flex flex-dir--top flex-main--justify"
                          >
                            <a-checkbox
                              v-model="isFollowExportTag"
                              @change="handleChangeTopOption"
                              id="paiban_downloadNC_follow_export_tag_checkbox"
                              >{{
                                $t('arrangedPage.followExportTag')
                              }}</a-checkbox
                            >

                            <div class="tag-template-select">
                              <span
                                class="temp-label"
                                :class="{
                                  'temp-label_disabel': !isFollowExportTag,
                                }"
                                >{{
                                  $t('arrangedPage.tagTemplateSelect')
                                }}</span
                              >
                              <a-select
                                ref="select"
                                v-model="currentTagTempID"
                                style="width: 120px; height: 25px"
                                :disabled="!isFollowExportTag"
                                @change="handleChangeTopOption"
                                id="paiban_tag_template_select"
                              >
                                <a-select-option
                                  v-for="(tagTemp, index) in tagTempList"
                                  :value="tagTemp.id"
                                  :key="tagTemp.id"
                                  :id="`paiban_tag_template_select${index}`"
                                  :title="tagTemp.name"
                                  >{{ tagTemp.name }}
                                </a-select-option>
                              </a-select>
                            </div>
                            <a-radio-group
                              name="radioGroup"
                              v-model="currentTagType"
                              @change="handleChangeTopOption"
                            >
                              <a-radio
                                v-for="option in tagTypeOptions"
                                :value="option.value"
                                :key="option.value"
                                :id="`paiban_tag_type_option_${option.value}`"
                                :disabled="
                                  option.value == '导出图片' &&
                                  print_color_tag &&
                                  ncSetting.labelImageFormatNew == 'bmp' &&
                                  ncSetting.bmp_color_style == 1
                                "
                              >
                                <span
                                  :id="`paiban_tag_type_option_${option.value}_label`"
                                  >{{ translateLang(option.label) }}</span
                                >
                                <!-- hover显示感叹号 -->
                                <a-tooltip placement="top">
                                  <template slot="title">
                                    <span>{{
                                      translateLang('common.hoverTip')
                                    }}</span>
                                  </template>
                                  <img
                                    src="@/assets/labelColor/error-circle.png"
                                    alt=""
                                    style="height: 16px; width: 16px"
                                    class="ml2"
                                    v-if="
                                      option.value == '导出图片' &&
                                      print_color_tag &&
                                      ncSetting.labelImageFormatNew == 'bmp' &&
                                      ncSetting.bmp_color_style == 1
                                    "
                                  />
                                </a-tooltip>
                              </a-radio>
                            </a-radio-group>
                          </div>
                        </a-button>
                      </a-menu-item>
                      <a-menu-item key="2"
                        ><a-button class="action-btn ml15">
                          <a-checkbox
                            v-model="isCuttingOrder"
                            @change="handleChangeTopOption"
                            id="paiban_show_cuttingOrder_checkbox"
                            ><span
                              id="paiban_show_cuttingOrder_checkbox_label"
                              >{{
                                $t('arrangedPage.showCuttingSequence')
                              }}</span
                            ></a-checkbox
                          >
                        </a-button></a-menu-item
                      >
                      <a-menu-item key="4"
                        ><a-button class="action-btn ml15">
                          <a-checkbox
                            v-model="isShowPartSize"
                            @change="handleChangeTopOption"
                            id="paiban_show_plankSize_checkbox"
                            ><span id="paiban_show_plankSize_checkbox_label">{{
                              $t('arrangedPage.showPlankSize')
                            }}</span></a-checkbox
                          >
                        </a-button></a-menu-item
                      >
                    </a-menu>
                  </template>
                  <a-button
                    @click="handleClickMoreSetting"
                    id="paiban_more_setting_btn"
                    class="action-btn"
                  >
                    <!-- 更多设置 -->
                    {{ $t('arrangedPage.exportSetting') }}
                    <span
                      class="more-icon iconfont icon-jiantoushang ml8"
                      :class="{ 'is-active': isMoreSetting }"
                    ></span>
                  </a-button>
                </a-dropdown>
                <a-button
                  class="action-btn ml8"
                  @click="gotoTagLabel"
                  id="paiban_print_tag_btn"
                  :disabled="hasEmtryPlank && ncSetting.isPreLayout"
                  :class="{
                    'disable-btn': hasEmtryPlank && ncSetting.isPreLayout,
                  }"
                  >{{ $t('main.sideBar.printTag') }}</a-button
                >

                <a-tooltip :title="false">
                  <a-button
                    class="action-btn"
                    :class="[
                      hasEmtryPlank || isBanRelayout ? 'disable-btn' : '',
                      hasEmtryPlank || isBanRelayout ? 'ml4' : 'ml8',
                    ]"
                    :disabled="hasEmtryPlank || isBanRelayout"
                    @click="
                      handleChangePaibanWay(activePaibanWay[0], true, true)
                    "
                    id="paiban_plank_reLayout_btn"
                    >{{ $t('arrangedPage.reArrange') }}</a-button
                  >
                </a-tooltip>

                <a-button
                  class="action-btn ml8"
                  :disabled="dataFrom == 'ggpc' || !hasLblAuth"
                  @click="showSavePaiban()"
                  id="paiban_save_history_btn"
                  >{{ $t('main.sideBar.history') }}</a-button
                >
                <a-button
                  class="action-btn ml8 mr5"
                  @click="alignedPlanDialog = !alignedPlanDialog"
                  v-if="isShowAlignedPlan"
                  id="paiban_more_paiban_result_btn"
                  >{{ $t('arrangedPage.moreResult') }}</a-button
                >

                <!-- <a-button
                  :disabled="hasSaveSurplus"
                  class="action-btn ml15 mr5"
                  :class="{ 'disable-btn': hasSaveSurplus }"
                  @click="handleSurplusSave"
                  id="paiban_surplus_all_btn"
                >
                  余料入库</a-button
                > -->

                <a-button
                  :show="!showSubtle"
                  class="ml8 mr8"
                  id="paiban_batch_bujian_btn"
                  @click="handleBatchBujian"
                  :disabled="isBatchBujian"
                  >{{ $t('arrangedPage.batchBujian') }}</a-button
                >

                <a-dropdown
                  class="more-setting"
                  :trigger="['click']"
                  id="paiban_more_setting_dropdown"
                >
                  <template #overlay>
                    <a-menu id="paiban_more_setting_menu">
                      <a-menu-item class="pt2 pb2" key="1">
                        <!-- 余料入库 点击余料入库，按钮置灰，文案变成已入库-->
                        <div class="surplus-in-storages">
                          <a-button
                            class="p0 color-0"
                            type="link"
                            id="paiban_surplus_all_btn"
                            @click="handleSurplusSave"
                            :disabled="hasSaveSurplus"
                            :class="{ 'disable-class': hasSaveSurplus }"
                            style="color: rgba(0, 0, 0, 0.65)"
                            ><i class="iconfont icon-warehousing1 mr2"></i
                            >{{
                              surplusAutoTailor
                                ? hasSaveSurplus
                                  ? translateLang('arrangedPage.stored')
                                  : translateLang('arrangedPage.saveSurplus')
                                : hasCut && hasSaveSurplus
                                ? translateLang('arrangedPage.stored')
                                : translateLang('arrangedPage.saveSurplus')
                            }}</a-button
                          >
                        </div>
                      </a-menu-item>
                      <a-menu-item class="pt2 pb2" key="2"
                        ><!-- 采购单 -->
                        <div class="purchase-order">
                          <a-button
                            type="link"
                            @click="handlePlankShopinglist"
                            id="paiban_plank_shop_list_btn"
                            class="p0"
                            style="color: rgba(0, 0, 0, 0.65)"
                          >
                            <i class="iconfont icon-purchaseorder mr2"></i
                            >{{ $t('arrangedPage.workeShop') }}
                          </a-button>
                          <el-tooltip
                            v-if="false"
                            class="item"
                            effect="dark"
                            content="敬请期待!"
                            placement="top-start"
                          >
                            <a-button class="disable-btn ml15 p0"
                              ><i class="iconfont icon-purchaseorder mr2"></i
                              >{{ $t('arrangedPage.workeShop') }}</a-button
                            >
                          </el-tooltip>
                        </div></a-menu-item
                      >
                      <a-menu-item class="pt2 pb2" key="3">
                        <a-button
                          type="link"
                          @click="handleToMaterialList"
                          class="p0"
                          style="color: rgba(0, 0, 0, 0.65)"
                        >
                          <i class="iconfont icon-materiallist mr3"></i
                          >{{ $t('taskPage.record.materialList') }}</a-button
                        >
                      </a-menu-item>
                      <a-menu-item class="pt2 pb2" key="4"
                        ><a-button
                          type="link"
                          class="p0"
                          style="color: rgba(0, 0, 0, 0.65)"
                          @click="handleSendToFactory"
                          :loading="isSendToFactoryLoading"
                          id="paiban_send_workShop_btn"
                          :disabled="hasEmtryPlank && ncSetting.isPreLayout"
                          :class="{
                            'disable-class':
                              hasEmtryPlank && ncSetting.isPreLayout,
                          }"
                          ><i class="iconfont icon-workshop mr2"></i
                          >{{
                            $t('arrangedPage.sendToFactory.title')
                          }}</a-button
                        ></a-menu-item
                      >
                    </a-menu>
                  </template>
                  <a-button id="paiban_more_setting_btn" class="action-btn">
                    <!-- 更多设置 -->
                    {{ $t('arrangedPage.moreSetting') }}
                    <span
                      class="more-icon iconfont icon-jiantoushang ml5"
                    ></span>
                  </a-button>
                </a-dropdown>

                <!-- 新增开料清单按钮 -->
                <div class="material-list-btn"></div>

                <div class="layout-loc">
                  <div class="only-surplus-box">
                    <a-checkbox
                      v-model="isOnlyShowSurplus"
                      @change="handleShowSurplus"
                      >{{ $t('arrangedPage.onlySurplus') }}</a-checkbox
                    ><span class="only-show-surplus"
                      >{{ surplusCount }}{{ $t('common.unit') }}</span
                    >
                  </div>

                  <!-- 排版方案选择部分 -->
                  <div
                    class="paiban-program-box"
                    ref="paibanProgramRef"
                    v-show="false"
                  >
                    <a-dropdown
                      :trigger="['click']"
                      id="paiban_program_setting_dropdown"
                      :visible="isShowProgram"
                    >
                      <template #overlay>
                        <!-- <a-menu id="paiban_program_setting_menu">
                        <a-menu-item key="1">
                          <paibanSetting
                            :showProLineDialog="isShowProgram"
                          ></paibanSetting>
                        </a-menu-item>
                      </a-menu> -->
                        <!-- 尽可能复用 和开料清单的设置同步改变-->

                        <paibanSetting
                          :clickFlag="clickFlag"
                          :isLessReverse="isLessReverse"
                          :isSpecialShape="isSpecialShape"
                          :selectedPaibanWay="selectedPaibanWay"
                          :showProLineDialog="isShowProgram"
                          :isFromDrawAllParts="true"
                          @onCancel="handleChangeSetting"
                          @getInitData="handleGetInitData"
                          @getFinalData="handleGetFinalData"
                          @getInitTableData="handleGetInitTableData"
                          @getFinalTableData="handleGetFinalTableData"
                          @getInitSurplusSelectData="
                            handleGetInitSurplusSelectData
                          "
                          @getFinalSurplusSelectData="
                            handleGetFinalSurplusSelectData
                          "
                        ></paibanSetting>
                      </template>
                      <div
                        class="flex flex-cross--center"
                        @click.stop="handleToggleSettings"
                      >
                        <span class="paiban-program-title"
                          >{{ $t('main.sideBar.paibanResult') }}：</span
                        >
                        <!-- <div class="paiban-program-choose"> -->
                        <div class="paiban-program-choose flex">
                          <a-tooltip
                            :title="
                              (selectedPaibanWay?.indexOf('v2') !== -1
                                ? translateLang('common.directArrange')
                                : translateLang('common.sawArrange')) +
                              (isLessReverse
                                ? `,${translateLang(
                                    'materialPage.paibanSetting.lessRoll'
                                  )}`
                                : '') +
                              (isSpecialShape
                                ? `,${translateLang(
                                    'materialPage.paibanSetting.computedSpecial'
                                  )}`
                                : '') +
                              (surplusAutoTailor
                                ? `,${translateLang('arrangedPage.cutSurplus')}`
                                : '')
                            "
                          >
                            <span class="span-box inline-block">
                              {{
                                (selectedPaibanWay?.indexOf('v2') !== -1
                                  ? translateLang('common.directArrange')
                                  : translateLang('common.sawArrange')) +
                                (isLessReverse
                                  ? `,${translateLang(
                                      'materialPage.paibanSetting.lessRoll'
                                    )}`
                                  : '') +
                                (isSpecialShape
                                  ? `,${translateLang(
                                      'materialPage.paibanSetting.computedSpecial'
                                    )}`
                                  : '') +
                                (surplusAutoTailor
                                  ? `,${translateLang(
                                      'arrangedPage.cutSurplus'
                                    )}`
                                  : '')
                              }}
                            </span>
                          </a-tooltip>
                          <a-icon slot="addonAfter" type="down" />
                        </div>
                        <!-- </div> -->
                      </div>
                    </a-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-alert
          type="warning"
          show-icon
          closable
          class="mh16 mt16"
          v-if="hasHighGlossPlank"
        >
          <template #message>
            <div class="highgloss-tips">
              <div v-show="showHighGlossTipType === 'back'">
                <div v-html="$t('arrangedPage.warningTip')"></div>
              </div>
              <div v-show="showHighGlossTipType === 'front'">
                <div v-html="$t('arrangedPage.frontWarningTip')"></div>
              </div>
            </div>
          </template>
        </a-alert>
        <div
          class="batch-bujian relative flex flex-main--justify flex-cross--center ml16 mr16 pl15 pr16"
          v-if="isBatchBujian"
        >
          <span class="bold">{{
            batchBujianList.length
              ? `${$t('workShopPage.selected')}${batchBujianList.length}`
              : $t('arrangedPage.selectTip')
          }}</span>
          <div class="flex flex-main--center flex-cross--center">
            <a-button
              id="paiban_cancel_batch_bujian_btn"
              class="mr10"
              @click="handleCloseBatchBujian"
              >{{ $t('common.cancel') }}</a-button
            >
            <a-button
              id="paiban_confirm_batch_bujian_btn"
              :disabled="!batchBujianList.length"
              :type="batchBujianList.length ? 'primary' : ''"
              @click="handleConfirmBatchBujian"
              >{{ $t('workShopPage.suplement') }}</a-button
            >
          </div>
        </div>
        <div class="all-parts" ref="allParts" @scroll="scrollCanvas">
          <div v-show="drawData.length">
            <drawPart
              v-for="(item, index) in drawData"
              :isOnlyShowSurplus="isOnlyShowSurplus"
              :isShowPartSize="isShowPartSize"
              :key="index"
              :order="index"
              :drawData="item"
              :ref="`drawpart${index}`"
              :isNonTexture="isNonTexture"
              :canvasKey="index"
              :startNum="getStartNum(index)"
              :canvasMaxHeight="canvasMaxHeight"
              :canvasMaxWidth="canvasMaxWidth"
              :isCuttingOrder="isCuttingOrder"
              :activePlankID="activePlankID"
              :activePlankIndex="activePlankIndex"
              :clearConflict="clearConflict"
              :isBatchBujian="isBatchBujian"
              :batchBujianList="batchBujianList"
              :calcFanbanCount="calcFanbanCount"
              :calcCutPositionOrder="calcCutPositionOrder"
              :isShowDelete="totalBigpartCounts > 1"
              @recordActivePlank="recordActivePlank"
              @clearAllActive="clearAllActive"
              @clearAllStyle="handleClearAllStyle"
              @forbidUserSelect="forbidUserSelect"
              @handleChangeRemarksAble="handleChangeRemarksAble"
              @gotoSubtle="gotoSubtle"
              @useSurplus="useSurplus"
              @closeSurplusDialog="closeSurplusDialog"
              @addNewBigpart="addNewBigpart"
              @deleteBigpart="deleteBigpart"
              @changePaibanData="changePaibanData"
              @getBigPlankH="setBigPlankH"
              @handleChangeImageTempMousedownFlag="
                handleChangeImageTempMousedownFlag
              "
              @handleChangePage="handleChangePage"
              @onConflictPart="handleConflictPart"
              @handleChangePerPage="handleChangePerPage"
              @recordPlankMove="handleRecordPlankMove"
              @handleClickLock="handleClickLock"
              @scaleChange="handlePartScaleChange"
              @setActiveBigPart="handleSetActiveBigpart"
              @onGotoSubtleErr="onGotoSubtleErr"
            ></drawPart>
          </div>
        </div>
        <div class="operate-menu">
          <div>
            <a-popover placement="top" overlayClassName="paiban-operate-menu">
              <template slot="content">
                <div class="operate-item paiban-intro">
                  <div class="rect-example"></div>
                  <span>{{ $t('arrangedPage.funished') }}</span>
                </div>
                <div class="operate-item paiban-intro">
                  <div class="holeslot-merge-example"></div>
                  <span>{{ $t('arrangedPage.holeSlotConflict1') }}</span>
                </div>
                <div class="operate-item paiban-intro">
                  <div class="rect-merge-example"></div>
                  <span>{{ $t('arrangedPage.plankErr') }}</span>
                </div>
                <div class="operate-item paiban-intro">
                  <div class="slot-example face-slot"></div>
                  <span>{{ $t('arrangedPage.frontSlot') }}</span>
                </div>
                <div class="operate-item paiban-intro">
                  <div class="slot-example back-slot"></div>
                  <span>{{ $t('arrangedPage.backSlot') }}</span>
                </div>
                <div class="operate-item paiban-intro">
                  <div class="hole-example face-hole"></div>
                  <span>{{ $t('arrangedPage.frontHole') }}</span>
                </div>
                <div class="operate-item paiban-intro">
                  <div class="hole-example back-hole"></div>
                  <span>{{ $t('arrangedPage.backHole') }}</span>
                </div>
              </template>
              <template slot="title">
                <span>{{ $t('arrangedPage.picSymble') }}</span>
              </template>
              <span class="icon_btn"
                ><i class="operate-icon iconfont icon-hint01"></i
              ></span>
            </a-popover>
            <a-popover placement="top" overlayClassName="paiban-operate-menu">
              <template slot="title">
                <span>{{ $t('arrangedPage.shortCut') }}</span>
              </template>
              <template slot="content">
                <div class="operate-item">
                  <span>{{ $t('arrangedPage.updownRoll') }}</span>
                  <div>
                    <span>{{ $t('arrangedPage.mouseRoll') }}</span>
                  </div>
                </div>
                <div class="operate-item">
                  <span>{{ $t('arrangedPage.leftRightRoll') }}</span>
                  <div>
                    <span>shift</span>
                    <span>+{{ $t('arrangedPage.mouseRoll') }}</span>
                  </div>
                </div>
                <div class="operate-item">
                  <span>{{ $t('arrangedPage.zoomScale') }}</span>
                  <div class="operate-item-more">
                    <div>
                      <span>ctrl</span>
                      <span>+{{ $t('arrangedPage.mouseRoll') }}</span>
                    </div>
                  </div>
                </div>
                <div class="operate-item">
                  <span>{{ $t('arrangedPage.dragCanvas') }}</span>
                  <div class="operate-item-more">
                    <div>
                      <span>{{ $t('arrangedPage.mouseRight') }}</span>
                    </div>
                  </div>
                </div>
                <div class="operate-item">
                  <span>{{ $t('arrangedPage.subtleArrange') }}</span>
                  <span>{{ $t('arrangedPage.doublePlank') }}</span>
                </div>
                <div class="operate-item">
                  <span>{{ $t('arrangedPage.deletePlank') }}</span>
                  <span>{{ $t('arrangedPage.keyboard') }}delete</span>
                </div>
              </template>
              <span class="icon_btn"
                ><i class="operate-icon iconfont icon-keyboard01"></i
              ></span>
            </a-popover>
          </div>
          <div class="zoom-percent-box">
            <span
              class="iconfont icon-reduce01 operate-icon"
              @mousedown="changeZoomPercent(-0.1)"
              @mouseup="clearAllTimer"
              id="paiban_sub_scale_btn"
            ></span>
            <span class="zoom-percent">{{
              showSubtle ? subtleZoomPercent : zoomPercent
            }}</span>
            <span
              class="iconfont icon-add_03 operate-icon"
              @mousedown="changeZoomPercent(0.1)"
              @mouseup="clearAllTimer"
              id="paiban_add_scale_btn"
            ></span>
          </div>
          <div class="mr1" style="border-left: solid #ccc 1px">
            <a-popover placement="top">
              <template slot="content">{{
                $t('arrangedPage.resetScale')
              }}</template>
              <i
                class="iconfont icon-reset-icon operate-icon ml3"
                @click="handleResetScale"
                id="paiban_reset_scale_btn"
              ></i>
            </a-popover>
          </div>
        </div>
      </div>

      <div class="right-operation">
        <a-input-search
          v-model="plankKeyWord"
          :placeholder="translateLang('arrangedPage.plankKeyWordPlaceholder')"
          @search="searchPlank"
          :class="{ 'empty-input': isEmptyInput }"
          id="paiban_search_plank_input"
          :disabled="isBatchBujian"
        >
          <template #suffix>
            <div>
              {{
                searchPlankList.length
                  ? `${searchPlankIndex + 1}/${searchPlankList.length}`
                  : ''
              }}
            </div>
          </template>
        </a-input-search>
        <div class="change-plank">
          <el-button
            class="presious-plank"
            :disabled="searchPlankList.length <= 1"
            @click="handlePresiousPlank"
            id="paiban_search_plank_pre_btn"
            >{{ $t('common.previous') }}</el-button
          >
          <el-button
            class="next-plank"
            :disabled="searchPlankList.length <= 1"
            @click="handleNextPlank"
            id="paiban_search_plank_next_btn"
            >{{ $t('common.next') }}</el-button
          >
        </div>
        <div class="active-plank-box">
          <div v-if="activePlank" class="active-plank-info">
            <div class="left-info">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ activePlank.orderNo }}</span>
                </template>
                <span
                  class="info-eliplise"
                  ref="activePlankAddr"
                  id="paiban_plank_info_orderNo_text"
                  >{{ $t('common.orderNo') }}：{{ activePlank.orderNo }}</span
                >
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ activePlank.address }}</span>
                </template>
                <span
                  class="info-eliplise"
                  ref="activePlankAddr"
                  id="paiban_plank_info_address_text"
                  >{{ $t('taskPage.search.orderName') }}：{{
                    activePlank.address
                  }}</span
                >
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>
                    {{ $t('common.plankName') }}：
                    {{ activePlank.partName }}
                  </span>
                </template>
                <span id="paiban_plank_info_address_text">
                  {{ $t('common.plankName') }}：
                  {{ activePlank.partName }}
                </span>
              </a-tooltip>

              <div class="plank-matCode-container">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span
                      >{{ $t('common.matCode') }}{{ $t('common.color') }}：{{
                        activePlank.thick
                      }}{{ activePlank.matCode }}（{{ activePlank.texture }}）
                    </span>
                  </template>
                  <span
                    class="info-eliplise"
                    style="max-with: 210px; width: 210px"
                    ref="activePlankAddr"
                    id="paiban_plank_info_matcode_text"
                    >{{ $t('common.matCode') }}{{ $t('common.color') }}：{{
                      activePlank.thick
                    }}{{ activePlank.matCode }}（{{
                      activePlank.texture
                    }}）</span
                  >
                </a-tooltip>
                <a-tooltip placement="top">
                  <template slot="title">
                    <span
                      >{{ $t('common.plankNo') }}：{{
                        activePlank.plankID
                      }}</span
                    >
                  </template>
                  <span
                    class="info-eliplise"
                    style="max-width: 100px; margin-left: 10px"
                    id="paiban_plank_info_plankID_text"
                    >{{ $t('common.plankNo') }}：{{ activePlank.plankID }}
                  </span>
                </a-tooltip>
              </div>
              <span class="info-eliplise" ref="activePlankAddr"
                >{{ $t('common.edge.info') }}：{{ activePlank.edgeInfo }}</span
              >

              <a-tooltip placement="top">
                <template slot="title">
                  <span
                    >{{
                      activePlank.srcTexDir !== 'reverse'
                        ? showPartSize('realRect', 'width')
                        : showPartSize('realRect', 'height')
                    }}*{{
                      activePlank.srcTexDir !== 'reverse'
                        ? showPartSize('realRect', 'height')
                        : showPartSize('realRect', 'width')
                    }}</span
                  >
                </template>
                <span id="paiban_plank_info_cuttingSize_text"
                  >{{ $t('common.cuttingSize') }}：{{
                    activePlank.srcTexDir !== 'reverse'
                      ? showPartSize('realRect', 'width')
                      : showPartSize('realRect', 'height')
                  }}*{{
                    activePlank.srcTexDir !== 'reverse'
                      ? showPartSize('realRect', 'height')
                      : showPartSize('realRect', 'width')
                  }}</span
                >
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span
                    >{{
                      activePlank.texDir !== 'reverse'
                        ? activePlank.specWidth
                        : activePlank.specHeight
                    }}*{{
                      activePlank.texDir !== 'reverse'
                        ? activePlank.specHeight
                        : activePlank.specWidth
                    }}</span
                  >
                </template>
                <span id="paiban_plank_info_oSize_text"
                  >{{ $t('common.finishedSize') }}：{{
                    activePlank.texDir !== 'reverse'
                      ? activePlank.specWidth
                      : activePlank.specHeight
                  }}*{{
                    activePlank.texDir !== 'reverse'
                      ? activePlank.specHeight
                      : activePlank.specWidth
                  }}</span
                >
              </a-tooltip>
              <span>{{ $t('common.plankNum') }}：{{ showPlankNum }}</span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span
                    >{{ $t('common.plankRemark') }}：{{
                      activePlank.plank_remarks
                    }}</span
                  >
                </template>
                <span
                  class="info-eliplise"
                  style="max-width: 190px"
                  id="paiban_plank_remarks_plankID_text"
                >
                  <span
                    >{{ $t('common.plankRemark') }}：{{
                      activePlank.plank_remarks
                    }}</span
                  >
                </span>
              </a-tooltip>
            </div>
            <div
              class="right-qrcode"
              style="display: none"
              id="paiban-qrcode"
              ref="paibanQRcode"
            ></div>
            <div class="right-qrcode" ref="plankQRcode"></div>
            <div
              class="absolute t1 r5"
              v-show="
                activePlank.specialFlag && activePlank.specialFlag !== 'default'
              "
            >
              <img :src="specialIcon[activePlank.specialFlag]" alt="无法显示" />
            </div>
          </div>
          <div class="no-active-plank" v-else>
            <div>
              <div>{{ $t('arrangedPage.showDetail.tip1') }}</div>
              <div>{{ $t('arrangedPage.showDetail.tip2') }}</div>
            </div>
          </div>
        </div>
        <div class="paiban-way">
          <div class="title-box">
            <span class="circle"></span>
            <span class="title"
              >{{ $t('main.sideBar.paibanResult') }}
              <span style="font-size: 12px">{{
                $t('arrangedPage.arrangeTip')
              }}</span>
            </span>
          </div>
          <a-tooltip :title="false">
            <div class="select-paiban">
              <a-select
                ref="select"
                v-model="selectedPaibanWay"
                style="width: 50%"
                :disabled="
                  isHistoryStatus || hasEmtryPlank || isHbsHistoryStatus
                "
                @change="handleChangePaibanWay(selectedPaibanWay)"
              >
                <a-select-option
                  v-for="(item, index) in paibanWayList"
                  :key="index"
                  :value="item.interface"
                  :id="`paiban_paibanWay_select_option${index}`"
                  >{{
                    item.name !== 'AI智能排版限时试用'
                      ? translateLang(item.name)
                      : null
                  }}</a-select-option
                >
              </a-select>
              <span
                style="margin-left: 10px; color: #2babcc"
                id="paiban_paibanWay_suit_text"
              >
                <!-- 适用于: }} -->
                {{ translateLang('arrangedPage.applyTo') }}:
                {{ translateLang(suitText) }}
              </span>
            </div>
            <a-checkbox
              v-model="isLessReverse"
              :disabled="isHistoryStatus || isHbsHistoryStatus"
              @change="handleChangePaibanWay('_roll')"
              id="paiban_paibanWay_setting_lessRoll_checkbox"
              ><span id="paiban_paibanWay_setting_lessRoll_checkbox_label">{{
                $t('materialPage.paibanSetting.lessRoll')
              }}</span></a-checkbox
            >
            <br />
            <a-checkbox
              v-model="isSpecialShape"
              :disabled="isHistoryStatus || isHbsHistoryStatus"
              @change="handleSpecial"
              id="paiban_paibanWay_setting_comSpecial_checkbox"
              style="margin-top: 2px"
              ><span id="paiban_paibanWay_setting_comSpecial_checkbox_label"
                >{{ $t('materialPage.paibanSetting.computedSpecial') }}（{{
                  $t('materialPage.paibanSetting.specialTip')
                }}）</span
              ></a-checkbox
            >
            <a-radio-group
              v-model="nestWay"
              name="radioGroup"
              class="factory relative"
              style="margin-top: 5px"
              v-if="isSpecialShape"
              :disabled="isHistoryStatus || isHbsHistoryStatus"
              @change="handlerRadioOption($event.target)"
            >
              <a-radio value="PRIMARY" style="width: 120px"
                ><g-t-view
                  :text="$t('materialPage.paibanSetting.primaryNesting')"
                  width="80px"
              /></a-radio>
              <a-radio value="SENIOR" style="width: 120px"
                ><g-t-view
                  :text="$t('materialPage.paibanSetting.seniorShape')"
                  width="80px"
                />
              </a-radio>
              <div
                style="
                  width: 200px;
                  margin-left: 8px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span class="radioGroup"
                  ><g-t-view
                    :text="
                      '(' +
                      $t('materialPage.paibanSetting.specialShapedPanel') +
                      ')'
                    "
                    width="80px"
                /></span>
                <span class="radioGroup"
                  ><g-t-view
                    :text="
                      '(' +
                      $t('materialPage.paibanSetting.heteromorphicInlay') +
                      ')'
                    "
                    width="80px"
                /></span>
              </div>
              <div
                class="factory-tip absolute pv1 ph5 br5"
                style="
                  background-color: #f9e0c7;
                  line-height: 12px;
                  top: -0.6em;
                  right: -0.5em;
                "
              >
                <g-t-view
                  :text="$t('materialPage.paibanSetting.limitedTimeTrial')"
                  width="80px"
                />
              </div>
            </a-radio-group>
          </a-tooltip>
        </div>
        <div class="plank-operation">
          <div class="title-box">
            <span class="circle"></span>
            <span class="title">{{ $t('arrangedPage.plankOpration') }}</span>
          </div>
          <div class="plank-operation-btns">
            <a-button
              class="plank-control-rotate"
              @click="rotatePlank"
              :disabled="
                !activePlank || !hasLblAuth || handleIslocked || isBatchBujian
              "
              id="paiban_rotatePlank_btn"
              >{{ $t('arrangedPage.rotate') }}</a-button
            >
            <a-button
              class="plank-control-rotate"
              :class="
                activePlank && activePlank.is_high_gloss_plank
                  ? 'highGlossDisabled'
                  : 'plank-control-rotate'
              "
              @click="reversePlank"
              :disabled="
                !activePlank || !hasLblAuth || handleIslocked || isBatchBujian
              "
              id="paiban_reversePlank_btn"
              >{{ $t('common.roll') }}</a-button
            >
            <el-tooltip
              v-if="false"
              class="item"
              effect="dark"
              :content="translateLang('sideHoleMachine.please')"
              placement="top-start"
            >
              <a-button
                class="plank-control-rotate disable-btn ml15"
                id="paiban_reversePlank_btn_disable"
                >{{ $t('common.roll') }}</a-button
              >
            </el-tooltip>
            <a-button
              class="plank-control-del"
              @click="deletePlank"
              :disabled="!activePlank || handleIslocked || isBatchBujian"
              id="paiban_deletePlank_btn"
              >{{ $t('materialPage.deletePlank') }}</a-button
            >
            <a-button
              class="plank-control-del ml8"
              :disabled="!activePlank || handleIslocked || isBatchBujian"
            >
              <a-checkbox
                v-model="isNonTexture"
                @change="handleNonTexture"
                :disabled="
                  !activePlank ||
                  handleIslocked ||
                  isBatchBujian ||
                  activePlank.srcTexDir === 'notcare'
                "
                id="paiban_plankNonTexture_checkbox"
                ><span id="paiban_plankNonTexture_checkbox_label">{{
                  $t('arrangedPage.notCare')
                }}</span></a-checkbox
              >
            </a-button>
            <a-button
              class="plank-control-del"
              :disabled="
                !activePlank ||
                !hasLblAuth ||
                handleIslocked ||
                !!this.activePlank.surplusPath ||
                isBatchBujian
              "
              id="paiban_calcCutPositionOrder_btn"
              v-if="!ableRewriteRemarks || !activePlank"
              @click="handleRewriteRemarks"
              >{{ $t('arrangedPage.editRemark') }}</a-button
            >

            <div v-if="ableRewriteRemarks && activePlank" class="w100">
              <div class="w100">
                <a-textarea
                  v-model="plankRemarks"
                  :placeholder="translateLang('arrangedPage.editRemark')"
                ></a-textarea>
                <div class="flex flex-main--justify mt8 mb8">
                  <a-button @click="plankRemarks = ''">{{
                    $t('arrangedPage.clearRemark')
                  }}</a-button>
                  <a-button @click="handleChangeRemarks">{{
                    $t('common.confirm')
                  }}</a-button>
                </div>
              </div>
            </div>
            <!-- 批量设置下刀点 -->
            <g-ant-button
              :show="showSubtle"
              class="plank-control-del ml8"
              @click="showPositionChange = true"
              id="paiban_origin_btn"
              :text="$t('arrangedPage.batchSetCut')"
            />

            <div
              v-if="showPositionChange"
              @click="showPositionChange = false"
              id="paiban_origin_mask"
              class="origin-dialog-mask"
            >
              <div class="origin-dialog" @click.stop>
                <div class="origin-dialog-title">
                  <span>{{ $t('arrangedPage.setCutPoint') }}</span>
                  <span
                    class="iconfont icon-close"
                    id="paiban_origin_cancel_icon_btn"
                    @click="showPositionChange = false"
                  ></span>
                </div>
                <div class="origin-btn">
                  <a-button
                    :class="selectedCutorigin == 'leftTop' ? 'active' : ''"
                    @click="changeCutOrigin('leftTop')"
                    id="paiban_origin_leftTop_btn"
                    >{{ $t('cuttingDock.cuttingParams.topLeftD') }}</a-button
                  >
                  <a-button
                    :class="selectedCutorigin == 'rightTop' ? 'active' : ''"
                    id="paiban_origin_rightTop_btn"
                    @click="changeCutOrigin('rightTop')"
                    >{{ $t('cuttingDock.cuttingParams.topRightD') }}</a-button
                  >
                  <a-button
                    :class="selectedCutorigin == 'leftBottom' ? 'active' : ''"
                    @click="changeCutOrigin('leftBottom')"
                    id="paiban_origin_leftBottom_btn"
                    >{{ $t('cuttingDock.cuttingParams.bottomLeftD') }}</a-button
                  >
                  <a-button
                    :class="selectedCutorigin == 'rightBottom' ? 'active' : ''"
                    id="paiban_origin_rightBottom_btn"
                    @click="changeCutOrigin('rightBottom')"
                    >{{
                      $t('cuttingDock.cuttingParams.bottomRightD')
                    }}</a-button
                  >
                </div>
                <div class="origin-dialog-btns">
                  <div>
                    <a-button
                      @click="showPositionChange = false"
                      id="paiban_origin_cancel_btn"
                      class="mr8"
                      >{{ $t('common.cancel') }}</a-button
                    >
                    <a-button
                      @click="confirmChangeCutOrigin"
                      id="paiban_origin_confirm_btn"
                      >{{ $t('common.confirm') }}</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <a-button
              class="plank-control-calc"
              @click="calcCutPositionOrder"
              :disabled="!hasLblAuth || handleIslocked"
              id="paiban_calcCutPositionOrder_btn"
              >{{ $t('arrangedPage.recalcIndex') }}</a-button
            >
          </div>
        </div>
        <m-plank-store
          ref="awaitStoreRef"
          :defaultScale="defaultScale"
          :scale="scalePercent"
          :activeBigpart="activeBigpart"
        ></m-plank-store>
      </div>
    </div>
    <newSubtle
      ref="subtleRef"
      :visible.sync="showSubtle"
      :subtleData="subtleData"
      :isNCBtnLoading="isNCBtnLoading"
      :standardPlank="standardPlank"
      :baseMaterialData="baseMaterialData"
      :isSpecialShape="isSpecialShape"
      :nestWay="this.nestWay == 'PRIMARY' ? 1 : 2"
      v-if="subtleData && showSubtle"
      @getBigPart="getBigPart"
      @downloadNC="subtleDownloadNC"
      @printTag="subtlePrintTag"
      @wheelScale="wheelScale"
      @changeBigpart="changeBigpart"
      @savePlan="savePlan"
      @updatePaibanData="updatePaibanData"
      @recordRotatePlank="handleRecordRotatePlank"
      @recordPlankMove="handleRecordPlankMove"
      @recordChangeCutOrigin="handleRecordChangeCutOrigin"
      @recordUpdatePriority="handleRecordUpdatePriority"
      @recordDeletePlank="handleRecordDeletePlank"
      @changeLockedStatus="changeLockedStatus"
      @judgeAbleFanban="handleJudgeAbleFanban"
    ></newSubtle>
    <div class="surplus-dialog" v-if="showSurplusDialog">
      <div class="content">
        <div class="title-box">
          <span class="title">{{ $t('arrangedPage.useSurplus.title') }}</span>
          <span class="iconfont icon-close" @click="closeSurplusDialog"></span>
        </div>
        <div class="match-surplus-box">
          <div class="top-operation">
            <a-button
              @click="confirmUseSurplus"
              :disabled="isSelectedSurplus"
              :class="isSelectedSurplus ? 'disable-use' : ''"
              >{{ $t('common.confirm') }}</a-button
            >
            <a-input-search
              v-model="surplusKeyword"
              :placeholder="translateLang('common.inputPlaceholder')"
              @search="searchSurplusList()"
            >
              <a-select
                slot="addonBefore"
                v-model="searchType"
                style="width: 90px"
              >
                <a-select-option value="name">{{
                  $t('surplusPage.surplusName')
                }}</a-select-option>
                <a-select-option value="remark">{{
                  $t('materialPage.surplusHead.remark')
                }}</a-select-option>
              </a-select>
              <a-button slot="enterButton" class="search-btn">
                <span class="iconfont icon-search"></span>
                <span>{{ $t('common.check') }}</span>
              </a-button>
            </a-input-search>
          </div>
          <div
            class="surplus-list"
            ref="surplusList"
            @scroll="scrollSurplusList"
          >
            <div v-if="surplusList.length > 0">
              <div
                v-for="(item, index) in surplusList"
                :key="index"
                class="surplus-item"
                @click="changeselectedSurplus(item)"
              >
                <a-radio :checked="item.isSelected"></a-radio>
                <div class="surplus-info">
                  <div class="surplus-info-item surplus-name">
                    <span class="title"
                      >{{ item.name ? item.name + '_' : ''
                      }}{{
                        item.shape == 0
                          ? translateLang('common.rect')
                          : translateLang('common.Ltype')
                      }}_{{ item.type }}_{{ item.color }}</span
                    >
                    <span
                      >{{ $t('materialPage.surplusHead.remark') }}：{{
                        item.remark
                      }}</span
                    >
                  </div>
                  <div class="surplus-info-item surplus-amount">
                    <span>{{ $t('common.count') }}</span>
                    <span class="info">1</span>
                  </div>
                  <div class="surplus-info-item surplus-thick">
                    <span>{{ $t('common.thick') }}(mm)</span>
                    <span class="info">{{ item.thick }}</span>
                  </div>
                  <div class="surplus-info-item surplus-long">
                    <span>{{ $t('common.long') }}(mm)</span>
                    <span class="info">{{ item.long }}</span>
                  </div>
                  <div class="surplus-info-item surplus-width">
                    <span>{{ $t('common.width') }}(mm)</span>
                    <span class="info">{{ item.width }}</span>
                  </div>
                  <div class="surplus-info-item surplus-width">
                    <span>余料仓</span>
                    <a-tooltip
                      v-if="item.branch_name && item.branch_name.length > 3"
                    >
                      <template #title>
                        {{ item.branch_name }}
                      </template>
                      <span style="color: #333; font-size: 16px">{{
                        item.branch_name.slice(0, 3) + '...'
                      }}</span>
                    </a-tooltip>
                    <span class="info" v-else>{{
                      item.branch_name || '-'
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-match-list" v-else>
              <div>
                <img
                  :src="require('../../assets/image/noMatchSurplus.png')"
                  alt=""
                />
                <div class="no-match-text">
                  <span>{{ $t('materialPage.surplusTip') }}</span
                  ><span class="add-new-surplus" @click="gotoSurplusStorage">{{
                    $t('materialPage.creatSurplus')
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="showAITipDialog"
      :title="translateLang('common.tip')"
      centered
      @ok="handleCloseAITip"
    >
      <p>AI智能排版限时试用，<span style="color: red">谨慎生产</span>。</p>
      <template #footer>
        <a-button key="back" @click="handleCloseAITip">{{
          $t('common.confirm')
        }}</a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="alignedPlanDialog"
      :title="translateLang('arrangedPage.moreResult')"
      centered
      :maskClosable="false"
      width="900px"
      :footer="null"
    >
      <moreAlignedPlan @useApply="useApply" v-if="isShowAlignedPlan" />
    </a-modal>
    <sendModal
      :visible="sendToFactoryDialog"
      :is-loading="false"
      :title="translateLang('arrangedPage.sendToFactory.title')"
      :proLineName="$store.state.recodrProductionLineChoose"
      :print_color_tag="print_color_tag"
      @onClose="handleCloseSend"
      @onConfirm="handleConfirmSend"
    >
    </sendModal>
    <!-- 避免拖动图片造成canvas滚动 -->
    <div class="drop-mask" v-if="imageTempMousedownFlag"></div>
    <!-- 试生产模态框 -->
    <MTryProductionDialog
      ref="trialRef"
      @onConfirm="handleTrialProductConfirm"
      @onCancel="handleTrialProductCancel"
    />
    <lack-knives-dialog
      v-if="isShowLackKnivesDialog"
      :lackKnivesValueString="lackKnivesValueString"
      :lackKnivesKeysList="lackKnivesKeysList"
      @handleCancle="handleCancle"
      @search="searchPlank"
    >
    </lack-knives-dialog>
    <g-base-modal
      :visible="isVisible"
      v-if="isVisible"
      :contain="translateLang('common.confirmDeleteTip')"
      @ok="handleConfirmDeletePlank"
      @cancel="isVisible = false"
      preffix="paiban_delete"
    >
    </g-base-modal>
    <!--  保存至历史 -->
    <g-base-modal
      :visible="showSaveDialog"
      v-if="showSaveDialog"
      :showIcon="false"
      :ok-text="translateLang('common.save')"
      :title="translateLang('materialPage.save.title')"
      :subtitle="translateLang('materialPage.save.subTitle')"
      @ok="savePaiban"
      :isLoading="isSavePaiBanLoading"
      @cancel="showSaveDialog = false"
      preffix="paiban_save"
    >
      <div class="flex flex-main--center flex-cross--center">
        <span class="flex-box--1">{{ $t('materialPage.save.name') }}</span>
        <div class="flex-box--3">
          <a-input v-model="paibanWayName"></a-input>
        </div>
      </div>
    </g-base-modal>
    <g-warning-modal
      :visible.sync="isShowWarningModal"
      :repeatNameList="repeatNameList"
      :repeatFileNameList="repeatFileNameList"
      :repeatFolderNameList="repeatFolderNameList"
    >
    </g-warning-modal>
    <MConflictTip
      :part="conflictPart"
      :conflictIndex="conflictIndex"
      :isExistsActivePlank="isExistsActivePlank"
      @searchConflictingPlates="searchPlank"
      v-if="isShowConflictDiagram"
      @closeConflictDiagram="handleCloseConflictDiagram"
    ></MConflictTip>
    <MDepthTip
      :isShow="isShowDepthTip"
      @onClose="isShowDepthTip = false"
      :depthErrorData="depthErrorData"
      :allDepthErrorData="allDepthErrorData"
      :depthErrorIndex="depthErrorIndex"
      :depthErrorNum="depthErrorNum"
      @onDepthErrorBtn="handleDepthTipBtnClick"
      @onSearch="searchPlank"
    ></MDepthTip>
    <a-modal
      v-model="isShowOrderMark"
      :title="translateLang('common.warning')"
      :okText="translateLang('common.export')"
      @ok="handleOrderMarkOk"
      @cancel="handleOrderMarkCancel"
      id="allPartsOrderMark"
    >
      <div class="order-mark-body">
        <p style="margin-left: 80px" v-if="proucePopupState.is_produced_orders">
          {{ $t('materialPage.sameOrder') }}
        </p>
        <p
          class="flex"
          style="margin-left: 80px"
          v-if="proucePopupState.is_display_mark"
        >
          <span class="mr10">{{ $t('materialPage.markOrder') }}</span>
          <a-radio-group
            :options="orderMarkOptions"
            :default-value="proucePopupState.mark_action"
            v-model="proucePopupState.mark_action"
          />
        </p>
      </div>
    </a-modal>
    <!-- 大板用料清单弹窗 -->
    <!-- 暂时注释 不太确定这个做不做 -->
    <!-- <a-modal
      v-model="showMaterialList"
      title="大板用料清单"
      width="891px"
      wrapClassName="material-list-modal"
      centered
      :footer="null"
    >
      <a-table
        :columns="materialListColumns"
        :data-source="materialList"
        :scroll="{ y: 200 }"
        class="material-list-table"
      />
      <div class="flex flex-main--justify pv16">
        <div class="flex flex-cross--center">
          <img src="../../assets/upload.png" />
          <span class="ml5">导出</span>
        </div>
        <span>总计：26 张大板</span>
      </div>
    </a-modal> -->
    <!-- 余料库容量不足弹窗 -->
    <a-modal
      v-model="showSurStoreTip"
      title="提示"
      centered
      okText="存入余料清单"
      class="sur-store-modal"
      width="450px"
      @ok="handleSaveSurStorage"
    >
      <span>余料库容量不足，余料无法存入仓库</span>
    </a-modal>
    <!-- 余料 补件 待排版库 扣减弹窗 -->
    <g-base-modal
      v-if="isShowDeduction"
      :visible="isShowDeduction"
      :title="translateLang('arrangedPage.deductionModalTitle')"
      :okText="translateLang('common.confirm')"
      contain=""
      @ok="handleDeductionPlank"
      @cancel="
        () => {
          closeDeductionModal()
          handleContinueDownloadNc()
        }
      "
      class="deduction-modal"
    >
      <template #title-icon>
        <i class="iconfont icon-warn mt1 mr6"></i>
      </template>
      <template>
        <div v-loading="$store.state.preferencesSetting.isLoading">
          <div
            style="margin-bottom: 29px"
            v-if="deductionShowState.isShowBujianDeduction"
          >
            <!-- 扣减补件小板 -->
            <g-t-view
              :text="$t('arrangedPage.bujianDeduction') + '：'"
              style="width: 112px; margin-right: 8px; text-align: right"
            ></g-t-view>
            <a-radio-group
              v-model="
                $store.state.preferencesSetting.setting.isDeductionBujian
              "
            >
              <a-radio :value="true"> {{ $t('common.is') }} </a-radio>
              <a-radio :value="false"> {{ $t('common.not') }} </a-radio>
            </a-radio-group>
          </div>
          <div
            style="margin-bottom: 29px"
            v-if="deductionShowState.isShowAwaitDeduction"
          >
            <!-- 扣减暂存区小板 -->
            <g-t-view
              :text="$t('arrangedPage.paibanDeduction') + '：'"
              style="width: 112px; margin-right: 8px; text-align: right"
            ></g-t-view>
            <a-radio-group
              v-model="
                $store.state.preferencesSetting.setting.isDeductionPaibanStore
              "
            >
              <a-radio :value="true"> {{ $t('common.is') }} </a-radio>
              <a-radio :value="false"> {{ $t('common.not') }} </a-radio>
            </a-radio-group>
          </div>
          <div v-if="deductionShowState.isShowSurplusDeduction">
            <!-- 扣减余料大板 -->
            <g-t-view
              :text="$t('arrangedPage.surplusDeduction') + '：'"
              style="width: 112px; margin-right: 8px; text-align: right"
            ></g-t-view>
            <a-radio-group
              v-model="
                $store.state.preferencesSetting.setting.isDeductionSurplus
              "
            >
              <a-radio :value="true"> {{ $t('common.is') }} </a-radio>
              <a-radio :value="false"> {{ $t('common.not') }} </a-radio>
            </a-radio-group>
            <div
              v-show="
                !$store.state.preferencesSetting.setting.isDeductionSurplus &&
                $store.state.preferencesSetting.setting.surplus_lock
              "
              class="mt11"
              style="margin-left: 120px; margin-bottom: 20px"
            >
              <a-radio-group
                v-model="
                  $store.state.preferencesSetting.setting
                    .maintain_paiban_plank_lock
                "
                style="display: flex; flex-direction: column"
              >
                <a-radio :value="false" style="margin-bottom: 9px">
                  <!-- 解除参与排版余料的锁定 -->
                  <g-t-view
                    :text="$t('arrangedPage.unlockPaiban')"
                    width="300px"
                  ></g-t-view>
                </a-radio>
                <a-radio :value="true">
                  <!-- 维持参与排版余料的锁定 -->
                  <g-t-view
                    :text="$t('arrangedPage.maintain')"
                    width="300px"
                  ></g-t-view>
                </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
      </template>
    </g-base-modal>
    <!-- 本地直接下载nc文件所使用的目录被删除提示的弹窗 -->
    <g-folder-choose-modal
      :cb="ncDownloadCallBack"
      ref="folderChooseModalRef"
    ></g-folder-choose-modal>
    <MNotFoundKnivesInfoDialog
      :visible.sync="isShowNotFoundKnivesInfoModal"
      :data="allKnivesErrInfos"
      :showViewBtn="!showSubtle"
      @viewDetailInfo="handleOpenErrDetailInfoDrawer"
    />
    <MNotFoundKnivesInfoDrawer
      :visible.sync="isShowNotFoundKnivesInfoDrawer"
      :data="notFoundKnivesInfo"
      @searchPart="searchPlank"
    />
    <!-- 孔槽冲突弹窗 -->
    <g-base-modal
      v-if="isShowConflictModal"
      :visible="isShowConflictModal"
      :okText="translateLang('common.toCheck')"
      :cancelText="translateLang('common.continueExport')"
      :contain="translateLang('common.pleaseCheck')"
      @ok="handleCloseConflictModal"
      @cancel="handleIgnoreConflict"
    >
    </g-base-modal>
    <m-line-select-modal
      :visible="isSelectProLine"
      :lineList="proLineList"
      :proLineId="ncSetting.process_setting_id"
      @onVisibleChange="handleChangeVisible"
      @onClose="handleChangeVisible"
      @onCancel="handleChangeVisible"
      @onConfirm="handleConfirmLineSelect"
    ></m-line-select-modal>
    <!-- 需要扣减的内容的弹窗 -->
    <MDeductModal
      v-if="isShowDeduction"
      :showDeductItem="deductionShowState"
      :visible.sync="isShowDeduction"
      @success="handleDeductionPlank"
      @cancel="handleContinueDownloadNc"
    />
    <!-- 彩色标签确认弹窗 需要展示超过限制的具体数据-->
    <g-base-modal
      v-if="isShowConfirmColorTag"
      :visible="isShowConfirmColorTag"
      :contain="`${translateLang('common.exist')}${limitTwelveList
        .map((item) =>
          item == 'orderNo'
            ? translateLang('common.orderNo')
            : item == 'roomName'
            ? translateLang('common.roomName')
            : translateLang('common.loc')
        )
        .join('/')}${translateLang('common.limitNotEffect')}`"
      :okText="$t('common.yes')"
      :cancelText="$t('common.no')"
      @ok="handleConfirmContinue"
      @cancel="handleCancelDownloadNC"
    >
    </g-base-modal>
    <!-- 下载NC 余料未被本订单全部锁定 提示弹窗 -->
    <g-base-modal
      :visible="isShowSurplusLock"
      v-if="isShowSurplusLock"
      :contain="translateLang('arrangedPage.lockModalText') + '？'"
      :cancelText="translateLang('common.not')"
      :ok-text="translateLang('common.is')"
      @cancel="
        () => {
          handleCloseSurLock()
          handleDownloadNC(false, true)
        }
      "
      @ok="
        () => {
          handleCloseSurLock()
          handleChangePaibanWay(activePaibanWay[0], false, false)
        }
      "
    >
    </g-base-modal>
    <!-- 精细排版 下载NC 锁定判断 -->
    <g-base-modal
      :visible="isShowSurplusLockNewSub"
      v-if="isShowSurplusLockNewSub"
      :contain="translateLang('arrangedPage.lockNewSModalText') + '？'"
      :cancelText="translateLang('common.not')"
      :ok-text="translateLang('common.is')"
      @cancel="handleCloseSurLockNewSub"
      @ok="
        () => {
          handleCloseSurLockNewSub()
          subtleDownloadNC(subtleBigPart, true)
        }
      "
    >
    </g-base-modal>
  </div>
</template>

<script>
import { getRawMaterialPlankInfo } from '@/apis/baseMaterial'
import { deleteStoreData, fetchStoreData } from '@/apis/bujianStore'
import { LibraryType, LibraryTypeJS } from '@/apis/bujianStore/types'
import { buryPoint } from '@/apis/common/buryPoint'
import { saveHistory } from '@/apis/common/saveHistory'
import { getProductionNcSetting } from '@/apis/equipment'
import {
  fetchDeductBaseMaterial,
  fetchIsSingleOrder,
  fetchSendUseMaterialData,
  getAllKnifes,
  getExcelData,
  getProucePopupState,
  saveOrderInfoToLbl,
  updateProduceStatus,
  useSurplus as useSurplusApi,
} from '@/apis/paiban/index'
import { getPrelayoutSetting } from '@/apis/preLayoutSetting'
import { httpRequest } from '@/apis/request'
import { getSurplusList, surplusLock } from '@/apis/surplusManage'
import { getSpecialRemarks } from '@/apis/tag/index'
import { updateToHasPaiban } from '@/apis/taskList/index'
import { batchBujian } from '@/apis/work-shop'
import circleShape from '@/assets/icon/circle_shape.svg'
import halfCircleShape from '@/assets/icon/half_circle_shape.svg'
import hexagonShape from '@/assets/icon/hexagon_shape.svg'
import squareShape from '@/assets/icon/square_shape.svg'
import triangleShapea from '@/assets/icon/triangle_shape.svg'
import loading from '@/components/common/loading'
import GBaseModal from '@/components/g-base-modal.vue'
import GFolderChooseModal from '@/components/g-folder-choose-modal.vue'
import GTaskLoading from '@/components/g-task-loading.vue'
import GWarningModal from '@/components/g-warning-modal.vue'
import newContent from '@/components/newContent.vue'
import GAntButton from '@/components/translateComp/g-ant-button.vue'
import GTView from '@/components/translateComp/g-t-view.vue'
import { sampleCabinets } from '@/constants'
import { buryPointPayloadMap } from '@/constants/buryPointMap'
import store from '@/store'
import { deepCopy, specialSymbol } from '@/util/LayoutFuncs'
import { rolloverPlank } from '@/util/LayoutFuncs.js'
import { changeDataStartPoint, viewDataToNCData } from '@/util/NCGenerator.js'
import { sendTrialRecord } from '@/util/NcFileRelated'
import { buryPointFunc } from '@/util/buryPointDeal'
import { EventBusKey } from '@/util/bus'
import EventBus from '@/util/bus'
import ClipperLib from '@/util/clipper_unminified.js'
import { copyText } from '@/util/common'
import { genColorRes, labelSources, translate } from '@/util/commonFun'
import {
  buryPointApi,
  checkPlankDataIsCorrect,
  compare,
  convertBlobToBase64,
  dataClustering,
  genOrderAddress,
  generateSimplePlankNum,
  getOriginRect,
  modalMsg,
} from '@/util/commonFuncs'
import { genFinalRecord } from '@/util/commonFuncs.js'
import { fileNameSetObj, folderKeyList } from '@/util/configure'
import { initNcLifeObj } from '@/util/createLifeFunc'
import { dealGuiguiJson } from '@/util/dealGuiguiJson.js'
import {
  checkPlankEdgeHasChanged,
  dealPaibanData,
  dealPlankListLabelID,
  dealPlankPoly,
  dealPreLayoutDataLabelID,
  genPaibanRequestParams,
  genSurplusParams,
  getPlateKnifeDiameter,
  setSurplusObj,
} from '@/util/dealPaibanData'
import { calcBigpartArea, dealPlankType } from '@/util/drawPlankFuncs.js'
import { getZipJson } from '@/util/getZipJson.js'
import {
  addNewBigPlank,
  clearObjAttr,
  dealGuimenFGNo,
  dealSurplusSize,
  dealTaskLoading,
  exportExcel,
  getSlotBasicInfo,
} from '@/util/plankCommonFuncs'
import {
  createTagImageZip,
  generateTagFile,
} from '@/util/productionDataGenerate/tagDataGenerate'
import {
  dealTransFileName,
  genContentPromises,
  genMergeSubFolderData,
  promiseToZip,
  saveDataJsonToOssForRecord,
} from '@/util/saveFile'
import { showCardDetail } from '@/util/showCardDetail'
import {
  genFirstLevelFileName,
  getGraghEdge,
  handleGenFileNameStr,
} from '@/util/tag'
import { tailorSurplus } from '@/util/tailorSurplus'
import {
  dealLblApartPackageCount,
  needApartPackUser,
} from '@/util/thirdPartyDealFunc/lblDealFuncs'
import paibanSetting from '@/views/materialsList/components/paibanSetting'
import { uploadFile, uploadFileToOss } from '@yige/utils'
import OSS from 'ali-oss'
import { message } from 'ant-design-vue'
import axios from 'axios'
import { log } from 'console'
import { Message } from 'element-ui'
import { cloneDeep } from 'lodash'
import QRCode from 'qrcodejs2'
import { get } from 'sortablejs'
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'

import {
  checkPrelayoutSettingChanged,
  dealPreLayoutSetting,
  deepCompare,
} from '../materialsList/util/preLayoutDeal'
import { getDate } from '../taskList/util/batchDataDeal'
import MConflictTip from './component/m-conflict-tip'
import MDeductModal from './component/m-deduct-modal'
import MDepthTip from './component/m-depth-tip'
import LackKnivesDialog from './component/m-lack-knives-dialog.vue'
import mLineSelectModal from './component/m-line-select-modal.vue'
import MNotFoundKnivesInfoDialog from './component/m-notFound-knives-info-dialog/m-notFound-knives-info-dialog.vue'
import MNotFoundKnivesInfoDrawer from './component/m-notFound-knives-info-drawer/index.vue'
import MPlankStore from './component/m-paiban-store/index.vue'
import {
  genAwaitStoreData,
  getPlankImage,
  parseAwaitStoreRenderUrl,
  plankAddAwaitStoreFeature,
  plankAddBujianStoreFeature,
} from './component/m-paiban-store/utlis'
import sendModal from './component/m-sendModal.vue'
import MTryProductionDialog from './component/m-try-production-dialog'
import moreAlignedPlan from './component/moreAlignedPlan'
import drawPart from './drawPart.vue'
import newSubtle from './newSubtle.vue'
import { dealBatchBujian } from './util/bujianData'
import { downloadFile as downloadNCFile } from './util/downLoadNC'
import {
  calcBaseMaterial,
  dealDataForPaiban,
  dealStandardPlank,
} from './util/layoutDataDeal'
import { dealPlankDataForLbl } from './util/lblPlankData'
import { uploadFiles } from './util/uploadFiles'

const defaultScale = 6
const orderMarkOptions = [
  { label: '标记', value: 1 },
  { label: '不标记', value: 0 },
]

export default {
  components: {
    GTView,
    mLineSelectModal,
    drawPart,
    newSubtle,
    loading,
    moreAlignedPlan,
    sendModal,
    GTaskLoading,
    newContent,
    MTryProductionDialog,
    LackKnivesDialog,
    GBaseModal,
    MConflictTip,
    GWarningModal,
    MDepthTip,
    paibanSetting,
    MPlankStore,
    GAntButton,
    GFolderChooseModal,
    MNotFoundKnivesInfoDialog,
    MNotFoundKnivesInfoDrawer,
    MDeductModal,
    GTView,
  },
  data() {
    return {
      isShowNotFoundKnivesInfoModal: false,
      clickFlag: false,
      initState: {},
      finalState: {},
      initTableList: [],
      finalTableList: [],
      initSurplusSelectList: [],
      finalSurplusSelectList: [],
      isShowProgram: false, //控制排版方案选择弹窗
      isOnlyShowSurplus: false, //只看余料大板
      surplusCount: 0, // 只看余料大板计数
      // isCancontinue: false,
      proucePopupState: {
        mark_action: 1,
        is_produced_orders: true,
        is_display_mark: true,
      },
      drawDataArr: [],
      orderMarkOptions,
      isSubtle: false,
      // 发送生产车间加载loading
      isSendToFactoryLoading: false,
      // 加载loading的提示词
      loadingPrompts: 'arrangedPage.dataLoading',
      // 控制重名弹窗显示
      isShowWarningModal: false,
      repeatFileNameList: [],
      repeatFolderNameList: [],
      // 重名数组
      repeatNameList: [],
      // 记录绘制的显示宽度和高度
      canvasMaxWidth: 0,
      canvasMaxHeight: 0,
      // 记录窗口可视区域的变化
      resizeEvent: null,
      // 记录键盘按下事件
      keyDownEvent: null,
      // 是否显示加载
      showLoading: false,
      // 记录排版数据
      oriArr: [],
      // 记录排版数据长度
      oriArrLen: 0,
      // 记录整体优化率
      totalUsedRate: 0,
      // 记录整体的翻板次数
      totalBigpartFanban: 0,
      // 记录所有大板数量
      totalBigpartCounts: 0,
      // 记录绘制数组
      drawData: [],
      // 记录整体的缩放比例
      zoomNum: 1,
      // 记录搜索的值
      plankKeyWord: '',
      // 记录选中的板件
      activePlank: null,
      // 排版方式
      paibanWayList: [
        {
          name: 'common.sawArrange',
          interface: 'paiban',
          desc: 'materialPage.paibanSetting.suitEquipmentSpecial',
        },
        {
          name: 'common.directArrange',
          interface: 'paiban_v2',
          desc: 'materialPage.paibanSetting.suitEquipmentNormal',
        },
        // {
        //   name: 'AI智能排版(试用)',
        //   interface: 'blue_ai/paiban',
        //   desc: '常规雕刻机',
        // },
      ],
      // 记录保存排版时输入的名字
      paibanWayName: '',
      // 记录当前点击的是第几个分类
      canvasKey: -1,
      // 记录是否显示保存弹窗
      showSaveDialog: false,
      // 记录搜索到的板件列表
      searchPlankList: [],
      // 记录搜索之后, 当前板件列表的选中板件的下标
      searchPlankIndex: -1,
      // 是否禁止选中文字
      isForbidUserSelect: false,
      // 记录点击暂存区时生成的图片
      tempImg: null,
      // 记录鼠标按下时图片的真实宽高
      imgRealSize: null,
      // 记录鼠标按下时, 鼠标位置相对于图片的位置
      imgPostion: null,
      // 记录鼠标移动事件
      mouseMoveEvent: null,
      // 是否显示精细排版
      showSubtle: false,
      // 记录显示精细排版时的数据
      subtleData: null,
      // 记录是否显示余料匹配的弹窗
      showSurplusDialog: false,
      // 记录搜索余料时选择的字段
      searchType: 'name',
      // 记录匹配到的余料数据
      surplusList: [],
      // 记录搜索的余料关键词
      surplusKeyword: '',
      // 记录当前选中大板的最大宽度和最大高度
      maxSurplusWidth: 0,
      maxSurplusHeight: 0,
      // 记录获取的余料页码
      surplusPage: 1,
      // 记录当前选中的大板
      activeBigpart: null,
      // 记录选中的余料
      selectedSurplus: null,
      // 记录替换余料时, 板件的最小x值和最大y值
      minSurplusX: 0,
      maxSurplusY: 0,
      // 记录是否正在下载NC
      isDownloadingNc: false,
      // 暂存区是否显示
      isShowzancun: false,
      // 下载NC文件时是否自动传保存记录
      isAutoPassData: false,
      // 板件是否显示切割顺序
      isCuttingOrder: true,
      // 记录点击的排版方式
      selectedPaibanWay: null,
      // 板件是否选择无纹理
      isNonTexture: false,
      // 记录操作无纹理选项后的板件信息
      plankArr: [],
      // 记录当前激活板件ID
      activePlankID: 0,
      // 记录当前激活板件Index
      activePlankIndex: 0,
      nestWay: 'PRIMARY',
      // 是否选中异形嵌套排版
      isSpecialShape: false,
      // 记录排版方案是否是AI排版
      isAIpaiban: false,
      // 是否选中少翻版
      isLessReverse: false,
      // 选中排版使用范围
      suitText: '',
      // AI排版后的提示框
      showAITipDialog: false,
      // 记录是否空输入
      isEmptyInput: false,
      // 记录是否排版数据变动
      hasChangePaiban: false,
      //记录初始的板件信息
      drawDataO: [],
      // 记录是否为AI智能排版
      isAIPaiban: false,
      // 多排版方案是否显示
      alignedPlanDialog: false,
      // 生成图片的点击标记
      imageTempMousedownFlag: false,
      // 记录激活大板的startY
      bigPlankH: 0,
      // 记录余料是否已经入库
      hasSaveSurplus: false,
      hasCut: false,
      // 记录当前大板序号
      currentIndex: 1,
      // 记录当前精细排版大板
      surplusBigpart: null,
      // 缩放timeOut
      scaleTimer: null,
      scaleInterv: null,
      // 精细排版缩放
      subtleZoomNum: 1,
      // 是否确认保存
      comfirmSave: false,
      specialIcon: {
        circle_shape: circleShape,
        half_circle_shape: halfCircleShape,
        triangle_shape: triangleShapea,
        hexagon_shape: hexagonShape,
        square_shape: squareShape,
      },
      sendToFactoryDialog: false,
      taskList: [],
      isShowTask: false,
      // 保存NC文件的ossurl
      NCUrls: [],
      // 是否确认发送
      confirmSendToFactory: false,
      // 保存标签文件的ossUrl
      ossUrl: { data: '', type: '' },
      loadingText: this.$t('materialPage.dealing'),
      // 是否显示小板尺寸
      isShowPartSize: false,
      //是否显示下载NC时，缺失刀具的提示框
      isShowLackKnivesDialog: false,
      //下载nc，没有相应刀的数据
      lackKnivesList: [],
      //下载nc，没有相应刀的数据的键组成数组
      lackKnivesKeysList: [],
      //下载nc，没有相应刀的数据的值组成数组,拼接的字符串
      lackKnivesValueString: '',
      // 用于保存每页多少张大板
      countPerPage: 20,
      // 用于保存当前页数
      currentPape: 1,
      // 是否顺带导出标签
      isFollowExportTag: false,

      // 标签文件输出方式
      tagTypeOptions: [
        {
          label: 'common.exportPDF',
          value: '导出PDF',
        },
        {
          label: 'common.exportPic',
          value: '导出图片',
        },
      ],
      // 标签模板项
      tagTemplateOptions: [],
      // 是否点击了更多设置
      isMoreSetting: false,
      // 当前选中的标签模板
      currentTagTempID: 0,
      // 当前导出标签的类型
      currentTagType: '导出图片',
      // 压缩图片时候的zip对象
      imgZip: null,
      // 贴标信息
      labelInfoList: [],
      // 精细排版数据
      subtleBigPart: null,
      // 显示删除小板弹窗
      isVisible: false,
      // 冲突显示的板件
      conflictPart: null,
      // 是否存在活跃板件
      isExistsActivePlank: false,
      //记录此次排版是否存在板件冲突
      isShowConflictPrompts: false,
      //孔槽冲突图是否显示
      isShowConflictDiagram: false,
      isShowDepthTip: false,
      //孔槽冲突图第一次显示的索引
      conflictIndex: 0,
      //孔槽冲突图冲突板件列表
      conflictPlankList: [],
      isTimeOut: false,
      // 是否在更新任务
      isUpdatingTask: false,
      // 收集贴标图文件数据
      labelImgList: [[]],
      // 操作记录
      operateArr: [],
      bigPart: null,
      // 全部锁定禁止重新排版
      isBanRelayout: false,
      isSavePaiBanLoading: false,
      // 下载NC的任务列表
      ncTaskList: [],
      // 下载NC的任务生命周期函数对象
      ncLifeFuncObj: {},
      // 好帮手历史跳转
      isHbsHistoryStatus: false,
      allDepthErrorData: [],
      depthErrorIndex: 0,
      // 是否展示订单标记模态框
      isShowOrderMark: false,
      // 是否加载下载NC按钮
      isNCBtnLoading: false,
      // 扣减的余料数组
      surplusUseArr: [],
      isOutputCheck: false,
      // 板件备注
      plankRemarks: '',
      // 是否能够修改板件备注
      ableRewriteRemarks: false,
      // 是否在批量补件
      isBatchBujian: false,
      // 批量补件列表
      batchBujianList: [],
      // 批量补件的加载状态
      isBatchBujianLoading: false,
      // 真正使用的标准板
      standardPlank: {},
      // 原片管理的标准板
      defaultPlankList: [],
      baseMaterialData: {},
      // 记录选择的下刀点
      selectedCutorigin: '',
      // 显示下刀点修改弹窗
      showPositionChange: false,
      cutOriginObj: {
        leftTop: '左上角',
        rightTop: '右上角',
        leftBottom: '左下角',
        rightBottom: '右下角',
      },
      // 大板用料清单弹窗显示
      showMaterialList: false,
      materialListColumns: [
        {
          title: '大板类型',
          dataIndex: 'plankType',
          filters: [
            { text: '标准板材', value: '标准板材' },
            { text: '特殊板材', value: '特殊板材' },
            { text: '余料板材', value: '余料板材' },
            { text: '全选', value: '全选' },
          ],
          width: 110,
        },
        {
          title: '材质',
          dataIndex: 'matCode',
        },
        {
          title: '颜色',
          dataIndex: 'texture',
        },
        {
          title: '厚度(mm)',
          dataIndex: 'thick',
          width: 100,
        },
        {
          title: '长度(mm)',
          dataIndex: 'plankHeight',
          width: 100,
        },
        {
          title: '宽度(mm)',
          dataIndex: 'plankWidth',
          width: 100,
        },
        {
          title: '余料仓库',
          dataIndex: 'branch_name',
          width: 100,
        },
        {
          title: '余料名称',
          dataIndex: 'name',
          width: 100,
        },
        {
          title: '数量',
          dataIndex: 'num',
        },
      ],
      materialList: [],
      // 余料库容量不足弹窗显示
      showSurStoreTip: false,
      awaitStoreRef: null,
      // 子画布默认缩放
      defaultScale: 6,
      // 子画布动态缩放
      scalePercent: 1,
      /** 扣减弹窗 */
      isShowDeduction: false,
      // 扣减对应选择框是否显示
      deductionShowState: {
        // 补件扣减
        isShowBujianDeduction: true,
        // 余料扣减
        isShowSurplusDeduction: true,
        // 待排版库扣减
        isShowAwaitDeduction: true,
        // 余料状态勾选
        isDisarm: false,
        // 扣减原片
        isShowBaseMaterialDeduction: true,
      },
      // 扣减对应选择框选中状态
      deductionStatus: {
        bujian: true,
        surplus: true,
        awaitStore: true,
      },
      /** 扣减需要扣减的数据 */
      deductionData: {
        // 补件扣减
        bujian: [],
        // 余料扣减
        surplus: [],
        // 待排版库扣减
        awaitStore: [],
      },

      // 当前执行的下载nc方式 paiban  jingxi
      currentDownloadType: 'paiban',
      // 有关小板上找不到刀的错误信息
      notFoundKnivesInfo: [{}],
      // 所有的刀错误信息
      allKnivesErrInfos: [],
      // 在精细排版下，排版后不展示查看找不到刀的错误信息按钮
      showNotFoundKnivesErrInfoBtn: true,
      isShowNotFoundKnivesInfoDrawer: false,
      // 当前排版的小板数量
      recordDrawPlankDataLength: 0,
      // 是否为重新排版
      isRefresh: false,
      // 孔槽冲突的弹窗
      isShowConflictModal: false,
      // 忽略错误，继续下载文件的标识
      isContinue: false,
      // 当前生产线
      isSelectProLine: false,
      oriNcSetting: null,
      oriKnifes: null,
      orderIds: [],
      isShowDeductModal: false, // 扣减内容弹窗
      useMaterialData: {},
      // 是否生成彩色标签
      print_color_tag: false,
      // 彩色标签生成警告
      isShowConfirmColorTag: false,
      // 彩色标签无法生效时选择继续下载NC
      continueDownloadFlag: false,
      // 超过限制的数组
      limitTwelveList: [],
      // 当前操作对象
      currentOperate: 'nc',
      // 当前排版使用余料id
      useSurplusId: [],
      // 当前排版使用余料列表
      useSurplusList: [],
      // 余料是否被当前订单全部锁定弹窗
      isShowSurplusLock: false,
      // 精细排版 下载NC 锁定弹窗
      isShowSurplusLockNewSub: false,
    }
  },
  computed: {
    ...mapState([
      'proLineList',
      'surplusAutoTailor',
      'userInfo',
      'paibanData',
      'codeIds',
      'paibanExtraInfo',
      'isHistoryStatus',
      'ncSetting',
      'preLayoutData',
      'orderInfo',
      'bigPartsInfo',
      'dataFrom',
      'hasLblAuth',
      'tempStorage',
      'supplusIndex',
      'isDragingPlank',
      'finalDrawData',
      'surplusParams',
      'activePaibanWay',
      'specialPlankParams',
      'surplusTailorWay',
      'surplusCommonSize',
      'paibanInfo',
      'recodeIsTailor',
      'paibanKey',
      'newTags',
      'sampleTableData',
      'isShowSample',
      'factoryId',
      'prePaibanData',
      'availableBujianList',
      'filterMaterialList',
      'filterObject',
      'beforeSearchMaterialList',
      'beforeSizeFilterList',
      'isTrialProduct',
      'tagTempList',
      'historyOrder',
      'highgloss_direction',
      'hasRollPoly',
      'isPrintTag',
      'labeling_folder',
      'isSurplusNoRoll',
      'materialTask',
      'produceTime',
      'isBatch',
      'isNewPaiban',
      'historyPlankEdgeOff',
      'historySecondTrimValue',
      'hasHistoryChangePaiban',
      'selectStandardPlank',
      'customPlankOrder',
      'plankOrderList',
      'onlyLayoutTooBig',
      'oriLayoutSetting',
      'paibanParams',
      'preLayoutSetting',
      'surplusObj',
      'userInfo',
      'selectSurplusList',
    ]),
    // 是否是只剩余空大板
    isEmptyData() {
      return this.finalDrawData.some(
        (item) =>
          !item.data.length ||
          (item.data.length === 1 &&
            item.data.some((plank) => !plank.parts.length))
      )
    },
    // 检测是否有深度异常板件
    isDepthError() {
      return (drawDataArr = this.paibanData) => {
        const flag = drawDataArr.some((dataEle) =>
          dataEle.parts.some((part) => part.depthError)
        )
        return flag
      }
    },
    depthErrorData() {
      return this.allDepthErrorData[this.depthErrorIndex]
    },
    depthErrorNum() {
      if (this.allDepthErrorData && this.allDepthErrorData.length) {
        const currentDepthError = this.allDepthErrorData[this.depthErrorIndex]
        const holeDepthErrorArr = currentDepthError.holes.filter(
          (hole) => hole.depthError
        )
        const slotDepthErrorArr = currentDepthError.slots.filter(
          (slot) => slot.depthError
        )
        return slotDepthErrorArr.length + holeDepthErrorArr.length
      }
      return 0
    },
    handleIslocked() {
      let isLocked = false
      this.paibanData.forEach((item) => {
        if (this.activePlank && item.stockKey == this.activePlank.stockKey) {
          isLocked = item.isLocked
        }
      })
      return isLocked
    },
    hasHighGlossPlank() {
      const combinedParts = this.paibanData.reduce((acc, currentItem) => {
        if (currentItem.parts) {
          acc.push(...currentItem.parts)
        }
        return acc
      }, [])
      const hasHighGlossPlank = combinedParts.some(
        (item) => item.is_high_gloss_plank
      )
      return hasHighGlossPlank
    },
    showHighGlossTipType() {
      let flag
      if (this.isHistoryStatus) {
        flag = this.historyOrder.plank_info.highgloss_direction
          ? this.historyOrder.plank_info.highgloss_direction
          : 'back'
      } else if (!this.isHistoryStatus) {
        flag = this.ncSetting.highgloss_direction
      }
      return flag
    },
    getHighgloss_direction() {
      let highgloss_direction
      if (this.isHistoryStatus) {
        highgloss_direction = this.historyOrder.plank_info.highgloss_direction
          ? this.historyOrder.plank_info.highgloss_direction
          : 'back'
      } else {
        highgloss_direction = this.highgloss_direction
      }
      return highgloss_direction
    },
    newPaibanTags() {
      return this.newTags['newPaiban']
    },

    zoomPercent() {
      return ((this.zoomNum / 1) * 100).toFixed() + '%'
    },
    subtleZoomPercent() {
      return `${(this.subtleZoomNum * 100).toFixed()}%`
    },
    // 文件夹设置
    folderSetting() {
      return this.ncSetting.customizeFolder
    },
    isSelectedSurplus() {
      let flag = false
      for (let i = 0; i < this.surplusList.length; ++i) {
        if (this.surplusList[i].isSelected) {
          this.selectedSurplus = this.surplusList[i]
          flag = true
          break
        }
      }
      if (!flag) {
        this.selectedSurplus = null
      }
      return !flag
    },
    isGlass() {
      return !!this.ncSetting.glass_setting
    },
    isShowAlignedPlan() {
      return (
        this.ncSetting.glass_setting &&
        this.$store.state.paibanStore.paibanDataCollect.length
      )
    },
    isWebToQt() {
      return Boolean(window.webToQt)
    },

    hasEmtryPlank() {
      let flag = false
      // 如果数据中都是空白大板也不允许排序
      let hasSmallParts = false // 是否有小板存在
      for (let i = 0; i < this.drawData.length; i++) {
        for (let j = 0; j < this.drawData[i].data.length; j++) {
          if (this.drawData[i].data[j].parts.length > 0) {
            hasSmallParts = true
          }
        }
      }
      if (!hasSmallParts) {
        flag = true
      }
      return flag
    },
    showPlankNum() {
      let str = this.activePlank.oriPlankNum
      if (this.ncSetting.genSimpleLabelBarcode) {
        str = this.activePlank.simplePlankNum ?? ''
      }
      return str
    },
    showPlankRemark() {
      return this.plankRemarks
        ? this.plankRemarks
        : this.activePlank.plank_remarks
    },
    finishPercent() {
      let percent =
        (this.ncTaskList.filter((item) => item.endDone).length /
          this.ncTaskList.length) *
        100
      return parseInt(percent)
    },
    // 根据每个小板上的错误信息获取到所有的错误信息
    allErrInfos() {
      return this.notFoundKnivesInfo
        .map((errObj) => {
          return errObj.errorInfo
        })
        .flat(1)
    },
    // 处理处排版用料数据
    materialData() {
      return this.dealUseMaterialData(
        this.dealDrawDataToTargetData(this.drawData)
      )
    },
  },
  watch: {
    // 监听板件是否全部锁定
    drawData: {
      handler(newVal) {
        let lockedPlank = []
        newVal.forEach((planks) => {
          planks.data.forEach((item) => {
            if (item.isLocked) {
              lockedPlank.push(item)
            }
          })
        })
        this.isBanRelayout = lockedPlank.length === this.oriArrLen
        // 更新孔槽深度异常数据
        this.handleCollectDepthErrorData(newVal)
        let surplusCount = 0
        if (this.drawData.length) {
          this.recordDrawPlankDataLength = 0
        }
        for (let item of this.drawData) {
          item.data.forEach(
            (it) => (this.recordDrawPlankDataLength += it.parts.length)
          )
          surplusCount =
            surplusCount +
            item?.data?.filter((it) => {
              return it.surplusInfo && !it.surplusInfo.isNotSurplus
            }).length
        }
        this.surplusCount = surplusCount
        this.setIsCanPrintTag(this.judgeFlag())
      },
      deep: true,
      immediate: true,
    },
    plankKeyWord(val) {
      if (val) {
        this.isEmptyInput = false
      } else {
        this.searchPlankList = []
        this.searchPlankIndex = -1
        this.isEmptyInput = true
      }
    },
    showSubtle(val) {
      // 精细排版的显隐，分别控制排版页事件的重新监听和移除
      for (let i = 0; i < this.drawData.length; i++) {
        if (val) {
          this.isShowzancun = false
          window.removeEventListener('keydown', this.keyDownEvent)
          if (this.$refs[`drawpart${i}`])
            this.$refs[`drawpart${i}`][0].clearWindowEvent()
        } else {
          window.addEventListener('keydown', this.keyDownEvent)
          if (this.$refs[`drawpart${i}`])
            this.$refs[`drawpart${i}`][0].addWindowEvent()
          this.dealOptimizeArea(this.drawData)
        }
      }
    },
    tagTempList: {
      immediate: true,
      deep: true,
      handler() {
        this.currentTagTempID = this.initTagTempID()
      },
    },
    activePlank(val) {
      this.handleCollectConflicts()
      if (val?.holeSlotMerge) {
        store.commit('setrecordCurrentConflictBoard', {
          ...val,
        })
        const index = this.conflictPlankList.findIndex(
          (i) => i.partUniqueId === val.partUniqueId
        )
        this.conflictIndex = index > -1 ? index : 0
        this.conflictPart = val
      }
    },
    currentTagType: {
      immediate: true,
      deep: true,
      handler(TagType) {
        this.ncTaskList = []
        this.ncLifeFuncObj = {}
        this.ncLifeFuncObj = initNcLifeObj(
          this,
          TagType,
          this.isFollowExportTag
        )
      },
    },
  },
  methods: {
    ...mapMutations([
      'setSurplusAutoTailor',
      'setLongSideRect',
      'setShortSideRect',
      'setPaibanExtraInfo',
      'setPreLayoutData',
      'setOrderInfo',
      'setCodeIds',
      'setPaibanData',
      'setNcSetting',
      'setBigPartsInfo',
      'changeHistoryStatus',
      'changeDataFrom',
      'changeLblAuth',
      'addTempStorage',
      'changeDragingPlank',
      'recordBeDraggedPlank',
      'setSupplusIndex',
      'deleteTempStorage',
      'changeTempStorage',
      'resetTempStorage',
      'setFinalDrawData',
      'setTempImgPos',
      'setActivePaibanWay',
      'deleteSurplusParams',
      'setPaibanTime',
      'setSurplusTailorWay',
      'setHasPrintTag',
      'setRecodeIsTailor',
      'setOffsetPointbetweenCacheImage',
      'setIsResetPaibanAllocation',
      'setPaibanKey',
      'setFactoryId',
      'setTempList',
      'setOpenSingleFile',
      'setHighglossDirection',
      'setHasRollPoly',
      'setIsSubPrintTag',
      'setIsPrintTag',
      'setSurplusNoRoll',
      'setProduceTime',
      'setBatchPaiban',
      'setIsNewPaiban',
      'setHistorySecondTrimValue',
      'setHistoryPlankEdgeOff',
      'setNewNCRes',
      'setPaibanInfo',
      'setFilterMaterialList',
      'setFilterObject',
      'setBeforeSearchMaterialList',
      'setBeforeSizeFilterList',
      'setIsTrialProduct',
      'setHistoryOrder',
      'setHasSendToTask',
      'setRecodeSelectPart',
      'setRecodeSelectSurplus',
      'setIsShowSample',
      'setThinkerxMaterialKeys',
      'setSampleTableData',
      'setSurplusParams',
      'setIsCanPrintTag',
      'setPreLayoutSetting',
      'setAllKnifes',
      'setSelectSurplusList',
    ]),
    // 批量补件
    handleBatchBujian() {
      this.isBatchBujian = true
      this.batchBujianList = []
      this.plankKeyWord = ''
      this.searchPlank('')
      this.clearAllActive()
    },
    // 关闭批量补件
    handleCloseBatchBujian() {
      this.isBatchBujian = false
      this.batchBujianList = []
      this.clearAllActive()
      this.renderAll()
    },
    // 确认批量补件
    async handleConfirmBatchBujian() {
      if (this.batchBujianList.length) {
        this.loadingPrompts = 'arrangedPage.addToBujianStore'
        this.isBatchBujianLoading = true
        const plankFileData = this.batchBujianList.map((part) => {
          const rePart = this.preLayoutData.find(
            (p) =>
              p.partUniqueId == part.partUniqueId ||
              p.partUniqueId == part.oriPartUniqueId ||
              // 第三种情况纯属意外出现
              (p.oriPartUniqueId &&
                part.oriPartUniqueId &&
                p.oriPartUniqueId === part.oriPartUniqueId)
          )
          if (!rePart) {
            return undefined
          }
          return {
            ...rePart,
            plankNum: part.plankNum,
            amount: 1,
          }
        })
        uploadFiles(
          {
            plank: plankFileData.filter((part) => part),
          },
          'plankFiles',
          async (re) => {
            const plankUrl = re
            const payload = this.batchBujianList.reduce(
              (res, cur) => {
                const rePart = this.preLayoutData.find(
                  (p) =>
                    p.partUniqueId == cur.oriPartUniqueId ||
                    p.oriPartUniqueId == cur.oriPartUniqueId
                )
                if (rePart) {
                  const bujianData = dealBatchBujian(cur, plankUrl)
                  res.supplements_info.push(bujianData)
                }
                return res
              },
              {
                supplements_info: [],
                reason: '',
                duty: '',
                library_type: LibraryTypeJS.bujianStore,
              }
            )
            const res = await batchBujian(payload)
            this.isBatchBujianLoading = false
            buryPointApi('layout', 'batchSuplement')
            if (!res.status) {
              this.$message.error(res.msg)
            } else {
              this.$message.success(translate('arrangedPage.storedSuccess'))
              this.handleCloseBatchBujian()
            }
          }
        )
      } else {
        this.$message.error(translate('arrangedPage.selectTip'))
      }
    },
    // 获取大板信息并更改激活状态
    getBigPart(bigPart) {
      this.drawData.forEach((item) => {
        item.data.forEach((plank) => {
          plank.isActive = plank.stockKey == bigPart.stockKey
        })
      })
    },
    changeLockedStatus(drawPart) {
      this.drawData.forEach((item) => {
        item.data.forEach((item) => {
          if (item.stockKey == drawPart.stockKey) {
            item.isLocked = drawPart.isLocked
          }
        })
      })
      let lockedPlank = []
      this.drawData.forEach((planks) => {
        planks.data.forEach((item) => {
          if (item.isLocked) {
            lockedPlank.push(item)
          }
        })
      })
      this.isBanRelayout = lockedPlank.length == this.oriArrLen
    },
    handleCancle() {
      this.isShowLackKnivesDialog = false
    },
    handleSeeDetails() {
      this.handleCollectConflicts()
      this.isShowConflictDiagram = true
    },
    handleCloseConflictDiagram() {
      this.isShowConflictDiagram = false
      this.conflictIndex = 0
    },

    /**
     * 处理大板序号
     * @param {*} currentPape 当前页
     * @param {*} countPerPage 每页有多少张大板
     */
    handleBigPartIndex(currentPape, countPerPage) {
      let index = 0
      this.drawData.forEach((item) => {
        item.data.forEach((dataItem) => {
          index++
          dataItem.canvasIndex = index
        })
      })
    },
    handleChangePage(val) {
      this.currentPape = val
    },
    handleChangePerPage(item) {
      this.countPerPage = item
      this.handleBigPartIndex(this.currentPape, this.countPerPage)
    },
    handleRecordPlankMove(val) {
      this.operateArr.push(val)
    },
    getStartNum(index) {
      let count = 0
      for (let i = 0; i < index; i++) {
        count += this.drawData[i].counts
      }
      this.drawData[index].startNum = count
      this.handleBigPartIndex(this.currentPape, this.countPerPage)
      return count
    },
    setBigPlankH(height) {
      if (this.isOnlyShowSurplus) {
        this.isOnlyShowSurplus = false
        this.handleShowSurplus()
      }
      if (height) {
        this.bigPlankH = height
      }
    },

    // 记录板件是否被拖动
    changePaibanData(isChanged) {
      this.hasChangePaiban = isChanged
    },

    // 记录顶部设置
    handleChangeTopOption() {
      this.$token(
        '/save_preferences_setting',
        {
          data: {
            isAutoPassData: this.isAutoPassData,
            isCuttingOrder: this.isCuttingOrder,
            isShowPartSize: this.isShowPartSize,
            isFollowExportTag: this.isFollowExportTag,
            currentTagTempID: this.currentTagTempID,
            // TODO 暂时关闭导出pdf的功能
            currentTagType: this.currentTagType,
          },
        },
        async (res) => {
          if (res.status != 1) {
            this.$message.error(res.msg)
          } else {
            if (this.isFollowExportTag) {
              buryPointFunc('下载NC顺带导出标签文件')
              if (this.currentTagType == '导出图片') {
                buryPointFunc('顺带导出图片')
              } else {
                buryPointFunc('顺带导出PDF')
              }
              await httpRequest.get('load_tag_setting', {
                id: this.currentTagTempID,
                default: 1,
              })
            }
            this.setTempList(
              this.tagTempList.map((temp) => ({
                ...temp,
                is_default: temp.id == this.currentTagTempID,
              }))
            )
          }
        }
      )
    },
    // 板件翻面
    reversePlank() {
      this.$refs[`drawpart${this.canvasKey}`][0].reverePlank()
      buryPointApi('layout', 'plate_flip')
    },
    // 选择异形嵌套
    handleSpecial() {
      this.handleChangePaibanWay('specialShape')
    },

    // 上一块板件
    handlePresiousPlank() {
      if (this.searchPlankList.length) {
        if (this.searchPlankIndex - 1 >= 0) {
          this.searchPlankIndex -= 1
        } else {
          this.searchPlankIndex = this.searchPlankList.length - 1
        }
        this.activePlankID = this.searchPlankList[this.searchPlankIndex].plankID
        this.activePlankIndex =
          this.searchPlankList[this.searchPlankIndex].index
        this.setActivePlank(this.searchPlankIndex)
      } else {
        const preIndex =
          this.plankArr.findIndex(
            (plank) =>
              plank.plankID === this.activePlank.plankID &&
              plank.index === this.activePlank.index
          ) - 1
        if (preIndex >= 0) {
          this.activePlankID = this.plankArr[preIndex].plankID
          this.activePlankIndex = this.plankArr[preIndex].index
        }
      }
    },
    // 下一块板件
    handleNextPlank() {
      if (this.searchPlankList.length) {
        if (this.searchPlankIndex + 1 < this.searchPlankList.length) {
          this.searchPlankIndex += 1
        } else {
          this.searchPlankIndex = 0
        }
        this.activePlankID = this.searchPlankList[this.searchPlankIndex].plankID
        this.activePlankIndex =
          this.searchPlankList[this.searchPlankIndex].index
        this.setActivePlank(this.searchPlankIndex)
      } else {
        const nextIndex =
          this.plankArr.findIndex(
            (plank) =>
              plank.plankID === this.activePlank.plankID &&
              plank.index === this.activePlank.index
          ) + 1
        if (nextIndex < this.plankArr.length) {
          this.activePlankID = this.plankArr[nextIndex].plankID
          this.activePlankIndex = this.plankArr[nextIndex].index
        }
      }
    },
    // 关闭AI排版的提示框
    handleCloseAITip() {
      this.showAITipDialog = false
    },
    // 显示精细排版
    gotoSubtle(data) {
      this.showSubtle = true
      this.isShowDepthTip = false
      this.subtleData = data
      this.$set(this.subtleData, 'zoomNum', 1)
      // 关闭冲突弹窗
      this.isShowConflictDiagram = false
    },
    // 关闭使用余料弹窗
    closeSurplusDialog() {
      this.surplusList = []
      this.showSurplusDialog = false
    },
    // 前往余料仓
    gotoSurplusStorage() {
      this.$router.push({
        path: '/supplusStorage',
        query: {
          addNew: 1,
        },
      })
    },
    // 显示余料匹配项
    useSurplus(data, canvasKey) {
      this.showSurplusDialog = true
      this.activeBigpart = data
      this.canvasKey = canvasKey
      // 找到所有板件中最大的横坐标和纵坐标
      let minX = 999999
      let minY = 999999
      let maxX = 0
      let maxY = 0
      for (let i = 0; i < data.parts.length; ++i) {
        let plank = data.parts[i]
        let top = plank.startY
        let left = plank.startX
        let right = plank.startX + plank.rect.width
        let bottom = plank.startY + plank.rect.height
        if (right > maxX) {
          maxX = right
        }
        if (bottom > maxY) {
          maxY = bottom
        }
        if (left < minX) {
          minX = left
        }
        if (top < minY) {
          minY = top
        }
      }
      // 如果是xy互换情况下, 则需要将用于匹配的数据进行互换
      if (this.ncSetting.xyReverse) {
        this.maxSurplusHeight = maxX - minX
        this.maxSurplusWidth = maxY - minY
        this.minSurplusX = maxY
        this.maxSurplusY = minX
      } else {
        this.maxSurplusWidth = maxX - minX
        this.maxSurplusHeight = maxY - minY
        this.minSurplusX = minX
        this.maxSurplusY = maxY
      }

      this.getSurplusList()
    },
    // 确定使用余料
    confirmUseSurplus() {
      const { color, type, thick } = this.selectedSurplus
      const stockKey = `${color}:${type}:${thick}`
      this.setSurplusParams({
        [stockKey]: [this.selectedSurplus],
      })
      this.$nextTick(() => {
        this.$refs[`drawpart${this.canvasKey}`][0].changeBigpart(
          this.selectedSurplus,
          this.minSurplusX,
          this.maxSurplusY
        )
      })
      // 锁定使用的余料
      const lockParams = {
        surplus: this.surplusList
          .filter((item) => item.id == this.selectedSurplus.id)
          .map((item) => ({
            ...item,
            lock_status: 1,
            lock_num: 1,
            lock_order: this.orderInfo?.order_address?.replace(
              /\(.*?\)|历史记录/g,
              ''
            ),
          })),
        release_all: false,
      }
      surplusLock(lockParams)
    },
    // 搜索余料
    searchSurplusList() {
      this.surplusList = []
      this.surplusPage = 1
      this.getSurplusList()
    },
    // 获取匹配的余料数据
    getSurplusList() {
      let params = {
        page: this.surplusPage,
        limit: 10,
        shape: 0,
        match_list: [
          {
            thick: Number(this.activeBigpart.thick),
            type: this.activeBigpart.matCode,
            color: this.activeBigpart.texture,
            width: this.maxSurplusWidth,
            long: this.maxSurplusHeight,
          },
        ],
      }
      if (this.surplusKeyword) {
        params.search_type = this.searchType
        params.search_value = this.surplusKeyword
      }
      this.$token('/search_surplus_depot', params, (res) => {
        if (res.status == 1) {
          if (res.data.data.length == 0 && this.surplusPage > 1) {
            this.surplusPage--
          }
          for (let i = 0; i < res.data.data.length; ++i) {
            res.data.data[i].isSelected = false
          }
          if (res.data.data.length > 0) {
            this.surplusList = [...this.surplusList, ...res.data.data]
            // 开启余料锁定 使用余料值展示未锁定
            if (this.$store.state.preferencesSetting.setting.surplus_lock) {
              this.surplusList = this.surplusList.filter(
                (item) => item.lock_status == 0
              )
            }
          } else {
            this.$message({
              type: 'info',
              message: translate('arrangedPage.noSurplus'),
            })
          }
        }
      })
    },
    // 滚动获取更多余料
    scrollSurplusList(e) {
      let scrollHeight = this.$refs.surplusList.scrollHeight
      let scrollTop = this.$refs.surplusList.scrollTop
      let clientHeight = this.$refs.surplusList.clientHeight
      if (scrollHeight - scrollTop == clientHeight) {
        this.surplusPage++
        this.getSurplusList()
      }
    },
    // 选中匹配到的余料
    changeselectedSurplus(item) {
      for (let i = 0; i < this.surplusList.length; ++i) {
        this.surplusList[i].isSelected = false
      }
      item.isSelected = true
      this.$forceUpdate()
    },
    // 添加大板
    addNewBigpart(bigpart, canvasKey) {
      let addIndex = 0
      let stockNum = 0
      // 找出现在所有大板中的最大的stockNum, 作为下一和新的大板的stockNum
      // 需要寻找所有的大板，因为在出现特殊大板和普通大板颜色材质厚度相同时会分开排，如果添加大板重新计算切割顺序会有误
      const { matCode, texture, thick } = bigpart
      this.drawData.forEach((series) => {
        series.data.forEach((item, idx) => {
          if (
            item.matCode === matCode &&
            item.texture === texture &&
            item.thick === thick
          ) {
            stockNum = Math.max(stockNum, item.stockNum)
          }
          if (item.stockKey === bigpart.stockKey) {
            addIndex = idx
          }
        })
      })
      stockNum += 1
      let plankWidth = 0
      let plankHeight = 0
      let isOtherPlate = false
      if (bigpart.otherPlate && Object.keys(bigpart.otherPlate).length > 0) {
        plankWidth = bigpart.otherPlate.width
        plankHeight = bigpart.otherPlate.height
        if (this.ncSetting.xyReverse) {
          plankWidth = bigpart.otherPlate.height
          plankHeight = bigpart.otherPlate.width
        }
        isOtherPlate = true
      } else {
        plankWidth = this.ncSetting.drawPlankWidth
        plankHeight = this.ncSetting.drawPlankHeight
      }
      const obj = addNewBigPlank({
        ableToggle: false,
        matCode: bigpart.matCode,
        parts: [],
        plankWidth: plankWidth,
        plankHeight: plankHeight,
        sideType: 1,
        stockKey:
          `${bigpart.texture}:${bigpart.matCode}:${bigpart.thick}:` + stockNum,
        stockNum: stockNum,
        surplusInfo: isOtherPlate ? bigpart.surplusInfo : null,
        texture: bigpart.texture,
        thick: bigpart.thick,
        usedRate: 0,
        otherPlate: isOtherPlate ? bigpart.otherPlate : null,
        margin: bigpart.margin,
      })
      this.drawData[canvasKey].data.splice(addIndex + 1, 0, obj)
      this.drawData[canvasKey].counts += 1
      this.totalBigpartCounts += 1

      for (let i = 0; i < this.drawData[canvasKey].data.length; ++i) {
        this.drawData[canvasKey].data[i].canvasIndex = i + 1
      }

      const oriPaibanData = JSON.parse(JSON.stringify(this.paibanData))
      let paibanArr = []
      for (let i = 0; i < this.drawData.length; ++i) {
        paibanArr = [...paibanArr, ...this.drawData[i].data]
      }
      this.setPaibanData(paibanArr)
      // TODO 考虑是否进行数据的重新处理  WC
      // this.dealAllDrawData()
      this.oriArr = this.paibanData
      this.oriArrLen = this.paibanData.length
      this.$refs[`drawpart${canvasKey}`][0].dealNewBigpart()
      this.operateArr.push({
        key: `大板${bigpart.canvasIndex}-添加大板${
          this.paibanData[this.paibanData.length - 1].canvasIndex
        }`,
        dataArr: [
          {
            type: 'form',
            current: {
              bigpartNum: this.paibanData.length,
            }, // 当前表单数据
            ori: {
              bigpartNum: oriPaibanData.length,
            }, // 原始表单数据
            compareMsg: [
              {
                title: '',
                keys: ['bigpartNum'],
              },
            ], // 要比较的内容
            formTextObj: {
              bigpartNum: '大板数量',
            }, // key对应的意思
            formValueObj: {}, // value对应的意思
          },
        ],
      })
    },
    // 删除大板
    deleteBigpart(bigpart, canvasKey, preLayoutData) {
      this.$confirm(
        bigpart && bigpart.parts.length > 0
          ? translate('common.confirmDeleteTip')
          : translate('common.deleteEmptyTip'),
        translate('common.warmTip'),
        {
          confirmButtonText: translate('common.confirm'),
          cancelButtonText: translate('common.cancel'),
          type: 'warning',
        }
      )
        .then(async () => {
          // 拿到当前大板的所有数据
          if (bigpart && bigpart.parts.length > 0) {
            // 将所有大板上的小板加入到待排版库中（未入库状态）
            bigpart.parts.forEach(async (item) => {
              const imgUrl = getPlankImage(item, false, {
                defaultScale,
                scale: 3,
                plankLineWidth: 1,
              })
              const data = await genAwaitStoreData(item, preLayoutData, imgUrl)
              this.$store.commit('awaitPaibanStore/setPlankToStore', data)
            })
          }
          bigpart.parts = []
          // 获取到没有排序前的大板标题信息(用来和排序后的对比减少不必要更新)
          const noShortPlankList = [...this.drawData].map((item) => item.title)
          for (let i = 0; i < this.drawData[canvasKey].data.length; ++i) {
            let _bigpart = this.drawData[canvasKey].data[i]
            if (_bigpart.stockKey === bigpart.stockKey) {
              // 如果删除的是余料大板, 则去掉记录的使用的余料大板参数
              try {
                if (
                  _bigpart.surplusInfo &&
                  Object.keys(_bigpart.surplusInfo).length > 0 &&
                  !_bigpart.surplusInfo.isNotSurplus
                ) {
                  this.deleteSurplusParams({
                    title: `${_bigpart.texture}:${_bigpart.matCode}:${_bigpart.thick}`,
                    ggid: _bigpart.surplusInfo.ggid,
                    id: _bigpart.surplusInfo.id,
                  })
                  const surRes = await getSurplusList({ page: 1, limit: 999 })
                  const allSurplusList = surRes.status ? surRes.data.data : []
                  // 解锁删除的余料大板
                  const lockParams = {
                    surplus: allSurplusList
                      .filter((item) => item.id == _bigpart.surplusInfo.id)
                      .map((item) => ({
                        ...item,
                        lock_status: 0,
                        lock_num: 1,
                      })),
                    release_all: false,
                  }
                  await surplusLock(lockParams)
                }
              } catch (error) {
                console.error(error)
              }
              this.drawData[canvasKey].data.splice(i, 1)
              break
            }
          }
          for (let i = 0; i < this.drawData[canvasKey].data.length; ++i) {
            this.drawData[canvasKey].data[i].canvasIndex = i + 1
          }

          this.drawData[canvasKey].counts -= 1
          this.totalBigpartCounts -= 1

          const oriPaibanData = JSON.parse(JSON.stringify(this.paibanData))
          let paibanArr = []
          for (let i = 0; i < this.drawData.length; ++i) {
            paibanArr = [...paibanArr, ...this.drawData[i].data]
          }
          this.setPaibanData(paibanArr)
          this.dealAllDrawData()
          this.oriArr = this.paibanData
          this.oriArrLen = this.paibanData.length
          this.$refs[`drawpart${canvasKey}`][0].dealDeleteBigpart()
          // 这个地方有问题，只考虑到更新当前canvasKey的位置的板件，但是没考虑单面板排序位置会换了，互换位置的板件还是以前的排列
          this.$refs[`drawpart${canvasKey}`][0].startDraw()
          this.finalDrawData.forEach((item, index) => {
            // 加判断的目的是为了减少不必要的更新
            if (item.title !== noShortPlankList[index]) {
              this.$refs[`drawpart${index}`][0].startDraw()
            }
          })
          this.operateArr.push({
            key: `删除大板${bigpart.canvasIndex}`,
            dataArr: [
              {
                type: 'form',
                current: {
                  bigpartNum: this.paibanData.length,
                }, // 当前表单数据
                ori: {
                  bigpartNum: oriPaibanData.length,
                }, // 原始表单数据
                compareMsg: [
                  {
                    title: '',
                    keys: ['bigpartNum'],
                  },
                ], // 要比较的内容
                formTextObj: {
                  bigpartNum: '大板数量',
                }, // key对应的意思
                formValueObj: {}, // value对应的意思
              },
            ],
          })
        })
        .catch(() => {})
    },
    // 保存精细排版方案
    savePlan() {
      this.oriArr = this.paibanData
      this.oriArrLen = this.paibanData.length
      this.setHasSavedSurplus()
      this.isBanRelayout = this.drawData[0].data[0].isLocked
      this.renderAll()
    },
    // 在精细排版中更新排版数据
    updatePaibanData() {
      this.oriArr = this.paibanData
      this.oriArrLen = this.paibanData.length
      this.renderAll()
    },
    // 记录精细排版旋转小板
    handleRecordRotatePlank(data) {
      this.operateArr.push(data)
    },
    handleRecordChangeCutOrigin(data) {
      this.operateArr.push(data)
    },
    handleRecordUpdatePriority(data) {
      this.operateArr.push(data)
    },
    handleRecordDeletePlank(data) {
      this.operateArr.push(data)
    },
    // 处理移动时有冲突板件
    handleConflictPart(part) {
      this.conflictPart = part
      this.isExistsActivePlank = !!this.getAllActive().filter((it) => it).length
    },

    // 返回开料清单
    goBackMaterial() {
      this.$router.push('/materialList')
    },
    resetDrawData(data) {
      const high_plank = []
      const big_high_plank = []
      const normal_plank = []
      const big_normal_plank = []
      for (const d of data) {
        const is_high = d.matCode.includes('高光_')
        if (is_high && d.otherPlate) {
          big_high_plank.push(d)
        } else if (is_high) {
          high_plank.push(d)
        } else if (d.otherPlate) {
          big_normal_plank.push(d)
        } else {
          normal_plank.push(d)
        }
      }
      return [
        ...high_plank,
        ...big_high_plank,
        ...normal_plank,
        ...big_normal_plank,
      ]
    },
    // 更新当前方案优化率
    // updateAlignedRate(obj) {
    //   const specialId = this.$store.state.paibanStore?.currentUsingSpecialId
    //   const alignedPlanResult =
    //     this.$store.state.paibanStore?.alreadyDealDrawData.get(specialId)
    //   if (alignedPlanResult) {
    //     alignedPlanResult.paibanExtraInfo = obj
    //   }
    // },
    // 将所有排版数据中, 按照特殊板材和正常板材分开进行处理
    dealAllDrawData(isClear = false) {
      if (isClear) {
        this.$store.commit('addLockedPlankInAll')
      }
      let paibanArr = this.paibanData
      // 记录大板张数
      this.oriArrLen = paibanArr.length
      let arr1 = [] // 正常大板
      let arr2 = [] // 特殊大板
      for (let i = 0; i < paibanArr.length; ++i) {
        if (paibanArr[i].otherPlate) {
          arr2.push(paibanArr[i])
        } else {
          arr1.push(paibanArr[i])
        }
      }
      let info1 = this.dealDrawData(arr1)
      let info2 = this.dealDrawData(arr2, true)
      let drawData = [...info1.drawData, ...info2.drawData]
      drawData = this.resetDrawData(drawData)
      this.totalUsedRate =
        (info1.totalUsedRate + info2.totalUsedRate) / this.oriArrLen
      let newPaibanArr = []
      // 记录大板的数量和翻板次数
      this.totalBigpartCounts = 0
      this.totalBigpartFanban = 0
      // 记录使用到的大板类型
      let bigPlankList = []
      for (let i = 0; i < drawData.length; ++i) {
        // 记录一个订单和房间的映射列表
        let orderIdToRoomMap = new Map()
        drawData[i].data.forEach((i) => {
          i.parts.forEach((part) => {
            if (!orderIdToRoomMap.has(part.orderId)) {
              orderIdToRoomMap.set(part.orderId, new Set())
            }
            orderIdToRoomMap.get(part.orderId).add(part.roomID)
          })
        })
        // 将映射转换为所需的结构
        let result = []
        orderIdToRoomMap.forEach((roomSet, orderId) => {
          result.push({ roomID: Array.from(roomSet), orderId: orderId })
        })
        // 合并具有相同 orderId 的对象
        let finalResult = []
        result.forEach((group) => {
          let existingGroup = finalResult.find(
            (item) => item.orderId === group.orderId
          )
          if (existingGroup) {
            existingGroup.roomID.push(...group.roomID)
            existingGroup.roomID = Array.from(new Set(existingGroup.roomID)) // 去重
          } else {
            finalResult.push(group)
          }
        })
        drawData[i].usedRate /= drawData[i].counts
        this.totalBigpartCounts += drawData[i].counts
        this.totalBigpartFanban += drawData[i].fanbanCount
        newPaibanArr = [...newPaibanArr, ...drawData[i].data]
        if (drawData[i].otherPlate) {
          bigPlankList.push({
            plankInOrderRoom: finalResult,
            // orderIds: drawData[i].data.map((i) =>
            //   i.parts.map((v) => v.orderId)
            // ),
            // roomIds: drawData[i].data.map((i) => i.parts.map((v) => v.roomID)),
            matCode: drawData[i].matCode,
            thick: drawData[i].thick,
            texture: drawData[i].texture,
            num: drawData[i].data.length,
            plankSize: `${drawData[i].otherPlate.height}*${drawData[i].otherPlate.width}`,
            otherPlankFlag: drawData[i].isManual ? 1 : 0,
            doorPlankFlag: drawData[i].hasDoor ? 1 : 0,
            type: drawData[i].type,
          })
        } else {
          const size = drawData[i].plankHeight
            ? `${drawData[i].plankHeight}*${drawData[i].plankWidth}`
            : `${drawData[i].data[0].plankHeight}*${drawData[i].data[0].plankWidth}`
          bigPlankList.push({
            plankInOrderRoom: finalResult,
            // orderIds: drawData[i].data.map((i) =>
            //   i.parts.map((v) => v.orderId)
            // ),
            // roomIds: drawData[i].data.map((i) => i.parts.map((v) => v.roomID)),
            matCode: drawData[i].matCode,
            thick: drawData[i].thick,
            texture: drawData[i].texture,
            num: drawData[i].data.length,
            plankSize: size,
            otherPlankFlag: drawData[i].isManual ? 1 : 0,
            doorPlankFlag: drawData[i].hasDoor ? 1 : 0,
            type: drawData[i].type,
          })
        }
      }
      this.setBigPartsInfo(bigPlankList)
      this.setPaibanExtraInfo({
        total_used_rate: this.totalUsedRate,
        total_fanban: this.totalBigpartFanban,
        total_big_planks: this.totalBigpartCounts,
      })

      // this.setPaibanData(newPaibanArr)
      this.oriArr = newPaibanArr
      this.setFinalDrawData(drawData)
      this.drawData = this.finalDrawData
      // 给所有板件数据添加是否锁定字段
      this.drawData.forEach((item) => {
        item.data.forEach((subItem) => {
          if (!subItem.isLocked) {
            subItem.isLocked = subItem.isActive = false
          }
          // 无标示
          subItem.parts.forEach((part) => {
            part.oriPlankNumNoMark = JSON.parse(JSON.stringify(part.plankNum))
          })
        })
      })
      this.drawDataO = JSON.parse(
        JSON.stringify(
          this.drawData.map((e) => e.data.map((x) => x.parts)).flat(3)
        )
      )
      // 记录一个用于查询的数组，查询板件的纹理状态
      const arr = JSON.parse(JSON.stringify(this.oriArr))
      this.plankArr = arr
        .map((e) => e.parts)
        .flat(1)
        .map((e) => ({ ...e, isNonTexture: e.texDir === 'notcare' }))
    },
    // 处理绘制数据
    dealDrawData(paibanData, isSpecial) {
      let paibanArr = paibanData
      // 记录所有的优化率总和
      let totalUsedRate = 0
      paibanArr.forEach((bigpart) => {
        bigpart.usedRate = calcBigpartArea(bigpart, this.ncSetting)
      })
      paibanArr = paibanArr.map((item) => {
        if (item.usedRate >= 1) {
          item.usedRate = 1
        }
        return item
      })
      // hasOwnProperty仅对直接属性返回true
      if (paibanArr.some((v) => v.hasOwnProperty('_sortNum'))) {
        totalUsedRate = paibanArr.reduce(
          (prev, cur) => (prev += cur.usedRate),
          0
        )
        // 通过_sortNum的取值升序排列
        paibanArr.sort(compare('_sortNum', 'up'))
      } else {
        // 将排版数据按照优化率从大到小排列
        paibanArr.sort(compare('usedRate', 'down'))
        // 记录需要绘制正反面的大板
        let needToggleParts = []
        // 记录不需要绘制正反面的大板
        let noToggleParts = []
        // 记录材质厚度颜色的分类信息
        for (let i = 0; i < paibanArr.length; ++i) {
          let toggleFlag = false
          totalUsedRate += paibanArr[i].usedRate
          const sideArr = paibanArr[i].parts
            .filter((p) => p.specialType !== 'supplus')
            .map((part) => [
              ...part.slots,
              ...part.holes,
              ...(part.curveHoles ?? []),
              ...(part.millInfo ?? []),
              ...(part.handleSlopes ?? []),
            ])
          toggleFlag = sideArr.flat(1).every((v) => v.side == 1)
          paibanArr[i].sideType = 1
          // 通孔通槽双面加工，大板显示正反
          const holeArr = paibanArr[i].parts
            .filter((p) => p.specialType !== 'supplus')
            .map((part) => [
              ...part.holes,
              // ...(part.curveHoles ? part.curveHoles : []),
            ])
          const slotArr = paibanArr[i].parts
            .filter((p) => p.specialType !== 'supplus')
            .map((part) => [
              ...part.slots,
              ...(part.millInfo ? part.millInfo : []),
              ...(part.handleSlopes ?? []),
            ])
          const holeToggle = holeArr
            .flat(1)
            .some((v) => v.deep * 1 + 0.001 >= paibanArr[i].thick)
          const slotToggle = slotArr
            .flat(1)
            .some((v) => v.deep * 1 + 0.001 >= paibanArr[i].thick)
          // 判断了通孔/通槽分双面打穿
          if (
            !toggleFlag ||
            (this.ncSetting.throughTowSideToCut && holeToggle) ||
            (this.ncSetting.through_slot_two_side_cut && slotToggle)
          ) {
            // 记录是否可以切换正反
            paibanArr[i].ableToggle = true
            this.totalFanbanCount++
            needToggleParts.push(paibanArr[i])
          } else {
            // 记录是否可以切换正反
            paibanArr[i].ableToggle = false
            noToggleParts.push(paibanArr[i])
          }
        }
        // 整合两个数组
        paibanArr = [...needToggleParts, ...noToggleParts]
      }
      let drawData = []
      // 对数据进行材质颜色厚度的分类
      for (let i = 0; i < paibanArr.length; ++i) {
        let title = `${paibanArr[i].texture}:${paibanArr[i].matCode}:${paibanArr[i].thick}`
        paibanArr[i]._sortNum = i + 1
        if (isSpecial) {
          title += `:${paibanArr[i].otherPlate.width}:${paibanArr[i].otherPlate.height}`
        }
        if (i == 0) {
          let obj = {
            matCode: paibanArr[i].matCode,
            thick: paibanArr[i].thick,
            texture: paibanArr[i].texture,
            title: title,
            data: [paibanArr[i]],
            fanbanCount: paibanArr[i].ableToggle ? 1 : 0,
            counts: 1,
            usedRate: paibanArr[i].usedRate,
            isManual: paibanArr[i].parts.some((v) => v.isManual),
            hasDoor: paibanArr[i].parts.some((v) => v.type == 'SingleDoor'),
            otherPlate: paibanArr[i].otherPlate,
            type: paibanArr[i].surplusInfo ? 'surplus' : '',
          }
          if (isSpecial) {
            obj.otherPlateW = obj.otherPlate.width
          }
          drawData.push(obj)
        } else {
          let hasClassify = false
          for (let k = 0; k < drawData.length; ++k) {
            if (drawData[k].title == title) {
              drawData[k].data.push(paibanArr[i])
              if (!drawData[k].isManual) {
                drawData[k].isManual = paibanArr[i].parts.some(
                  (v) => v.isManual
                )
              }
              if (!drawData[k].hasDoor) {
                drawData[k].hasDoor = paibanArr[i].parts.some(
                  (v) => v.type == 'SingleDoor'
                )
              }
              drawData[k].counts += 1
              drawData[k].usedRate += paibanArr[i].usedRate
              if (paibanArr[i].ableToggle) {
                drawData[k].fanbanCount += 1
              }
              hasClassify = true
              break
            }
          }
          if (!hasClassify) {
            let obj = {
              matCode: paibanArr[i].matCode,
              thick: paibanArr[i].thick,
              texture: paibanArr[i].texture,
              title: title,
              data: [paibanArr[i]],
              fanbanCount: paibanArr[i].ableToggle ? 1 : 0,
              counts: 1,
              usedRate: paibanArr[i].usedRate,
              isManual: paibanArr[i].parts.some((v) => v.isManual),
              hasDoor: paibanArr[i].parts.some((v) => v.type == 'SingleDoor'),
              otherPlate: paibanArr[i].otherPlate,
            }
            if (isSpecial) {
              obj.otherPlateW = obj.otherPlate.width
            }

            drawData.push(obj)
          }
        }
      }
      if (isSpecial) {
        drawData.sort(compare('thick', 'down', 'otherPlateW', 'up'))
      } else {
        drawData.sort(compare('thick', 'down'))
      }
      // 按规定的板件顺序来控制展示的板件顺序
      for (const plank of drawData) {
        plank.data = this.resetPlankOrder(plank.data, [
          'highligh',
          'big_highligh',
          'surplus',
          'normal',
          'big_normal',
        ])
      }
      return {
        totalUsedRate,
        drawData,
      }
    },
    /**
     * 根据优先级排序板件
     * @param { object[] } oriPlank 需要排序的板件数组
     * @param { string | number [] } priorityRule 排序应用的优先级规则
     */
    resetPlankOrder(oriPlank, priorityRule) {
      const d = {}
      let result = []
      for (const plank of oriPlank) {
        const plankType = this.backPlankType(plank)
        if (!d[plankType]) {
          d[plankType] = [plank]
        } else {
          d[plankType].push(plank)
        }
      }
      for (const k of priorityRule) {
        if (d[k]) {
          result = result.concat(d[k])
        }
      }
      return result
    },
    backPlankType(plank) {
      let type
      const part = plank.parts[0] ?? {}
      if (part.is_high_gloss_plank && part.otherPlate) {
        type = 'big_highligh'
      } else if (part.is_high_gloss_plank) {
        type = 'highligh'
      } else if (plank.surplusInfo) {
        type = 'surplus'
      } else if (part.otherPlate) {
        type = 'big_normal'
      } else {
        type = 'normal'
      }
      return type
    },
    // 记录选中的板件
    async recordActivePlank(plank, canvasKey, bigPart) {
      if (bigPart) {
        this.bigPart = bigPart
      }
      if (plank) {
        if (this.isBatchBujian) {
          if (plank.specialType && plank.specialType === 'supplus') {
            this.$message({
              message: translate('arrangedPage.selectErrTip'),
              type: 'error',
            })
          } else {
            if (
              this.batchBujianList.every(
                (part) => part.partUniqueId !== plank.partUniqueId
              )
            ) {
              this.batchBujianList = [...this.batchBujianList, plank]
            } else {
              this.batchBujianList = this.batchBujianList.filter(
                (part) => part.partUniqueId !== plank.partUniqueId
              )
            }
          }
        }
        this.activePlank = plank
        const part = this.plankArr.find(
          (e) => e.partUniqueId === plank.partUniqueId
        )
        if (part) {
          this.isNonTexture = part.isNonTexture
        } else {
          this.isNonTexture = false
        }
      } else {
        this.isNonTexture = false
      }
      if (plank) {
        this.activePlank = plank
        this.canvasKey = canvasKey
        this.$nextTick(() => {
          if (this.$refs.paibanQRcode) {
            this.$refs.paibanQRcode.innerHTML = ''
            let paibanQRcode = new QRCode(
              document.getElementById('paiban-qrcode'),
              {
                width: 80,
                height: 80,
                colorDark: '#000000', //前景色
                colorLight: '#ffffff', //背景色
                typeNumber: 4,
                correctLevel: QRCode.CorrectLevel.H,
                text: plank.ggid,
              }
            )
            const canvas = paibanQRcode._oDrawing._elCanvas
            this.$refs.plankQRcode.innerHTML = ''
            this.$refs.plankQRcode.appendChild(canvas)
          }
        })
      } else {
        this.activePlank = null
        this.canvasKey = -1
      }
    },
    // 超出隐藏时显示tooltip
    showTooltip(value, surplus, prop) {},
    // 拖动板件时, 禁止选中文字
    forbidUserSelect(val) {
      this.isForbidUserSelect = val
    },
    // 清除所有的选中状态
    clearAllActive() {
      for (let i = 0; i < this.drawData.length; ++i) {
        this.$refs[`drawpart${i}`][0].clearLastActive()
      }
    },
    // 清除所有选中颜色
    handleClearAllStyle(activePlank) {
      for (let i = 0; i < this.drawData.length; ++i) {
        this.$refs[`drawpart${i}`][0].clearLastStyle(activePlank)
      }
    },
    // 获取活跃板件
    getAllActive() {
      let arr = []
      for (let i = 0; i < this.drawData.length; ++i) {
        arr.push(this.$refs[`drawpart${i}`][0].getActivePlank())
      }
      return arr
    },

    // 整体缩放
    changeZoomPercent(value) {
      this.canvasScale(value)
      clearTimeout(this.scaleTimer)
      this.scaleTimer = setTimeout(() => {
        this.scaleInterv = setInterval(() => {
          this.canvasScale(value)
        }, 100)
      }, 300)
    },
    // 缩放方法
    canvasScale(value, isReset) {
      this.$nextTick(() => {
        if (!this.showSubtle) {
          if (this.zoomNum - 0.5 > 0.0001 || value !== -0.1) {
            this.zoomNum += value
          } else {
            return
          }

          for (let i = 0; i < this.drawData.length; ++i) {
            let elInfo =
              this.$refs[`drawpart${i}`][0].$el.getBoundingClientRect()
            let event = {
              wheelDelta: value > 0 ? 20 : -20,
              clientX: elInfo.height / 2,
              clientY: elInfo.width / 2,
            }
            this.$refs[`drawpart${i}`][0].scaleCanvas(event, value, isReset)
          }
        } else {
          if (this.subtleZoomNum - 0.5 > 0.0001 || value !== -0.1) {
            this.subtleZoomNum += value
          } else {
            this.subtleData.zoomNum = this.subtleZoomNum
            this.$set(this.subtleData, 'zoomNum', this.subtleZoomNum)
            return
          }

          this.subtleData.zoomNum = this.subtleZoomNum
          this.$set(this.subtleData, 'zoomNum', this.subtleZoomNum)
          this.finalDrawData.forEach((drawData) => {
            drawData.data.forEach((bigPart) => {
              if (bigPart.stockNum == this.subtleData.stockNum) {
                bigPart.zoomNum = this.subtleData.zoomNum
              }
            })
          })
          let elInfo =
            this.$refs['subtleRef'].$refs[
              'subtleCanvas'
            ].getBoundingClientRect()
          let event = {
            wheelDelta: value > 0 ? 20 : -20,
            clientX: elInfo.height / 2,
            clientY: elInfo.width / 2,
          }
          this.$refs['subtleRef'].scaleCanvas(event, value, isReset)
        }
      })
    },
    handleResetScale() {
      this.zoomNum = 1
      if (this.subtleData) this.subtleData.zoomNum = 1
      this.canvasScale(0, true)
    },
    // 余料缩放倍率处理
    wheelScale(value) {
      this.subtleZoomNum = value
      this.$set(this.subtleData, 'zoomNum', this.subtleZoomNum)
    },
    // 精细排版切换大板时
    changeBigpart(bigPart) {
      this.subtleZoomNum = bigPart.zoomNum ? bigPart.zoomNum : 1
    },
    // 滚轮事件
    scrollCanvas() {
      this.$nextTick(() => {
        for (let i = 0; i < this.drawData.length; ++i) {
          let elInfo = this.$refs[`drawpart${i}`][0].$el.getBoundingClientRect()
          this.$refs[`drawpart${i}`][0].scrollCanvas(elInfo)
        }
      })
    },
    // 处理数据
    dealPaibanData() {
      this.dealAllDrawData()
      // 如果是从老板良&&好帮手跳转过来的则自动保存一次
      if (this.$route.query.from) {
        if (this.$route.query.from == 'lbl') {
          this.paibanWayName = this.orderInfo.order_address || ''
          this.savePaiban()
        }
      }
      // 监听可视区域变化
      this.resizeEvent = this.calcCanvasWidth()
      window.addEventListener('resize', this.resizeEvent)
    },
    // 计算可视区域的宽高
    calcCanvasWidth() {
      return (e) => {
        let windowWidth = document.body.offsetWidth
        let windowHeight = document.body.offsetHeight
        this.canvasMaxWidth = windowWidth - 650 < 988 ? 988 : windowWidth - 650
        this.canvasMaxHeight = windowHeight - 80
      }
    },
    // 搜索板件 (这里的index 是打开详情点击上一个和下一个传过来的)
    searchPlank(value, index = this.conflictIndex, type = '') {
      this.searchPlankList = []
      this.searchPlankIndex = -1
      this.isEmptyInput = true
      if (!this.plankKeyWord && !value) {
        this.recordActivePlank(null)
        this.clearAllActive()
      } else {
        this.isEmptyInput = false
        if (this.searchPlankList.length == 0) {
          this.clearAllActive()
          // 将符合条件的板件记录下来
          for (let i = 0; i < this.oriArrLen; ++i) {
            for (let k = 0; k < this.oriArr[i].parts.length; ++k) {
              let plank = this.oriArr[i].parts[k]
              if (plank.specialType) {
                continue
              }
              if (
                plank.plankID == this.plankKeyWord ||
                plank.partName.includes(this.plankKeyWord || value) ||
                plank.oriPlankNum == (this.plankKeyWord || value) ||
                plank.partUniqueId === value
              ) {
                this.searchPlankList.push(plank)
              }
            }
          }
          if (this.searchPlankList.length == 0) {
            this.recordActivePlank(null)
            this.$message({
              type: 'info',
              message: translate('arrangedPage.noPlank'),
            })
            return
          }
          this.searchPlankIndex = 0
          if (type === 'knivesErr') {
            this.setActivePlank(index)
          } else if (type === 'depthError') {
            this.setActivePlank(index, this.depthErrorData, true)
          } else {
            // 收集冲突数组
            if (typeof index === 'number') {
              this.setActivePlank(index, this.conflictPlankList)
              store.commit('setrecordCurrentConflictBoard', {
                ...this.conflictPlankList[index],
              })
            } else {
              // 默认激活第一个板件
              this.setActivePlank(0)
              store.commit('setrecordCurrentConflictBoard', {
                ...this.searchPlankList[0],
              })
            }
          }
        } else {
          if (type === 'depthError') {
            this.setActivePlank(index, this.depthErrorData, true)
          } else {
            this.searchPlankIndex++
            if (this.searchPlankIndex == this.searchPlankList.length) {
              this.searchPlankIndex = 0
            }
            this.setActivePlank(this.searchPlankIndex)
          }
        }
      }
    },
    // 选中板件
    setActivePlank(index, list, onlyOne = false) {
      // 先清除所有板件
      this.clearAllActive()
      let plank = list?.length ? list[index] : this.searchPlankList[index]
      if (onlyOne) {
        plank = list
      } else {
        plank = list?.length ? list[index] : this.searchPlankList[index]
      }
      this.activePlankID = 0
      this.activePlankIndex = 0
      this.activePlankID = plank.plankID
      this.activePlankIndex = plank.index
      const str = `${plank.texture}:${plank.matCode}:${plank.thick}`
      let title = plank.otherPlate
        ? str + `:${plank.otherPlate.width}:${plank.otherPlate.height}`
        : str
      for (let i = 0; i < this.drawData.length; ++i) {
        if (this.drawData[i].title == title) {
          this.activePlank = plank
          this.recordActivePlank(plank)
          this.$refs[`drawpart${i}`][0].setNewActivePlank(plank)
          this.scrollToCanvasHeight(i)
          break
        }
      }
    },
    // 滚动到激活板件的画布
    scrollToCanvasHeight(index) {
      const allPartRef = this.$refs.allParts
      let scrollH = 0
      for (let i = 0; i < index; i++) {
        const drawpartH =
          this.$refs[`drawpart${i}`][0].$refs.drawPartRef.offsetHeight + 10
        const drawpartDisplay = this.$refs[`drawpart${i}`][0].isShowPaiban
        if (drawpartDisplay) {
          scrollH += drawpartH
        } else {
          scrollH += 50
        }
      }
      this.$nextTick(() => {
        if (this.bigPlankH > 100) {
          scrollH += this.bigPlankH
        }
        // allPartRef.scrollTop(scrollH)
        allPartRef.scrollTo({
          top: scrollH,
          behavior: 'smooth',
        })
      })
    },
    /**
     * 选择普通或高级嵌套时调用的回调函数
     * @param {RadioEventTarget} target 事件对象
     * @return
     * @author zhouchi 2024-07-17 7-23由于test分支问题重新提交
     * */
    handlerRadioOption(target) {
      this.handleChangePaibanWay('specialShape')
      // 记录选择的嵌套模式
      store.commit('preferencesSetting/setSetting', {
        nesting_version: target.value === 'SENIOR' ? 2 : 1,
      })
      // 更新
      store.dispatch('preferencesSetting/updateSetting')
    },
    // 切换排版方式
    // activePaibanWay true true
    async handleChangePaibanWay(
      item,
      isRefresh = false,
      isClear = false,
      ortherOps = null
    ) {
      if (isRefresh) {
        this.isRefresh = true
      }
      this.showLoading = true
      this.handleCloseBatchBujian()
      // TODO wc 预排版处理
      // this.oriNcSetting = deepCopy(store.state.ncSetting)
      // this.oriKnifes = deepCopy(store.state.allKnifes)
      // if (!this.ncSetting.isPreLayout) {
      //   ortherOps = {}
      //   ortherOps.lineId = this.ncSetting.process_setting_id
      // } else {
      //   const { setting_value: setting } = this.preLayoutSetting
      //   const ncSetting = dealPreLayoutSetting(setting)
      //   store.commit('setNcSetting', ncSetting)
      // }
      // if (ortherOps && (ortherOps.isPreLayout || ortherOps.lineId)) {
      //   if (ortherOps.lineId > 0) {
      //     const res = await getProductionNcSetting({
      //       setting_id: ortherOps.lineId,
      //     })
      //     if (res.status > 0) {
      //       store.commit('setNcSetting', {
      //         ...res.data,
      //         process_setting_id: ortherOps.lineId,
      //       })
      //       const allKnifes = await getAllKnifes({
      //         setting_id: ortherOps.lineId,
      //       })
      //       store.commit('setAllKnifes', allKnifes.data.data)
      //     } else {
      //       this.$message.error('获取生产线设置失败，请稍后重试或联系售后！')
      //       return
      //     }
      //   }
      // }
      if (this.surplusAutoTailor) {
        this.hasSaveSurplus = false
      } else {
        this.hasSaveSurplus = true
        this.hasCut = false
      }
      if (item) {
        this.clickFlag = true
      } else {
        this.clickFlag = false
      }
      // TODO wc 待定
      const res = await checkPlankEdgeHasChanged(this.baseMaterialData)
      if (!res) {
        this.isShowTask = false
        this.isDownloadingNc = false
        this.showLoading = false
        this.isRefresh = false
        if (item == '_roll') {
          this.isLessReverse = !this.isLessReverse
        } else if (item == 'specialShape') {
          this.isSpecialShape = !this.isSpecialShape
        } else if (item == 'paiban') {
          this.selectedPaibanWay = 'paiban_v2'
        } else {
          this.selectedPaibanWay = 'paiban'
        }
        return
      }

      EventBus.$emit('handleChangePaibanWay')
      localStorage.setItem('isChange', 'isChange')
      this.searchPlankList = []
      this.plankKeyWord = ''
      let activePaibanWay = this.activePaibanWay
      this.hasChangePaiban = true
      this.setHistoryPlankEdgeOff(this.historyPlankEdgeOff)
      this.setHistorySecondTrimValue(this.ncSetting.secondTrimValue)
      if (!isRefresh) {
        if (item != 'specialShape' && !item.includes('_roll')) {
          this.selectedPaibanWay = item // 记录选择的排版方式
          if (this.isLessReverse) {
            activePaibanWay = item.includes('v2')
              ? [item.replace('v2', 'roll_v2')]
              : [item + '_roll']
          } else {
            activePaibanWay = [item]
          }
          this.isAIpaiban = item === 'blue_ai/paiban'
          this.suitText = this.paibanWayList.find(
            (e) => e.interface === item
          ).desc
        } else {
          this.selectedPaibanWay = this.activePaibanWay[0].replace('_roll', '')
          if (item === 'specialShape') {
            if (this.isSpecialShape) {
              if (!activePaibanWay.includes('specialShape'))
                activePaibanWay.push('specialShape')
            } else {
              activePaibanWay = activePaibanWay.filter(
                (e) => e !== 'specialShape'
              )
            }
          } else {
            const rollWay = this.selectedPaibanWay.includes('v2')
              ? this.selectedPaibanWay.replace('_v2', item + '_v2')
              : this.selectedPaibanWay + item

            if (this.isLessReverse) {
              if (!activePaibanWay.includes(rollWay))
                activePaibanWay[0] = rollWay
            } else {
              activePaibanWay[0] = rollWay.replace('_roll', '')
            }
          }
        }
      }
      _hmt.push(['_trackEvent', '切换排版方案', 'click', activePaibanWay])
      if (isRefresh) {
        // 关闭孔槽冲突图
        this.isShowConflictDiagram = false
        buryPointApi('layout', 'reload_layout')
      } else {
        switch (item) {
          case 'paiban':
            buryPointApi('layout', 'paiban')
            break
          case 'paiban_v2':
            buryPointApi('layout', 'paiban_v2')
            break
        }
      }
      this.setActivePaibanWay(activePaibanWay)
      let obj = {}
      let allPart = {}
      let arr = JSON.parse(JSON.stringify(this.oriArr))
      if (this.ncSetting.xyReverse) {
        arr = viewDataToNCData(arr, 'toView')
      }
      for (let i = 0; i < this.oriArrLen; ++i) {
        for (let k = 0; k < arr[i].parts.length; ++k) {
          let part = arr[i].parts[k]
          let partInPlankarr = this.plankArr.find(
            (plank) => part.partUniqueId === plank.partUniqueId
          )
          if (partInPlankarr && partInPlankarr.isNonTexture) {
            part.texDir = 'notcare'
          } else {
            part.texDir = part.srcTexDir
          }
          // 余料不参与排版
          if (part.specialType == 'supplus') continue
          if (arr[i].isLocked && isClear) continue
          let title = `${part.texture}:${part.matCode}:${part.thick}`
          let plankParam = {
            rect: {
              x1: part.rect.x,
              y1: part.rect.y,
              x2: part.rect.width,
              y2: part.rect.height,
            },
            needRoll: part.needRoll,
            rotate: part.texDir == 'notcare' ? true : false,
          }
          if (this.isSpecialShape && part.path) {
            plankParam.cutCurve = part.path
          }
          if (obj[title]) {
            obj[title] = [...obj[title], plankParam]
            allPart[title] = [...allPart[title], part]
          } else {
            obj[title] = [plankParam]
            allPart[title] = [part]
          }
        }
      }
      let preData = []
      for (let key in allPart) {
        let num = 1
        for (let k = 0; k < allPart[key].length; ++k) {
          allPart[key][k].rectInd = num
          num++
          preData.push(allPart[key][k])
        }
      }
      const objMap = new Map()
      const objKeys = Object.keys(obj)
      // 按不同刀径分开
      objKeys.forEach((key) => {
        const k = +getPlateKnifeDiameter(key, this.ncSetting)
        if (objMap.has(k)) {
          const target = objMap.get(k)
          Object.assign(target.data, { [key]: obj[key] })
        } else {
          objMap.set(k, { data: { [key]: obj[key] }, diameter: k })
        }
      })

      const paramObjList = []
      let filterSurList = []
      let oldUseSurplusId = JSON.parse(JSON.stringify(this.useSurplusId))
      const surplusPlank = this.paibanData.find(
        (item) =>
          item.surplusInfo &&
          Object.keys(item.surplusInfo).length &&
          !item.surplusInfo.isNotSurplus
      )
      // 获取使用余料
      const surplusRes = await getSurplusList({ page: 1, limit: 999 })
      const allSurplusList = surplusRes.status ? surplusRes.data.data : []
      this.useSurplusList = allSurplusList.filter((item) =>
        this.useSurplusId.includes(item.id)
      )
      objMap.forEach((it) => {
        let ncConfig = {
          changeKnifePosition: this.ncSetting.xyReverse ? true : false,
          decimal: this.ncSetting.decimal,
          gap: this.ncSetting.panelSize.layoutGap,
          knifeR: it.diameter / 2,
          outHeight: this.standardPlank.plankHeight,
          outWidth: this.standardPlank.plankWidth,
          startPosition: this.ncSetting.startPosition,
          margin: this.standardPlank.plankEdgeOff,
          xyReverse: this.ncSetting.xyReverse ? 1 : 0,
          surplus_position: this.ncSetting.surplusPosition,
          trim_side: this.ncSetting.trimSide,
          surplus_no_roll: this.isSurplusNoRoll,
          onlyLayoutTooBig: this.onlyLayoutTooBig,
        }
        // 没有开启自定义大板排版顺序 重新排版还是原来的传参
        if (!this.customPlankOrder) {
          if (Object.keys(this.specialPlankParams).length > 0) {
            ncConfig.otherPlateSize = this.specialPlankParams
            let outSizeMap = {}
            this.specialPlankParams.forEach((item) => {
              if (item.isPicked) {
                let key = `${item.color}:${item.matCode}:${item.thick}`
                outSizeMap[key] = {
                  width: item.width,
                  height: item.height,
                  trim_edge: item.trim_edge,
                }
              }
            })
            ncConfig.outSizeMap = outSizeMap
          }
        } else {
          // 开启自定义大板排版顺序
          ncConfig.customPlankOrder = this.customPlankOrder
          const standardPlank = this.plankOrderList.find(
            (item) => item.plankType == '标准大板'
          )
          if (standardPlank) {
            ncConfig.order = standardPlank.plankOrder
            ncConfig.amount = Number(standardPlank.amount)
          } else {
            ncConfig.amount = 0
          }
          // 有特殊板且数量不为0
          const plateList = this.plankOrderList.filter(
            (item) => item.plankType != '标准大板' && item.amount != '0'
          )
          if (plateList.length) {
            ncConfig.otherPlateSize = plateList.map((item) => ({
              color: item.texture,
              height: Number(item.plankSize.split('x')[1]),
              matCode: item.isHighPlank.includes('非')
                ? item.matCode
                : `高光_${item.matCode}`,
              symbol: `${item.matCode}:${item.thick}:${item.thick}`,
              thick: item.thick,
              trim_edge: item.plankEdgeOff,
              width: Number(item.plankSize.split('x')[0]),
              order: item.plankOrder,
              amount: Number(item.amount),
            }))
          }
        }
        // 只要开启了特殊大板上只排超尺小板，那么这个outSizeMap字段的值就不需要了
        if (this.onlyLayoutTooBig) {
          ncConfig.outSizeMap = {}
          // 对用到的所有特殊大板进行分组
          const newGroup = ncConfig.otherPlateSize?.reduce((acc, cur) => {
            // 这里不用cur中的symbol的原因就是因为这里的symbol可能是重复的：：：false/true，即使他们的颜色、材质、厚度不一样
            const symbol = `${cur.color}:${cur.matCode}:${cur.thick}`
            if (!acc[symbol]) {
              acc[symbol] = []
            }
            acc[symbol].push(cur)
            return acc
          }, {})
          if (newGroup) {
            // 获取到每一类板件中可以排下所有超尺小板的长边最小的特殊大板
            const otherPlateSize = Object.keys(newGroup).map((k) => {
              newGroup[k].sort((a, b) => {
                const aMax = Math.max(a.width, a.height)
                const bMax = Math.max(b.width, b.height)
                return bMax - aMax
              })
              return newGroup[k][0]
            })
            ncConfig.otherPlateSize = otherPlateSize
          }
        }
        let paibanParam = {
          layoutConfig: ncConfig,
          layoutHoleMap: {},
          layoutRectMap: it.data,
        }
        let surplusParams
        let notOrderLockList
        // 开启余料锁定 且使用了余料时 处理重新排版的余料参数
        if (
          this.$store.state.preferencesSetting.setting.surplus_lock &&
          surplusPlank
        ) {
          // 获取被其它订单锁定的余料id
          notOrderLockList = this.useSurplusList
            .filter(
              (item) =>
                item.lock_status == 1 &&
                item.lock_order !=
                  this.orderInfo?.order_address?.replace(
                    /\(.*?\)|历史记录/g,
                    ''
                  )
            )
            .map((item) => item.id)
        }
        if (
          (notOrderLockList && notOrderLockList.length) ||
          this.selectSurplusList.length
        ) {
          // 历史记录 直接剔除被其它订单锁定的余料
          if (this.isHistoryStatus) {
            surplusParams = setSurplusObj(
              arr.filter(
                (item) =>
                  !item.isLocked &&
                  !item.customSurplus &&
                  !notOrderLockList.includes(item?.surplusInfo?.id)
              )
            )
          } else {
            // 开料清单 从所选余料中剔除被其它订单锁定的余料
            filterSurList = this.selectSurplusList.filter(
              (item) => !notOrderLockList.includes(item.id)
            )
            // 更新所选余料 （拆分的情况）
            filterSurList = filterSurList.map((item) => ({
              ...item,
              amount: allSurplusList.find((useItem) => useItem.id == item.id)
                ?.amount,
            }))
            this.setSelectSurplusList(filterSurList)
            surplusParams = genSurplusParams(filterSurList)
          }
        } else {
          surplusParams = setSurplusObj(
            arr.filter((item) => !item.isLocked && !item.customSurplus)
          )
          if (!isRefresh) {
            surplusParams = setSurplusObj(
              arr.filter((item) => !item.customSurplus)
            )
          }
        }
        const paramObj = genPaibanRequestParams(this.$store, surplusParams, {
          data: paibanParam,
        })
        paramObjList.push(paramObj)
      })

      this.drawData = []
      this.showLoading = true
      // 清空收集的多排版方案
      this.$store.commit('paibanStore/setPaibanDataCollect', [])
      this.$store.commit('paibanStore/clearAlreadyDealDrawData')
      // TODO wc 预排版处理
      // await this.reLayout(this.ncSetting.process_setting_id)
      // this.activePlank = null
      // this.showLoading = false

      dealPaibanData(paramObjList, preData, this.activePaibanWay[0]).then(
        async (paibanRes) => {
          this.activePlank = null
          this.showLoading = false
          this.isRefresh = false
          // 开启自定义大板顺序 剔除被其它订单锁定的余料后 排版失败的处理
          if (
            this.customPlankOrder &&
            paibanRes?.text == '大板数量不够或超尺'
          ) {
            // '余料大板被其他订单占用，可用大板不足，请到开料清单重新排版'
            this.$message.warning(translate('arrangedPage.rePaibanWarn'))
            return
          }
          if (isClear) {
            this.$store.commit('setLockedPlank', arr)
            this.dealAllDrawData(true)
          } else {
            this.dealAllDrawData()
          }
          this.showAITipDialog = item.includes('blue_ai')
          // 调用余料自动裁剪
          this.startTailorSurplus()
          this.rateSort()
          const str = this.activePaibanWay[0].replace('_roll', '')
          const paibanWayStr = this.activePaibanWay[0].includes('roll')
            ? this.paibanWayList.find((e) => e.interface === str).name +
              '_少翻版'
            : this.paibanWayList.find((e) => e.interface === str).name
          // 重新排版使用的余料ID
          this.useSurplusId = this.paibanData
            .map(
              (item) => !item.surplusInfo?.isNotSurplus && item.surplusInfo?.id
            )
            .filter((id) => id)
          if (
            this.$store.state.preferencesSetting.setting.surplus_lock &&
            this.useSurplusId.length
          ) {
            // 锁定使用的余料 -存在重新排版后又使用了新的余料或者余料管理又去手动解锁了使用的余料
            const lockParams = {
              surplus: filterSurList
                .filter((item) => this.useSurplusId.includes(item.id))
                .map((item) => ({
                  ...item,
                  lock_status: 1,
                  lock_order: this.orderInfo?.order_address?.replace(
                    /\(.*?\)|历史记录/g,
                    ''
                  ),
                  lock_num: this.useSurplusId.filter((id) => id == item.id)
                    .length,
                })),
              release_all: false,
            }
            await surplusLock(lockParams)
            // 解锁本订单之前用了的余料 但重新排版未使用的余料
            const beforeUseId = oldUseSurplusId.filter(
              (id) => !this.useSurplusId.includes(id)
            )
            const unLockParams = {
              surplus: this.useSurplusList
                .filter(
                  (item) =>
                    beforeUseId.includes(item.id) &&
                    item.lock_order ==
                      this.orderInfo?.order_address?.replace(
                        /\(.*?\)|历史记录/g,
                        ''
                      )
                )
                .map((item) => ({
                  ...item,
                  lock_status: 0,
                  lock_num: beforeUseId.filter((id) => id == item.id).length,
                })),
              release_all: false,
            }
            await surplusLock(unLockParams)
          }
          this.$token(
            '/save_preferences_setting',
            {
              data: {
                surplusAutoTailor:
                  this.finalState.surplusAutoTailor ?? this.surplusAutoTailor,
                surplus_no_roll: this.finalState.isSurplusNoRoll,
                shortSideRect: this.finalState.shortSideRect || 250,
                longSideRect: this.finalState.longSideRect || 300,
                surplusTailorWay: this.finalState.surplusTailorWay,
                isLessReverse: this.isLessReverse,
                isSpecialShape: this.isSpecialShape,
                paibanWay: this.activePaibanWay[0],
                // paibanWay:
                //   this.selectedPaibanWay?.replace('_roll', '') ?? 'paiban_v2',
                layoutMethod: this.isSpecialShape
                  ? paibanWayStr + '_计算异形'
                  : paibanWayStr,
              },
            },
            (res) => {
              if (res.status != 1) {
                this.$message.error(res.msg)
              }
            }
          )
        }
      )
    },
    // 选择无纹理
    handleNonTexture() {
      buryPointApi('layout', 'plate_without_texture')
      this.hasChangePaiban = true
      this.activePlank.texDir = this.isNonTexture
        ? 'notcare'
        : this.activePlank.srcTexDir
      this.plankArr = this.plankArr.map((plank) => {
        if (
          plank.plankID === this.activePlank.plankID &&
          plank.index === this.activePlank.index
        ) {
          return { ...plank, isNonTexture: this.isNonTexture }
        } else {
          return plank
        }
      })
    },
    // 旋转板件
    rotatePlank() {
      if (this.activePlank) {
        this.$nextTick(() => {
          let bigpart
          for (let i = 0; i < this.drawData[this.canvasKey].data.length; i++) {
            if (
              this.drawData[this.canvasKey].data[i].stockKey ===
              this.activePlank.stockKey
            ) {
              bigpart = this.drawData[this.canvasKey].data[i]
            }
          }
          const oriActivePlank = JSON.parse(JSON.stringify(this.activePlank))
          this.$refs[`drawpart${this.canvasKey}`][0].rotatePlank()
          buryPointApi('layout', 'rotate_90')
          this.operateArr.push({
            key: '旋转小板',
            dataArr: [
              {
                type: 'form',
                current: {
                  oRectWidth: this.activePlank.oRect.width,
                }, // 当前表单数据
                ori: {
                  oRectWidth: oriActivePlank.oRect.width,
                }, // 原始表单数据
                compareMsg: [
                  {
                    title: `${this.activePlank.partName}-大板序号: ${bigpart.canvasIndex}`,
                    // `${this.activePlank.partName}-大板序号：${bigpart.canvasIndex}`
                    keys: ['oRectWidth'],
                  },
                ], // 要比较的内容
                formTextObj: {
                  oRectWidth: '成品宽度',
                }, // key对应的意思
                formValueObj: {}, // value对应的意思
              },
            ],
          })
        })
      } else {
        this.$message({
          message: '请先选中板件再进行此操作!',
          type: 'info',
        })
      }
    },
    // 重新计算下刀点和下刀顺序
    calcCutPositionOrder() {
      buryPointApi('layout', 'recalculate ')
      for (let i = 0; i < this.oriArr.length; ++i) {
        for (let k = 0; k < this.oriArr[i].parts.length; ++k) {
          if (this.oriArr[i].parts[k].isMerge) {
            this.$message({
              type: 'error',
              message:
                '重新计算下刀点失败！请检查是否有板件重叠或板件在大板外!',
            })
            return
          }
        }
      }
      let cloneOriArr = JSON.parse(JSON.stringify(this.oriArr))
      cloneOriArr = cloneOriArr.filter((item) => {
        return !item.isLocked
      })
      let {
        startPosition,
        xyReverse,
        cutDirection,
        drawPlankWidth,
        drawPlankHeight,
        surplusPosition,
      } = this.ncSetting
      const isFromHistory = this.$route.query.from === 'history_paiabn'
      const pHeight = drawPlankHeight ?? this.selectStandardPlank.plankHeight
      const pWidth = drawPlankWidth ?? this.selectStandardPlank.plankWidth
      let plankWidth = xyReverse && isFromHistory ? pHeight : pWidth
      let plankHeight = xyReverse && isFromHistory ? pWidth : pHeight

      let rectMap = {}
      // 未使用的变量不知道作用暂时注释
      // let plankOffset = Math.abs(
      //   this.ncSetting.knife.diameter / 2 -
      //     this.ncSetting.panelSize.plankEdgeOff
      // )
      let cutKnifeR = this.ncSetting.knife.diameter / 2
      const realSurplusPosition = this.calcSurplusPositionByStartPosition(
        startPosition,
        surplusPosition,
        xyReverse
      )
      for (let i = 0; i < cloneOriArr.length; ++i) {
        let title = `${cloneOriArr[i].texture}:${cloneOriArr[i].matCode}:${cloneOriArr[i].thick}`
        checkPlankDataIsCorrect(cloneOriArr[i])
        for (let k = 0; k < cloneOriArr[i].parts.length; ++k) {
          const part = cloneOriArr[i].parts[k]
          const { startX, startY } = this.calcSurplusPosition(
            realSurplusPosition,
            part,
            cutKnifeR,
            { plankWidth, plankHeight }
          )
          let partRectWidth = 0
          let partRectHeight = 0
          partRectWidth = part.rect.width
          partRectHeight = part.rect.height
          let partInfo = {
            width: partRectWidth,
            height: partRectHeight,
            // startX: part.startX + cutKnifeR,
            // startY: plankHeight - partRectHeight - part.startY + cutKnifeR,
            startX: Number(startX),
            startY: Number(startY),
            isRotated: part.isRotated ? part.isRotated : false,
            partIndex: part.index,
            stockNum: part.stockNum,
          }
          if (part.surplusInfo) {
            partInfo.surplusInfo = part.surplusInfo
          }
          if (rectMap[title]) {
            rectMap[title].push(partInfo)
          } else {
            rectMap[title] = [partInfo]
          }
        }
      }
      let obj = {
        ncConfig: {
          plankWidth: plankWidth ?? this.ncSetting.panelSize.plankWidth,
          plankHeight: plankHeight ?? this.ncSetting.panelSize.plankHeight,
          startPosition: this.calcStartPositionBySurplus(realSurplusPosition),
          // 后端要求不传该字段
          // xyReverse: xyReverse,
          // xyReverse: false, //
          cutDirection: cutDirection ?? '逆时针',
          gap: this.ncSetting.panelSize.layoutGap,
          surplus_position: realSurplusPosition,
          new_cut_sequence: Boolean(
            this.ncSetting.movePlankSetting?.newCutSequence
          ),
        },
        layoutRectMap: rectMap,
        uid: this.userInfo.id,
      }
      this.showLoading = true
      if (sessionStorage.getItem('thinkerx_material')) {
        obj['from'] = '门窗erp'
      }
      this.$token('/get_cal_priority', obj, (res) => {
        if (res.status == 1) {
          let data = res.result.data
          for (let key in data) {
            for (let i = 0; i < data[key].length; ++i) {
              let newPosition = data[key][i]
              this.changePartPosition(key, newPosition, realSurplusPosition)
            }
          }
          this.isRefresh = false
          this.showLoading = false
          for (let i = 0; i < this.drawData.length; ++i) {
            this.$nextTick(() => {
              this.$refs[`drawpart${i}`][0].renderAll()
            })
          }
        }
      })
    },

    // 根据余料摆放位置，计算排版的起始顺序（原来代码中有加减刀半径）（返回的板子的起始坐标是余料摆放位置的对角位置）
    calcSurplusPosition(
      surplusPosition,
      part,
      cutKnifeR,
      plank,
      isReverse = false
    ) {
      const { startY, startX, rect } = part
      const plankHeight = Number(plank.plankHeight)
      const plankWidth = Number(plank.plankWidth)
      const width = Number(rect.width)
      const height = Number(rect.height)
      // const realCutknifeR = isReverse ? -cutKnifeR : cutKnifeR
      const realCutknifeR = 0
      switch (surplusPosition) {
        case 'bottomLeft':
          return {
            startX: plankWidth - startX - width,
            startY: startY + realCutknifeR,
          }
        case 'bottomRight':
          return {
            startX: startX + realCutknifeR,
            startY: startY + realCutknifeR,
          }
        case 'topLeft':
          return {
            startX: plankWidth - startX - width + realCutknifeR,
            startY: plankHeight - startY - height + realCutknifeR,
          }
        case 'topRight':
          return {
            startX: startX + realCutknifeR,
            startY: plankHeight - startY - height + realCutknifeR,
          }
      }
    },
    // 根据余料摆放位置计算出起始位置
    calcStartPositionBySurplus(surplusPosition) {
      switch (surplusPosition) {
        case 'bottomLeft':
          return '右上角'
        case 'bottomRight':
          return '左上角'
        case 'topLeft':
          return '右下角'
        case 'topRight':
          return '左下角'
        default:
          return '左下角'
      }
    },
    // 根据计算位置计算出余料摆放位置
    calcSurplusPositionByStartPosition(
      startPosition,
      surplusPosition,
      isXyReverse
    ) {
      // 记录四个位置的对角位置
      const revsersePositionMap = new Map([
        ['topLeft', 'bottomRight'],
        ['bottomLeft', 'topRight'],
        ['topRight', 'bottomLeft'],
        ['bottomRight', 'topLeft'],
      ])
      const positionMap = new Map([
        ['右上角', 'topRight'],
        ['右下角', 'bottomRight'],
        ['左上角', 'topLeft'],
        ['左下角', 'bottomLeft'],
      ])
      // 如果未开启了xy轴互换，那么不处理
      // 2. 开启了xy轴互换，就分以下情况：
      // 2.1 如果起始位置和余料摆放位置相同/完全相反，那么就原样返回surplusPosition
      // 2.2 否则的话就返回余料位置的对角位置
      if (!isXyReverse) return surplusPosition
      // 判断起始位置和余料摆放位置是否相同或者完全相反
      if (
        positionMap.get(startPosition) === surplusPosition ||
        revsersePositionMap.get(positionMap.get(startPosition)) ===
          surplusPosition
      )
        return surplusPosition
      else return revsersePositionMap.get(surplusPosition)
    },

    handleChangeRemarks() {
      if (this.activePlank) {
        this.ableRewriteRemarks = false
        this.preLayoutData.forEach((nowPlank) => {
          if (this.activePlank.oriPlankNum == nowPlank.oriPlankNum) {
            nowPlank.plank_remarks = this.plankRemarks
          }
        })
        for (let i = 0; i < this.drawData[this.canvasKey].data.length; i++) {
          if (
            this.drawData[this.canvasKey].data[i].stockKey ===
            this.activePlank.stockKey
          ) {
            this.drawData[this.canvasKey].data[i].parts.forEach((nowPlank) => {
              if (
                this.activePlank.oriPlankNum == nowPlank.oriPlankNum &&
                this.activePlank.priority == nowPlank.priority
              ) {
                nowPlank.plank_remarks = this.plankRemarks
              }
            })
          }
        }
        this.$set(this.activePlank, 'plank_remarks', this.plankRemarks)
        this.plankRemarks = ''
      }
    },
    handleChangeRemarksAble(val) {
      this.ableRewriteRemarks = val
      this.plankRemarks = ''
      if (!this.plankRemarks) {
        this.plankRemarks = this.activePlank.plank_remarks
      }
    },
    handleRewriteRemarks() {
      if (!this.plankRemarks) {
        this.plankRemarks = this.activePlank.plank_remarks
      }
      this.ableRewriteRemarks = true
    },
    renderAll(isInitial) {
      this.drawData = this.finalDrawData
      if (this.drawData.length) {
        for (let i = 0; i < this.drawData.length; ++i) {
          this.$nextTick(() => {
            const drawpart = this.$refs[`drawpart${i}`][0]
            if (drawpart) {
              drawpart.startDraw()
              drawpart.renderAll()
            }
          })
        }
      }
    },
    changePartPosition(key, newPosition, realSurplusPosition) {
      const {
        xyReverse,
        drawPlankWidth,
        drawPlankHeight,
        knife,
        surplusPosition,
      } = this.ncSetting
      const isFromHistory = this.$route.query.from === 'history_paiabn'
      const pHeight = drawPlankHeight ?? this.selectStandardPlank.plankHeight
      const pWidth = drawPlankWidth ?? this.selectStandardPlank.plankWidth
      let plankHeight = xyReverse && isFromHistory ? pWidth : pHeight
      let plankWidth = xyReverse && isFromHistory ? pHeight : pWidth
      let cutKnifeR = knife.diameter / 2
      for (let i = 0; i < this.oriArr.length; ++i) {
        let title = `${this.oriArr[i].texture}:${this.oriArr[i].matCode}:${this.oriArr[i].thick}`
        if (title == key) {
          for (let k = 0; k < this.oriArr[i].parts.length; ++k) {
            let part = this.oriArr[i].parts[k]
            if (
              part.index == newPosition.index &&
              part.rect.width == newPosition.weight &&
              part.rect.height == newPosition.height &&
              part.stockNum == newPosition.stockNum
            ) {
              const { startX, startY } = this.calcSurplusPosition(
                realSurplusPosition,
                {
                  ...newPosition,
                  rect: {
                    width: newPosition.weight,
                    height: newPosition.height,
                  },
                },
                cutKnifeR,
                { plankWidth, plankHeight },
                true
              )
              part.startX = startX
              part.startY = startY
              // part.startX = newPosition.startX - cutKnifeR
              // part.startY =
              //   plankHeight + cutKnifeR - part.rect.height - newPosition.startY
              part.priority = newPosition.priority
              part.cutOrigin = newPosition.cutOrigin
            }
          }
        }
      }
    },
    // 删除板件
    deletePlank() {
      if (this.activePlank) {
        this.isVisible = true
      } else {
        this.$message({
          message: '请先选中板件再进行此操作!',
          type: 'info',
        })
      }
    },
    // 确认删除板件
    async handleConfirmDeletePlank() {
      buryPointApi('layout', 'delete_plate')
      for (let i = 0; i < this.drawData[this.canvasKey].data.length; ++i) {
        const oriBigpart = JSON.parse(
          JSON.stringify(this.drawData[this.canvasKey].data[i])
        )
        let bigpart = this.drawData[this.canvasKey].data[i]
        if (bigpart.stockKey == this.activePlank.stockKey) {
          // 找到对应的数据，将小板上的数据加入到待排版库中
          const li = bigpart.parts.find(
            (item) => item.index == this.activePlank.index
          )
          const imgUrl = getPlankImage(li, false, {
            defaultScale,
            scale: 3,
            plankLineWidth: 1,
          })
          const data = await genAwaitStoreData(li, this.preLayoutData, imgUrl)
          this.$store.commit('awaitPaibanStore/setPlankToStore', data)
          bigpart.parts.splice(
            bigpart.parts.findIndex(
              (item) => item.index == this.activePlank.index
            ),
            1
          )
          this.operateArr.push({
            key: '删除小板',
            dataArr: [
              {
                type: 'form',
                current: {
                  partsNum: bigpart.parts.length,
                }, // 当前表单数据
                ori: {
                  partsNum: oriBigpart.parts.length,
                }, // 原始表单数据
                compareMsg: [
                  {
                    title: `${this.activePlank.partName}-大板序号：${bigpart.canvasIndex}`,
                    keys: ['partsNum'],
                  },
                ], // 要比较的内容
                formTextObj: {
                  partsNum: '小板数量',
                }, // key对应的意思
                formValueObj: {}, // value对应的意思
              },
            ],
          })
          calcBigpartArea(bigpart, this.ncSetting)
          this.dealOptimizeArea(this.drawData)
          this.$message.success(translate('common.deleteSuccess'))
          this.handleJudgeAbleFanban(bigpart, this.canvasKey)
          // 如果删除板件是待排版服务器上的数据则需要将板件再显示出来
          this.$store.commit(
            'awaitPaibanStore/showPlank',
            this.activePlank.partUniqueId
          )
          break
        }
      }

      this.activePlank = null
      this.$nextTick(() => {
        // 调用渲染方法
        this.$refs[`drawpart${this.canvasKey}`][0].renderAll()
      })
      this.isVisible = false
      this.calcFanbanCount()
    },
    // 从暂存区拖拽出来时
    dragImg(e, item, index) {
      this.changeDragingPlank(true)
      this.$nextTick(() => {
        e.preventDefault()
        let imgDom = this.$refs[`tempImg${index}`][0]
        let imgBox = imgDom.getBoundingClientRect()
        // 计算点击的图片位置, 相对于图片的位置, 并用于后续拖动板件到大板时的位置判断
        this.imgPosition = {
          x: e.offsetX / imgBox.width,
          y: e.offsetY / imgBox.height,
        }
        this.setTempImgPos(this.imgPosition)
        this.setOffsetPointbetweenCacheImage({
          x: e.offsetX,
          y: e.offsetY,
        })
        // 记录现在鼠标相对于浏览器的位置
        let screenPos = {
          x: e.clientX,
          y: e.clientY,
        }
        // 点击的时候, 在鼠标位置生成原始大小的板件图片
        let img = null
        if (this.tempImg) {
          img = this.tempImg
        } else {
          img = new Image()
        }
        img.src = item.imgUrl
        new Promise((resolve) => {
          img.onload = function () {
            resolve({
              x: this.width,
              y: this.height,
            })
          }
        }).then((res) => {
          // 计算原始图片大小, 相当于从鼠标位置进行缩放
          let x = this.imgPosition.x * res.x
          let y = this.imgPosition.y * res.y
          this.imgRealSize = res
          let left = screenPos.x - x
          let top = screenPos.y - y
          // 记录图片缩放后鼠标相对于图片的偏移值
          this.setOffsetPointbetweenCacheImage({
            x: e.x - left,
            y: e.y - top,
          })
          // 设置其位置
          img.style.position = 'fixed'
          img.style.opacity = '0.7'
          img.style.left = left + 'px'
          img.style.top = top + 'px'
          img.style.display = 'block'
          img.style.zIndex = 1000
          // 挂载在页面上
          if (!this.tempImg) {
            document.body.appendChild(img)
          }
          this.tempImg = img
          this.mouseMoveEvent = this.mouseMoveFunc()
          window.addEventListener('mousemove', this.mouseMoveEvent)
          let that = this
          this.tempImg.onmouseup = function () {
            that.tempImg.style.display = 'none'
            window.removeEventListener('mousemove', that.mouseMoveEvent)
          }
        })

        this.recordBeDraggedPlank(item)
      })
    },
    mouseMoveFunc() {
      return (e) => {
        if (this.tempImg) {
          e.preventDefault()

          let x = this.imgPosition.x * this.imgRealSize.x
          let y = this.imgPosition.y * this.imgRealSize.y
          let left = e.clientX - x
          let top = e.clientY - y

          this.tempImg.style.left = left + 'px'
          this.tempImg.style.top = top + 'px'
        }
      }
    },
    // 显示保存排版的弹窗
    showSavePaiban() {
      if (this.beforeCommonCheck()) {
        return
      }
      _hmt.push(['_trackEvent', '保存排版方案', 'click'])
      buryPointApi('layout', 'save_history')
      this.showSaveDialog = true
      let date = new Date()
      let second = ('0' + date.getSeconds()).substr(-2, 2)
      let minute = ('0' + date.getMinutes()).substr(-2, 2)
      let hour = ('0' + date.getHours()).substr(-2, 2)
      let day = ('0' + date.getDate()).substr(-2, 2)
      let month = ('0' + (date.getMonth() + 1)).substr(-2, 2)
      let year = date.getFullYear()
      let name = `历史方案 ${year}-${month}-${day} ${hour}:${minute}:${second}`
      const noArr = dealGuimenFGNo(this.preLayoutData)
      let orderName = noArr.join(',')
      this.paibanWayName = orderName ?? name
    },
    // 前往标签
    async gotoTagLabel(bigpart = null, isSubPrintTag = false) {
      if (this.ncSetting.isPreLayout) {
        this.checkSettingChange()
        return
      }
      if (this.beforeCommonCheck()) {
        return
      }
      this.setIsPrintTag(true)
      _hmt.push(['_trackEvent', '打印标签', 'click'])
      buryPointApi('layout', 'to_tag')
      this.setIsSubPrintTag(isSubPrintTag)
      if (this.checkIsEmptyPlank()) return
      // if (this.isOnlyShowSurplus) {
      //   this.isOnlyShowSurplus = false
      //   this.handleShowSurplus()
      // }
      try {
        if (needApartPackUser.includes(this.userInfo.phone)) {
          this.loadingText = '正在加载预分包信息,请稍等...'
          this.isDownloadingNc = true
          const paibanData = await dealLblApartPackageCount(
            this.$store.state.preLayoutData,
            this.$store.state.paibanData,
            this.factoryId
          ).catch((err) => {
            this.$message.error(err)
          })
          if (paibanData) {
            this.setPaibanData(paibanData)
          }
        }
      } catch (err) {
        this.$message.error(err)
      } finally {
        this.setHasPrintTag(true)
        this.surplusBigpart = bigpart
        this.isDownloadingNc = false
        this.isTimeOut = false
        this.loadingText = '正在努力加载中......'
        this.$router.push({
          name: 'printTag',
          params: {
            bigpart,
            stockKey: bigpart.stockKey,
          },
        })
      }
    },
    // 部分操作公用的预检查预检查
    beforeCommonCheck() {
      let isReturn = false
      if (!this.checkAwaitLocalStore()) {
        isReturn = true
      }
      return isReturn
    },
    gotoTagLabelNew() {
      _hmt.push(['_trackEvent', '打印标签', 'click'])
      this.$router.push('/printTagNew')
    },
    checkIsEmptyPlank(msg = '标签打印') {
      const panbanDataArr = JSON.parse(
        JSON.stringify(this.$store.state.paibanData)
      )
      const emptyPlanks = panbanDataArr.filter((item) => !item.parts.length)
      if (emptyPlanks.length) {
        const str = [...new Set(emptyPlanks.map((item) => item.stockKey))].join(
          '---'
        )
        this.$message.error(
          `存在${emptyPlanks.length}块空板<${str}>,请处理后再进行${msg}!`
        )
        return !!emptyPlanks.length
      }
      if (!panbanDataArr.length) {
        this.$message.error(`${translate('arrangedPage.noPaibanData')}${msg}!`)
        return !panbanDataArr.length
      }
    },
    // 保存排版, isNcFile: 是否为下载nc时自动保存的数据
    savePaiban(isNcFile = false, isNCSave = false, src = '') {
      if (!isNcFile && !isNCSave) {
        this.isSavePaiBanLoading = true
      }
      if (this.paibanWayName == '' && !isNcFile && !isNCSave) {
        if (!isNcFile && !isNCSave) {
          this.isSavePaiBanLoading = false
        }
        this.$message({
          message: translate('arrangedPage.historyNameErr'),
          offset: 60,
          duration: 1500,
          type: 'error',
        })
      } else {
        const paibanData = JSON.parse(JSON.stringify(this.oriArr))
        paibanData.forEach((e) => {
          e.parts.forEach((part) => {
            let partInPlankarr = this.plankArr.find(
              (plank) =>
                part.plankID === plank.plankID && part.index === plank.index
            )
            if (partInPlankarr && partInPlankarr.isNonTexture) {
              part.texDir = 'notcare'
            } else {
              part.texDir = part.srcTexDir
            }
          })
        })

        const recordData = {
          key: '', // 操作记录最外层的名字，比如：雕刻机
          operateArr: this.operateArr,
        }

        const time = new Date()
        const jsonFileName =
          '/bluenMaterialData/' +
          time.getTime() +
          '_' +
          this.paibanWayName +
          '.json'

        this.$getByToken('/get_oss_token', {}, async (res) => {
          if (res.status == 1) {
            let client = new OSS({
              region: 'oss-cn-hangzhou',
              accessKeyId: res.data.AccessKeyId,
              accessKeySecret: res.data.AccessKeySecret,
              stsToken: res.data.SecurityToken,
              bucket: 'eggrj',
            })
            // 处理保存历史的json文件数据
            const { jsonFile } = await this.dealJsonFile(jsonFileName)
            // 上传保存历史的json文件
            const response = await client.multipartUpload(
              jsonFileName,
              jsonFile
            )
            // 成功后拼接保存历史的json文件url
            if (response.res.status == 200) {
              let url =
                'https://eggrj.oss-cn-hangzhou.aliyuncs.com' + jsonFileName
              // 保存历史接口调用
              this.saveMaterialPaiban(url, isNcFile, isNCSave, src)
              genFinalRecord(recordData, `排版页-${this.paibanWayName}`)
              if (!isNcFile && !isNCSave) {
                this.isSavePaiBanLoading = false
              }
            } else {
              if (!isNcFile && !isNCSave) {
                this.isSavePaiBanLoading = false
              }
              this.$message({
                type: 'error',
                meesage: '上传失败!',
                duration: 1500,
                offset: 60,
              })
            }
          } else {
            if (!isNcFile && !isNCSave) {
              this.isSavePaiBanLoading = false
            }
          }
        })
      }
    },
    async saveMaterialPaiban(
      url,
      isNcFile,
      isNCSave = false,
      src = '',
      otherData = null
    ) {
      let plankList = this.bigPartsInfo
      // let plankList = this.bigPartsInfo.map((item) => {
      //   return {
      //     ...item,
      //     roomIds: [...new Set(item.roomIds.flat())],
      //     orderIds: [...new Set(item.orderIds.flat())],
      //   }
      // })
      let usedRate = this.paibanExtraInfo.total_used_rate
      let oids = this.orderInfo.order_ids
        ? this.orderInfo.order_ids.split(',').map(Number)
        : []
      const str = this.activePaibanWay[0].replace('_roll', '')
      const paibanWayStr = this.activePaibanWay[0].includes('roll')
        ? this.paibanWayList.find((e) => e.interface === str).name + '_少翻版'
        : this.paibanWayList.find((e) => e.interface === str).name
      const highgloss_direction = this.getHighgloss_direction
      let obj = {
        name: this.paibanWayName,
        from: this.$route.query.from,
        plank_info: {
          historyUrl: url,
          plankTypeList: plankList,
          used_rate: usedRate,
          hasSaveSurplus: this.hasSaveSurplus,
          process_setting_name: this.ncSetting
            ? this.ncSetting.process_setting_name
            : '',
          order_codes: this.orderInfo.order_codes,
          isLessReverse: this.isLessReverse,
          isSpecialShape: this.isSpecialShape,
          paibanWay: this.activePaibanWay[0],
          layoutMethod: this.isSpecialShape
            ? paibanWayStr + '_计算异形'
            : paibanWayStr,
          paibanKey: this.paibanKey,
          filterObj: this.filterObject,
          highgloss_direction,
          process_setting_id: this.ncSetting.process_setting_id ?? -2,
          process_setting_name:
            this.ncSetting.process_setting_name ??
            this.$store.state.recodrProductionLineChoose ??
            this.$t('preLayoutSetting.preWayEmpty'),
          preLayoutSettingUrl:
            otherData && otherData.preLayoutSettingUrl
              ? otherData.preLayoutSettingUrl
              : undefined,
          batch: this.orderInfo.batch
            ? Object.keys(this.orderInfo.batch).every((key) => {
                return this.orderInfo.batch[key] > 0
              })
            : false,
        },
        process_setting_id: this.ncSetting.process_setting_id ?? -2,
        order_list: oids,
        file_type: 'paiban',
        save_node: !this.showSaveDialog && this.isFollowExportTag ? 3 : 2,
        generate_batch: this.orderInfo.batch
          ? Object.keys(this.orderInfo.batch).every((key) => {
              return this.orderInfo.batch[key] > 0
            })
          : false,
      }
      if (isNcFile) {
        let addressArr = []
        this.$store.state.preLayoutData.forEach((part) => {
          const str = part.groupNo ?? part.address
          if (!addressArr.includes(str)) {
            addressArr.push(str)
          }
        })
        obj.plank_info.isNcFile = src

        // 历史方案名称
        let name =
          this.isAutoPassData && isNCSave
            ? `${this.paibanWayName}_FromWebPage_生产线(${
                this.ncSetting?.process_setting_name ?? ''
              })`
            : `${addressArr.join('+')}_FromWebPage_生产线(${
                this.ncSetting?.process_setting_name ?? ''
              })`
        obj.name = name
      }

      if (
        this.orderInfo.needPaibanRoomIds &&
        this.orderInfo.needPaibanRoomIds.length > 0
      ) {
        obj.room_ids = this.orderInfo.needPaibanRoomIds
      }
      if (!isNCSave && !isNcFile) {
        obj.auto_save = 0
      } else {
        obj.auto_save = 1
      }
      try {
        const res = await saveHistory(obj)
        if (res.status == 1) {
          this.hasChangePaiban = false
          if (!obj.auto_save) {
            this.$message({
              type: 'success',
              message: translate('common.saveSuccess') + '!',
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
      } catch (err) {
        this.$message({
          type: 'error',
          message: err,
        })
      } finally {
        this.showSaveDialog = false
      }
    },
    // 跳转至柜柜云仓
    handlePlankShopinglist() {
      buryPointApi('layout', 'plate_purchase')
      const shoppingList = this.drawData.map((e) => {
        return {
          matCode: e.data[0].matCode,
          count: e.data.length,
          color: e.texture,
        }
      })
      this.genPaibanKey().then(() => {
        window.open(
          process.env.VUE_APP_ENV === 'production'
            ? `https://mensvsp.thinkerx.com/purchase?paiban_key=${this.paibanKey}&uid=${this.userInfo.id}&register_id=${this.factoryId}&source=ypb`
            : `https://mensvsp-dev.thinkerx.com/purchase?paiban_key=${this.paibanKey}&uid=${this.userInfo.id}&register_id=13195&source=ypb`
        )
      })
    },

    drawParts(ctx, part, startX, startY, scale, way) {
      ctx.strokeStyle = '#0008'
      ctx.lineWidth = 1
      let rect = {
        x: startX + part.startX / scale,
        y: startY + part.startY / scale,
        width: part.rect.width / scale,
        height: part.rect.height / scale,
      }
      if (part.path) {
        for (let i = 0; i < part.path.length; ++i) {
          ctx.beginPath()
          for (let k = 0; k < part.path[i].length; ++k) {
            let x = rect.x + part.path[i][k].x / scale
            let y = rect.y + part.path[i][k].y / scale
            if (k == 0) {
              ctx.moveTo(x, y)
            } else {
              ctx.lineTo(x, y)
            }
          }
          ctx.closePath()
          ctx.stroke()
        }
      } else {
        ctx.strokeRect(rect.x, rect.y, rect.width, rect.height)
      }
      // 绘制孔、槽、牛角拉手、异型孔(只有排版图3才画)
      if (way == 3) {
        if (part.holes?.length > 0) {
          part.holes.forEach((hole) => {
            ctx.beginPath()
            ctx.arc(
              startX + (part.startX + hole.center.x) / scale,
              startY + (part.startY + hole.center.y) / scale,
              hole.diameter / 2 / scale,
              0,
              Math.PI * 2
            )
            ctx.closePath()
            ctx.stroke()
          })
        }
        if (part.slots?.length > 0) {
          part.slots.forEach((slot) => {
            const { x, y, width, height } = getSlotBasicInfo(slot, 'pt')
            ctx.beginPath()
            ctx.strokeRect(
              startX + (part.startX + x) / scale,
              startY + (part.startY + y) / scale,
              width / scale,
              height / scale
            )
            ctx.closePath()
          })
        }
        if (part.curveHoles?.length > 0) {
          part.curveHoles.forEach((curveHole) => {
            ctx.beginPath()
            for (let index = 0; index < curveHole.path.length; index++) {
              const curve = curveHole.path[index]
              let x, y
              x = startX + (part.startX + curve.x) / scale
              y = startY + (part.startY + curve.y) / scale
              if (index == 0) {
                ctx.moveTo(x, y)
              } else {
                ctx.lineTo(x, y)
              }
            }
            ctx.stroke()
            ctx.closePath()
          })
        }
        if (part.path?.length > 1) {
          part.path.forEach((path) => {
            ctx.beginPath()
            for (let index = 0; index < path.length; index++) {
              const curPath = path[index]
              let x, y
              x = startX + (part.startX + curPath.x) / scale
              y = startY + (part.startY + curPath.y) / scale
              if (index == 0) {
                ctx.moveTo(x, y)
              } else {
                ctx.lineTo(x, y)
              }
            }
            ctx.stroke()
            ctx.closePath()
          })
        }
        if (part.millInfo?.length > 0) {
          part.millInfo.forEach((mill) => {
            ctx.beginPath()
            for (let index = 0; index < mill.shape.length; index++) {
              const curMill = mill.shape[index]
              let x, y
              x = startX + (part.startX + curMill.x) / scale
              y = startY + (part.startY + curMill.y) / scale
              if (index == 0) {
                ctx.moveTo(x, y)
              } else {
                ctx.lineTo(x, y)
              }
            }
            ctx.stroke()
            ctx.closePath()
          })
        }
      }
    },
    drawText(ctx, part, startX, startY, scale) {
      let fontSize = 13
      ctx.fillStyle = '#333'
      ctx.font = `${fontSize}px 'PingFangSC-Regular, PingFang SC'`
      let lineHeight = 13
      let rect = {
        x: startX + part.startX / scale,
        y: startY + part.startY / scale,
        width: part.rect.width / scale,
        height: part.rect.height / scale,
      }
      let partInfo = `${part.realRect.height.toFixed(
        2
      )}*${part.realRect.width.toFixed(2)}*${part.thick}`

      const partName = part.partName ? part.partName : part.name
      let { y: partNameHeight, isFull } = this.wrapText(
        ctx,
        partName,
        rect.y + lineHeight,
        lineHeight,
        rect
      )
      if (!isFull) {
        this.wrapText(
          ctx,
          partInfo,
          lineHeight + partNameHeight,
          lineHeight,
          rect,
          'partInfo'
        )
      }
    },
    wrapText(ctx, text, y, lineHeight, rect, partInfo) {
      let { x, y: rectY, width: maxWidth, height: maxHeight } = rect
      let words = text.split('')
      let line = ''
      let isFull = false
      let textStartY = y
      let partNameHeight = partInfo ? y - rectY - lineHeight : 0
      for (let n = 0; n < words.length; n++) {
        let testLine = line + words[n]
        let metrics = ctx.measureText(testLine)
        let testWidth = metrics.width
        if (testWidth > maxWidth && n > 0) {
          if (y - textStartY + lineHeight + partNameHeight < maxHeight) {
            ctx.fillText(line, x, y)
            line = words[n] + ' '
            y += lineHeight
          } else {
            isFull = true
            line = ''
            break
          }
        } else {
          line = testLine
        }
      }
      ctx.fillText(line, x, y)
      return { y, isFull }
    },
    // 数据来源是paibanData的深拷贝this.oriArr
    getLabelPaibanImg() {
      let imgArr = {}
      if (this.ncSetting.labelingMachine) {
        let imgExportArr = []
        for (
          let i = 0;
          i < this.ncSetting.layoutImageSetting.layoutImage.length;
          ++i
        ) {
          let imgSetting = this.ncSetting.layoutImageSetting.layoutImage[i]
          let obj = {
            type: imgSetting.imageFormat,
            prefix: imgSetting.imageNamePrefix,
            direction: imgSetting.imageDirection == 'horiz' ? true : false, // 判断是横向图片还是竖向图片
            way: imgSetting.way,
            enable: imgSetting.enable,
          }
          if (obj.direction) {
            obj.width = imgSetting.imageSize[1]
            obj.height = imgSetting.imageSize[0]
          } else {
            obj.width = imgSetting.imageSize[0]
            obj.height = imgSetting.imageSize[1]
          }
          obj.index = i + 1
          imgExportArr.push(obj)
        }
        let plankWidth = this.ncSetting.drawPlankWidth
        let plankHeight = this.ncSetting.drawPlankHeight
        // 循环输出两种排版图
        for (let j = 0; j < imgExportArr.length; ++j) {
          if (!imgExportArr[j].enable) continue
          imgArr[j + 1] = []
          let direction = imgExportArr[j].direction
          // 创建canvas标签
          let oCanvas = document.createElement('canvas')

          // 设置canvas长宽
          oCanvas.width = Number(imgExportArr[j].width)
          oCanvas.height = Number(imgExportArr[j].height)
          let ctx = oCanvas.getContext('2d')
          let fontSize = 14
          // 如果是横向, 则需要切换旋转canvas, 切换canvas的长宽
          if (direction) {
            var temp = oCanvas.width
            oCanvas.width = oCanvas.height
            oCanvas.height = temp
          }
          // 循环绘制每一块大板 判断是否是精细排版，精细排版下载当前大板的排版图
          let subtleTypographyList = this.isSubtle
            ? this.drawDataArr
            : this.oriArr
          for (let i = 0; i < subtleTypographyList.length; ++i) {
            ctx.clearRect(0, 0, oCanvas.width, oCanvas.height)
            let center = {
              x: 0,
              y: 0,
            }
            ctx.fillStyle = '#fff'
            ctx.fillRect(0, 0, oCanvas.width, oCanvas.height)

            // 如果是横向, 则以中心点位置旋转, 并重新计算原点坐标
            if (direction) {
              center = {
                x: Math.round(oCanvas.width / 2),
                y: Math.round(oCanvas.height / 2),
              }
              ctx.translate(center.x, center.y)
              ctx.rotate(Math.PI / 2)
              ctx.translate(-center.y, -center.x)
            }

            // 设置绘制的属性
            ctx.fillStyle = '#fff'
            let finalScale = 0
            let startX = 0
            let startY = 0

            // 根据余料或者正常大板, 计算绘制起始点(居中绘制)
            if (
              subtleTypographyList[i].surplusInfo &&
              Object.keys(subtleTypographyList[i].surplusInfo).length > 0
            ) {
              let surplusInfo = subtleTypographyList[i].surplusInfo
              // 减少一个文字大小的高度和宽度
              let scaleX =
                Number(surplusInfo.width) / Number(imgExportArr[j].width - 64)
              let scaleY =
                Number(surplusInfo.height) / Number(imgExportArr[j].height - 64)
              finalScale = scaleX > scaleY ? scaleX : scaleY
              startX =
                Math.abs(
                  surplusInfo.width / finalScale - imgExportArr[j].width
                ) / 2
              startY =
                Math.abs(
                  surplusInfo.height / finalScale - imgExportArr[j].height
                ) / 2
              if (surplusInfo.shape && surplusInfo.shape == 'lshape') {
                let x3 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.y5)
                  : Number(surplusInfo.x3)
                let y3 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.x5)
                  : Number(surplusInfo.y3)
                let x4 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.y4)
                  : Number(surplusInfo.x4)
                let y4 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.x4)
                  : Number(surplusInfo.y4)
                let x5 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.y3)
                  : Number(surplusInfo.x5)
                let y5 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.x3)
                  : Number(surplusInfo.y5)
                let newWidth = surplusInfo.width
                let newHeight = surplusInfo.height
                y3 = newHeight - y3
                y4 = newHeight - y4
                y5 = newHeight - y5

                ctx.beginPath()
                ctx.moveTo(startX, startY)

                ctx.lineTo(startX + x3 / finalScale, startY + y3 / finalScale)
                ctx.lineTo(startX + x4 / finalScale, startY + y4 / finalScale)
                ctx.lineTo(startX + x5 / finalScale, startY + y5 / finalScale)
                ctx.lineTo(
                  startX + newWidth / finalScale,
                  startY + newHeight / finalScale
                )
                ctx.lineTo(startX, startY + newHeight / finalScale)

                ctx.closePath()
                ctx.stroke()
              } else {
                ctx.strokeRect(
                  startX,
                  startY,
                  surplusInfo.width / finalScale,
                  surplusInfo.height / finalScale
                )
              }
            } else {
              let scaleX =
                Number(plankWidth) / Number(imgExportArr[j].width - 64)
              let scaleY =
                Number(plankHeight) / Number(imgExportArr[j].height - 64)
              finalScale = scaleX > scaleY ? scaleX : scaleY
              startX =
                Math.abs(plankWidth / finalScale - imgExportArr[j].width) / 2
              startY =
                Math.abs(plankHeight / finalScale - imgExportArr[j].height) / 2
              ctx.strokeRect(
                startX,
                startY,
                plankWidth / finalScale,
                plankHeight / finalScale
              )
              ctx.fillRect(
                startX,
                startY,
                plankWidth / finalScale,
                plankHeight / finalScale
              )
            }

            // 将图片进行居中
            for (let k = 0; k < subtleTypographyList[i].parts.length; ++k) {
              let part = subtleTypographyList[i].parts[k]
              this.drawParts(
                ctx,
                part,
                startX,
                startY,
                finalScale,
                imgExportArr[j].way,
                direction,
                center
              )
            }

            if (direction) {
              ctx.translate(center.y, center.x)
              ctx.rotate(-Math.PI / 2)
              ctx.translate(-center.x, -center.y)
            }

            // 获取的方法getGraghEdge
            let { size } = getGraghEdge(subtleTypographyList[i], this.ncSetting)
            ctx.fillStyle = '#333'
            ctx.font = `${fontSize}px 'PingFangSC-Regular, PingFang SC'`
            let str = `${subtleTypographyList[i].thick}${subtleTypographyList[i].matCode}${subtleTypographyList[i].texture}`
            let usedRate = subtleTypographyList[i].usedRate
              ? subtleTypographyList[i].usedRate >= 1
                ? `${(100).toFixed(2)}%`
                : `${(subtleTypographyList[i].usedRate * 100).toFixed(2)}%`
              : '0%'
            let textWidth = this.calcTextSize(str, fontSize)
            let textStartX = (oCanvas.width - textWidth) / 2
            let textStartY = direction ? startX : startY
            //排版图3单独处理
            if (imgExportArr[j].way == 3) {
              str = `第${i + 1}/${
                subtleTypographyList.length
              }张大板   利用率：${usedRate}`
              let strSecond = `材质:${subtleTypographyList[i].matCode}   颜色:${subtleTypographyList[i].texture}   规格：${size}`
              textWidth = this.calcTextSize(strSecond, fontSize)
              textStartX = (oCanvas.width - textWidth) / 2
              ctx.fillText(str, textStartX, textStartY - 20)
              ctx.fillText(strSecond, textStartX, textStartY - 4)
              for (let k = 0; k < subtleTypographyList[i].parts.length; ++k) {
                let part = subtleTypographyList[i].parts[k]
                this.drawText(ctx, part, startX, startY, finalScale)
              }
            } else {
              ctx.fillText(str, textStartX, textStartY - 4)
            }
            // 规定生成图片的文件格式
            const format = `image/${this.ncSetting.labelImageFormatNew}`
            let imgUrl = oCanvas.toDataURL(format)
            const num_auto_offset =
              this.ncSetting.layoutImageSetting.num_auto_offset
            const formatOrder = this.formatNumber(i + 1, num_auto_offset)
            let imgName = `${imgExportArr[j].prefix}${formatOrder}.${imgExportArr[j].type}`
            let index = imgExportArr[j].index
            // 大板有L形和矩形两种情况
            // 获取的方法getGraghEdge
            // let { size } = getGraghEdge(subtleTypographyList[i], this.ncSetting)
            const sizeList = size.split('*').splice(0, 2)
            size = `${sizeList[1]}*${sizeList[0]}`
            imgArr[j + 1].push({
              url: imgUrl,
              name: imgName,
              index: index,
              stock_key: subtleTypographyList[i].stockKey,
              // 将原始数据的长宽存储进去
              size,
            })
          }
          if (document.body.contains(oCanvas)) {
            document.body.removeChild(oCanvas)
          }
        }
      }
      return imgArr
    },
    formatNumber(input, paddingValue) {
      // 获取输入的数字
      let number = parseInt(input, 10)
      // 获取补偿值
      let padding = parseInt(paddingValue, 10)
      // 计算最终补偿后的数字
      let formattedNumber = String(number).padStart(padding, '0')
      // 返回补偿后的结果
      return formattedNumber
    },
    calcTextSize(text, fontSize) {
      let span = document.createElement('span')
      let result = {}
      result.width = span.offsetWidth
      result.height = span.offsetHeight
      span.style.visibility = 'hidden'
      span.style.fontSize = `${fontSize}px`
      span.style.fontFamily = 'PingFangSC-Regular, PingFang SC'
      span.style.display = 'inline-block'
      document.body.appendChild(span)
      if (typeof span.textContent != 'undefined') {
        span.textContent = text
      } else {
        span.innerText = text
      }
      result.width =
        parseFloat(window.getComputedStyle(span).width) - result.width
      document.body.removeChild(span)
      let width = result.width
      return width
    },
    // 下载nc前的预检查
    beforDownloadCheck(surplusPlank, isContinue, isSubtle) {
      let isReturn = false
      const {
        dock6FDrill,
        dock5FDrill,
        dock6FDrill_fileType,
        dock5FDrill_fileType,
        process_setting_id,
        process_setting_name,
      } = this.ncSetting
      // 检查五六钻模板是否存在
      if (
        (dock6FDrill && !dock6FDrill_fileType) ||
        (dock5FDrill && !dock5FDrill_fileType)
      ) {
        isReturn = true
        const msg = translate('arrangedPage.tempErrTip')
        const path = `/dockFiveSix?setting_id=${process_setting_id}&line=${process_setting_name}&equipType=fiveSix`
        modalMsg(
          msg,
          'warning',
          translate('common.tip'),
          () => {
            this.isSendToFactoryLoading = false
            this.isNCBtnLoading = false
            this.$router.push(path)
          },
          {
            okText: translate('materialPage.goAdd'),
            cancelButtonProps: null,
            //ssss
            onCancel: () => {
              this.isSendToFactoryLoading = false
              this.isNCBtnLoading = false
            },
          }
        )
      }
      // 待排版库进行检查
      if (!this.checkAwaitLocalStore()) {
        isReturn = true
      }
      // 开启余料锁定 存在余料大板 下载NC判断使用余料是否被其它订单锁定
      if (
        this.$store.state.preferencesSetting.setting.surplus_lock &&
        surplusPlank &&
        Object.keys(surplusPlank) &&
        !isContinue
      ) {
        const order_address = this.orderInfo?.order_address?.replace(
          /\(.*?\)|历史记录/g,
          ''
        )
        if (
          this.useSurplusList.some(
            (item) =>
              item.lock_status == 1 &&
              item.lock_order &&
              item.lock_order != order_address
          ) &&
          !isSubtle
        ) {
          this.isShowSurplusLock = true
          isReturn = true
        } else {
          this.isShowSurplusLock = false
        }
        if (isSubtle) {
          const newSurplus = this.useSurplusList.find(
            (item) => item.id == surplusPlank.surplusInfo.id
          )
          this.isShowSurplusLock = false
          if (
            newSurplus?.lock_status == 1 &&
            newSurplus?.lock_order != order_address
          ) {
            this.isShowSurplusLockNewSub = true
            isReturn = true
          } else {
            this.isShowSurplusLockNewSub = false
            isReturn = false
          }
        }
      }
      return isReturn
    },
    async handleDownloadNC(isSendToFactory = false, isContinue) {
      if (this.ncSetting.isPreLayout) {
        this.checkSettingChange()
        return
      }
      if (this.beforeCommonCheck()) {
        return
      }
      // 检查本地所使用的目录是否存在
      if (
        !isSendToFactory &&
        !(await this.$refs.folderChooseModalRef?.verify())
      ) {
        return
      }
      if (isSendToFactory) {
        this.currentOperate = 'factory'
      } else {
        this.currentOperate = 'nc'
      }
      this.currentDownloadType = 'paiban'
      let flag = this.judgeFlag()
      if (!flag) {
        this.$message.warning(translate('arrangedPage.cutOrderErr'))
        return
      }
      if (this.isOnlyShowSurplus) {
        this.isOnlyShowSurplus = false
        this.handleShowSurplus()
      }
      const surplusPlank = this.paibanData.find(
        (item) =>
          item.surplusInfo &&
          Object.keys(item.surplusInfo).length &&
          !item.surplusInfo.isNotSurplus
      )
      if (surplusPlank) {
        this.useSurplusId = this.paibanData
          .map(
            (item) => !item.surplusInfo?.isNotSurplus && item.surplusInfo?.id
          )
          .filter((id) => id)
        const surplusRes = await getSurplusList({ page: 1, limit: 999 })
        const allSurplusList = surplusRes.status ? surplusRes.data.data : []
        this.useSurplusList = allSurplusList.filter((item) =>
          this.useSurplusId.includes(item.id)
        )
      }
      // 初始化下载nc的任务状态
      this.ncTaskList = []
      this.ncLifeFuncObj = {}
      this.ncLifeFuncObj = initNcLifeObj(
        this,
        this.currentTagType,
        this.isFollowExportTag
      )
      const { checkData, loadSetting } = this.ncLifeFuncObj
      checkData('start')
      const isReturn = this.beforDownloadCheck(surplusPlank, isContinue)
      if (isReturn) return
      checkData('end')
      loadSetting('start')
      this.isOutputCheck = true
      const isShowTrialDialog =
        await this.$refs.trialRef.checkUserSettingStatus(
          this.ncSetting.process_setting_id
        )
      // 如要弹出模态框则返回，走模态框逻辑
      this.isSendToFactoryLoading = false
      if (isShowTrialDialog) {
        this.isDownloadingNc = false
        this.isOutputCheck = false
        return
      }
      this.isNCBtnLoading = true
      this.subtleBigPart = null
      if (this.isHistoryStatus) {
        this.hasChangePaiban =
          JSON.stringify(this.drawDataO) !=
          JSON.stringify(
            this.drawData
              .map((e) => e.data.map((x) => x.parts))
              .flat(3)
              .map((e) => {
                let partInPlankarr = this.plankArr.find(
                  (plank) =>
                    e.plankID === plank.plankID && e.index === plank.index
                )
                if (partInPlankarr && partInPlankarr.isNonTexture) {
                  return { ...e, texDir: 'notcare' }
                } else {
                  return { ...e, texDir: e.srcTexDir }
                }
              })
          )
        // 吴姐测试要求
        this.hasChangePaiban = false
        if (this.hasChangePaiban && this.isAutoPassData) {
          this.$confirm(
            translate('arrangedPage.changeDataTip'),
            translate('common.tip'),
            {
              confirmButtonText: translate('common.confirm'),
              cancelButtonText: translate('common.cancel'),
              type: 'warning',
            }
          )
            .then(() => {
              this.comfirmSave = true
              this.beforeDownloadNC()
            })
            .catch(() => {
              this.hasChangePaiban = false
              this.comfirmSave = false
              this.beforeDownloadNC()
            })
        } else {
          await this.beforeDownloadNC()
        }
      } else {
        await this.beforeDownloadNC()
      }
    },
    // 下载NC文件
    async beforeDownloadNC(subtleArr = [], isSubtle = false) {
      // 如果当前选中的操作是导出图片
      // 发送车间currentTagType 下载NCtagDocType
      const continueFlag =
        this.currentOperate == 'nc' &&
        this.currentTagType == '导出图片' &&
        this.print_color_tag &&
        this.ncSetting.labelImageFormatNew == 'bmp' &&
        this.ncSetting.bmp_color_style == 1
      const res = await checkPlankEdgeHasChanged(this.baseMaterialData)
      if (this.$refs.subtleRef) {
        this.$refs.subtleRef.changeLoading(false)
      }
      this.isOutputCheck = false

      if (!res || continueFlag) {
        this.isShowTask = false
        this.isDownloadingNc = false
        this.showLoading = false
        this.isNCBtnLoading = false
        this.isRefresh = false
        if (continueFlag) {
          this.$message({
            type: 'error',
            message: translate('common.hoverTip'),
          })
        }
        return
      }
      this.isNCBtnLoading = true
      // 强制更新paibanData使其与finalDrawData中的data顺序保持一致
      this.handleSetNewPaibanData()
      this.oriArr = this.paibanData
      this.oriArr.forEach((e) => {
        e.parts.forEach((part) => {
          let partInPlankarr = this.plankArr.find(
            (plank) =>
              part.plankID === plank.plankID && part.index === plank.index
          )
          if (partInPlankarr && partInPlankarr.isNonTexture) {
            part.texDir = 'notcare'
          } else {
            part.texDir = part.srcTexDir
          }
        })
      })
      this.isSubtle = isSubtle
      const drawDataArr = (this.drawDataArr = isSubtle
        ? subtleArr
        : this.oriArr)
      // 存在空的大板时， 结束执行函数，重置下载nc按钮状态
      if (this.checkIsEmptyPlank('文件下载')) {
        this.isNCBtnLoading = false
        return
      }
      if (
        (this.$store.state.userInfo.edition_id == 7 ||
          this.$store.state.userInfo.edition_id == 4) &&
        !sessionStorage.getItem('thinkerx_material')
      ) {
        let edition =
          this.$store.state.userInfo.edition_id == 7 ? '木工版' : '设计版'
        const h = this.$createElement
        this.$msgbox({
          title: translate('common.warning'),
          message: h('p', null, [
            h('i', { style: 'color: red' }, `抱歉，${edition}暂无此权限`),
            h('span', null, translate('arrangedPage.upgradeTip')),
          ]),
          showCancelButton: true,
          confirmButtonText: translate('arrangedPage.upgrade'),
          cancelButtonText: translate('common.cancel'),
          beforeClose(action, instance, done) {
            if (action == 'confirm') {
              window.open('https://www.eggrj.com/purchase.html')
            } else {
              done()
            }
          },
        }).catch((e) => {
          console.error(e)
        })
        return
      }
      const labelCheck = this.judgeLimit(this.oriArr)
      // 小板重叠检测
      for (let i = 0; i < drawDataArr.length; ++i) {
        for (let k = 0; k < drawDataArr[i].parts.length; ++k) {
          if (drawDataArr[i].parts[k].plankMerge) {
            this.plankKeyWord = ''
            this.searchPlankList = []
            this.searchPlankList.push(drawDataArr[i].parts[k])
            this.genLocationOfErrorPlank(i, k)
            this.$message({
              type: 'error',
              message: translate('arrangedPage.plankOverEdge'),
            })
            // 修改按钮的loading状态
            this.isNCBtnLoading = false
            return
          }
        }
      }
      // 当前排版结果存在孔槽冲突或孔槽深度异常
      if (
        ((this.isShowConflictPrompts || this.isDepthError()) &&
          !this.isContinue &&
          !this.isSubtle) ||
        ((this.judgmentConflicts(drawDataArr) ||
          this.isDepthError(drawDataArr)) &&
          this.isSubtle &&
          !this.isContinue)
      ) {
        this.isShowConflictModal = true
        this.isNCBtnLoading = false
        this.isSendToFactoryLoading = false
        return
      }
      // else if (this.isContinue) {
      //   this.isContinue = false
      // }

      // 如果开启了彩色标签，需要进行数据源限制数量检查
      if (
        this.isFollowExportTag &&
        !this.isWebToQt &&
        this.print_color_tag &&
        !this.continueDownloadFlag
      ) {
        this.limitTwelveList = []
        for (let key in labelCheck) {
          if (labelCheck[key].length > 12) {
            // 弹出提示弹窗
            this.limitTwelveList.push(key)
          }
        }
        if (this.limitTwelveList.length) {
          this.isShowConfirmColorTag = true
          this.isNCBtnLoading = false
          this.isSendToFactoryLoading = false
          return
        }
      }
      // 如果开启了彩色标签，需要判断是否需要重新生成新的颜色
      // TODO 生成颜色
      if (this.print_color_tag) {
        this.generateColor(labelCheck)
      }
      // 检查扣减的状态，决定是否需要弹出扣减框
      this.checkDeductionStatus()
      if (this.isShowDeduction) {
        return
      }
      // 没有扣减项则继续下载nc
      this.handleContinueDownloadNc()
    },
    /** 获取需要扣减的余料数据 */
    getNeedDeductionSurplus(drawDataArr) {
      const surplusArr = []
      for (let i = 0; i < drawDataArr.length; ++i) {
        if (
          drawDataArr[i].surplusInfo &&
          Object.keys(drawDataArr[i].surplusInfo.length > 0) &&
          !drawDataArr[i].surplusInfo.isNotSurplus
        ) {
          surplusArr.push(drawDataArr[i].surplusInfo)
        }
      }
      return surplusArr
    },
    /** 检查是否需求扣减 */
    checkDeductionStatus() {
      this.deductionData.surplus = this.getNeedDeductionSurplus(
        this.drawDataArr
      )
      this.deductionData.awaitStore = []
      this.deductionData.bujian = []
      const { servePlankStore = [] } = this.$store.state.awaitPaibanStore
      this.drawDataArr.forEach((bigPlank) => {
        bigPlank.parts.forEach((plank) => {
          if (plank._isAwaitStorePlank && plank._awaitStoreId) {
            // 获取到数据前还需要检查一下，待排版库是否真的存在这个数据,避免用户删除再回来下载
            if (!servePlankStore.some((it) => it.id === plank._awaitStoreId))
              return
            this.deductionData.awaitStore.push({
              id: plank._awaitStoreId,
              type: 'awaitStore',
              new: true,
              plank,
            })
          }
          if (plank._isBujian && plank._bujianStoreId) {
            this.deductionData.bujian.push({
              id: plank._bujianStoreId,
              type: 'bujianStore',
              new: plank._isNewTableBujian,
              plank,
            })
          }
        })
      })
      // TODO: 需要新增是否展示扣减原片的项
      // 判断是否显示对应的选框
      this.deductionShowState.isShowSurplusDeduction =
        !!this.deductionData.surplus.length
      this.deductionShowState.isShowBujianDeduction =
        !!this.deductionData.bujian.length
      this.deductionShowState.isShowAwaitDeduction =
        !!this.deductionData.awaitStore.length
      // TODO: 暂时对所有人关闭此功能
      this.deductionShowState.isShowBaseMaterialDeduction = false
      // !!this.userInfo.userPlatform
      // 是否显示扣减的弹窗
      this.isShowDeduction =
        this.deductionShowState.isShowSurplusDeduction ||
        this.deductionShowState.isShowBujianDeduction ||
        this.deductionShowState.isShowAwaitDeduction ||
        this.deductionShowState.isShowBaseMaterialDeduction
    },
    /** 扣减补件和待排版库的板件 */
    deductionBujianAndAwaitStorePlank() {
      const { awaitStore, bujian } = this.deductionData
      const { isDeductionBujian, isDeductionPaibanStore } =
        this.$store.state.preferencesSetting.setting
      const { isShowAwaitDeduction, isShowBujianDeduction } =
        this.deductionShowState
      // 根据显示与选中情况决定是否执行
      if (!isDeductionBujian || isShowAwaitDeduction) {
        bujian.length = 0
      }
      if (!isDeductionPaibanStore || isShowBujianDeduction) {
        awaitStore.length = 0
      }
      // 不存在任何 扣减项则不再执行
      if (!awaitStore?.length && !bujian?.length) return
      const [ids, news, planks] = [...awaitStore, ...bujian].reduce(
        (pre, next) => {
          pre[0].push(next.id)
          pre[1].push(next.new)
          pre[2].push(next.plank)
          return pre
        },
        [[], [], []]
      )
      if (!ids.length || !news.length) {
        return
      }
      deleteStoreData({
        ids: ids.join(),
        new: news.map((it) => (it ? 1 : 0)).join(),
      }).then(() => {
        // 补件或者待排版库扣减完成后重新获取补件库数据
        this.$store.dispatch('awaitPaibanStore/getServePlankData')
        // 扣减完成后将板件有关补件和待排版库的标识删除避免二次扣减
        planks.forEach((plank) => {
          plank._isAwaitStorePlank = false
          plank._isBujian = false
          // 删除预排版数据中的标识
          const target = this.preLayoutData.find(
            (it) => it.partUniqueId === plank.partUniqueId
          )
          if (target) {
            target._isAwaitStorePlank = false
            target._isBujian = false
          }
        })
      })
    },
    /** 扣减 余料/补件/待排版库的板件, 扣减lbl仓库原片*/
    async handleDeductionPlank() {
      this.closeDeductionModal()
      let surplusArr = this.getNeedDeductionSurplus(this.drawDataArr)
      // 被其它订单锁定余料id
      const otherOrderLock = this.useSurplusList
        .filter(
          (item) =>
            this.useSurplusId.includes(item.id) &&
            item.lock_status == 1 &&
            item.lock_order !=
              this.orderInfo.order_address.replace(/\(.*?\)|历史记录/g, '')
        )
        .map((item) => item.id)
      surplusArr = surplusArr.filter(
        (item) => !otherOrderLock.includes(item.id)
      )
      const arr = []
      const { isDeductionSurplus, surplus_lock, maintain_paiban_plank_lock } =
        this.$store.state.preferencesSetting.setting
      // 余料选择框显示且选择才进行处理
      if (
        this.deductionShowState.isShowSurplusDeduction &&
        isDeductionSurplus
      ) {
        for (let i = 0; i < surplusArr.length; ++i) {
          if (arr.length == 0) {
            arr.push({
              id: surplusArr[i].id,
              ggid: surplusArr[i].ggid,
              use_num: 1,
            })
          } else {
            let flag = true
            for (let k = 0; k < arr.length; ++k) {
              if (
                surplusArr[i].id === arr[k].id &&
                surplusArr[i].ggid === arr[k].ggid
              ) {
                arr[k].use_num++
                flag = false
                break
              }
            }
            if (flag) {
              arr.push({
                id: surplusArr[i].id,
                ggid: surplusArr[i].ggid,
                use_num: 1,
              })
            }
          }
        }
      } else if (surplus_lock && !isDeductionSurplus) {
        let currentOrderLockList
        if (!maintain_paiban_plank_lock) {
          // 解除当前订单锁定的余料
          currentOrderLockList = this.useSurplusList
            .filter(
              (item) =>
                this.useSurplusId.includes(item.id) &&
                item.lock_status == 1 &&
                item.lock_order ==
                  this.orderInfo.order_address.replace(/\(.*?\)|历史记录/g, '')
            )
            .map((item) => ({
              ...item,
              lock_status: 0,
              lock_num: this.useSurplusId.filter((id) => id == item.id).length,
            }))
        } else {
          // 选择维持锁定 解锁的余料被锁定
          currentOrderLockList = this.useSurplusList
            .filter(
              (item) =>
                this.useSurplusId.includes(item.id) && item.lock_status == 0
            )
            .map((item) => ({
              ...item,
              lock_status: 1,
              lock_order: this.orderInfo.order_address.replace(
                /\(.*?\)|历史记录/g,
                ''
              ),
              lock_num: this.useSurplusId.filter((id) => id == item.id).length,
            }))
        }
        const lockParams = {
          surplus: currentOrderLockList,
          release_all: false,
        }
        await surplusLock(lockParams)
      }
      await this.handleShowOrderMark(arr)
      if (!this.isShowOrderMark) {
        arr.length && (await useSurplusApi(arr))
        /** 扣减补件和待排版库的板件 */
        this.deductionBujianAndAwaitStorePlank()
        this.downloadNC(this.drawDataArr, this.isSubtle)
      }
    },
    /** 检查并下载nc文件 */
    async handleContinueDownloadNc() {
      await this.handleShowOrderMark()
      if (!this.isShowOrderMark) {
        this.downloadNC(this.drawDataArr, this.isSubtle)
      }
    },
    closeDeductionModal() {
      this.isShowDeduction = false
    },
    //定位问题板件,调用组件函数
    genLocationOfErrorPlank() {
      this.setActivePlank(0)
      this.searchPlankList = []
    },
    generateLabelNo(data) {
      const start = this.$store.state.ncSetting?.front_labeling_start_num || '0'
      const _no = Number(start) - 1
      if (data && data.length) {
        data.forEach((item) => {
          item.parts.forEach((it) => {
            it.auto_index = this.generateId(start, _no + it.priority)
          })
        })
      }
    },
    generateId(initStart, id) {
      let _id = id + ''
      if (_id.length < initStart.length) {
        _id = _id.padStart(initStart.length, 0)
      }
      return _id
    },
    // 下载NC文件
    async downloadNC(drawData = this.oriArr, isSubtle = false) {
      // 更新偏好设置
      this.$store.dispatch('preferencesSetting/updateSetting')
      const { dealNCData, genFile, loadSetting } = this.ncLifeFuncObj
      dealNCData('start')
      _hmt.push(['_trackEvent', '下载nc', 'click'])
      buryPointApi('layout', 'download_nc')
      if (drawData.length == 0) {
        this.$message({
          message: translate('arrangedPage.noDataExport'),
          type: 'error',
        })
        return
      }

      let isBlank = drawData.every((v) => v.parts.length == 0)
      if (isBlank) {
        this.$message({
          message: translate('arrangedPage.noPlankExport'),
          type: 'error',
        })
        this.isNCBtnLoading = false
        return
      }

      // 关闭弹窗
      this.isSendToFactoryLoading = false
      // 发送车间不展示加载图片，下载NC时需要
      this.isDownloadingNc = !this.confirmSendToFactory
      setTimeout(() => {
        this.isTimeOut = true
      }, 20000)
      let arr = []
      for (let i = 0; i < drawData.length; ++i) {
        if (drawData[i].parts.length == 0) continue
        let address = drawData[i].parts[0].address
        if (arr.length == 0) {
          arr.push(address)
        } else {
          if (!arr.includes(address)) {
            arr.push(address)
          }
        }
      }
      let name = ''
      let obj = await genFirstLevelFileName(true)
      const {
        data: { labeling_folder },
      } = obj
      store.commit('setLabelingFolder', labeling_folder)
      name = obj.firstLevelFileName
      let process_setting = JSON.parse(JSON.stringify(this.ncSetting))

      const imgArr = this.getLabelPaibanImg()
      const dealArr = JSON.parse(JSON.stringify(drawData))
      const ncDownData = viewDataToNCData(dealArr)

      loadSetting('end')
      // 生成对应id
      changeDataStartPoint(ncDownData)
      let isNeedRecordSize = false
      for (let i = 0; i < drawData.length; ++i) {
        if (
          ncDownData[i].surplusInfo &&
          Object.keys(ncDownData[i].surplusInfo).length > 0 &&
          ncDownData[i].surplusInfo.isNotSurplus
        ) {
          isNeedRecordSize = true
          break
        }
      }
      // 处理所有异形路径点的顺序
      for (let i = 0; i < drawData.length; ++i) {
        // break
        // if (isNeedRecordSize) {
        if (
          ncDownData[i].surplusInfo &&
          Object.keys(ncDownData[i].surplusInfo).length > 0 &&
          ncDownData[i].surplusInfo.isNotSurplus
        ) {
          ncDownData[i].big_plank_size = {
            width: ncDownData[i].surplusInfo.width,
            height: ncDownData[i].surplusInfo.height,
            otherPlate: true,
          }
        } else {
          ncDownData[i].big_plank_size = {
            width: this.standardPlank.plankWidth,
            height: this.standardPlank.plankHeight,
            otherPlate: false,
          }
        }
        // }
        for (let j = 0; j < ncDownData[i].parts.length; ++j) {
          let plank = ncDownData[i].parts[j]
          // oriCurveHoles
          ncDownData[i].parts[j].oriCurveHoles = ncDownData[i].parts[j]
            .oriCurveHoles
            ? JSON.parse(JSON.stringify(ncDownData[i].parts[j].oriCurveHoles))
            : []
          // realCurve
          if (plank.realCurve) {
            let realCurveArea = ClipperLib.JS.AreaOfPolygon(plank.realCurve)
            if (realCurveArea > 0) {
              let arr = plank.realCurve
              let cutCurve_1 = arr[0].b
              let flag = arr[1] && arr[1].b
              let len = arr.length
              for (let k = 0; k < len; ++k) {
                if (k == len - 1) {
                  if (arr[0].hasOwnProperty('b') && cutCurve_1) {
                    arr[k].b = -cutCurve_1
                    if (!flag) {
                      delete arr[0].b
                    }
                  }
                } else {
                  if (arr[k + 1].hasOwnProperty('b')) {
                    arr[k].b = -arr[k + 1].b
                    delete arr[k + 1].b
                  }
                }
              }
              plank.realCurve.reverse()
            }
          }
          // realCurveEx
          if (plank.realCurveEx) {
            for (let k = 0; k < plank.realCurveEx.length; ++k) {
              let realCurveExArea = ClipperLib.JS.AreaOfPolygon(
                plank.realCurveEx[k]
              )
              if (realCurveExArea > 0) {
                let arr = plank.realCurveEx[k]
                let cutCurve_1 = arr[0].b
                let flag = arr[1] && arr[1].b
                let len = arr.length
                for (let k = 0; k < len; ++k) {
                  if (k == len - 1) {
                    if (arr[0].hasOwnProperty('b') && cutCurve_1) {
                      arr[k].b = -cutCurve_1
                      if (!flag) {
                        delete arr[0].b
                      }
                    }
                  } else {
                    if (arr[k + 1].hasOwnProperty('b')) {
                      arr[k].b = -arr[k + 1].b
                      delete arr[k + 1].b
                    }
                  }
                }
                plank.realCurveEx[k].reverse()
              }
            }
          }
          // cutCurve
          if (plank.cutCurve) {
            let cutCurveArea = ClipperLib.JS.AreaOfPolygon(plank.cutCurve)
            if (cutCurveArea > 0) {
              let arr = plank.cutCurve
              let cutCurve_1 = arr[0].b
              let flag = arr[1] && arr[1].b
              let len = arr.length
              for (let k = 0; k < len; ++k) {
                if (k == len - 1) {
                  if (arr[0].hasOwnProperty('b') && cutCurve_1) {
                    arr[k].b = -cutCurve_1
                    if (!flag) {
                      delete arr[0].b
                    }
                  }
                } else {
                  if (arr[k + 1].hasOwnProperty('b')) {
                    arr[k].b = -arr[k + 1].b
                    delete arr[k + 1].b
                  }
                }
              }
              plank.cutCurve.reverse()
            }
          }
          // curCurveEx
          if (plank.cutCurveEx) {
            for (let k = 0; k < plank.cutCurveEx.length; ++k) {
              let cutCurveExArea = ClipperLib.JS.AreaOfPolygon(
                plank.cutCurveEx[k]
              )
              if (cutCurveExArea > 0) {
                let arr = plank.cutCurveEx[k]
                let cutCurve_1 = arr[0].b
                let flag = arr[1] && arr[1].b
                let len = arr.length
                for (let k = 0; k < len; ++k) {
                  if (k == len - 1) {
                    if (arr[0].hasOwnProperty('b') && cutCurve_1) {
                      arr[k].b = -cutCurve_1
                      if (!flag) {
                        delete arr[0].b
                      }
                    }
                  } else {
                    if (arr[k + 1].hasOwnProperty('b')) {
                      arr[k].b = -arr[k + 1].b
                      delete arr[k + 1].b
                    }
                  }
                }
                plank.cutCurveEx[k].reverse()
              }
            }
          }
          // opath
          if (plank.oPath) {
            for (let k = 0; k < plank.oPath.length; ++k) {
              let oPathArea = ClipperLib.JS.AreaOfPolygon(plank.oPath[k])
              if (oPathArea > 0) {
                plank.oPath[k].reverse()
              }
            }
          }
          // path
          if (plank.path) {
            for (let k = 0; k < plank.path.length; ++k) {
              let pathArea = ClipperLib.JS.AreaOfPolygon(plank.path[k])
              if (pathArea > 0) {
                plank.path[k].reverse()
              }
            }
          }
          // 孔槽处理
          const { thick, slots, holes } = plank
          const curveHoles = plank.curveHoles ?? []
          const handleSlopes = plank.handleSlopes ?? []
          const holeAllPenetrate = holes.every(
            (it) => it.deep * 1 + 0.001 >= thick
          )
          const curveHolesAllPenetrate = curveHoles.every(
            (it) => it.deep * 1 + 0.001 >= thick
          )
          const slotAllPenetrate = slots.every(
            (it) => it.deep * 1 + 0.001 >= thick
          )
          if (holeAllPenetrate && slotAllPenetrate && curveHolesAllPenetrate) {
            const { xyReverse } = this.ncSetting
            holes.forEach((hole) => {
              if (xyReverse) {
                hole.side = -1
              }
            })
            slots.forEach((slot) => {
              if (xyReverse) {
                slot.side = -1
              }
            })
            handleSlopes.forEach((slope) => {
              // 判断symbol是不是haitang_corner
              if (xyReverse && slope.symbol !== 'haitang_corner') {
                slope.side = -1
              }
            })
            curveHoles.forEach((cHole) => {
              if (xyReverse) {
                cHole.side = -1
              }
            })
          }
        }
      }
      if (this.userInfo.edition_id == 4) {
        for (let i = 0; i < ncDownData.length; ++i) {
          for (let k = 0; k < ncDownData[i].parts.length; ++k) {
            let plank = ncDownData[i].parts[k]
            plank.holes = []
            plank.sholes = []
            plank.sslots = []
            plank.slots = []
          }
        }
      }

      // 门窗ERP跳转需要给他们传一些数据
      if (sessionStorage.getItem('thinkerx_material')) {
        //大板信息
        // 小板信息（重复的聚合，包括数量）
        let oriArr = drawData
        let partPlanks = this.$store.state.preLayoutData
        let mcData = {
          factory_id: partPlanks[0].factoryId,
          order_ids: '',
          fog_ids: '',
          result_data: [],
        }
        partPlanks.forEach((item) => {
          if (mcData.order_ids) {
            if (mcData.order_ids.indexOf(item.orderId) == -1) {
              mcData.order_ids += `,${item.orderId}`
            }
          } else {
            mcData.order_ids += item.orderId
          }
          if (mcData.fog_ids) {
            if (mcData.fog_ids.indexOf(item.groupId) == -1) {
              mcData.fog_ids += `,${item.groupId}`
            }
          } else {
            mcData.fog_ids += item.groupId
          }
        })
        oriArr.forEach((item) => {
          let plankObj = {
            order_ids: '',
            fog_ids: '',
            count: item.parts.length,
            thing_id: item.parts[0].thingId ? item.parts[0].thingId : '',
            content: item.parts,
          }
          item.parts.forEach((e) => {
            if (plankObj.order_ids) {
              if (plankObj.order_ids.indexOf(e.orderId) == -1) {
                plankObj.order_ids += `,${e.orderId}`
              }
            } else {
              plankObj.order_ids += e.orderId
            }
            if (plankObj.fog_ids) {
              if (plankObj.fog_ids.indexOf(e.groupId) == -1) {
                plankObj.fog_ids += `,${e.groupId}`
              }
            } else {
              plankObj.fog_ids += e.groupId
            }
          })
          mcData.result_data.push(plankObj)
        })
        const dealAfterData = this.thinkerxMaterialSkip()
        this.$post(
          '/window_save_optimization',
          { ...dealAfterData },
          (res) => {}
        )
      }

      // 将信息发送到工厂车间
      const sendToFactoryFun = async (urls) => {
        let isErr = false
        urls.forEach((url) => {
          if (url.error_info) {
            isErr = true
            this.$confirm(url.error_info, translate('common.warning'), {
              type: 'error',
            })
            return
          }
        })
        if (isErr) return
        this.NCUrls = urls
        this.confirmSendToFactory = false

        const { jsonObj } = await this.dealJsonFile()
        let img_url = ''
        await uploadFiles({ imgArr }, 'imgFiles', (res) => {
          img_url = res
          const files = [
            {
              fileType: 'tag',
              url: this.ossUrl.data,
              file_display_name: this.orderInfo.order_address,
              img_url,
            },
            ...this.NCUrls,
            {
              jsonObj,
            },
          ]

          let file_url = ''
          let plank_url = ''
          uploadFiles({ files }, 'factoryFiles', (res) => {
            file_url = res
            const filterArr = this.prePaibanData.length
              ? this.prePaibanData.map((data) => data.partUniqueId)
              : this.paibanData
                  .map((data) => data.parts)
                  .flat(1)
                  .map((part) => part.partUniqueId)
            uploadFiles(
              {
                plank: [
                  ...this.preLayoutData.filter((data) =>
                    filterArr.includes(data.partUniqueId)
                  ),
                  ...this.availableBujianList,
                ].map((part) => ({ ...part, amount: 1 })),
              },
              'plankFiles',
              (res) => {
                plank_url = res
                this.$token(
                  '/workshop/files',
                  {
                    address: Array.from(
                      new Set(
                        this.preLayoutData.map((data) =>
                          data.address ? data.address : data.groupNo
                        )
                      ).values()
                    ).join(','),
                    order_id: Array.from(
                      new Set(
                        this.preLayoutData.map((data) => data.orderNo)
                      ).values()
                    ).join(','),
                    product_line:
                      this.ncSetting.process_setting_name ??
                      this.$store.state.recodrProductionLineChoose,
                    file_url,
                    plank_url,
                    process_line_id:
                      this.ncSetting.process_line_id ??
                      this.ncSetting.process_setting_id,
                    paibanImgArr: this.getLabelPaibanImg(),
                  },
                  (res) => {
                    if (res.status == 1) {
                      this.isDownloadingNc = false
                      this.isTimeOut = false
                      this.$message.success(
                        translate('materialPage.sendFactorySuccess')
                      )
                    } else {
                      this.$confirm(
                        translate('arrangedPage.sendErr'),
                        translate('common.warning'),
                        {
                          type: 'error',
                        }
                      )
                    }
                    this.isContinue = false
                    this.continueDownloadFlag = false
                    return
                  }
                )
              }
            )
          })
        })
      }
      if (isSubtle) {
        name =
          handleGenFileNameStr([this.subtleBigPart], {
            ...this.$store.state.ncSetting?.customizeFolder,
            max_file_name:
              this.$store.state.ncSetting?.customizeFolder.max_file_name -
              `(大板${this.currentIndex})`.length,
          }) + `(大板${this.currentIndex})`
      }
      if (process_setting.glass_setting) {
        // 给每一个大板添加修边尺寸数据
        const data = JSON.parse(JSON.stringify(ncDownData)).map((item) => {
          return {
            ...item,
            trim_size: this.standardPlank.plankEdgeOff,
          }
        })
        const glassFileFunc = async () => {
          const { data: res } = await axios({
            url: process.env.VUE_APP_BASE_URL + '/glass',
            method: 'POST',
            data: {
              setting_id: process_setting.process_setting_id,
              data,
            },
            headers: {
              token: window.sessionStorage.getItem('token'),
            },
          })
          if (res.status === 1) {
            if (this.confirmSendToFactory) {
              sendToFactoryFun(res.data)
            } else {
              const files = await this.genFileUrls(res.data)
              if (files && files.length) {
                this.downloadFile(
                  files,
                  name,
                  imgArr,
                  isNeedRecordSize,
                  isSubtle
                )
                this.isNCBtnLoading = false
              }
            }
          }
        }

        if (this.confirmSendToFactory) {
          this.generateFilesLoad(glassFileFunc)
        } else {
          // 玻璃切割机下载NC
          await glassFileFunc()
          if (this.proucePopupState.mark_action) {
            const data = {
              order_ids: [...new Set(this.orderIds)],
              mark_action: this.proucePopupState.mark_action,
            }
            await updateProduceStatus(data)
          }
        }
      } else {
        const newNCFileLoadFunc = async (isSendToFactory = false) => {
          const embedded = sessionStorage.getItem('embedded') ? 1 : 0
          const guigui_version = sessionStorage.getItem('guigui_version')
          let plate_knife_map = this.ncSetting.plate_knife_map
          for (const key in plate_knife_map) {
            let matCode = key.split(':')[0]
            let thick = key.split(':')[1]
            ncDownData.forEach((nc) => {
              if (matCode == nc.matCode && Number(thick) == Number(nc.thick)) {
                nc.plankKnife = plate_knife_map[key].knife
              }
            })
          }
          this.generateLabelNo(ncDownData)
          const copyData = JSON.parse(JSON.stringify(ncDownData))
          const payloadData = copyData.map((plank) => {
            return {
              ...plank,
              parts: plank.parts.map((part) => ({
                ...part,
                texDir: part.texDir == 'reverse' ? 'normal' : part.texDir,
                form_data: {
                  width:
                    part.texDir != 'reverse' ? part.specWidth : part.specHeight,
                  height:
                    part.texDir != 'reverse' ? part.specHeight : part.specWidth,
                },
              })),
            }
          })
          // parts按切割顺序进行升序排序
          payloadData.forEach((item) => {
            item.parts.sort((a, b) => a.priority - b.priority)
          })
          // 锯片切割增加longPlankFlag参数 长板加工生效的板件(门板和厚度超过xx的常规板)
          // 判断是否开启长板二次加工
          let {
            longPlankMinLength: longSide,
            longPlankShortMinLength: shortSide,
            longPlankExpandedSize: lpes,
            longPlankShortExpandedSize: lpses,
            longPlankCuttingEnable,
            cutSingleDoor,
            cutGeneralPlank,
            generalPlankThick,
          } = this.ncSetting.longPlankCuttingSetting

          if (longPlankCuttingEnable) {
            payloadData.forEach((item) => {
              item.parts.forEach((it) => {
                // 判断板子是否是门板或者厚度超过xx的常规板
                const rectWH = [it.rect.width, it.rect.height]
                const partLongSide = Math.max(...Object.values(rectWH))
                const partShortSide = Math.min(...Object.values(rectWH))
                if (
                  (it.type == 'SingleDoor' &&
                    cutSingleDoor &&
                    partLongSide > longSide &&
                    partShortSide > shortSide) ||
                  (it.type == 'Plank' &&
                    cutGeneralPlank &&
                    it.thick > generalPlankThick &&
                    partLongSide > longSide &&
                    partShortSide > shortSide)
                ) {
                  it.longPlankFlag = true
                } else {
                  it.longPlankFlag = false
                }
              })
            })
          } else {
            payloadData.forEach((item) => {
              item.parts.forEach((it) => {
                it.longPlankFlag = false
              })
            })
          }
          const { waste_setting } = this.ncSetting
          const { data: res } = await axios({
            url: process.env.VUE_APP_BASE_URL + '/new_nc',
            method: 'POST',
            data: {
              uid: this.userInfo.id,
              data: JSON.stringify(payloadData),
              id:
                process_setting.process_setting_name == '默认生产线'
                  ? -1
                  : process_setting.process_setting_id,
              keepKnifeOrigin: true,
              from: 'web',
              embedded,
              guigui_version,
              version: 'v1',
              longPlankCuttingSetting: this.ncSetting.longPlankCuttingSetting,
              waste_setting,
            },
            headers: {
              token: window.sessionStorage.getItem('token'),
            },
          })
          if (!isSendToFactory)
            await genMergeSubFolderData(
              this.paibanData,
              true,
              this.$store.state.ncSetting,
              'paibanData'
            )
          this.isNCBtnLoading = false
          if (res?.status == 1) {
            dealNCData('end')
            this.setNewNCRes(res)
            // 存储nc记录到试生产
            let isIconfontDownLoad = false
            this.lackKnivesList = []

            // TODO: 完成后续接口处理
            // 执行完毕后，根据是否是单订单，同步用料量
            const { orderIds, roomIds } = this.getOrderIdAndRoomId()
            if (orderIds.length === 1) {
              const boardList = this.dealDataToQianliyan(this.useMaterialData)
              const params = {
                order_id: orderIds[0],
                room_ids: roomIds,
                board_list: boardList,
              }
              fetchSendUseMaterialData(params)
                .then((res) => {
                  if (res.status === 1) {
                    message.success(res.msg)
                  } else {
                    message.error(res.msg)
                  }
                })
                .catch((err) => {
                  console.log('同步失败', err)
                })
            }

            const { isDeductionBaseMaterial } =
              this.$store.state.preferencesSetting.setting
            // 扣减原片显示，且勾选了才扣减原片
            if (
              this.deductionShowState.isShowBaseMaterialDeduction &&
              isDeductionBaseMaterial
            ) {
              try {
                const res = await fetchDeductBaseMaterial({
                  summary_plank_data: this.useMaterialData,
                })
                if (res.status === 1) {
                  message.success(res.data?.lbl_result?.data.export_msg)
                } else {
                  message.error(res.data?.msg)
                }
              } catch (error) {
                console.log(error)
              }
            }

            res.data.forEach((item) => {
              if (item.filetype === 'label' && item.specifications) {
                this.setOpenSingleFile(true)
              }
              if (
                item.hole_slot_unable_process &&
                Object.keys(item.hole_slot_unable_process).length > 0
              ) {
                this.lackKnivesList.push(item.hole_slot_unable_process)
                this.isShowLackKnivesDialog = true
                isIconfontDownLoad = true
              }
            })

            this.lackKnivesKeysList = Array.from(
              new Set(this.lackKnivesList.map((obj) => Object.keys(obj)[0]))
            )

            this.lackKnivesValueString = Array.from(
              new Set(
                this.lackKnivesList
                  .map((obj) => Object.values(obj)[0])
                  .flatMap((subArr) => subArr)
              )
            ).join(';')

            if (isIconfontDownLoad) {
              this.isDownloadingNc = false
              this.isTimeOut = false
              return
            }
            if (this.isTrialProduct) {
              sendTrialRecord(res.data, this.ncSetting)
            }
            if (this.confirmSendToFactory) {
              sendToFactoryFun(res.data)
            } else {
              if (genFile) {
                genFile('start')
              }
              const files = await this.genFileUrls(
                res.data,
                JSON.stringify(payloadData)
              )
              if (files && files.length) {
                this.downloadFile(
                  files,
                  name,
                  imgArr,
                  isNeedRecordSize,
                  isSubtle
                )
              } else {
                this.isDownloadingNc = false
                this.isTimeOut = false
                return
              }
            }
          } else {
            this.isDownloadingNc = false
            this.isTimeOut = false
            let regError = /过长工位无法加工/gi
            if (regError.test(res.msg)) {
              this.$confirm(`${res.msg}`, '提示', {
                confirmButtonText: '前往修改',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  this.$router.push('/baseMaterial')
                })
                .catch(() => {})
              return
            }
            if (res.msg === '找不到刀') {
              // 处理数据
              this.handleDealNotFoundKnivesData(res.data.data)
              return
            }
            this.$confirm(`${res.msg}`, '警告', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'error',
            })
              .then(() => {})
              .catch(() => {})
          }
          return res
        }
        // 确认发送到车间
        if (this.confirmSendToFactory) {
          this.generateFilesLoad(newNCFileLoadFunc, true)
        } else {
          // 普通NC下载
          const nc_data = await newNCFileLoadFunc()
          if (nc_data && Array.isArray(nc_data.data)) {
            this.labelInfoList = nc_data.data.filter(
              (i) => i.filetype === 'label'
            )
          }
          const data = {
            order_ids: [...new Set(this.orderIds)],
            mark_action: this.proucePopupState.mark_action,
          }
          await updateProduceStatus(data)
        }
      }
    },
    async dealJsonFile(jsonFileName) {
      const paibanData = JSON.parse(JSON.stringify(this.oriArr))
      paibanData.forEach((e) => {
        e.parts.forEach((part) => {
          const partInPlankarr = this.plankArr.find(
            (plank) =>
              part.plankID === plank.plankID && part.index === plank.index
          )
          if (partInPlankarr && partInPlankarr.isNonTexture) {
            part.texDir = 'notcare'
          } else {
            part.texDir = part.srcTexDir
          }
        })
      })
      let defaultStandardPlank = await dealStandardPlank(
        this.selectStandardPlank,
        {
          uid: this.userInfo.id,
          from: sessionStorage.getItem('thinkerx_material')
            ? 'guimen'
            : undefined,
        }
      )

      const jsonObj = {
        layoutData: this.preLayoutData,
        paibanData,
        process_setting_id: this.ncSetting.process_setting_id ?? -2,
        process_setting_name:
          this.ncSetting.process_setting_name ??
          this.$store.state.recodrProductionLineChoose ??
          this.$t('preLayoutSetting.preWayEmpty'),
        recodeSelectPart: this.$store.state.recodeSelectPart,
        recodeSelectSurplus: this.$store.state.recodeSelectSurplus,
        thinkerxMaterialKeys: this.$store.state.thinkerxMaterialKeys,
        paibanInfo: {
          paibanWay: this.selectedPaibanWay,
          isLessReverse: this.isLessReverse,
          isSpecialShape: this.isSpecialShape,
          isCuttingOrder: this.isCuttingOrder,
          isAutoPassData: this.isAutoPassData,
          hasSaveSurplus: this.hasSaveSurplus,
          paibanKey: this.paibanKey,
          // 嵌套方式
          nestWay: this.nestWay,
          historyPlankEdgeOff: this.historyPlankEdgeOff,
          historySecondTrimValue: this.ncSetting.secondTrimValue,
          selectedStandardPlank: defaultStandardPlank,
          customPlankOrder: store.state.customPlankOrder,
          plankOrderList: store.state.plankOrderList,
          batch: this.orderInfo.batch
            ? Object.keys(this.orderInfo.batch).every((key) => {
                return this.orderInfo.batch[key] > 0
              })
            : false,
        },
        // 保存历史需要记录未入库的板件
        localPlankStore: this.$store.state.awaitPaibanStore.localPlankStore,
        preLayoutSetting: this.ncSetting.isPreLayout
          ? this.oriLayoutSetting
          : undefined,
      }
      if (this.isShowSample) {
        jsonObj.sampleFormData = this.sampleTableData
      }
      const jsonBlob = new Blob([JSON.stringify(jsonObj)], { type: '' })
      let jsonFile
      if (jsonFileName) {
        jsonFile = new File([jsonBlob], jsonFileName, {
          type: '',
          lastModified: Date.now(),
        })
      }
      return { jsonFile, jsonBlob, jsonObj }
    },

    thinkerxMaterialSkip() {
      // 获取到所有补件的id
      const bujianOrderIds = this.availableBujianList.map(
        (item) => item.partUniqueId
      )
      const drawData = JSON.parse(JSON.stringify(this.drawData))
      const dealAfterData = {
        factory_id: drawData[0].data[0].parts[0].factoryId ?? this.factoryId,
        order_ids: '',
        fog_ids: '',
        result_data: [],
      }

      const newDrawData = drawData.map((ctx) => {
        return {
          surplusList: ctx.data.filter(
            (plank) => plank.surplusInfo && !plank.surplusInfo.isNotSurplus
          ),
          standPlankList: ctx.data.filter(
            (plank) =>
              (!plank.surplusInfo || plank.surplusInfo.isNotSurplus) &&
              !plank.otherPlate
          ),
          specialPlankList: ctx.data.filter(
            (plank) =>
              (!plank.surplusInfo || plank.surplusInfo.isNotSurplus) &&
              plank.otherPlate
          ),
          matCode: ctx.matCode,
          thick: ctx.thick,
          texture: ctx.texture,
        }
      })

      const parts = newDrawData
        .map((d) => [
          d.surplusList.map((s) => s.parts),
          d.standPlankList.map((s) => s.parts),
          d.specialPlankList.map((s) => s.parts),
        ])
        .flat(3)

      const fog_ids = Array.from(
        new Set(parts.map((part) => part.groupId))
      ).join(',')
      const order_ids = Array.from(
        new Set(parts.map((part) => part.orderId))
      ).join(',')

      const payload = newDrawData
        .map((data) => {
          const surplusPlankList = data.surplusList.map((s) => {
            return {
              surplusPlank: true,
              color: data.texture,
              thick: data.thick,
              count: 1,
              content: s.parts.map((item) => ({
                ...item,
                isSupplement: bujianOrderIds.includes(item.partUniqueId),
              })),
              thing_id: '',
              fog_ids,
              order_ids,
              width: s.plankWidth,
              length: s.plankHeight,
            }
          })

          // 经过处理后的标准大板上的小板数据
          const clonedStandParts = this.handleAddFlagForSurplusListToLbl(
            data.standPlankList,
            bujianOrderIds
          )
          let standPlank
          if (data.standPlankList.length) {
            standPlank = {
              // name: '标准大板',
              name: data.matCode,
              surplusPlank: false,
              color: data.texture,
              thick: data.thick,
              count: data.standPlankList.length,
              content: clonedStandParts,
              fog_ids,
              order_ids,
              thing_id: '',
              width: data.standPlankList[0].plankWidth,
              length: data.standPlankList[0].plankHeight,
            }
          }
          // 经过处理后的特殊大板上的小板数据
          const clonedSpecialParts = this.handleAddFlagForSurplusListToLbl(
            data.specialPlankList,
            bujianOrderIds
          )
          let specialPlank
          if (data.specialPlankList.length) {
            specialPlank = {
              // name: '特殊板材',
              name: data.matCode,
              surplusPlank: false,
              color: data.texture,
              thick: data.thick,
              count: data.specialPlankList.length,
              content: clonedSpecialParts,
              fog_ids,
              order_ids,
              thing_id:
                data.specialPlankList[0].surplusInfo.window_plank_id ?? '',
              width: data.specialPlankList[0].plankWidth,
              length: data.specialPlankList[0].plankHeight,
            }
          }
          const res = [...surplusPlankList]
          if (standPlank) res.push(standPlank)
          if (specialPlank) res.push(specialPlank)
          return res
        })
        .flat(1)

      dealAfterData.order_ids = order_ids
      dealAfterData.fog_ids = fog_ids
      dealAfterData.result_data = payload
      // 生成玻璃切割机数据
      if (this.ncSetting?.glass_setting) {
        dealAfterData.result_detail_data =
          this.genThinkerxMaterialGlassPlankData()
      }
      return dealAfterData
    },
    genThinkerxMaterialGlassPlankData() {
      const planks = this.drawData.map((it) => it.data).flat(1)
      const addAmount = (isExist, data) => {
        if (isExist === 'exists') {
          data.amount += 1
        } else {
          data.amount = 1
        }
      }
      return planks.map((plank) => {
        const data = window.$copy(plank.parts)
        return dataClustering(data, 'orderId', 'outItemPanelId', addAmount)
      })
    },
    // 获取文件数据
    getFile(url) {
      return new Promise((resolve) => {
        axios({
          method: 'get',
          url,
          responseType: 'arraybuffer',
        })
          .then((data) => {
            resolve(data.data)
          })
          .catch((err) => {
            resolve(false)
          })
      })
    },
    // 下载NC文件
    async downloadFile(arr, name, imgArr, isNeedRecordSize, isSubtle = false) {
      const { genFile, genZip } = this.ncLifeFuncObj
      const { jsonBlob } = await this.dealJsonFile()
      this.$store.commit('setFolderContents', {})
      this.$store.commit('setRepeatFileName', [])
      this.$store.commit('setTempFileName', [])
      const result = await downloadNCFile(
        arr,
        name,
        imgArr,
        isNeedRecordSize,
        this.paibanWayName,
        this.mergeFolder,
        this.ncSetting,
        () => {
          this.isDownloadingNc = false
          this.isTimeOut = false
          this.paibanWayName = this.orderInfo.order_codes
          !isSubtle && this.savePaiban(true, this.isAutoPassData)
        },
        jsonBlob,
        () => {
          this.isDownloadingNc = false
          this.isTimeOut = false
          this.isShowWarningModal = true
        }
      )
      if (genFile) {
        genFile('end')
      }

      genZip('start')
      if (result.isErr) {
        this.$confirm(result.errMsg, '警告', {
          type: 'error',
        })
        this.isDownloadingNc = false
        this.isTimeOut = false
        return
      }
      const {
        zip,
        nameStr,
        orderName,
        timeName,
        ncSuffix,
        qtRepeat,
        repeatNameList,
        repeatZipFileNameList,
        repeatZipFolderNameList,
      } = result
      // TODO 重名文件判断
      // 获取merge_folder_display_name
      if (qtRepeat) {
        this.repeatNameList = repeatNameList
        this.repeatFileNameList = repeatZipFileNameList
        this.repeatFolderNameList = repeatZipFolderNameList
        return
      }
      let repeatZipFolderNameList2 = []
      let repeatZipFileNameList2 = JSON.parse(
        JSON.stringify(this.$store.state.repeatFileName)
      )
      Object.keys(this.$store.state?.folderContents)?.forEach((item) => {
        if (
          !this.ncSetting.customizeFolder?.merge_folder_list.includes(
            fileNameSetObj[item] + '文件'
          ) &&
          this.ncSetting.customizeFolder?.merge_folder_list.length > 1
        ) {
          if (
            this.ncSetting?.customizeFolder?.merge_folder_display_name.trim() ===
            this.$store.state.folderContents[item].trim()
          ) {
            repeatZipFolderNameList2.push(fileNameSetObj[item])
          }
        }
      })
      this.repeatFileNameList = repeatZipFileNameList2
      this.repeatFolderNameList = repeatZipFolderNameList2
      this.repeatNameList = []
        .concat(repeatZipFolderNameList2)
        .concat(repeatZipFileNameList2)
      if (this.repeatNameList.length) {
        this.isDownloadingNc = false
        this.isTimeOut = false
        this.isShowWarningModal = true
        return
      }
      if (Object.keys(this.orderInfo).length && this.isTrialProduct) {
        try {
          for (let index = 0; index < sampleCabinets.length; index++) {
            const file = sampleCabinets[index]
            const result = await fetch(file).then((res) => res.blob())
            const base64Url = await convertBlobToBase64(result)
            zip.file(
              `试生产样品柜样例图/样品柜样例${index + 1}.png`,
              base64Url,
              {
                base64: true,
              }
            )
          }
        } catch (error) {
          console.error(error)
        }
      }
      if (JSON.parse(sessionStorage.getItem('isImportOrder'))) {
        // 柜门需要导出nc的订单数据信息
        const orderPlankData = dealPlankDataForLbl()
        const allPaibanPlankList = this.paibanData.map((p) => p.parts).flat(1)
        const plankParams = {
          uid: this.userInfo.id,
          order_list: orderPlankData.map((data) => {
            const stockKeyList = Array.from(
              new Set(
                data.map((part) => {
                  const paibanPart = allPaibanPlankList.find(
                    (p) => p.oriPartUniqueId == part.partUniqueId
                  )
                  const stockKey = paibanPart?.stockKey ?? paibanPart.stock_key
                  return stockKey
                })
              )
            )
            const oriPartUniqueIdList = data.map((part) => part.partUniqueId)
            const plankData = allPaibanPlankList.filter((plank) =>
              oriPartUniqueIdList.includes(plank.oriPartUniqueId)
            )
            return {
              buyer_address: data[0].address ?? '',
              customer: data[0].customer ?? data[0].customer_name ?? '',
              order_code: data[0].orderNo ?? '',
              room_name: data[0].roomName ?? '',
              remark: data[0].remark ?? '',
              plank_data: plankData.map((part) => {
                const p = data.find(
                  (p) => p.partUniqueId === part.oriPartUniqueId
                )
                return {
                  ...p,
                  name: p.partName ?? '',
                  edgeInfo: p.edgeInfo ?? '',
                  number: p.plankID ?? '',
                  width: p.specWidth,
                  height: p.specHeight,
                  amount: 1,
                  plankNum: part.plankNum,
                }
              }),
              big_plank_data: Object.values(
                this.paibanData
                  .filter((item) => stockKeyList.includes(item.stockKey))
                  .reduce((acc, cur) => {
                    const stock_key = `${cur.plankHeight}-${cur.plankWidth}-${cur.texture}-${cur.thick}-${cur.matCode}}`
                    if (acc[stock_key]) {
                      acc[stock_key].count += 1
                    } else {
                      acc[stock_key] = {
                        count: 1,
                        name: cur.matCode,
                        length: cur.plankHeight,
                        width: cur.plankWidth,
                        color: cur.texture,
                        thick: cur.thick,
                      }
                    }
                    return acc
                  }, {})
              ),
            }
          }),
        }
        saveOrderInfoToLbl(plankParams)
      }
      if (this.isAutoPassData) {
        this.paibanWayName = orderName ? orderName : timeName
        this.paibanWayName =
          this.paibanWayName.length > 900
            ? this.paibanWayName.slice(0, 900).concat('···')
            : this.paibanWayName
      }
      this.isDownloadingNc = false
      this.isTimeOut = false
      // 贴标文件和大板的文件的对应关系
      const plankWidthLabel = {}
      // 处理贴标文件
      const { label_img_big_plank_folder, labeling_file_insert } =
        this.ncSetting
      if (
        label_img_big_plank_folder &&
        labeling_file_insert &&
        this.imgZip &&
        this.currentTagType.includes('图片')
      ) {
        // 获取zip文件里面的贴标信息
        const zipLabelInfoList = {}
        Object.keys(zip.files).forEach((key) => {
          if (key.startsWith(`${this.labeling_folder}/`) && key.length > 5) {
            const k = key.split('/')[1]
            if (k) {
              zipLabelInfoList[k] = zip.files[key]
            }
          }
        })
        const zipLableKeys = Object.keys(zipLabelInfoList)
        // 获取大板文件夹
        const bigpartDirList = []
        Object.keys(this.imgZip.files).forEach((key) => {
          if (key.endsWith('/')) {
            bigpartDirList.push(key.slice(0, -1))
          }
        })
        // 将贴标信息放到大板里面去
        this.labelInfoList.forEach((item, index2) => {
          // 当前的大板文件夹位置
          const index = item.stock_num - 1
          if (zipLableKeys[index2]) {
            // this.imgZip.files[
            //   `${bigpartDirList[index] + '/' + zipLableKeys[index]}`
            // ] = zipLabelInfoList[zipLableKeys[index]]
            if (!plankWidthLabel[index]) {
              plankWidthLabel[index] = []
            }
            plankWidthLabel[index].push({
              [`${bigpartDirList[index] + '/' + zipLableKeys[index2]}`]:
                zipLabelInfoList[zipLableKeys[index2]],
            })
          }
        })
        zip.remove(this.labeling_folder)
      }
      const savePaibanFn = (url) => {
        if (!this.isAutoPassData) {
          this.savePaiban(true, false, url)
        } else {
          this.isHistoryStatus
            ? this.comfirmSave
              ? this.savePaiban(
                  true,
                  this.isAutoPassData && this.hasChangePaiban,
                  url
                )
              : this.savePaiban(true, false, url)
            : this.savePaiban(true, this.isAutoPassData, url)
        }
      }
      // 将贴标信息放进最终生成的文件夹里面
      this.labelImgList.forEach((item, i) => {
        // 将贴标信息放到大板里面去
        if (
          item[0] &&
          Object.keys(plankWidthLabel).length &&
          labeling_file_insert &&
          label_img_big_plank_folder &&
          this.currentTagType !== '导出PDF'
        ) {
          plankWidthLabel[i].forEach((it) => {
            let labelName =
              item[0].name.split('/').slice(0, -1).join('/') +
              '/' +
              Object.keys(it)[0].split('/').pop()
            zip.files[labelName] = it[Object.keys(it)[0]]
          })
        }
        if (this.currentTagType !== '导出PDF') {
          item.forEach(async (it) => {
            await zip.file(it.name, it.url, { base64: true })
          })
        }
      })
      await promiseToZip(
        zip,
        null,
        nameStr,
        (url) => {
          !isSubtle && savePaibanFn(url)
        },
        () => {
          this.isDownloadingNc = false
          this.isTimeOut = false
        },
        this,
        this.ncSetting
      )
      this.isContinue = false
      this.continueDownloadFlag = false
      // 下载成功提示
      this.$message.success(this.$t('common.downloadDoneByType'))
    },
    mergeFolder() {
      this.mergeFolderList = this.folderSetting.merge_folder_list
      this.mergeFolderDisplayName = this.folderSetting.merge_folder_display_name
      // 如果有合并文件
      this.needMerge = this.folderSetting.merge_folder_list?.length > 1
      if (this.folderSetting.use_category_folder) {
        // 如果为true，那么就不使用合并文件
        this.needMerge = false
      }
    },

    // 读取老板良跳转过来的json数据
    dealJsonFromLbl(url) {
      axios.get(url).then((res) => {
        if (res.data.hasOwnProperty('employee')) {
          if (res.data.employee != 0) {
            this.changeLblAuth(false)
          }
        }
        this.isRefresh = false
        this.showLoading = false
        let lblJson = res.data
        let orderInfo = {
          order_address: res.data.order_address,
          order_list: res.data.order_list.join(','),
          room_ids: [],
          needPaibanRoomIds: [],
          order_codes: res.data.order_code,
        }
        this.setOrderInfo(orderInfo)
        // 区分柜柜和班鲁保存的数据
        // 柜柜
        if (
          lblJson.plank_info.dataFrom &&
          lblJson.plank_info.dataFrom == 'ggpc'
        ) {
          let zipUrl = lblJson.plank_info.historyUrl
          getZipJson(zipUrl).then((result) => {
            this.changeDataFrom('ggpc')
            for (let i = 0; i < result.length; ++i) {
              let data = JSON.parse(result[i])
              dealGuiguiJson(JSON.parse(data.layoutResult)).then(
                (finalData) => {
                  this.dealPaibanData()
                  this.setPreLayoutData(dealPreLayoutDataLabelID(finalData))
                }
              )
            }
          })
        } else {
          // 班鲁web
          axios.get(lblJson.plank_info.historyUrl).then((finalData) => {
            this.setPaibanData(finalData.data.paibanData)
            this.setPreLayoutData(
              dealPreLayoutDataLabelID(finalData.data.layoutData)
            )
            let params = {
              setting_id: finalData.data.process_setting_id,
            }
            if (window.sessionStorage.getItem('thinkerx_material')) {
              params.production_from = 'guimen'
            }
            this.$getByToken('/get_production_nc_setting', params, (nc) => {
              nc.data.drawPlankWidth = nc.data.xyReverse
                ? nc.data.panelSize.plankHeight
                : nc.data.panelSize.plankWidth
              nc.data.drawPlankHeight = nc.data.xyReverse
                ? nc.data.panelSize.plankWidth
                : nc.data.panelSize.plankHeight
              let ncSetting = Object.assign(nc.data, {
                process_setting_id: finalData.data.process_setting_id,
                process_setting_name: finalData.data.process_setting_name,
              })
              this.setNcSetting(ncSetting)
              this.dealPaibanData()
            })
          })
        }
      })
    },
    // 键盘删除事件
    keyDownFuncs() {
      return (e) => {
        if (e.code == 'Delete') {
          e.preventDefault()
          if (this.bigPart && this.bigPart.isLocked) {
            return this.$message.error('板件被锁定，无法删除!')
          }
          this.deletePlank()
        }
      }
    },
    dealOptimizeArea(drawData) {
      const data = drawData.map((item) => item.data).flat(2)
      // 计算优化率
      drawData.forEach((item) => {
        let usedRate = item.data.reduce(
          (prev, cur) => (prev += cur.usedRate),
          0
        )
        item.usedRate = usedRate / item.data.length
      })
      let totalUsedRate = data.reduce((prev, cur) => (prev += cur.usedRate), 0)
      this.totalUsedRate = totalUsedRate / this.oriArrLen
      this.setPaibanExtraInfo({
        total_used_rate: this.totalUsedRate,
        total_fanban: this.totalBigpartFanban,
        total_big_planks: this.totalBigpartCounts,
      })
    },
    // 应用方案
    useApply(specialId, paibanResult, loadingInstance) {
      this.alignedPlanDialog = false
      this.dealAllDrawData()
      this.renderAll(true)
      // 方案存在直接使用已存在的方案数据 不在重新生成
      if (!paibanResult) {
        // 如果当前排版方案重未保存过，保存一次排版绘制数据
        const obj = {
          paibanData: this.paibanData,
          specialId,
        }
        this.$store.commit('paibanStore/setAlreadyDealDrawData', {
          specialId,
          value: JSON.parse(JSON.stringify(obj)),
        })
      }
      // 关闭loading
      this.startTailorSurplus()
      this.rateSort()
      loadingInstance.close()
    },
    async startTailorSurplus() {
      const res = await tailorSurplus(this.drawData)
      if (res) {
        this.dealOptimizeArea(this.drawData)
        this.renderAll()
      }
    },
    async updateTaskStatus() {
      this.loadingPrompts = 'arrangedPage.updateTaskStatus'
      this.isUpdatingTask = true
      const taskDetail = showCardDetail(this.preLayoutData)

      const jsonData = {
        layoutData: this.preLayoutData,
        paibanData: JSON.parse(JSON.stringify(this.oriArr)),
        process_setting_id: this.ncSetting.process_setting_id,
        process_setting_name: this.ncSetting.process_setting_name,
        recodeSelectPart: this.$store.state.recodeSelectPart,
        recodeSelectSurplus: this.$store.state.recodeSelectSurplus,
        thinkerxMaterialKeys: this.$store.state.thinkerxMaterialKeys,
        paibanInfo: {
          paibanWay: this.selectedPaibanWay,
          isLessReverse: this.isLessReverse,
          isSpecialShape: this.isSpecialShape,
          filterPlankList: this.filterPlankList,
          historyPlankEdgeOff: this.historyPlankEdgeOff,
          historySecondTrimValue: this.ncSetting.secondTrimValue,
        },
        matCode: taskDetail?.matCode ? taskDetail?.matCode : '',
        texDir: taskDetail?.texDir ? taskDetail?.texDir : '',
        texture: taskDetail?.texture ? taskDetail?.texture : '',
      }
      //dddd
      if (this.isBatch) {
        Object.assign(jsonData, {
          batchNo: this.batchPaiban
            ? this.batchPaiban.batchNo
            : this.batchNo
            ? this.batchNo
            : `${getDate()}-${Number(this.produceTime) + 1}`,
        })
      }
      if (this.ncSetting.isPreLayout) {
        Object.assign(jsonData, {
          isPreLayout: true,
          preLayoutSetting: this.oriLayoutSetting,
        })
      }
      const paibanJson = new File(
        [new Blob([JSON.stringify(jsonData)], { type: '' })],
        `taskData_${new Date().getTime()}.json`,
        {
          type: '',
          lastModified: Date.now(),
        }
      )
      const res = await uploadFile({
        file: paibanJson,
        ossDir: 'bluenMaterialData',
      })
      if (!this.isBatch) {
        this.materialTask.forEach(async (task) => {
          let payload = {
            platform: task.platform,
            task_ids: [task.id],
            paiban_json: res,
            process_id:
              this.ncSetting.process_line_id ??
              this.ncSetting.process_setting_id ??
              -2,
            task_batch: `${getDate()}-${Number(this.produceTime) + 1}`,
          }
          if (task.current_step) {
            Object.assign(payload, { current_step: task.current_step })
          }
          this.setProduceTime(Number(this.produceTime) + 1)
          await updateToHasPaiban(payload)
        })
      } else {
        const payload = {
          platform: this.materialTask[0].platform,
          task_ids: this.materialTask.map((task) => task.id),
          process_id:
            this.ncSetting.process_line_id ??
            this.ncSetting.process_setting_id ??
            -2,
          paiban_json: res,
        }

        if (!this.batchPaiban) {
          Object.assign(payload, {
            task_batch: this.batchNo
              ? this.batchNo
              : `${getDate()}-${Number(this.produceTime) + 1}`,
          })
        }

        await updateToHasPaiban(payload)
        this.setBatchPaiban({
          ...jsonData,
          batchNo: this.batchPaiban
            ? this.batchPaiban.batchNo
            : this.batchNo
            ? this.batchNo
            : `${getDate()}-${Number(this.produceTime) + 1}`,
        })
        if (!this.batchPaiban) {
          this.setProduceTime(Number(this.produceTime) + 1)
        }
      }
      this.setIsNewPaiban(false)
      this.isUpdatingTask = false
    },
    handleChangeImageTempMousedownFlag(val) {
      this.imageTempMousedownFlag = val
    },
    showPartSize(field, needShowField, part) {
      const plank = part ?? this.activePlank
      const rect = getOriginRect(plank, field)
      return (rect?.[needShowField] ?? 0).toFixed(2)
    },

    handleSurplusSave(event, isSaveStorage) {
      this.hasSaveSurplus = true
      buryPointApi('layout', 'sup_once_warehousing')
      const allBigparts = this.paibanData
      const allSurplus = allBigparts
        .map((bigpart) => bigpart.parts)
        .flat(1)
        .filter((part) => part.surplusPath && !part.hasSaved)

      this.$getByToken('/get_user_plank_setting', {}, (res) => {
        if (res.status == 1) {
          const surplusIndex = res.data.next_surplus_name.split('余料')[1]
          const surplusPayload = allSurplus.map((plank) => {
            const { matCode, texture, thick, texDir, surplusPath } = plank
            const { min_width, min_long, width, long } = dealSurplusSize(plank)
            const area =
              min_long && min_width
                ? width * long - (width - min_width) * (long - min_long)
                : width * long
            const isLshape = surplusPath[0].length > 4
            return {
              stockKey: this.genStockKey(plank),
              type: matCode,
              color: texture,
              thick,
              width,
              long,
              area: Number(
                (isLshape
                  ? area / 1000 / 1000
                  : (width * long) / 1000 / 1000
                ).toFixed(4)
              ),
              texDir,
              shape: isLshape ? 1 : 0,
              min_long,
              min_width,
            }
          })
          const resultSurplus = this.groupBy(surplusPayload, 'stockKey')
          const payload = []
          for (const key in resultSurplus) {
            payload.push({
              ...resultSurplus[key][0],
              amount: resultSurplus[key].length,
            })
          }
          const surplus_data = payload.map((e, index) => ({
            ...e,
            name: '余料' + (Number(surplusIndex) + index),
          }))
          this.$token(
            '/create_surplus_depot',
            { surplus_data, designated: isSaveStorage ? true : false },
            (res) => {
              if (res.status == 1) {
                const savedPlankNumList = allSurplus.map((sur) => sur.plankNum)
                allBigparts.forEach((bigpart) => {
                  bigpart.parts.forEach((part) => {
                    if (savedPlankNumList.includes(part.plankNum)) {
                      part.hasSaved = true
                    }
                  })
                })
                if (res.data.length) {
                  allSurplus.forEach((surplus, index) => {
                    // surplus.hasSaved = true
                    surplus.branch_name =
                      res.data[index]?.branch_name ?? res.data[0]?.branch_name
                    surplus.branch_no =
                      res.data[index]?.branch_no ?? res.data[0]?.branch_name
                  })
                }
                this.hasSaveSurplus = true
                this.$message({
                  message: '入库成功!',
                  type: 'success',
                })
                if (isSaveStorage) {
                  this.showSurStoreTip = false
                }
              } else {
                this.hasSaveSurplus = false
                if (res.data?.error_code == '0') {
                  this.showSurStoreTip = true
                  return
                }
                this.$message({
                  message: res.msg,
                  type: 'warning',
                })
                if (isSaveStorage) {
                  this.showSurStoreTip = false
                }
              }
            }
          )
        }
      })
    },

    genStockKey(plank) {
      const { matCode, texture, thick, surplusPath } = plank
      const { min_width, min_long, width, long } = dealSurplusSize(plank)
      const stockKey = `${matCode}-${texture}-${thick}-${long}-${width}`
      return surplusPath[0].length <= 4
        ? stockKey
        : stockKey + `-${min_long}-${min_width}`
    },

    groupBy(arr, property) {
      return arr.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    },

    isNewContent(key) {
      return (
        this.newPaibanTags &&
        Boolean(this.newPaibanTags.find((tag) => tag.web_property === key))
      )
    },
    setHasSavedSurplus() {
      this.$nextTick(() => {
        const allSurplus = this.paibanData
          .map((bigpart) => bigpart.parts)
          .flat(1)
          .filter((part) => part.surplusPath && !part.hasSaved)
        this.hasSaveSurplus = !allSurplus.length
        const addSurplus = this.paibanData
          .map((bigpart) => bigpart.parts)
          .flat(1)
          .filter((part) => part.surplusPath)
        this.hasCut = addSurplus.length
      })
    },
    async subtleDownloadNC(bigpart, isContinue) {
      this.currentDownloadType = 'jingxi'
      this.currentOperate = 'nc'
      // 必须在最开始就赋值，后面会使用
      this.subtleBigPart = bigpart
      // 检查本地所使用的目录是否存在
      if (!(await this.$refs.folderChooseModalRef?.verify())) {
        return
      }
      this.$refs.subtleRef.changeLoading(true)
      this.isNCBtnLoading = true
      this.currentIndex = bigpart.orderIndex
      this.subtleBigPart = bigpart
      let surplusPlank
      if (
        bigpart.surplusInfo &&
        Object.keys(bigpart.surplusInfo).length &&
        !bigpart.surplusInfo.isNotSurplus
      ) {
        surplusPlank = bigpart
        this.useSurplusId = [bigpart.surplusInfo.id]
        const surplusRes = await getSurplusList({ page: 1, limit: 999 })
        const allSurplusList = surplusRes.status ? surplusRes.data.data : []
        this.useSurplusList = allSurplusList.filter((item) =>
          this.useSurplusId.includes(item.id)
        )
      }
      // 初始化下载nc的任务状态
      this.ncTaskList = []
      this.ncLifeFuncObj = {}
      this.ncLifeFuncObj = initNcLifeObj(
        this,
        this.currentTagType,
        this.isFollowExportTag
      )
      const { checkData } = this.ncLifeFuncObj
      checkData('start')
      const isReturn = this.beforDownloadCheck(surplusPlank, isContinue, true)
      if (isReturn) {
        this.isNCBtnLoading = false
        return
      }
      checkData('end')
      // 传递精细排版的数据
      this.beforeDownloadNC([this.subtleBigPart], true)
    },
    subtlePrintTag(bigpart) {
      this.currentIndex = bigpart.orderIndex
      this.gotoTagLabel(bigpart, true)
    },
    //生成排版key
    async genPaibanKey() {
      const statisticData = dealPlankType(this.paibanData)
      const plankData = statisticData.plankData
      let obj = {
        plank_only: 0,
        plank_data: [],
      }

      obj.edge_data = statisticData.edgeData
      obj.addon_data = statisticData.addonData

      obj.plank_data = plankData.map(
        ({
          matCode,
          count,
          texture,
          thick,
          width,
          height,
          plankType,
          branch_name,
        }) => {
          return {
            material: matCode,
            color: texture,
            thick,
            spec: `${width}*${height}`,
            count: String(count),
            plankType,
            branch_name,
          }
        }
      )

      obj.filter_data = [
        {
          order_id: this.orderInfo.order_ids,
          room_id: this.orderInfo.room_ids,
          order_code: this.orderInfo.order_codes,
        },
      ]
      obj.uid = this.userInfo.id

      const src =
        process.env.VUE_APP_ENV === 'production'
          ? 'https://ggtools.thinkerx.com/erp/put_paiban_info'
          : 'https://test-api.eggrj.com/erp/put_paiban_info'

      await axios.post(src, obj).then((res) => {
        if (res.data.data) {
          this.setPaibanKey(res.data.data)
          this.setFactoryId(
            res.data.factory_id
              ? res.data.factory_id
              : sessionStorage.getItem('thinkerx_material_factory_id')
          )
        }
      })
    },
    handleSendToFactory() {
      if (this.ncSetting.isPreLayout) {
        this.checkSettingChange()
        return
      }
      if (this.beforeCommonCheck()) {
        return
      }
      // 打开发给车间生产页面
      this.isSendToFactoryLoading = true
      this.sendToFactoryDialog = true
      this.confirmSendToFactory = false
      let flag = this.judgeFlag()
      if (!flag) {
        this.$message.warning(translate('arrangedPage.cutOrderErr'))
        this.isSendToFactoryLoading = false
        this.sendToFactoryDialog = false
        return
      }
    },
    // 显示大板用料清单弹窗
    handleShowMatList() {
      this.showMaterialList = true
    },
    handleCloseSend() {
      this.sendToFactoryDialog = false
      this.confirmSendToFactory = false
      this.isSendToFactoryLoading = false
    },
    // 确认发送给车间生产
    async handleConfirmSend({ tagDocType, currentTagTemp }) {
      buryPointApi('layout', 'send_factory')
      if (this.isOnlyShowSurplus) {
        this.isOnlyShowSurplus = false
        this.handleShowSurplus()
      }
      this.sendToFactoryDialog = false
      this.confirmSendToFactory = true
      this.tagSetting = {
        tagDocType,
        currentTagTemp: {
          ...currentTagTemp,
          render_url: currentTagTemp.render_url
            ? currentTagTemp.render_url?.replace(/http:/g, 'https:')
            : '',
        },
      }
      this.handleDownloadNC(true)
    },
    async generateTagsFile(
      template,
      exportType,
      isNeedLoading = true,
      isFileOnly = false
    ) {
      this.taskList = []
      this.isShowTask = isNeedLoading
      const exportFlag = exportType === '导出PDF'
      // 非pdf导出都导出图片
      const exportObj = { isTag: !exportFlag, isPdf: exportFlag }
      const lifeFuncObj = {
        loadSetting: dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.loadSetting'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.loadSetting'
          )}`,
          translate('arrangedPage.ncDownloadLifeText.loadSettingText'),
          this
        ),
        loadPreferences: dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.loadPreference'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.loadPreference'
          )}`,
          translate('arrangedPage.loadPreference'),
          this
        ),
        dealTemp: dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.dealTemplate'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.dealTemplate'
          )}`,
          translate('arrangedPage.dealTemplate'),
          this
        ),
        initialData: dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.initTagData'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.initTagData'
          )}`,
          translate('arrangedPage.ncDownloadLifeText.genTagFileText', {
            fileType: exportFlag
              ? 'PDF'
              : translate('arrangedPage.ncDownloadLifeText.fileTypeText'),
          }),
          this
        ),
        dealDrawData: dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.prepareDrawData'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.prepareDrawData'
          )}`,
          translate('arrangedPage.drawDataText'),
          this
        ),
      }
      // 生成其他的任务信息(主要是后面的需求变更导致这样写)
      let zipLoadExe, upLoadExe
      if (exportFlag) {
        lifeFuncObj.genTagPdf = dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.genPdfTagFile'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.genPdfTagFile'
          )}`,
          translate('arrangedPage.genPdfTagFile'),
          this
        )
      } else if (exportType === '导出图片') {
        lifeFuncObj.genTagImg = dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.genPicTagFile'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.genPicTagFile'
          )}`,
          translate('arrangedPage.genPicTagFile'),
          this
        )
        zipLoadExe = dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.zipFile'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.zipFile'
          )}`,
          translate('arrangedPage.ncDownloadLifeText.genZipText'),
          this
        )
      }
      upLoadExe = dealTaskLoading(
        `${translate('arrangedPage.loadding')}:${translate(
          'arrangedPage.uploadFile'
        )}`,
        `${translate('arrangedPage.finish')}:${translate(
          'arrangedPage.uploadFile'
        )}`,
        translate('arrangedPage.uploadFile'),
        this
      )
      // 生成pdf blob对象或标签tag base64数组
      this.generateLabelNo(
        this.subtleBigPart ? [this.subtleBigPart] : this.paibanData
      )
      const data = this.subtleBigPart ? [this.subtleBigPart] : this.paibanData
      const file = await generateTagFile(
        data,
        template,
        this.$store,
        exportObj,
        lifeFuncObj,
        this.ncLifeFuncObj
      )
      // 未能正常生成直接返回
      if (!file) {
        return null
      }
      if (exportType === '直接打印') {
        return {
          data: file.tagData,
          type: 'print',
        }
      }
      // 是否需要压缩，只有图片需要压缩
      let zipFile = null
      if (file.tagData && exportType === '导出图片') {
        const {
          data,
          zip: imgZip,
          labelImgList,
        } = await createTagImageZip(
          file.tagData,
          this.$store,
          template.name,
          this.subtleBigPart
            ? [this.subtleBigPart]
            : JSON.parse(JSON.stringify(this.paibanData)),
          true
        )
        zipFile = data
        this.imgZip = imgZip
        this.labelImgList = labelImgList
        zipLoadExe('end')
      }
      let ossUrl = null
      if (exportFlag || exportType === '导出图片') {
        upLoadExe('start')
        const data = zipFile ? zipFile : file.pdfData.data
        const suffix = zipFile ? '.zip' : '.pdf'
        const uploadFile = new File([data], template.name + suffix, {
          type: data.type,
        })
        // 直接导出文件
        if (isFileOnly) {
          upLoadExe('end')
          return { uploadFile, tagfileData: file.tagData?.collect }
        } else {
          // 上传文件
          ossUrl = await uploadFileToOss(
            uploadFile,
            null,
            null,
            'productionWorkShop',
            true
          )
          upLoadExe('end')
        }
      }
      return {
        data: ossUrl,
        type: 'url',
      }
    },
    // 文件处理加载
    async generateFilesLoad(NCLoading, isSendToFactory = false) {
      const { currentTagTemp, tagDocType } = this.tagSetting
      try {
        const ossUrl = await this.generateTagsFile(currentTagTemp, tagDocType)
        if (!ossUrl) return
        const ncLoadExe = dealTaskLoading(
          `${translate('arrangedPage.loadding')}:${translate(
            'arrangedPage.dealNCFile'
          )}`,
          `${translate('arrangedPage.finish')}:${translate(
            'arrangedPage.dealNCFile'
          )}`,
          translate('arrangedPage.dealNCFile'),
          this
        )
        ncLoadExe('start')
        this.ossUrl = ossUrl
        await NCLoading(isSendToFactory)
        ncLoadExe('end')
        this.isShowTask = false
        this.confirmSendToFactory = false
        this.isNCBtnLoading = false
        // 更新标记状态
        if (!this.orderIds || !this.orderIds.length) {
          return
        }
        const data = {
          order_ids: [...new Set(this.orderIds)],
          mark_action: this.proucePopupState.mark_action,
        }
        await updateProduceStatus(data)
      } catch (error) {
        this.isShowTask = false
        this.confirmSendToFactory = false
        this.isNCBtnLoading = false
        this.$confirm(translate('arrangedPage.sendErr'))
      }
    },
    // 试生产模态框已上传图片后确认下载nc
    async handleTrialProductConfirm() {
      await this.beforeDownloadNC()
    },
    // 试生产模态框关闭
    handleTrialProductCancel() {
      // 切换nc下载按钮loading状态
      this.isNCBtnLoading = false
    },
    // 窗口点击事件
    onMoreSetting(e) {
      let isMoreSetting = false //是不是更多设置
      let isBody = false // 是不是已经找到了body元素了
      let target = e.target
      const bodyElement = document.getElementsByTagName('body')[0] //body元素
      while (!isMoreSetting && !isBody) {
        // 点击弹框中的内容说明是更多设置
        if (
          (target.id && target.id === 'paiban_export_setting_menu') ||
          target.id === 'paiban_export_setting_dropdown' ||
          target.id.includes('paiban_tag_template_select')
        ) {
          isMoreSetting = true
        } else if (target === bodyElement) {
          isBody = true
          this.isMoreSetting = false
        } else {
          target = target.parentNode
        }
      }
    },

    handleClickMoreSetting() {
      this.isMoreSetting = !this.isMoreSetting
      if (this.isMoreSetting) {
        document.body.addEventListener('mousedown', this.onMoreSetting)
      } else {
        document.body.removeEventListener('mousedown', this.onMoreSetting)
      }
      this.currentTagTempID = this.initTagTempID()
    },
    // 生成需要下载的文件URL数组
    async genFileUrls(data, ncPayloadData = '') {
      let flag = this.judgeFlag()
      if (!flag) {
        this.$message.warning(translate('arrangedPage.cutOrderErr'))
        return []
      }
      const files = [...data]
      // TODO 暂时下线内嵌下载NC顺带导出标签
      if (this.isFollowExportTag && !this.isWebToQt) {
        const currentTagTemp = this.tagTempList.find(
          (temp) => temp.id === this.currentTagTempID
        )
        const res = await this.generateTagsFile(
          currentTagTemp,
          this.currentTagType,
          false,
          this.isFollowExportTag
        )
        const tagFile = res.uploadFile
        files.push({
          fileType: 'tag',
          url: tagFile.data,
          exportType: this.currentTagType.includes('PDF') ? 'pdf' : 'zip',
          file_display_name: this.orderInfo.order_address,
          fileData: this.isFollowExportTag ? tagFile : false,
          tagData:
            this.isFollowExportTag && this.currentTagType.includes('图片')
              ? res.tagfileData
              : null,
        })
      }

      if (this.ncSetting.automation) {
        const res = await getExcelData({
          big_planks: ncPayloadData,
          factory_id: this.factoryId ?? 13195,
        })
        const excelFile = exportExcel(res.data)
        files.push({
          fileType: 'excel',
          url: excelFile,
          exportType: 'xlsx',
          file_display_name: '生产进度跟踪批次导入表格',
          fileData: excelFile,
        })
      }

      try {
        files.forEach((url) => {
          // 利用异常抛出错误
          if (url.error_info) {
            throw new Error(url.error_info)
          }
        })
      } catch (e) {
        this.$confirm(e.message, translate('common.warning'), {
          type: 'error',
        })
        return
      }

      return files
    },
    // 标签文件类型值的兼容
    initTagFileType(type) {
      if (type) {
        if (['导出PDF', '导出pdf'].includes(type)) return '导出PDF'
        else return '导出图片'
      } else {
        return '导出PDF'
      }
    },
    // 初始化标签选中模板ID
    initTagTempID() {
      const tempIDArr = this.tagTempList.map((temp) => temp.id)
      const defaultTemp = this.tagTempList.find((temp) => temp.is_default)
      const defualtTempID = defaultTemp ? defaultTemp.id : 0
      let id = 0
      if (tempIDArr.includes(this.currentTagTempID)) {
        id = defualtTempID
      } else if (
        this.paibanInfo &&
        tempIDArr.includes(this.paibanInfo.currentTagTempID)
      ) {
        id = this.paibanInfo.currentTagTempID
      } else {
        id = 0
      }
      return id
    },
    handlePlankRollPoly() {
      this.drawData = this.drawData.map((plankData) => {
        plankData.data.forEach((item) => {
          item.parts.forEach((val) => {
            if (val.is_high_gloss_plank) {
              rolloverPlank(val)
              dealPlankPoly(val)
            }
          })
        })
        return plankData
      })
      this.setHasRollPoly(true)
    },
    // 初始化高光翻面
    async handleInitAllHighGlossSide() {
      // 本次排版是正面且不是历史订单进行高光翻面
      if (
        this.highgloss_direction === 'front' &&
        !this.isHistoryStatus &&
        !this.hasRollPoly
      ) {
        const { from } = this.$route.query
        if (from == 'history_paiabn') {
          return
        } else {
          this.handlePlankRollPoly()
        }
      }
    },
    judgeFlag() {
      let flag = true

      const paibanData =
        this.surplusBigpart && this.surplusBigpart.stockNum
          ? [this.surplusBigpart]
          : this.paibanData
      for (let index = 0; index < paibanData.length; index++) {
        const plank = paibanData[index]
        // 是否存在重复切割顺序
        // 是否是连续的切割顺序
        let prioritys = []
        for (let idx = 0; idx < plank.parts.length; idx++) {
          const part = plank.parts[idx]
          if (prioritys.includes(part.priority)) {
            flag = false
            return flag
          } else {
            prioritys.push(part.priority)
          }
        }
        prioritys
          .sort((a, b) => a - b)
          .forEach((item, idx) => {
            // 排序后检测是否是连续性的 如1，2，3 true  1，3，2 false
            if (flag) {
              flag = item == idx + 1
            }
          })
      }
      return flag
    },
    clearConflict() {
      this.isExistsActivePlank = !!this.getAllActive().filter((it) => it).length
    },
    judgmentConflicts(drawDataArr = this.paibanData) {
      this.isShowConflictPrompts = false
      drawDataArr.forEach((item) => {
        item.parts.forEach((val) => {
          if (val?.holeSlotMerge) {
            this.isShowConflictPrompts = true
          }
        })
      })
      return this.isShowConflictPrompts
    },
    // 收集冲突板件数据
    handleCollectConflicts() {
      const list = []
      JSON.parse(JSON.stringify(store.state.finalDrawData)).forEach((item) => {
        item.data.forEach((parts) => {
          // 过滤掉不包含孔槽冲突的板件并且按照小板切割顺序
          parts.parts = parts.parts
            .filter((i) => i.holeSlotMerge)
            .sort((a, b) => a.priority - b.priority)
          parts.parts.forEach((it) => {
            list.push(it)
          })
        })
      })
      this.conflictPlankList = list
    },
    handleClickLock() {
      this.jundgeAllPlankLocked()
    },
    // 判断所有的大板是否锁定
    jundgeAllPlankLocked() {
      const lockedPlank = []
      this.drawData.forEach((planks) => {
        const lockedPlankList = planks.data
          .slice()
          .filter((item) => item.isLocked)
        lockedPlank.push(...lockedPlankList)
      })
      this.isBanRelayout = lockedPlank.length === this.oriArrLen
    },
    // 前往料单前的统一处理
    goToBeforeMaterialDeal(data, item) {
      data.sampleFormData
        ? this.setIsShowSample(true)
        : this.setIsShowSample(false)
      data.paibanData.forEach((plank) => {
        checkPlankDataIsCorrect(plank)
        plank.parts.forEach((part) => {
          generateSimplePlankNum(part)
        })
      })
      this.setPreLayoutData(dealPreLayoutDataLabelID(data.layoutData))
      if (data.thinkerxMaterialKeys) {
        this.setThinkerxMaterialKeys(data.thinkerxMaterialKeys)
      }
      let orderInfo = {
        order_address:
          genOrderAddress(data.layoutData).join(',') + '(历史记录)',
        order_ids: item.order_list ? item.order_list.join(',') : '',
        order_codes: item.plank_info.order_codes,
        needPaibanRoomIds: item.room_ids ? item.room_ids : [],
        room_ids: [],
      }
      this.setOrderInfo(orderInfo)
      const sampleData =
        data.sampleFormData ?? clearObjAttr(this.sampleTableData)
      this.setSampleTableData(sampleData)
      // 当是第三方直接进入，并且没有样表信息的不显示样表头
      if (this.isLinkLogin && !data.sampleFormData) {
        this.setIsShowSample(false)
      }
    },
    // 优化率排序
    rateSort() {
      let arr = []
      // 对数据进行修改赋值
      for (let item of this.drawData) {
        arr.push(...item.data)
      }
      arr.sort((a, b) => {
        return b.usedRate - a.usedRate
      })
      // 将不同的材质拆分出来再组合
      const surplusDataList = []
      const normalDataList = []
      for (const plank of arr) {
        if (plank.surplusInfo) {
          surplusDataList.push(plank)
        } else {
          normalDataList.push(plank)
        }
      }
      const sort = (data) => {
        const single = []
        const double = []
        for (const item of data) {
          if (item.ableToggle) {
            double.push(item)
          } else {
            single.push(item)
          }
        }
        // 按照双先单后顺序返回数据
        return [double, single].flat()
      }

      const surplus = sort(surplusDataList)
      const normal = sort(normalDataList)

      arr = [].concat(surplus).concat(normal)
      for (let i = 0; i < arr.length; i++) {
        arr[i]._sortNum = i + 1
      }
      this.drawData.forEach((d) => {
        d.data
          .sort((a, b) => {
            return a._sortNum - b._sortNum
          })
          ?.map((item, index) => {
            item.canvasIndex = index + 1
          })
      })

      this.resetNeedData()
      this.dealPaibanData()
      // 再次赋值
      this.resetNeedData()
    },
    resetNeedData() {
      this.setFinalDrawData(this.drawData)
      this.handleSetNewPaibanData()
      this.oriArr = this.paibanData
    },
    handleJudgeAbleFanban(bigpart, index) {
      this.$refs[`drawpart${index}`][0].judgeAbleFanban(bigpart)
    },
    // 查看深度异常详情
    handleSeeDepthError() {
      this.isShowDepthTip = true
    },
    // TODO收集深度异常数据allDepthErrorData
    handleCollectDepthErrorData(data) {
      const cloneFinalDrawData = JSON.parse(JSON.stringify(data))
      const partsList = cloneFinalDrawData
        .flatMap((item) => item.data)
        .flatMap((item) => item.parts)
      this.allDepthErrorData = partsList.filter((item) => item.depthError)
    },
    handleDepthTipBtnClick(type) {
      switch (type) {
        case 'next':
          this.depthErrorIndex++
          if (this.depthErrorIndex === this.allDepthErrorData.length) {
            this.depthErrorIndex = 0
          }
          break

        case 'prev':
          this.depthErrorIndex--
          if (this.depthErrorIndex < 0) {
            this.depthErrorIndex = this.allDepthErrorData.length - 1
          }
          break
      }
    },
    handleSetNewPaibanData() {
      let newPaibanArr = []
      this.finalDrawData.forEach((item) => {
        newPaibanArr = [...newPaibanArr, ...item.data]
      })
      this.setPaibanData(newPaibanArr)
    },
    async handleOrderMarkOk() {
      // 继续导出nc并关闭弹窗
      this.isShowOrderMark = false
      this.isCancontinue = true
      if (this.surplusUseArr.length) {
        await useSurplusApi(this.surplusUseArr)
      }
      await this.downloadNC(this.drawDataArr, this.isSubtle)
      if (!this.orderIds.length) {
        return
      }
      // 更新标记状态
      const data = {
        order_ids: [...new Set(this.orderIds)],
        mark_action: this.proucePopupState.mark_action,
      }
      await updateProduceStatus(data)
    },
    handleOrderMarkCancel() {
      // 中断导出nc并关闭弹窗
      this.isNCBtnLoading = false
      this.isShowOrderMark = false
      this.confirmSendToFactory = false
    },
    async handleShowOrderMark(surplusUseArr = []) {
      const hasOrderIds = JSON.parse(JSON.stringify(this.paibanData))
        .flatMap((item) => item.parts)
        .some((item) => item.orderId)
      if (hasOrderIds) {
        // 当前订单所有的orderIds
        this.orderIds = JSON.parse(JSON.stringify(this.paibanData))
          .flatMap((item) => item.parts)
          .map((item) => item.orderId)
      } else {
        return
      }
      const fliterGGIds = JSON.parse(localStorage.getItem('ggOrderIds')) || []
      // 计算 fliterGGIds 中每个值的重复次数
      const countMap = fliterGGIds.reduce((map, value) => {
        map.set(value, (map.get(value) || 0) + 1)
        return map
      }, new Map())

      // 过滤 this.orderIds 中包含在 fliterGGIds 中的值，并确保输出数组中每个值的重复次数不超过 fliterGGIds 中的重复次数
      this.orderIds = this.orderIds.filter((value) => {
        if (countMap.has(value) && countMap.get(value) > 0) {
          countMap.set(value, countMap.get(value) - 1)
          return true
        }
        return false
      })

      // 所有的原始数据补件orderIds
      const allPreBujianOrderIds = JSON.parse(
        JSON.stringify(this.preLayoutData)
      )
        .filter((item) => item._isBujian)
        .map((item) => item.orderId)

      const allBujianOrderIds = JSON.parse(
        JSON.stringify(allPreBujianOrderIds)
      ).filter((orderId) => this.orderIds.includes(orderId))
      // 所有的板件都是补件也不显示任何弹窗
      const isAllBujian = allBujianOrderIds.length === this.orderIds.length
      if (!this.orderIds.length || isAllBujian) {
        return
      }
      const data = {
        order_ids: [...new Set(this.orderIds)], //真实ids
      }
      const proucePopupState = await getProucePopupState(data)
      if (proucePopupState.status) {
        const { data } = proucePopupState
        Object.assign(this.proucePopupState, data)
      }
      if (
        proucePopupState.data.is_display_mark ||
        proucePopupState.data.is_produced_orders
      ) {
        this.isShowOrderMark = true
        if (surplusUseArr.length) {
          this.surplusUseArr = surplusUseArr
        }
      } else {
        this.isShowOrderMark = false
      }
    },
    // 勾选只看余料的时候执行
    handleShowSurplus() {
      if (this.isShowDepthTip) this.isShowDepthTip = false
      if (this.isShowConflictDiagram) this.isShowConflictDiagram = false
      // 需要保存原始数据，取消数据的赋值回原数据，在每次勾选前保存一次
      this.$nextTick(() => {
        this.drawData.map((item, index) => {
          this.$refs[`drawpart${index}`][0].startDraw()
        })
      })
    },
    // 点击料单跳往开料清单
    handleToMaterialList() {
      this.$router.push('/materialList')
    },
    // 切换排版设置
    handleToggleSettings() {
      // 点击打开或者关闭弹窗
      this.isShowProgram = !this.isShowProgram
    },
    // 判断鼠标所在区域，关闭弹窗（点击除弹窗的部分触发）
    onToggleSettings(e) {
      //获取当前点击区域
      let target = e.target
      // 利用includes判断
      if (!this.$refs.paibanProgramRef?.contains(target)) {
        this.isShowProgram = false
      }
    },
    // 标记TODO
    async handleChangeSetting() {
      // 当设置发生变更时
      this.isShowProgram = false
      // 判断设置的初始数据，以及开启余料自动裁剪后的情况
      if (
        JSON.stringify(this.initState) !== JSON.stringify(this.finalState) ||
        JSON.stringify(this.initTableList) !==
          JSON.stringify(this.finalTableList) ||
        JSON.stringify(this.initSurplusSelectList) !==
          JSON.stringify(this.finalSurplusSelectList)
      ) {
        if (this.finalState.surplusAutoTailor) {
          this.hasSaveSurplus = false
        } else {
          this.hasSaveSurplus = true
          this.hasCut = false
        }
        this.selectedPaibanWay =
          this.finalState.paibanWay?.replace('_roll', '') ?? 'paiban_v2'
        this.isLessReverse = this.finalState.isLessReverse
        this.nestWay = this.finalState.nestWay
        this.isSpecialShape = this.finalState.isSpecialShape
        this.setSurplusAutoTailor(this.finalState.surplusAutoTailor)
        if (this.finalState.surplusAutoTailor) {
          this.setSurplusTailorWay(this.finalState.surplusTailorWay)
          this.setRecodeIsTailor(this.finalState.surplusAutoTailor)
          this.setLongSideRect(this.finalState.longSideRect)
          this.setShortSideRect(this.finalState.shortSideRect)
        }
        this.handleChangePaibanWay(this.selectedPaibanWay)
      }
    },
    handleGetInitData(state) {
      this.initState = state
    },
    handleGetFinalData(state) {
      this.finalState = state
    },
    handleGetInitTableData(state) {
      this.initTableList = state
    },
    handleGetFinalTableData(state) {
      this.finalTableList = state
    },
    handleGetInitSurplusSelectData(state) {
      this.initSurplusSelectList = state
    },
    handleGetFinalSurplusSelectData(state) {
      this.finalSurplusSelectList = state
    },
    // 修改下刀位置
    changeCutOrigin(type) {
      this.selectedCutorigin = type
    },
    confirmChangeCutOrigin() {
      this.drawData.forEach((draw) =>
        draw.data.forEach((plank) =>
          plank.parts.forEach(
            (part) => (part.cutOrigin = this.selectedCutorigin)
          )
        )
      )

      this.showPositionChange = false
      this.paibanData.forEach((plank) =>
        plank.parts.forEach((part) => (part.cutOrigin = this.selectedCutorigin))
      )
      this.setFinalDrawData(this.drawData)
      this.renderAll()
      // 这里操作记录
      const payload = [
        {
          key: '排版页',
          value: [
            {
              key: '批量修改下刀点',
              oldValue: '',
              value: this.selectedCutorigin,
            },
          ],
        },
      ]
      saveDataJsonToOssForRecord(
        payload,
        `排版页-${this.orderInfo.order_address ?? ''}`
      )
      this.selectedCutorigin = ''
    },
    /** 重新计算总翻板数 */
    calcFanbanCount() {
      // 统计大板件翻板数
      this.totalBigpartFanban = 0
      this.drawData.forEach((series) => {
        // 重置大板片翻板数
        series.fanbanCount = 0
        series.data.forEach((plank) => {
          if (plank.ableToggle) {
            series.fanbanCount += 1
          }
        })
        this.totalBigpartFanban += series.fanbanCount
      })
    },
    clearAllTimer() {
      clearTimeout(this.scaleTimer)
      clearInterval(this.scaleInterv)
    },
    /** 清除所有监听,避免泄漏 */
    clearAllListener() {
      window.removeEventListener('keydown', this.keyDownEvent)
      window.removeEventListener('resize', this.resizeEvent)
      window.removeEventListener('mouseup', this.clearAllTimer)
      window.removeEventListener('mousedown', this.onMoreSetting)
      window.removeEventListener('mousemove', this.mouseMoveEvent)
    },
    //不改变传入的小板数据,老板良那边要求，需要给补件的每块小板增加标识，用于区分是补件，该字段和值由前端定义
    handleAddFlagForSurplusListToLbl(plankList, bujianOrderIds) {
      const clonedPlankList = cloneDeep(plankList)
      const tempData = clonedPlankList.flatMap((item) =>
        item.parts.map((part) => ({
          ...part,
          isSupplement: bujianOrderIds.includes(part.partUniqueId) || false,
        }))
      )
      return tempData
    },
    // 存入余料清单
    handleSaveSurStorage() {
      this.handleSurplusSave({}, true)
    },
    /** 子画布缩放值改变 */
    handlePartScaleChange({ defaultScale, scale }) {
      this.defaultScale = defaultScale ?? 6
      this.scalePercent = scale ?? 1
    },
    /** 设置激活大板 */
    handleSetActiveBigpart(val) {
      this.activeBigpart = val
    },
    /** 检查进行下载或标签相关操作前需要先检查待排版库是否存在板件 */
    checkAwaitLocalStore() {
      return this.$refs['awaitStoreRef']?.checkLocalPlankStore()
    },
    translateLang(key, val) {
      return translate(key, val)
    },
    // 打开生产线选择框
    handleSelectProLine() {
      buryPointApi('layout', 'select_proLine')
      if (this.ncSetting.isPreLayout) {
        this.checkSettingChange()
      } else {
        this.isSelectProLine = true
      }
    },
    //关闭生产线选择框
    handleChangeVisible(val) {
      this.isSelectProLine = val
    },
    // 生产线确认按钮及重新排版按钮
    async handleConfirmLineSelect(lineId = null, isReLayout = false) {
      this.isSelectProLine = false
      if (!lineId) return
      this.oriNcSetting = deepCopy(store.state.ncSetting)
      this.oriKnifes = deepCopy(store.state.allKnifes)
      const res = await getProductionNcSetting({ setting_id: lineId })
      store.commit('setNcSetting', {
        ...res.data,
        process_setting_id: lineId,
      })
      const allKnifes = await getAllKnifes({ setting_id: lineId })
      store.commit('setAllKnifes', allKnifes.data.data)
      if (lineId && isReLayout) {
        this.reLayout(lineId)
      } else if (lineId) {
        this.showLoading = true
        store.commit(
          'setRecodrProductionLineChoose',
          this.proLineList.find((item) => item.id === lineId).name
        )
        this.showLoading = false
      }
    },
    async reLayout(lineId, arr) {
      // const hasPart = this.finalDrawData.every((item) => {
      //   const data = item.data
      //   return data.every(
      //     (plank) =>
      //       plank.parts.length && !plank.parts.every((part) => part.surplusPath)
      //   )
      // })
      const allParts = this.finalDrawData
        .map((item) => item.data.map((plank) => plank.parts))
        .flat(Infinity)
      const noPart = allParts.every((part) => part.surplusPath)
      if (noPart) {
        Vue.prototype.$antdConfirm({
          title: translate('common.tip'),
          content: translate('arrangedPage.uselessPart'),
          okText: translate('common.confirm'),
          cancelText: translate('common.cancel'),
          centered: true,
        })
        this.showLoading = false
        this.isRefresh = false
        this.resetNCSetting()
        return new Promise((resolve) => resolve(false))
      }
      // 请求待排版库数据
      const result = await fetchStoreData({
        library_type: LibraryType.bujianStore,
      })
      const bujianPlankList = result.data?.data ?? []
      this.showLoading = true
      this.setIsResetPaibanAllocation(true)
      const paibanPlankResList = await Promise.all(
        this.paibanData
          .map((item) => item.parts)
          .flat(1) // 平铺所有的part
          .filter((item) => !item.surplusPath) //筛选出非余料板
          .map(async (part) => {
            const p = this.preLayoutData.find(
              (item) => item.partUniqueId === part.oriPartUniqueId
            )
            let plank
            if (part._isAwaitStorePlank) {
              const awaitPart =
                store.state.awaitPaibanStore.servePlankStore.find(
                  (i) => i.id == part._awaitStoreId
                )
              if (awaitPart) {
                plank = await parseAwaitStoreRenderUrl(
                  awaitPart.render_url,
                  awaitPart.from
                )
                plankAddAwaitStoreFeature(plank, awaitPart)
              }
            } else if (part._isBujian) {
              const bujianPart = bujianPlankList.find(
                (i) => i.id == part._bujianStoreId
              )
              if (bujianPart) {
                const bujianRes = await axios.get(bujianPart.render_url)
                if (bujianRes.status) {
                  plank = Array.isArray(bujianRes.data.plank)
                    ? bujianRes.data.plank.find(
                        (p) => p.plankID == part.plankID
                      )
                    : bujianRes.data.plank
                }
                plankAddBujianStoreFeature(plank, bujianPart)
              }
            }

            return p
              ? { ...p, amount: 1 }
              : plank
              ? { ...plank, amount: 1 }
              : false
          })
          .filter((item) => item) // 筛选出存在的小板
      )
      const paibanPlankList = paibanPlankResList.every((item) => !item)
        ? this.preLayoutData.filter((item) => item.isSelected)
        : paibanPlankResList.filter((item) => item)
      const surObj = setSurplusObj(this.paibanData)
      const surplusRes = await getSurplusList({ page: 1, limit: 999 })
      const allSurplusList = surplusRes.status ? surplusRes.data.data : []
      // 如果xy互换设置不同的情况下，需要对匹配的余料、大板的长宽对调

      for (const key in surObj) {
        surObj[key] = surObj[key]
          .map((p) => {
            const sur = allSurplusList.find((s) => s.id === p.id)
            return sur ? { ...sur, isSelected: true } : false
          })
          .filter((p) => p)
      }
      // 处理余料参数数据
      let surplusParams = genSurplusParams(Object.values(surObj).flat(1))
      // =====余料锁定-排版接口余料参数相关处理=====
      let filterSurList = []
      let oldUseSurplusId = JSON.parse(JSON.stringify(this.useSurplusId))
      const surplusPlank = this.paibanData.find(
        (item) =>
          item.surplusInfo &&
          Object.keys(item.surplusInfo).length &&
          !item.surplusInfo.isNotSurplus
      )
      this.useSurplusList = allSurplusList.filter((item) =>
        this.useSurplusId.includes(item.id)
      )
      let notOrderLockList
      // 开启余料锁定 且使用了余料时 处理重新排版的余料参数
      if (
        this.$store.state.preferencesSetting.setting.surplus_lock &&
        surplusPlank
      ) {
        // 获取被其它订单锁定的余料id
        notOrderLockList = this.useSurplusList
          .filter(
            (item) =>
              item.lock_status == 1 &&
              item.lock_order !=
                this.orderInfo?.order_address?.replace(/\(.*?\)|历史记录/g, '')
          )
          .map((item) => item.id)
        if (
          (notOrderLockList && notOrderLockList.length) ||
          this.selectSurplusList.length
        ) {
          // 历史记录 直接剔除被其它订单锁定的余料
          if (this.isHistoryStatus) {
            surplusParams = setSurplusObj(
              arr.filter(
                (item) =>
                  !item.isLocked &&
                  !item.customSurplus &&
                  !notOrderLockList.includes(item?.surplusInfo?.id)
              )
            )
          } else {
            // 开料清单 从所选余料中剔除被其它订单锁定的余料
            filterSurList = this.selectSurplusList.filter(
              (item) => !notOrderLockList.includes(item.id)
            )
            // 更新所选余料 （拆分的情况）
            filterSurList = filterSurList.map((item) => ({
              ...item,
              amount: allSurplusList.find((useItem) => useItem.id == item.id)
                ?.amount,
            }))
            this.setSelectSurplusList(filterSurList)
            surplusParams = genSurplusParams(filterSurList)
          }
        }
      }

      store.commit('setOriLayoutSetting', this.preLayoutSetting) // 保存排版用预排版设置
      let isErrRes = false
      const res = await calcBaseMaterial(
        paibanPlankList,
        store.state.selectStandardPlank,
        lineId,
        (errType, errText = '') => {
          this.showLoading = false
          this.isRefresh = false
          if (errType === 'plank') {
            this.knifeErrCallback(lineId, errText)
          } else {
            this.$confirm(errText, this.$t('common.tip'), {
              confirmButtonText: this.$t('common.confirm'),
              cancelButtonText: this.$t('common.cancel'),
              type: 'error',
            })
          }
          isErrRes = true
          this.resetNCSetting()
        }
      )
      if (isErrRes) {
        this.showLoading = false
        this.isRefresh = false
        this.resetNCSetting()
        return
      }
      if (!res) {
        this.showLoading = false
        this.isRefresh = false
        this.resetNCSetting()
        return
      }
      const needBaseMaterials = res ? res.needBaseMaterials : []
      const data = dealDataForPaiban(paibanPlankList, store.state.ncSetting, {
        isSpecialShape: this.isSpecialShape,
        isNewLayout: true,
        baseMaterialList: needBaseMaterials,
        surplusObj: surplusParams,
        isOnlyLayoutTooBig: res.materialRes
          ? res.materialRes.only_layout_too_big
          : false,
      })
      if (!data) {
        this.showLoading = false
        this.isRefresh = false
        this.resetNCSetting()
        // this.knifeErrCallback(lineId)
        return
      }

      let paibanWay = this.activePaibanWay[0]
      if (this.isLessReverse) {
        switch (paibanWay) {
          case 'paiban':
            paibanWay = 'paiban_roll'
            break
          case 'paiban_v2':
            paibanWay = 'paiban_roll_v2'
            break
          default:
            break
        }
      }
      const paibanRes = await dealPaibanData(
        data,
        store.state.prePaibanData,
        paibanWay,
        true,
        true,
        false,
        true
      )
      if (!paibanRes) {
        this.isRefresh = false
        this.showLoading = false
        this.resetNCSetting()
        store.commit(
          'setRecodrProductionLineChoose',
          this.oriNcSetting.isPreLayout
            ? this.$t('preLayoutSetting.preWayEmpty')
            : this.proLineList.find(
                (item) => item.id === this.oriNcSetting.process_setting_id
              ).name
        )
        return
      }
      if (paibanRes.isOverSize && paibanRes.text) {
        this.isRefresh = false
        this.showLoading = false
        this.resetNCSetting()
        this.$message({
          message: paibanRes.text,
          type: 'error',
          duration: 3000,
        })
        return
      }
      // 开启自定义大板顺序 剔除被其它订单锁定的余料后 排版失败的处理
      if (this.customPlankOrder && paibanRes?.text == '大板数量不够或超尺') {
        // '余料大板被其他订单占用，可用大板不足，请到开料清单重新排版'
        this.$message.warning(translate('arrangedPage.rePaibanWarn'))
        return
      }

      this.activePlank = null
      this.showLoading = false
      this.isRefresh = false
      // 处理板件
      this.dealPaibanData()
      const standardPlank = this.paibanData.find((plank) => {
        return Object.hasOwnProperty.call(plank, 'standardPlankId')
      })
      if (standardPlank) {
        this.standardPlank = {
          ...this.standardPlank,
          plankEdgeOff: standardPlank.margin,
          plankWidth: standardPlank.plankWidth,
          plankHeight: standardPlank.plankHeight,
        }
      }
      if (lineId) {
        const line = this.proLineList.find((item) => item.id === lineId)
        store.commit(
          'setRecodrProductionLineChoose',
          line ? line.name : this.$t('preLayoutSetting.preWayEmpty')
        )
      }
      // 余料裁剪
      if (this.surplusAutoTailor) {
        await this.startTailorSurplus()
        this.setRecodeIsTailor(false)
        this.rateSort()
        this.isUpdatingTask = false
      }
      this.$nextTick(() => {
        this.drawData.map((item, index) => {
          this.$refs[`drawpart${index}`][0].startDraw()
        })
        this.renderAll()
      })
      if (!lineId) {
        // 预排版设置修改
        const { setting_value: setting } = this.preLayoutSetting
        const ncSetting = dealPreLayoutSetting(setting)
        store.commit('setOriLayoutSetting', this.preLayoutSetting) // 保存排版用预排版设置
        store.commit('setNcSetting', ncSetting)
      }
      // 重新排版使用的余料ID
      this.useSurplusId = this.paibanData
        .map((item) => !item.surplusInfo?.isNotSurplus && item.surplusInfo?.id)
        .filter((id) => id)
      if (
        this.$store.state.preferencesSetting.setting.surplus_lock &&
        this.useSurplusId.length
      ) {
        // 锁定使用的余料 -存在重新排版后又使用了新的余料或者余料管理又去手动解锁了使用的余料
        const lockParams = {
          surplus: filterSurList
            .filter((item) => this.useSurplusId.includes(item.id))
            .map((item) => ({
              ...item,
              lock_status: 1,
              lock_order: this.orderInfo?.order_address?.replace(
                /\(.*?\)|历史记录/g,
                ''
              ),
              lock_num: this.useSurplusId.filter((id) => id == item.id).length,
            })),
          release_all: false,
        }
        await surplusLock(lockParams)
        // 解锁本订单之前用了的余料 但重新排版未使用的余料
        const beforeUseId = oldUseSurplusId.filter(
          (id) => !this.useSurplusId.includes(id)
        )
        const unLockParams = {
          surplus: this.useSurplusList
            .filter(
              (item) =>
                beforeUseId.includes(item.id) &&
                item.lock_order ==
                  this.orderInfo?.order_address?.replace(
                    /\(.*?\)|历史记录/g,
                    ''
                  )
            )
            .map((item) => ({
              ...item,
              lock_status: 0,
              lock_num: beforeUseId.filter((id) => id == item.id).length,
            })),
          release_all: false,
        }
        await surplusLock(unLockParams)
      }
    },

    resetNCSetting() {
      this.setNcSetting(this.oriNcSetting)
      this.setAllKnifes(this.oriKnifes)
    },

    async checkSettingChange(isMounted = false) {
      const newSetting = await getPrelayoutSetting()
      this.setPreLayoutSetting(newSetting.data)
      // 检测预排版设置是否改变 对象对比法
      const hasPreLayoutSettingChanged = checkPrelayoutSettingChanged(
        this.oriLayoutSetting.setting_value,
        this.preLayoutSetting.setting_value
      )
      // 保存时间对比法 有问题
      // const hasPreLayoutSettingChanged =
      //   this.oriLayoutSetting.update_time != this.preLayoutSetting.update_time

      this.isPreLayoutSettingChenged = hasPreLayoutSettingChanged
      if (hasPreLayoutSettingChanged) {
        this.$antdConfirm({
          title: '提示',
          content: '系统检测预排版设置发生改变，是否重新排版？',
          okText: '重新排版',
          cancelText: '前往修改',
          closable: true,
          centered: true,
          onCancel: (e) => {
            if (typeof e === 'function') {
              e()
              this.$router.push({
                path: '/preLayoutSetting',
                query: {
                  from: 'paiban',
                },
              })
            }
          },
          onOk: () => {
            const { setting_value: setting } = this.preLayoutSetting
            const ncSetting = dealPreLayoutSetting(setting)
            store.commit('setNcSetting', ncSetting)
            this.oriNcSetting = deepCopy(ncSetting)
            this.reLayout()
          },
        })
      } else if (!isMounted) {
        this.isSelectProLine = true
      }
    },
    onGotoSubtleErr() {
      this.checkSettingChange()
    },
    /** 验证目录是否还在使用后的nc下载回调  只区分排版页和精细排版页的下载 */
    ncDownloadCallBack() {
      if (this.currentDownloadType === 'jingxi') {
        this.subtleDownloadNC(this.subtleBigPart)
      } else {
        this.handleDownloadNC()
      }
    },
    /** 刀具错误提示 */
    knifeErrCallback(lineId, errText = '') {
      this.showLoading = false
      this.isRefresh = false
      let errStr = errText
      if (this.ncSetting.plate_knife_map) {
        for (const key in this.ncSetting.plate_knife_map) {
          errStr = this.ncSetting.plate_knife_map[key].error
        }
      }
      this.$confirm(errStr, this.$t('common.tip'), {
        confirmButtonText: this.$t('materialPage.goAdd'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning',
      }).then(() => {
        const line = this.proLineList.find((item) => item.id === lineId)
        const setting_id = lineId,
          equipType = 'engraving'
        this.$router.push({
          path: `/${equipType}`,
          query: {
            setting_id,
            line: line.name,
            equipType,
          },
        })
      })
    },
    // 查看未找到的刀具信息
    handleOpenNotFoundKnifeInfoModal() {
      this.isShowNotFoundKnivesInfoModal = true
      this.isShowNotFoundKnivesInfoDrawer = false
    },
    // 查看详细信息
    handleOpenErrDetailInfoDrawer() {
      // 关闭modal
      this.isShowNotFoundKnivesInfoModal = false
      // 打开抽屉
      this.isShowNotFoundKnivesInfoDrawer = true
    },
    // 处理找不到刀的数据
    handleDealNotFoundKnivesData(data, isSendToFactory = false) {
      this.notFoundKnivesInfo = data
      // // 统计出所有的错误信息
      this.allKnivesErrInfos = data
        .map((errObj) => {
          return errObj.errorInfo
        })
        .flat(1)
      // 对相同的错误信息进行去重
      this.allKnivesErrInfos = Array.from(new Set(this.allKnivesErrInfos))
      // 打开刀错误信息弹窗
      this.handleOpenNotFoundKnifeInfoModal()
      if (isSendToFactory) {
        // 关闭下载nc弹窗
        this.isDownloadingNc = false
        this.isShowTask = false
        this.isNCBtnLoading = false
      }
    },
    // 其余刀错误信息处理
    handleDealOtherNotFoundKnivesData(msg) {
      // 关闭下载nc弹窗
      this.isDownloadingNc = false
      this.isShowTask = false
      this.isNCBtnLoading = false
      let regError = /过长工位无法加工/gi
      if (regError.test(msg)) {
        this.$confirm(`${res.msg}`, '提示', {
          confirmButtonText: '前往修改',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$router.push('/baseMaterial')
          })
          .catch(() => {})
        return
      }
      this.$confirm(`${msg}`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
      })
        .then(() => {})
        .catch(() => {})
    },
    // 关闭弹窗
    handleCloseConflictModal() {
      this.isShowConflictModal = false
    },
    handleIgnoreConflict(type) {
      // 关闭弹窗
      this.isShowConflictModal = false
      // 继续下载NC
      if (type.cancelType == 'button') {
        this.isContinue = true
        if (this.isSubtle) {
          this.subtleDownloadNC(this.subtleBigPart)
        } else {
          this.handleDownloadNC()
        }
      }
    },
    // 复制用料信息
    handleCopyData() {
      const successMsg = '已经复制大板用量为文本，可以复制到其他软件'
      // this.dealDrawDataToTargetData()
      // const res = this.dealUseMaterialData(this.useMaterialData)
      copyText(this.materialData.join('\n'), successMsg)
    },
    // 后端返回的用料数据处理
    dealUseMaterialData(data) {
      const result = []
      const { xyReverse } = this.ncSetting
      try {
        const standardMap = new Map()
        const surpluMap = new Map()
        const specialMap = new Map()
        data?.data.detailed_info.forEach((item) => {
          const matCode = item?.plank_kind.replaceAll(':', ' ')
          item.special_stock.forEach((stock) => {
            const { plank_type: plankType, stock_num: stockNum } = stock
            // 标准大板
            if (plankType === 'standard') {
              const [height, width] = stock.size.split('*')
              const size = `${width}*${height}`
              const mapKey = `${matCode}-${size}`
              if (!standardMap.get(mapKey)) standardMap.set(mapKey, stockNum)
            }
            // 余料数据
            else if (plankType === 'surplus') {
              const { size: size2 } = stock
              let [height, width] = size2.split('*')
              if (xyReverse) {
                ;[width, height] = [height, width]
              }
              let size = `${width}*${height}`
              // L形余料
              if (stock.shape === 'lshape') {
                const { x3, y5 } = stock
                // const longWidth = xyReverse ? y5 : x3
                // const lognHeight = xyReverse ? x3 : y5
                const longWidth = x3
                const lognHeight = y5
                size = `${width} (${longWidth}) * ${height} (${lognHeight})`
              }
              const mapKey = `${matCode}-${size}`
              if (!surpluMap.get(mapKey)) surpluMap.set(mapKey, stockNum)
            }
            // 特殊大板
            else if (plankType === 'special') {
              const [height, width] = stock.size.split('*')
              const size = `${width}*${height}`
              const mapKey = `${matCode}-${size}`
              if (!specialMap.get(mapKey)) specialMap.set(mapKey, stockNum)
            }
          })
        })
        standardMap.forEach((value, key) => {
          const [matCode, size] = key?.split('-')
          result.push(`${matCode} ${size} ${value}块`)
        })
        specialMap.forEach((value, key) => {
          const [matCode, size] = key?.split('-')
          result.push(`${matCode} ${size} ${value}块`)
        })
        surpluMap.forEach((value, key) => {
          const [matCode, size] = key?.split('-')
          result.push(`${matCode} ${size} ${value}块 (余料)`)
        })
      } catch (error) {
        console.log('数据出错啦，请重试', error)
      }
      return result
    },
    // 将drawData处理成目标数据结构
    dealDrawDataToTargetData(data) {
      const map = new Map()
      const useMaterialData = {
        data: {
          kind_count: 0,
          detailed_info: [],
        },
      }
      const { plankHeight, plankWidth } = this.$store.state.selectStandardPlank

      data?.forEach((data) => {
        const { matCode, texture, thick, type } = data
        const { otherPlate } = data.data[0] || {}
        const standardMap = new Map()
        const plankKind = `${matCode}:${texture}:${thick}`
        let specialStockItem = {}
        if (!map.get(plankKind)) map.set(plankKind, [])
        const arr = map.get(plankKind)
        // 特殊大板
        if (otherPlate) {
          const { width, height } = otherPlate
          specialStockItem = {
            plank_type: 'special',
            // shape:
            size: `${height}*${width}`,
            stock_num: data.data.length,
          }
          arr.push(specialStockItem)
          map.set(plankKind, arr)
        } else {
          const surplusMap = new Map()
          // 余料
          data.data.forEach((item) => {
            const { surplusInfo } = item
            if (surplusInfo) {
              const { width, height, shape, x3, y3, x4, y4, x5, y5 } =
                surplusInfo
              const size = `${width} (${x3}) * ${height} (${y5})`
              if (!surplusMap.get(size)) surplusMap.set(size, [])
              const surplus = surplusMap.get(size)
              const specialStockItem = {
                plank_type: 'surplus',
                shape,
                size: `${height}*${width}`,
                x3,
                y3,
                x4,
                y4,
                x5,
                y5,
              }
              surplus.push(specialStockItem)
            } else {
              // 解决，余料板添加大板时，添加的是标准大板，但是这个标准大板出现在了预料板的分类里面。需要单独统计
              const standardSize = `${plankWidth}*${plankHeight}`
              if (!standardMap.get(standardSize))
                standardMap.set(standardSize, [])
              const standards = standardMap.get(standardSize)
              // 标准大板
              specialStockItem = {
                plank_type: 'standard',
                size: `${plankHeight}*${plankWidth}`,
              }
              standards.push(specialStockItem)
            }
          })
          surplusMap.forEach((value) => {
            specialStockItem = {
              ...value[0],
              stock_num: value.length,
            }
            arr.push(specialStockItem)
            map.set(plankKind, arr)
          })
          let standarsArr = []
          standardMap.forEach((value) => {
            specialStockItem = {
              ...value[0],
              stock_num: value.length,
            }
            standarsArr.push(specialStockItem)
            map.set(`${plankKind}-standard`, standarsArr)
          })
        }
        // if (type === 'surplus' && !otherPlate) {
        //   const surplusMap = new Map()
        //   // 余料
        //   data.data.forEach((item) => {
        //     const { surplusInfo } = item
        //     if (surplusInfo) {
        //       const { width, height, shape, x3, y3, x4, y4, x5, y5 } =
        //         surplusInfo
        //       const size = `${width} (${x3}) * ${height} (${y5})`
        //       if (!surplusMap.get(size)) surplusMap.set(size, [])
        //       const surplus = surplusMap.get(size)
        //       const specialStockItem = {
        //         plank_type: 'surplus',
        //         shape,
        //         size: `${height}*${width}`,
        //         x3,
        //         y3,
        //         x4,
        //         y4,
        //         x5,
        //         y5,
        //       }
        //       surplus.push(specialStockItem)
        //     } else {
        //       // 解决，余料板添加大板时，添加的是标准大板，但是这个标准大板出现在了预料板的分类里面。需要单独统计
        //       const standardSize = `${plankWidth}*${plankHeight}`
        //       if (!standardMap.get(standardSize))
        //         standardMap.set(standardSize, [])
        //       const standards = standardMap.get(standardSize)
        //       // 标准大板
        //       specialStockItem = {
        //         plank_type: 'standard',
        //         size: `${plankHeight}*${plankWidth}`,
        //       }
        //       standards.push(specialStockItem)
        //     }
        //   })
        //   surplusMap.forEach((value) => {
        //     specialStockItem = {
        //       ...value[0],
        //       stock_num: value.length,
        //     }
        //     arr.push(specialStockItem)
        //     map.set(plankKind, arr)
        //   })
        //   let standarsArr = []
        //   standardMap.forEach((value) => {
        //     specialStockItem = {
        //       ...value[0],
        //       stock_num: value.length,
        //     }
        //     standarsArr.push(specialStockItem)
        //     map.set(`${plankKind}-standard`, standarsArr)
        //   })
        // } else if (otherPlate) {
        // } else {
        //   // const { plankHeight, plankWidth } =
        //   //   this.$store.state.selectStandardPlank
        //   // // 标准大板
        //   // specialStockItem = {
        //   //   plank_type: 'standard',
        //   //   // shape:
        //   //   size: `${plankHeight}*${plankWidth}`,
        //   //   stock_num: data.data.length,
        //   // }
        //   // arr.push(specialStockItem)
        //   // map.set(plankKind, arr)
        //   const surplusMap = new Map()
        //   // 余料
        //   data.data.forEach((item) => {
        //     const { surplusInfo } = item
        //     if (surplusInfo) {
        //       const { width, height, shape, x3, y3, x4, y4, x5, y5 } =
        //         surplusInfo
        //       const size = `${width} (${x3}) * ${height} (${y5})`
        //       if (!surplusMap.get(size)) surplusMap.set(size, [])
        //       const surplus = surplusMap.get(size)
        //       const specialStockItem = {
        //         plank_type: 'surplus',
        //         shape,
        //         size: `${height}*${width}`,
        //         x3,
        //         y3,
        //         x4,
        //         y4,
        //         x5,
        //         y5,
        //       }
        //       surplus.push(specialStockItem)
        //     } else {
        //       // 解决，余料板添加大板时，添加的是标准大板，但是这个标准大板出现在了预料板的分类里面。需要单独统计
        //       const standardSize = `${plankWidth}*${plankHeight}`
        //       if (!standardMap.get(standardSize))
        //         standardMap.set(standardSize, [])
        //       const standards = standardMap.get(standardSize)
        //       // 标准大板
        //       specialStockItem = {
        //         plank_type: 'standard',
        //         size: `${plankHeight}*${plankWidth}`,
        //       }
        //       standards.push(specialStockItem)
        //     }
        //   })
        //   surplusMap.forEach((value) => {
        //     specialStockItem = {
        //       ...value[0],
        //       stock_num: value.length,
        //     }
        //     arr.push(specialStockItem)
        //     map.set(plankKind, arr)
        //   })
        //   let standarsArr = []
        //   standardMap.forEach((value) => {
        //     specialStockItem = {
        //       ...value[0],
        //       stock_num: value.length,
        //     }
        //     standarsArr.push(specialStockItem)
        //     map.set(`${plankKind}-standard`, standarsArr)
        //   })
        // }
      })
      map.forEach((value, key) => {
        if (key.includes('-standard')) {
          key = key.replace('-standard', '')
        }
        useMaterialData.data.detailed_info.push({
          plank_kind: key,
          special_stock: value,
          stock_count: value.length,
        })
      })
      const kindCount = useMaterialData.data.detailed_info.length
      useMaterialData.data.kind_count = kindCount
      this.useMaterialData = useMaterialData
      return useMaterialData
    },
    // 将tooltip挂载到当前组件
    getContainer(node) {
      return node
    },
    // 统计板件订单id和房间id
    getOrderIdAndRoomId() {
      const orderIdset = new Set()
      const roomIdset = new Set()
      for (let i = 0; i < this.drawData.length; i++) {
        this.drawData[i].data.forEach((data) => {
          data.parts.forEach((part) => {
            // 排除待排版库和补件
            if (part._isAwaitStorePlank || part._isBujian) return
            if (part.orderId) {
              orderIdset.add(part.orderId)
            }
            if (part.roomID) {
              roomIdset.add(part.roomID)
            }
          })
        })
      }
      return {
        orderIds: Array.from(orderIdset),
        roomIds: Array.from(roomIdset),
      }
    },
    // 处理成千里眼适用数据
    dealDataToQianliyan(data) {
      const plankTypeMap = new Map([
        ['standard', 1],
        ['special', 2],
        ['surplus', 3],
      ])
      const result = []
      data?.data.detailed_info.forEach((item) => {
        const { plank_kind, special_stock } = item
        const [substrate, color, thick] = plank_kind?.split(':')
        special_stock.forEach((stock) => {
          const { plank_type, stock_num, size, shape } = stock
          let [height, width] = size.split('*')
          if (plank_type === 'surplus') {
            if (this.ncSetting.xyReverse) {
              ;[width, height] = [height, width]
            }
            if (shape === 'lshape') {
              const { x3, y5 } = stock
              // L形余料
              const longWidth = x3
              const lognHeight = y5
              width = `${width} (${longWidth})`
              height = `${height} (${lognHeight})`
            }
          }
          result.push({
            substrate,
            thick,
            color,
            spec: `${width}*${height}`,
            num: stock_num,
            board_type: plankTypeMap.get(plank_type),
          })
        })
      })
      return result
    },
    handleCancelDownloadNC() {
      // 关闭弹窗
      this.isShowConfirmColorTag = false
      this.isNCBtnLoading = false
    },
    handleConfirmContinue() {
      // 选择是，继续下载NC，关闭弹窗
      this.isShowConfirmColorTag = false
      this.continueDownloadFlag = true
      // 调用NC下载的方法
      if (this.isSubtle) {
        this.subtleDownloadNC(this.subtleBigPart)
      } else {
        this.handleDownloadNC()
      }
    },
    judgeLimit(arr) {
      const labelCheck = {}
      // 精细排版时还是判断全局数据 需要剔除余料板的数据
      for (let i = 0; i < arr.length; ++i) {
        for (let k = 0; k < arr[i].parts.length; ++k) {
          // 彩色标签数据源数量限制判断
          // 如果当前板件是余料板，不需要进行检测，直接进入下一循环
          if (arr[i].parts[k]?.surplusPath) continue
          for (let v of labelSources) {
            // 判断目标对象里面是否已经存在数据源，不存在时给默认值[]
            if (!labelCheck[v]) {
              labelCheck[v] = []
            }
            // 目标对象属性里面是否已经存在小板上的数据源信息，不存在时push进去
            if (
              !labelCheck[v].includes(arr[i].parts[k][v]) &&
              arr[i].parts[k][v] !== undefined
            ) {
              labelCheck[v].push(arr[i].parts[k][v])
            }
          }
        }
      }
      return labelCheck
    },
    // 添加颜色的方法
    formPlankColor(arr, colorRes) {
      for (let i = 0; i < arr.length; ++i) {
        for (let k = 0; k < arr[i].parts.length; ++k) {
          const part = arr[i].parts[k]
          // 余料板不走下面的逻辑
          if (part.surplusPath) continue
          // 重新给每块板子添加上新的颜色数据
          if (
            !part.colorRes ||
            (part._isAwaitStorePlank && !part._isGenColor)
          ) {
            part.colorRes = {}
            if (part._isAwaitStorePlank) {
              part._isGenColor = true
            }
            // 添加对应数据
            for (let item of labelSources) {
              if (colorRes[item]) {
                part.colorRes[item] =
                  colorRes[item][(part[item] ?? '')?.toString()]
              } else {
                part.colorRes[item] = 'rgb(255, 255, 255)'
              }
            }
          }
        }
      }
    },
    // 需要重新生成颜色
    // 判断小板的colorRes数据是否为null，如果为null就需要重新生成，待排版库已入库的小板都需要重新生成
    generateColor(labelCheck) {
      let checkTag = false
      const limitOvers = []
      for (let key in labelCheck) {
        if (labelCheck[key].length > 12) {
          // 弹出提示弹窗
          checkTag = true
          limitOvers.push(key)
          break
        }
      }
      if (checkTag) {
        this.$store.commit('setLimitOvers', limitOvers)
        return
      } else {
        this.$store.commit('setLimitOvers', [])
      }
      // 此时颜色不超过12个
      // 给板子添加colorRes
      const colorRes = genColorRes(labelCheck)
      // 给保存历史添加历史数据
      this.formPlankColor(this.oriArr, colorRes)
      // 当前绘制数据添加颜色
      this.formPlankColor(this.drawDataArr, colorRes)
    },
    // 关闭余料锁定提示弹窗
    handleCloseSurLock() {
      this.isShowSurplusLock = false
    },
    // 关闭余料锁定提示弹窗
    handleCloseSurLockNewSub() {
      this.isShowSurplusLockNewSub = false
    },
    // 解锁处理
    async handleUnlock(allSurplusList) {
      const lockParams = {
        surplus: [],
        release_all: false,
      }
      allSurplusList.forEach((item) => {
        // 使用的余料数量
        const useSurNum = this.useSurplusId.filter((id) => id == item.id).length
        // 开料清单所选余料未使用
        if (!this.useSurplusId.includes(item.id)) {
          lockParams.surplus.push({
            ...item,
            lock_status: 0, // 0代表未锁定，也就相当于解锁
            lock_num: item.amount,
          })
          // 开料清单所选余料虽使用了但没有使用完
        } else if (
          this.useSurplusId.includes(item.id) &&
          item.amount != useSurNum
        ) {
          lockParams.surplus.push({
            ...item,
            lock_status: 0,
            lock_num: item.amount - useSurNum,
          })
        }
      })
      if (lockParams.surplus.length) {
        // 解锁本次排版未使用余料
        await surplusLock(lockParams)
      }
    },
  },

  async mounted() {
    // 监听找不到刀的错误事件
    EventBus.$on(EventBusKey.KNIVES_ERR, this.handleDealNotFoundKnivesData)
    EventBus.$on(
      EventBusKey.OTHER_KNIVES_ERR,
      this.handleDealOtherNotFoundKnivesData
    )

    await this.$store.dispatch('preferencesSetting/getSetting')
    // 本次排版使用的余料ID
    this.useSurplusId = this.paibanData
      .map((item) => !item.surplusInfo?.isNotSurplus && item.surplusInfo?.id)
      .filter((id) => id)
    const routeQuery = this.$route.query
    // 代表从开始清单-开始排版跳转过来 且 开启余料锁定
    if (
      (routeQuery?.from == 'material_paiban' ||
        routeQuery?.from == 'lbl' ||
        routeQuery?.isSpecialShape) &&
      this.$store.state.preferencesSetting.setting.surplus_lock
    ) {
      this.handleUnlock(this.selectSurplusList)
    }
    this.standardPlank =
      this.selectStandardPlank && Object.keys(this.selectStandardPlank).length
        ? this.selectStandardPlank
        : {
            ...this.paibanData.find(
              (item) => !item.otherPlate && !item.surplusInfo
            ),
            plankEdgeOff: this.historyPlankEdgeOff,
          }
    let payLoad = {
      uid: this.userInfo.id,
      data: {},
    }
    if (window.sessionStorage.getItem('thinkerx_material')) {
      payLoad.production_from = 'guimen'
    }
    this.$token('/load_plank_manage_setting_info', payLoad, (res) => {
      if (res.status == 1) {
        let data = res.data
        this.baseMaterialData = res.data
        if (Array.isArray(data.defaultPlankInfo)) {
          this.defaultPlankList = data.defaultPlankInfo
        } else {
          this.defaultPlankList = [data.defaultPlankInfo]
        }
        if (
          !this.defaultPlankList.some(
            (item) =>
              item.plankWidth == this.standardPlank.plankWidth &&
              item.plankHeight == this.standardPlank.plankHeight &&
              item.plankEdgeOff == this.standardPlank.plankEdgeOff
          )
        ) {
          this.standardPlank = this.ncSetting.panelSize
        }
      }
    })
    this.surplusUseArr = []
    if (this.recodeIsTailor) {
      this.isUpdatingTask = true
    }
    // 判断当前排版是否存在冲突
    this.judgmentConflicts()

    // 收集冲突板件数组
    this.handleCollectConflicts()
    this.canvasMaxWidth =
      document.body.offsetWidth - 650 < 988
        ? 988
        : document.body.offsetWidth - 650
    this.canvasMaxHeight = document.body.offsetHeight - 80
    window.onresize = () => {
      this.canvasMaxWidth = document.body.offsetWidth - 650
      this.canvasMaxHeight = document.body.offsetHeight - 80
    }
    window.addEventListener('mouseup', this.clearAllTimer)
    let params = {}
    if (window.sessionStorage.getItem('thinkerx_material')) {
      params.production_from = 'guimen'
    }
    // 好帮手跳转排版方案处理
    const querys = this.$route.query
    if (querys?.from && querys?.from === 'lblerp') {
      const item = JSON.parse(querys.item)
      this.$store.commit(
        'setRecodrProductionLineChoose',
        item.plank_info.process_setting_name
      )
      this.setFilterMaterialList(null)
      this.setBeforeSearchMaterialList(null)
      this.setBeforeSizeFilterList(null)
      this.setFilterObject(item.plank_info.filterObj)
      // 清空收集的多排版方案
      this.$store.commit('paibanStore/setPaibanDataCollect', [])
      this.$store.commit('paibanStore/clearAlreadyDealDrawData')
      // 非试生产
      this.setIsTrialProduct(false)
      await axios.get(item.plank_info.historyUrl).then(async (res) => {
        let params = {
          setting_id: item.process_setting_id,
        }
        if (res.data.filterPlankList) {
          const plankList = dealPlankListLabelID(res.data.filterPlankList)
          this.setFilterMaterialList(plankList)
          this.setBeforeSearchMaterialList(plankList)
          this.setBeforeSizeFilterList(plankList)
        }
        await this.$getByToken(
          '/get_production_nc_setting',
          params,
          (result) => {
            if (result.status === 1) {
              this.setHasSendToTask(false)
              if (res.data.paibanInfo) {
                this.setHistoryPlankEdgeOff(
                  res.data.paibanInfo.historyPlankEdgeOff
                )
                this.setHistorySecondTrimValue(
                  res.data.paibanInfo.historySecondTrimValue
                )
                this.setFilterMaterialList(
                  res.data.paibanInfo.filterPlankList ?? null
                )
              } else {
                this.setFilterMaterialList(null)
              }
              this.setPaibanKey(res.data.paibanKey)
              this.setPreLayoutData(
                dealPreLayoutDataLabelID(res.data.layoutData)
              )
              this.goToBeforeMaterialDeal(res.data, item)
              this.setPaibanInfo(res.data.paibanInfo ?? {})
              if (res.data.paibanData.length > 0) {
                this.setPaibanData(res.data.paibanData)
                this.setFinalDrawData([])
              }
              result.data.drawPlankWidth = result.data.xyReverse
                ? result.data.panelSize.plankHeight
                : result.data.panelSize.plankWidth
              result.data.drawPlankHeight = result.data.xyReverse
                ? result.data.panelSize.plankWidth
                : result.data.panelSize.plankHeight
              let obj = Object.assign(result.data, {
                process_setting_id: item.process_setting_id,
                process_setting_name: item.plank_info.process_setting_name,
              })
              this.setNcSetting(obj)
              this.isHbsHistoryStatus = true
            }
          }
        )
      })
      this.setHistoryOrder(item)
      this.setRecodeSelectPart({ selects: [], isHistory: true })
      this.setRecodeSelectSurplus({ selects: [], isHistory: true })
      if (item.save_node === 3) {
        this.setHasPrintTag(true)
      } else {
        this.setHasPrintTag(false)
      }
    }
    if (this.isHistoryStatus || this.isHbsHistoryStatus) {
      this.isLessReverse = this.paibanInfo
        ? this.paibanInfo.isLessReverse
        : false
      this.isSpecialShape = this.paibanInfo
        ? this.paibanInfo.isSpecialShape
        : false
      // 需要使用的是基础的三种排版，不需要组合少翻版之后的排版方式
      this.selectedPaibanWay = this.paibanInfo.paibanWay
        ? this.paibanInfo.paibanWay.replace('_roll', '')
        : 'paiban_v2'
      this.isAutoPassData = this.paibanInfo
        ? this.paibanInfo.isAutoPassData
        : false
      this.isCuttingOrder = this.paibanInfo
        ? this.paibanInfo.isCuttingOrder
        : true
      this.isFollowExportTag = this.paibanInfo
        ? this.paibanInfo.isFollowExportTag
        : false
      this.nestWay = this.paibanInfo ? this.paibanInfo.nestWay : 'PRIMARY'
      this.currentTagTempID = this.initTagTempID()
      // TODO 暂时关闭导出pdf的功能
      // this.currentTagType = this.paibanInfo
      //   ? this.initTagFileType(this.paibanInfo.currentTagType)
      //   : '导出PDF'
      this.suitText = this.paibanWayList.find(
        (e) => e.interface === this.selectedPaibanWay
      ).desc
      this.hasSaveSurplus = Boolean(this.paibanInfo.hasSaveSurplus)
      this.$getByToken('/get_preferences_setting', params, (res) => {
        if (res.status === 1) {
          this.print_color_tag = res.data.printColorTag
        }
      })
    } else {
      params.platform = this.userInfo.platform
      this.$getByToken('/get_preferences_setting', params, (res) => {
        if (res.status === 1) {
          // 获取偏好设置
          this.setActivePaibanWay([res.data.paibanWay])
          this.setSurplusNoRoll(res.data.surplus_no_roll)
          this.isLessReverse = res.data.isLessReverse
          this.isSpecialShape = res.data.isSpecialShape
          this.isShowPartSize = res.data.isShowPartSize
          this.selectedPaibanWay = this.activePaibanWay[0]
            ? this.activePaibanWay[0].replace('_roll', '')
            : 'paiban_v2'
          this.isAutoPassData = res.data.isAutoPassData
          this.isCuttingOrder = res.data.isCuttingOrder ?? true
          this.isFollowExportTag = res.data.isFollowExportTag
          this.nestWay = res.data.nesting_version === 2 ? 'SENIOR' : 'PRIMARY'
          this.print_color_tag = res.data.printColorTag
          // TODO 暂时关闭导出pdf的功能
          this.currentTagType = this.initTagFileType(res.data.currentTagType)
          this.suitText = this.paibanWayList.find(
            (e) => e.interface === this.selectedPaibanWay
          ).desc
        } else {
          this.$message.error(res.status)
        }
      })
    }

    if (this.$route.query.json) {
      this.dealJsonFromLbl(this.$route.query.json)
      return
    }
    if (Object.keys(this.ncSetting).length == 0) {
      return
    }
    if (window.sessionStorage.getItem('isHistory')) {
      this.changeHistoryStatus(true)
    }
    // 处理板件
    this.dealPaibanData()

    this.keyDownEvent = this.keyDownFuncs()
    window.addEventListener('keydown', this.keyDownEvent)
    this.setPaibanTime(new Date().getTime())
    // 生成排版Key
    this.genPaibanKey()
    // 余料裁剪
    if (this.recodeIsTailor) {
      await this.startTailorSurplus()
      this.setRecodeIsTailor(false)
      this.rateSort()
      this.drawData.map((item, index) => {
        this.$refs[`drawpart${index}`][0].startDraw()
      })
      this.isUpdatingTask = false
    }
    // 获取勾选的数据
    if (this.isOnlyShowSurplus) {
      this.handleShowSurplus()
    }
    if (this.isNewPaiban) {
      this.updateTaskStatus()
    }
    this.setIsResetPaibanAllocation(false)
    this.setHasSavedSurplus()
    // 开料清单跳转到排版页，勾选了计算异形时，弹出提示框
    if (this.$route.query.isSpecialShape) {
      Vue.prototype.$antdConfirm({
        title: translate('common.tip'),
        content: translate('arrangedPage.someTips'),
        okText: translate('common.confirm'),
        class: 'special-shape-modal',
        centered: true,
      })
    }
    const parts = this.paibanData.map((i) => i.parts).flat(1)
    if (parts.some((part) => !part.priority)) {
      this.calcCutPositionOrder()
    }
    // this.handleInitAllHighGlossSide()
    getSpecialRemarks().then((res) => {
      if (res.status === 200) {
        this.$store.commit('setSpecialRemarks', res?.data?.data ?? {})
      }
    })
    if (this.ncSetting.isPreLayout) {
      this.checkSettingChange(true)
    }
  },
  beforeDestroy() {
    this.clearAllListener()
  },
  // 前往标签页前，检查切割顺序是否正确
  beforeRouteLeave(to, from, next) {
    let flag = this.judgeFlag()
    this.surplusBigpart = null
    if (to.path == '/printTag' && !to.query.from) {
      if (!flag) {
        this.$message.warning(translate('arrangedPage.cutOrderErr'))
        return false
      } else if (this.print_color_tag) {
        // 生成颜色
        const labelCheck = this.judgeLimit(this.oriArr)
        // 在这里提示
        this.generateColor(labelCheck)
      }
    }
    // 试进入生产柜时排版方案选项无法选中（paibandata必须清空）-历史遗留BUG-
    !store.state.isTrialProduct && this.handleSetNewPaibanData()
    next()
  },
}
</script>

<style scoped lang="less">
#allPartsOrderMark {
  /deep/ .ant-modal {
    top: 30% !important;
  }
}

.draw-parts {
  height: 100%;

  .AI-paiban-btn {
    width: 100%;
    margin: 0 0 8px;

    &.activeWay {
      color: #18a8c7;
      border-color: #18a8c7;
    }
  }

  span {
    font-family: PingFangSC-Regular, PingFang SC;
  }

  &.forbid-select {
    span {
      user-select: none !important;
    }

    img {
      user-select: none !important;
    }
  }

  .loading-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000000000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #0008;
  }

  .paiban-bread {
    height: 38px;
    padding: 0 16px;
    color: #999;
    font-size: 14px;
    line-height: 38px;
    background: #fafafa;
    cursor: default;

    .material-list {
      color: #333;
      cursor: pointer;
    }
  }

  .draw-parts-main {
    display: flex;
    align-items: flex-start;
    height: 98%;

    // justify-content: space-between;
    .left-operation {
      // overflow: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(100% - 350px);
      //width: 100%;
      height: 100%;
      margin-top: 16px;

      .paiban-top-container {
        background: #fafafa;
        padding: 0 16px;
        margin: 0 16px;
        // height: 150px;
        // min-height: 150px;
        overflow: auto;
      }

      .paiban-top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .order-title {
          margin-right: 90px;
          max-width: 800px;
          overflow: hidden;
          color: #000;
          font-weight: 600;
          font-size: 21px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .more-title {
          max-width: 600px !important;
        }

        .paiban-info {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          flex: 1;
          min-width: 920px;

          &.order-address {
            display: inline-block;
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .paiban-info-label {
            margin-right: 35px;
            color: rgba(0, 0, 0, 0.4);
            font-size: 16px;
            display: inline-block;
            // min-width: 200px;
          }
          .cursor {
            cursor: pointer;
            color: #18a8c7;
          }

          .paiban-info-value {
            color: #fb4444;
          }
        }

        .paiban-actions {
          display: flex;
          align-items: center;
        }

        .tag-nc {
          display: flex;

          .new-button {
            #paiban_batch_bujian_btn {
              color: #000;
              background: #fafafa;
            }

            #paiban_more_setting_btn {
              color: #000;
            }
          }
        }

        .action-btn,
        .disable-btn {
          // line-height: 40px;
          display: inline-block;
          box-sizing: border-box;
          // margin-left: 15px;
          color: #000;
          // width: 138px;
          // height: 40px;
          font-size: 14px;
          text-align: center;
          background: #fafafa;
          border-color: #dddddd;
          border-radius: 4px;
          user-select: none;

          &:hover {
            color: #18a8c7;
            border-color: #18a8c7;
          }

          /deep/ .ant-checkbox-wrapper {
            color: inherit;
          }
        }

        .disable-btn {
          color: #ccc !important;
          background: #f5f5f5 !important;
          cursor: not-allowed !important;

          &:hover {
            color: #ccc;
            border-color: #ccc;
          }
        }

        .down-nc {
          // line-height: 40px;
          display: inline-block;
          color: #fff;
          font-size: 14px;
          text-align: center;
          // width: 81px;
          // height: 40px;
          background: #18a8c7;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          user-select: none;
        }

        .more-setting {
          .more-icon {
            transform: rotate(180deg);
            transition: transform 0.5s;
          }
        }
      }

      .normal-top {
        margin-top: -20px;
      }

      .oneErr-top {
        margin-top: -15px;
      }

      .batch-bujian {
        height: 50px;
        background-color: #f9f9f9;

        .close-batch-bujian-btn {
          right: 10px;
          top: 0px;
        }
      }

      .all-parts {
        // flex-grow: 1;
        // width: calc(100vw - 316px);
        margin: 16px 9px 16px 16px;
        overflow: scroll;
        transition: all 0.3s;
        scroll-behavior: smooth;

        /* 改造滚动条样式 */

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.05);
        }

        &:hover::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }

        &::-webkit-scrollbar-track {
          background-color: #eee;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-track:hover {
          background-color: #eee;
        }
      }

      .operate-menu {
        position: fixed;
        right: 380px;
        bottom: 24px;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 35px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 1px 1px 5px #333;
      }

      .operate-menu .operate-icon {
        color: #333;
        font-size: 16px;
        cursor: pointer;

        &.icon-hint01 {
          margin-right: 15px;
        }

        &:hover {
          color: #18a8c7;
        }
      }

      .zoom-percent-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 127px;
        cursor: default;
        user-select: none;
      }

      .zoom-percent {
        display: inline-block;
        width: 38px;
        text-align: center;
      }
    }

    .right-operation {
      position: relative;
      flex-shrink: 0;
      width: 350px;
      height: 100%;
      margin-top: 16px;
      padding: 16px;
      overflow: auto;
      background: #fff;

      /* 改造滚动条样式 */

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.05);
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }

      &::-webkit-scrollbar-track {
        background-color: #eee;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track:hover {
        background-color: #eee;
      }

      .empty-input {
        /deep/ .ant-input:focus {
          border-color: rgb(251, 70, 70);
          box-shadow: 0 0 0 2px rgba(251, 70, 70, 0.2);
        }
      }

      .paiban-way,
      .plank-operation {
        margin: 20px 0;

        /deep/ .ant-checkbox-wrapper {
          margin-left: 0;
        }
      }

      .change-plank {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .el-button {
          width: 50%;
          height: 30px;
          padding: 0 15px;
        }
      }

      .active-plank-box {
        width: 100%;
        height: 234px;
        margin-top: 16px;
        border: 1px solid #d9d9d9;
      }

      .active-plank-info {
        position: relative;
        // display: flex;
        // align-items: flex-end;
        // justify-content: space-between;
        height: 100%;
        padding: 0 10px 0 8px;

        .left-info {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          height: 100%;
          color: #333;
          font-size: 12px;

          span {
            display: block;
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .info-eliplise {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .plank-matCode-container {
          display: flex;
          width: 100%;
        }

        .right-qrcode {
          position: absolute;
          right: 10px;
          bottom: 0;
          margin-bottom: 10px;
          padding-top: 11px;
          user-select: none;

          img {
            display: none !important;
          }

          /deep/ canvas {
            display: block !important;
          }
        }
      }

      .no-active-plank {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 178px;
        color: #999;
        font-size: 14px;
        text-align: center;
      }

      .title-box {
        display: flex;
        align-items: center;
        margin: 8px 0;

        .circle {
          display: inline-block;
          width: 6px;
          height: 6px;
          margin-right: 8px;
          background: #999999;
          border-radius: 50%;
        }

        .title {
          color: #999999;
          font-size: 14px;
          user-select: none;
        }
      }

      .select-paiban {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;

        .paiban-way-item {
          display: inline-block;
          box-sizing: border-box;
          width: 138px;
          height: 32px;
          margin-bottom: 8px;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          border-radius: 2px;

          &.activeWay {
            color: #18a8c7;
            border-color: #18a8c7;
          }

          &:last-child {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 0;

            > div {
              display: flex;
              align-items: center;
            }

            .icon-save {
              margin-right: 4px;
            }
          }
        }
      }

      .plank-operation-btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .plank-control-rotate {
          width: 150px;
          height: 32px;
          margin-bottom: 8px;
        }

        .highGlossDisabled {
          color: #ccc;
          background: #f5f5f5;
          cursor: not-allowed;
        }

        .disable-btn {
          color: #ccc;
          background: #f5f5f5 !important;
          cursor: not-allowed;

          &:hover {
            color: #ccc;
            border-color: #ccc;
          }
        }

        .plank-control-del {
          width: 150px;
          height: 32px;
          margin-bottom: 8px;

          &:hover {
            .ant-checkbox-wrapper {
              color: #18a8c7;
            }
          }
        }

        .plank-control-calc {
          width: 100%;
          height: 32px;
          margin-bottom: 8px;
        }

        .plank-control-temp {
          width: 100%;
        }

        .origin-dialog-mask {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #0008;
          z-index: 1000;
        }

        .origin-dialog {
          position: fixed;
          top: 135px;
          right: 75px;
          width: 333px;
          height: 228px;
          margin: auto;
          background: #fff;

          .origin-dialog-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            width: 100%;
            height: 56px;
            padding: 0 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);

            span {
              color: #333;
              font-size: 16px;
              cursor: default;
            }

            .icon-close {
              cursor: pointer;
            }
          }

          .origin-btn {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            width: 100%;
            padding: 24px 24px 16px 24px;
            color: #333;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);

            .ant-btn {
              width: 138px;
              height: 32px;
              margin-bottom: 8px;
              border-radius: 2px;
            }

            .ant-btn.active {
              color: #18a8c7;
              border-color: #18a8c7;
            }
          }

          .origin-dialog-btns {
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            padding: 10px;

            > div {
              .ant-btn {
                width: 65px;
                height: 32px;

                &:last-child {
                  color: #fff;
                  background: #18a8c7;
                  border: none;
                }
              }
            }
          }
        }
      }

      .temp-area {
        z-index: 2;
        box-sizing: border-box;
        height: calc(100% - 700px);
        min-height: 455px;
        margin-top: 8px;
        overflow: hidden;
        background: #eee;
        border: 1px solid #ddd;

        .temp-area-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 58px;
          padding: 0 24px;
          color: #333;
          font-weight: 600;
          font-size: 18px;
          border-bottom: 1px solid rgba(229, 229, 229, 1);

          .el-icon-close {
            cursor: pointer;
          }
        }

        .temp-area-content {
          height: calc(100% - 58px);
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.05);
          }

          &:hover::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 0, 0, 0.4);
          }

          &::-webkit-scrollbar-track {
            background-color: #fff;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-track:hover {
            background-color: #fff;
          }
        }

        .temp-area-content .plank-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          float: left;
          box-sizing: border-box;
          width: 50%;
          height: 180px;
          padding: 8px 0;
          text-align: center;
          border: 1px solid rgba(229, 229, 229, 1);
          border-top: none;

          &:first-child {
            border-top: 1px solid rgba(229, 229, 229, 1);
          }

          &:nth-child(2) {
            border-top: 1px solid rgba(229, 229, 229, 1);
          }

          &:nth-child(2n) {
            border-left: none;
          }

          img {
            display: block;
            margin: 0 auto;
          }
        }
      }

      .no-temp-storage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-bottom: 15px;
          }

          span {
            color: #999;
            font-size: 14px;
          }
        }
      }
    }
  }

  .save-paiban-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2003;
    width: 100%;
    height: 100%;
    background: #0008;

    .mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 400px;
      height: 228px;
      margin: auto;
      background: #fff;
    }

    .top-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);

      .title {
        color: #333;
        font-size: 16px;
        line-height: 56px;
      }

      .icon-close {
        font-size: 14px;
        line-height: 56px;
      }
    }

    .paiban-way-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);

      > div {
        width: 95%;
        margin: 0 auto;

        .paiban-name {
          color: #333;
          font-size: 16px;
        }

        .ant-input {
          width: 240px;
        }
      }
    }

    .btns {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 0 16px;

      > div {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          display: inline-block;
          font-size: 14px;
        }

        .cancel {
          margin-right: 8px;
          color: #18a8c7;
        }

        .save {
          width: 80px;
          height: 28px;
          color: #fff;
          line-height: 28px;
          text-align: center;
          background: #18a8c7;
          border-radius: 4px;
        }
      }
    }
  }

  .surplus-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;

    .content {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 800px;
      height: 500px;
      margin: auto;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);

      .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 58px;
        padding: 16px 24px;
        background: #fafafa;
        box-shadow: inset 0px -1px 0px 0px #e5e5e5;

        .title {
          color: #333;
          font-size: 18px;
        }

        .icon-close {
          cursor: pointer;
        }
      }

      .match-surplus-box {
        padding: 16px 24px 0 24px;
      }

      .top-operation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .ant-btn {
          width: 86px;
          height: 32px;
          color: #fff;
          background: #18a8c7;
          border: none;
        }

        .disable-use {
          color: rgba(0, 0, 0, 0.25);
          background: #f5f5f5;
          border: 1px solid #d9d9d9;
        }

        /deep/ .ant-input-search {
          width: 369px;
        }

        /deep/ .ant-input-wrapper {
          width: 369px;

          .ant-input-group-addon {
            .ant-select {
              width: 100px !important;
            }
          }
        }
      }

      .surplus-list {
        height: 378px;
        max-height: 378px;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .surplus-item {
          display: flex;
          align-items: center;
          height: 98px;
          margin-bottom: 16px;
          border: 1px solid #e5e5e5;
          cursor: pointer;

          &:hover {
            border-color: #18a8c7;

            .title {
              color: #18a8c7;
            }
          }
        }

        .ant-radio-wrapper {
          margin: 0 24px;
          transform: scale(1.5);
          transform-origin: center;
        }

        .surplus-info {
          display: flex;
          align-items: center;

          .surplus-info-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            span {
              display: block;
              overflow: hidden;
              color: #999999;
              font-size: 14px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .info {
              color: #333;
              font-size: 16px;
            }
          }

          .surplus-name {
            width: 210px;
            margin-right: 45px;

            .title {
              font-size: 16px;
            }
          }

          .surplus-amount {
            width: 30px;
            margin-right: 32px;
          }

          .surplus-thick {
            width: 70px;
            margin-right: 32px;
          }

          .surplus-long {
            width: 70px;
            margin-right: 32px;
          }

          .surplus-width {
            width: 70px;
            margin-right: 32px;
          }
        }

        .no-match-list {
          text-align: center;

          > div {
            margin-top: 131px;
          }

          img {
            display: block;
            width: 64px;
            margin: 0 auto;
            margin-bottom: 8px;
          }

          .no-match-text {
            color: #999;
            font-size: 14px;
            cursor: default;
          }

          .add-new-surplus {
            color: #18a8c7;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.prompts {
  color: red;
}

.see-details {
  width: 80px;
  height: 30px;
}

.display {
  min-width: 280px;
  align-items: center;
}

// .conflict-display {
//   line-height: 32px;
//   min-width: 335px;
// }

.tag-template-select {
  .ant-select {
    margin-left: 10px;
  }

  /deep/ .ant-select-selection--single {
    height: 25px;
  }

  /deep/ .ant-select-selection__rendered {
    line-height: 25px;
  }

  .temp-label {
    color: rgba(0, 0, 0, 0.65);
  }

  .temp-label_disabel {
    color: rgba(0, 0, 0, 0.25);
  }
}

.follow-export {
  height: 80px;
  text-align: left;
}

.drop-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  // background-color: rgba(0, 0, 0, 0.3);
}

/deep/ .material-list-modal {
  .ant-modal-close {
    width: 54px;
    height: 54px;
  }

  .ant-modal-header {
    border-bottom: none;
    padding: 16px;
    padding-bottom: 14px;

    .ant-modal-title {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .ant-modal-body {
    height: 298px;
    padding: 0 16px;
  }
}

/deep/ .material-list-table {
  .ant-table-thead > tr > th {
    height: 36px;
    background-color: #f3f3f3;
  }

  .ant-table-body > tr > td {
    height: 40px;
  }
}

.icon-warn {
  color: #18a8c7;
  font-size: 22px;
}
/deep/ .useMaterial-tooltip-card {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      width: 350px;
      max-height: 30px;
      background-color: #fff;
      display: flex;
      align-items: center;
      color: #000;
      .tip {
        max-width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>

<style lang="less">
.paiban-operate-menu.ant-popover {
  opacity: 1;

  .ant-popover-content {
    width: 210px;

    .ant-popover-inner-content {
      padding: 0;
    }

    .operate-item {
      display: flex;
      justify-content: space-between;
      padding: 9px;
      border-bottom: 1px solid #e5e5e5;

      &.paiban-intro {
        align-items: center;
      }
    }

    .operate-item-more {
      text-align: right;
    }

    .ant-popover-title {
      padding: 5px 10px;
    }

    .rect-example {
      width: 36px;
      height: 12px;
      background: #eeeea1;
    }

    .holeslot-merge-example {
      width: 36px;
      height: 12px;
      background: #f808;
    }

    .rect-merge-example {
      width: 36px;
      height: 12px;
      background: #f008;
    }

    .slot-example {
      width: 36px;
      height: 12px;

      &.face-slot {
        border: 1px solid #f00;
      }

      &.back-slot {
        border: 1px solid #00f;
      }
    }

    .hole-example {
      width: 12px;
      height: 12px;
      margin-left: 12px;
      border-radius: 50%;

      &.face-hole {
        border: 1px solid #f00;
      }

      &.back-hole {
        border: 1px solid #00f;
      }
    }
  }
}

.special-shape-modal {
  .ant-modal-confirm-btns {
    .ant-btn:first-child {
      display: none;
    }
  }
}

.highgloss-tips {
  border-radius: 4px;
  min-width: 335px;

  .font-style {
    color: red;
  }
}

.only-surplus-box {
  min-width: 120px;

  .only-show-surplus {
    color: red;
    margin-left: -20px;
  }
}

.paiban-program-box {
  .paiban-program-choose {
    width: 210px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;

    .span-box {
      margin-left: 10px;
      width: 180px;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .anticon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      font-size: 12px;
      margin-right: 7px;
    }
  }

  .paiban-program-title {
    min-width: 70px;
  }
}

.disable-class {
  color: rgba(0, 0, 0, 0.25) !important;
}

.material-list-btn,
.surplus-in-storages {
  .disable-class {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-btn-link {
    // color: #8322ff;
  }
}

.purchase-order {
  .purchase-btn {
    display: flex;
    align-items: center;
  }

  .ant-btn-link {
    color: rgba(0, 0, 0, 0.65);
  }
}

.layout-loc {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
g-t-view .sur-store-modal {
  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-footer {
    border-top: none;

    .ant-btn:first-child {
      display: none;
    }
  }
}
.deduction-modal {
  .deduction-label {
    display: inline-block;
    width: 112px;
    text-align: right;
  }
}
</style>
