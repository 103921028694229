<template>
  <div class="subtle-page">
    <div class="loading-box" v-if="showLoading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 60px" spin />
      </a-spin>
    </div>
    <div class="left-category">
      <div v-for="(item, index) in drawData" :key="index">
        <div
          @click="foldCategory(item)"
          class="category-title overflow-text color-0"
          :id="`paiban_surplus_foldCategory_box_${index}`"
        >
          <span
            :class="['iconfont', item.show ? 'icon-xia' : 'icon-you']"
          ></span>
          <span class="iconfont icon-group"></span>
          <a-tooltip>
            <template slot="title">
              {{ item.thick }}{{ item.matCode }}({{ item.texture }})
            </template>
            <span>{{ showPartTitle(item) }}</span>
          </a-tooltip>
        </div>
        <div v-if="item.show" class="mb10">
          <div
            :class="[
              'big-part-info  overflow-text',
              bigPart.show ? 'active-bigpart' : '',
              isBatchPosition ? 'big-part-disable' : '',
            ]"
            v-for="(bigPart, partIndex) in item.data"
            :key="partIndex"
            @click="
              changeBigPart(
                bigPart,
                clacplankIndex(index) + partIndex + 1,
                index,
                true
              )
            "
            :id="`paiban_surplus_drawData_${index}_bigpart_${partIndex}`"
            :title="`${(bigPart.usedRate * 100).toFixed(2)}%`"
          >
            <span
              >[{{ clacplankIndex(index) + partIndex + 1 }}]
              {{ $t('arrangedPage.rate') }}</span
            >
            <span v-if="bigPart.usedRate"
              >{{ (bigPart.usedRate * 100).toFixed(2) }}%</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="center-canvas" :style="`max-width: ${canvasWidth}`">
      <div class="top-operation">
        <div class="top-left-operation">
          <a-button @click="goBackPaiban" id="paiban_surplus_goBack_btn">{{
            $t('common.return')
          }}</a-button>
          <a-button
            @click="changeLastBigpart(true)"
            v-show="!isBatchPosition"
            id="paiban_surplus_changeLastBigpart_btn"
          >
            <span class="iconfont icon-left"></span>
          </a-button>
          <a-button
            @click="changeNextBigpart(true)"
            v-show="!isBatchPosition"
            id="paiban_surplus_changeNextBigpart_btn"
          >
            <span class="iconfont icon-right"></span>
          </a-button>
        </div>
        <div>
          {{ $t('arrangedPage.subtlePage.cutPoint') }}
          <a-checkbox
            v-model="isAdsorb"
            @click="isAdsorb = !isAdsorb"
            class="surplus-adsorb"
            :disabled="bigpart.isLocked"
            id="paiban_surplus_adsorb_checkbox"
          ></a-checkbox>
          <a-button
            class="mr10"
            :class="{
              'locked-btn': bigpart.isLocked,
              'is-end': isCuttingSurplus,
            }"
            :disabled="bigpart.isLocked"
            id="paiban_surplus_isCuttingSurplus_btn"
            @click="cutSurplus"
            >{{
              isCuttingSurplus
                ? translateLang('arrangedPage.subtlePage.cancelCut')
                : translateLang('arrangedPage.subtlePage.cut')
            }}</a-button
          >
          <a-button
            class="mr10"
            @click="debounceSaveSurplus"
            id="paiban_surplus_saveSurplus_btn"
            >{{ $t('arrangedPage.saveSurplus') }}</a-button
          >
          <a-button
            v-if="!ncSetting.isPreLayout"
            class="mr10"
            @click="downloadNC"
            id="paiban_surplus_downloadNC_btn"
            :loading="isNCBtnLoading"
            >{{ $t('arrangedPage.downloadNC') }}</a-button
          >
          <a-tooltip v-else :title="$t('preLayoutSetting.backTip')">
            <a-button
              class="mr10"
              id="paiban_surplus_downloadNC_btn"
              :loading="isNCBtnLoading"
              :disabled="true"
              >{{ $t('arrangedPage.downloadNC') }}</a-button
            >
          </a-tooltip>
          <a-button
            v-if="!ncSetting.isPreLayout"
            class="mr10"
            @click="printTag"
            id="paiban_surplus_printTag_btn"
            >{{ $t('main.sideBar.printTag') }}</a-button
          >
          <a-tooltip v-else :title="$t('preLayoutSetting.backTip')">
            <a-button
              class="mr10"
              id="paiban_surplus_printTag_btn"
              :disabled="true"
              >{{ $t('main.sideBar.printTag') }}</a-button
            >
          </a-tooltip>
          <a-button
            class="mr10"
            @click="handlePositionChange"
            :disabled="isCuttingSurplus"
            id="paiban_surplus_handleBatchPosition_btn"
            >{{ $t('cuttingDock.cuttingParams.setBatchPosition') }}</a-button
          >
          <a-button
            class="top-right-operation"
            @click="saveSubtleChange"
            id="paiban_surplus_saveSurplusPaiban_btn"
            >{{ $t('materialPage.save.title') }}</a-button
          >
        </div>
      </div>
      <div
        class="flex batch-position flex-main--justify flex-cross--center"
        v-if="isBatchPosition"
      >
        <div class="ml8">
          {{
            $t('cuttingDock.cuttingParams.activeBatchPosition', {
              activePartCount: activePartCount + '',
            })
          }}
        </div>
        <div>
          <span>{{ $t('cuttingDock.cuttingParams.setBatchPositionAs') }}</span>
          <a-select
            v-model="defaultPositionPoint"
            id="paiban_surplus_positionPoints_select"
            class="ml8 mr8"
            showArrow
          >
            <a-select-option
              v-for="(item, index) in positionPointsObj"
              :key="item.value"
              :value="item.value"
              :id="`paiban_surplus_positionPoints_option_${index}`"
            >
              {{ $t(item.label) }}
            </a-select-option>
          </a-select>
          <a-button
            class="top-right-operation"
            @click="handleBatchPositionCancel"
            id="paiban_surplus_savePositionPoint_btn"
            >{{ $t('common.cancel') }}</a-button
          >
          <a-button
            class="top-right-operation"
            @click="handleBatchPositionChange"
            id="paiban_surplus_savePositionPoint_btn"
            :disabled="!activePartCount"
            >{{ $t('common.confirm') }}</a-button
          >
        </div>
      </div>
      <div class="canvas-box">
        <canvas
          width="1287"
          height="744"
          ref="subtleCanvas"
          @mousedown="clickCanvas"
          @mouseup="clickUpCanvas"
          @mousemove="movePlank"
          @mousewheel="wheelScale"
          @contextmenu.prevent
        ></canvas>
        <div
          id="line_box"
          :style="{ top: lineRecode.top, left: lineRecode.left }"
          v-show="lineRecode.show"
        >
          <el-input
            size="mini"
            v-model="lineRecode.current"
            @change="dealCurrentLineLength"
            id="paiban_surplus_dealCurrentLine_input"
          ></el-input>
          <span
            >({{ $t('arrangedPage.maxLong') }}{{ lineRecode.maxLine }})</span
          >
        </div>
      </div>
    </div>
    <div class="right-operation">
      <div class="tag-btn-box">
        <div class="tag" v-if="activePlank == null">
          <div class="no-active">
            <span>{{ $t('arrangedPage.showDetail.tip1') }}</span>
            <span>{{ $t('arrangedPage.showDetail.tip2') }}</span>
          </div>
        </div>
        <div v-else class="plank-info active-plank-box">
          <div class="left-info">
            <a-tooltip placement="top">
              <template slot="title">
                <span>{{ activePlank.orderNo }}</span>
              </template>
              <span class="info-eliplise" ref="activePlankAddr"
                >{{ $t('common.orderNo') }}：{{ activePlank.orderNo }}</span
              >
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>{{ activePlank.address }}</span>
              </template>
              <span class="info-eliplise" ref="activePlankAddr"
                >{{ $t('taskPage.search.orderName') }}：{{
                  activePlank.address
                }}</span
              >
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>
                  {{ $t('common.plankName') }}：
                  {{ activePlank.partName }}
                </span>
              </template>
              <span>
                {{ $t('common.plankName') }}：
                {{ activePlank.partName }}
              </span>
            </a-tooltip>

            <div class="plank-matCode-container">
              <a-tooltip placement="top">
                <template slot="title">
                  <span
                    >{{ $t('common.plankColor') }}：{{ activePlank.thick
                    }}{{ activePlank.matCode }}（{{ activePlank.texture }}）
                  </span>
                </template>
                <span
                  class="info-eliplise"
                  style="max-with: 210px; width: 210px"
                  ref="activePlankAddr"
                  >{{ $t('common.plankColor') }}：{{ activePlank.thick
                  }}{{ activePlank.matCode }}（{{ activePlank.texture }}）</span
                >
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span
                    >{{ $t('common.plankNo') }}：{{ activePlank.plankID }}</span
                  >
                </template>
                <span
                  class="info-eliplise"
                  style="
                    max-width: 100px;
                    position: absolute;
                    right: -20px;
                    top: 100px;
                  "
                  >{{ $t('common.plankNo') }}：{{ activePlank.plankID }}
                </span>
              </a-tooltip>
            </div>
            <span class="info-eliplise" ref="activePlankAddr"
              >{{ $t('common.edge.info') }}：{{ activePlank.edgeInfo }}</span
            >

            <a-tooltip placement="top">
              <template slot="title">
                <span
                  >{{
                    activePlank.srcTexDir !== 'reverse'
                      ? showPartSize('realRect', 'height')
                      : showPartSize('realRect', 'width')
                  }}*{{
                    activePlank.srcTexDir !== 'reverse'
                      ? showPartSize('realRect', 'width')
                      : showPartSize('realRect', 'height')
                  }}</span
                >
              </template>
              <span
                >{{ $t('common.cuttingSize') }}：{{
                  activePlank.srcTexDir !== 'reverse'
                    ? showPartSize('realRect', 'height')
                    : showPartSize('realRect', 'width')
                }}*{{
                  activePlank.srcTexDir !== 'reverse'
                    ? showPartSize('realRect', 'width')
                    : showPartSize('realRect', 'height')
                }}</span
              >
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span
                  >{{
                    activePlank.srcTexDir !== 'reverse'
                      ? showPartSize('oRect', 'height')
                      : showPartSize('oRect', 'width')
                  }}*{{
                    activePlank.srcTexDir !== 'reverse'
                      ? showPartSize('oRect', 'width')
                      : showPartSize('oRect', 'height')
                  }}</span
                >
              </template>
              <span
                >{{ $t('common.finishedSize') }}：{{
                  activePlank.srcTexDir !== 'reverse'
                    ? showPartSize('oRect', 'height')
                    : showPartSize('oRect', 'width')
                }}*{{
                  activePlank.srcTexDir !== 'reverse'
                    ? showPartSize('oRect', 'width')
                    : showPartSize('oRect', 'height')
                }}</span
              >
            </a-tooltip>
            <span>{{ $t('common.plankNum') }}：{{ showPlankNum }}</span>
            <a-tooltip placement="top">
              <template slot="title">
                <span
                  >{{ $t('common.plankRemark') }}：{{
                    activePlank.plank_remarks
                  }}</span
                >
              </template>
              <span class="info-eliplise" style="max-width: 190px">
                <span
                  >{{ $t('common.plankRemark') }}：{{
                    activePlank.plank_remarks
                  }}</span
                >
              </span>
            </a-tooltip>
          </div>
          <div class="right-qrcode" id="subtle-qrcode" ref="qrcode"></div>
        </div>
        <div class="btn">
          <span class="title">{{ $t('arrangedPage.plankOpration') }}</span>
          <div class="operation-btn">
            <a-button @click="changeCutOrder" :disabled="bigpart.isLocked">{{
              $t('arrangedPage.editCutOrder')
            }}</a-button>
            <a-tooltip
              :title="
                paibanWayLimit
                  ? translateLang('arrangedPage.specialPaibanErr')
                  : false
              "
            >
              <a-button
                @click="paibanAgain(false)"
                :disabled="paibanWayLimit"
                >{{ $t('arrangedPage.reArrange') }}</a-button
              >
            </a-tooltip>
            <a-button
              :disabled="
                bigpart.isLocked || activePlank == null || isBatchPosition
              "
              id="paiban_surplus_rotatePlank_btn"
              @click="rotatePlank"
              >{{ $t('arrangedPage.rotate') }}</a-button
            >
            <div
              class="cutorigin flex flex-cross--center"
              :class="{
                'cutorigin-active': isCutorigin,
                'cutorigin-disable':
                  !isCutorigin &&
                  (bigpart.isLocked || activePlank == null || isBatchPosition),
                'cursor-not-allowed':
                  bigpart.isLocked || activePlank == null || isBatchPosition,
              }"
              @click="
                showPosition(
                  bigpart.isLocked || activePlank == null || isBatchPosition
                )
              "
              id="paiban_surplus_cutorigin_btn"
            >
              <a-checkbox
                v-model="isCutorigin"
                id="paiban_surplus_cutorigin_checkbox"
                class="ml20 mr5"
                :disabled="isBatchPosition"
                @click.stop
              ></a-checkbox>
              <span>{{ $t('arrangedPage.setCutPoint') }}</span>
            </div>
            <a-button
              class="plank-control-del"
              :disabled="
                bigpart.isLocked ||
                activePlank == null ||
                !!this.activePlank.surplusPath ||
                isBatchPosition
              "
              id="subtle_calcCutPositionOrder_btn"
              v-if="!ableRewriteRemarks"
              @click="handleRewriteRemarks"
              >{{ $t('arrangedPage.editRemark') }}</a-button
            >
            <div
              v-if="
                ableRewriteRemarks && !bigpart.isLocked && activePlank != null
              "
              class="w100"
            >
              <div class="w100">
                <a-textarea
                  v-model="plankRemarks"
                  :placeholder="translateLang('arrangedPage.editRemark')"
                ></a-textarea>
                <div class="flex flex-main--justify mt8 mb8">
                  <a-button @click="plankRemarks = ''">{{
                    $t('arrangedPage.clearRemark')
                  }}</a-button>
                  <a-button @click="handleChangeRemarks">{{
                    $t('common.confirm')
                  }}</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modify-plank" v-if="!showSurplusTemp">
        <div class="nav">
          <span
            :class="this.plankActiveNav == 'zancun' ? 'active' : ''"
            @click="changeNav(1)"
            >{{ $t('arrangedPage.subtlePage.storageArea') }}</span
          >
          <span
            :class="this.plankActiveNav == 'fangan' ? 'active' : ''"
            @click="changeNav(2)"
            >{{ $t('main.sideBar.paibanResult') }}</span
          >
        </div>
        <div
          class="content-zancun drop-area jinxi-paiban-await-store"
          v-show="plankActiveNav == 'zancun'"
        >
          <m-plank-store
            ref="paibanStoreRef"
            :defaultScale="5"
            :scale="scalePercent"
            :activeBigpart="bigpart"
            page="jinxi"
            @onStoreDone="handleAwaitStoreDone"
          ></m-plank-store>
        </div>
        <div class="plan-group" v-show="plankActiveNav == 'fangan'">
          <div
            class="plan-item flex flex-dir--top flex-cross--center cursor-pointer w100"
            v-for="(item, index) in paibanWays"
            :key="index"
            @click="handleChangePaibanWay(item)"
          >
            <span class="mt5"
              >{{ $t('main.sideBar.paibanResult') }}{{ index + 1 }}</span
            >
            <canvas
              :ref="`planCanvasRef${item.canvasIndex}-${index}`"
              style="width: 190px"
            />
          </div>
        </div>
      </div>
      <div class="modify-plank">
        <surPlusTemp
          @dealSurPlusCut="dealSurPlusCut"
          :scalePercent="scalePercent"
          :showSurplusTemp.sync="showSurplusTemp"
        ></surPlusTemp>
      </div>
    </div>
    <div
      v-if="showCutOrign"
      @click="handleCloseCutOrign()"
      id="paiban_surplus_dialog_mask"
      class="origin-dialog-mask"
    >
      <div class="origin-dialog" @click.stop>
        <div class="origin-dialog-title">
          <span>{{ $t('arrangedPage.setCutPoint') }}</span>
          <span
            class="iconfont icon-close"
            @click="handleCloseCutOrign()"
            id="paiban_surplus_iconClose_btn"
          ></span>
        </div>
        <div class="origin-btn">
          <a-button
            :class="selectedCutorigin == 'leftTop' ? 'active' : ''"
            @click="changeCutOrigin('leftTop')"
            >{{ $t('cuttingDock.cuttingParams.topLeftD') }}</a-button
          >
          <a-button
            :class="selectedCutorigin == 'rightTop' ? 'active' : ''"
            @click="changeCutOrigin('rightTop')"
            >{{ $t('cuttingDock.cuttingParams.topRightD') }}</a-button
          >
          <a-button
            :class="selectedCutorigin == 'leftBottom' ? 'active' : ''"
            @click="changeCutOrigin('leftBottom')"
            >{{ $t('cuttingDock.cuttingParams.bottomLeftD') }}</a-button
          >
          <a-button
            :class="selectedCutorigin == 'rightBottom' ? 'active' : ''"
            @click="changeCutOrigin('rightBottom')"
            >{{ $t('cuttingDock.cuttingParams.bottomRightD') }}</a-button
          >
        </div>
        <div class="origin-dialog-btns" v-if="!isCutorigin">
          <div>
            <a-button @click="showPositionChange = false">{{
              $t('common.cancel')
            }}</a-button>
            <a-button @click="confirmChangeCutOrigin">{{
              $t('common.confirm')
            }}</a-button>
          </div>
        </div>
        <div v-else class="pl20 pr18 warning">
          {{ $t('cuttingDock.cuttingParams.setBatchPositionWaring') }}
        </div>
      </div>
    </div>
    <bigpartPriority
      v-if="isChangingPriority"
      :visible.sync="isChangingPriority"
      :bigpart="bigpart"
      @renderAll="renderAll"
      @changeBigPartPriotity="changeBigPartPriotity"
      @resetPriorityChange="resetPriorityChange"
      @updatePriority="updatePriority"
      @goBackPaiban="goBackPaiban"
    ></bigpartPriority>
    <g-base-modal
      :visible="isShowDeleteDialog"
      v-if="isShowDeleteDialog"
      @ok="handleConfirmDelete"
      @cancel="isShowDeleteDialog = false"
      :contain="translateLang('common.confirmDeleteTip')"
      :ok-text="translateLang('materialPage.delete.confirm')"
    >
    </g-base-modal>
    <div
      class="fixed p10 bg-dark color-f br4"
      v-show="lockHoverInfo.isShow"
      :style="{
        top: `${lockHoverInfo.top}px`,
        left: `${lockHoverInfo.left}px`,
      }"
    >
      <span v-if="lockHoverInfo.isLocked">
        {{ $t('arrangedPage.lockTip1') }}<br />{{ $t('arrangedPage.lockTip2') }}
      </span>
      <!-- <span v-else>
        点击进行锁定，锁定后不可进行<br />小板位置和属性的编辑
      </span> -->
    </div>
    <!-- 余料库容量不足弹窗 -->
    <a-modal
      v-model="showSurStoreTip"
      title="提示"
      centered
      okText="存入余料清单"
      class="sur-store-modal"
      width="450px"
      @ok="handleSaveSurStorage"
    >
      <span>余料库容量不足，余料无法存入仓库</span>
    </a-modal>
  </div>
</template>

<script>
// 锁的icon图
import { surplusLock } from '@/apis/surplusManage/index.ts'
import LockedImg from '@/assets/jiesuo.png'
import UnLockedImg from '@/assets/suoding.png'
import GBaseModal from '@/components/g-base-modal.vue'
import { DrawPlankColorJs } from '@/data/plank'
import { rolloverPlank, specialSymbol } from '@/util/LayoutFuncs'
import { rotatePart } from '@/util/LayoutTool.js'
import { viewDataToNCData } from '@/util/NCGenerator.js'
import ClipperLib from '@/util/clipper_unminified.js'
import { translate } from '@/util/commonFun'
import {
  buryPointApi,
  calcClosePlank,
  calcPlankSize,
  generateSimplePlankNum,
  mapGuimenToYPB,
} from '@/util/commonFuncs.js'
import {
  compare,
  compareTowNum,
  dealNumber,
  getOriginRect,
  isSamePart,
  toDecimal,
} from '@/util/commonFuncs.js'
import { checkPlankEdgeHasChanged } from '@/util/dealPaibanData'
import {
  checkOneBigpart,
  checkOnePlank,
  dealBigPlank,
  dealPlankPoly,
} from '@/util/dealPaibanData.js'
import {
  dealPaibanData,
  getPlateKnifeDiameter,
  paibanDataToView,
} from '@/util/dealPaibanData.js'
import {
  dealCurrentPointDistance,
  drawActivePlankOtherInfo,
  drawPlankEmptyDistance,
  drawPlankHSDetailInfo,
} from '@/util/dealPlankExtraDraw'
import { Plank, plankDrawFactory } from '@/util/drawPlank'
import {
  calcBigpartArea,
  dealCurveHoles,
  getPathsMaxAreaObj,
  getThroughPathObj,
} from '@/util/drawPlankFuncs.js'
import {
  PlankControl,
  clearPlankToBigPlank,
  dealSurplusSize,
  isExistPriority,
  judgePlankPushBigPlank,
  mousePointISInElement,
  partIsConflict,
} from '@/util/plankCommonFuncs'
import { dealLineSegmentIntersect, polyContain } from '@/util/polygonRelation'
import {
  dealChecklistID,
  surplusAutoFill,
  surplusMaxtailor,
} from '@/util/tailorSurplus'
import { cloneDeep } from 'lodash'
import { debounce } from 'lodash'
import { nanoid } from 'nanoid'
import QRCode from 'qrcodejs2'
import { mapMutations, mapState } from 'vuex'

import bigpartPriority from './bigpartPriority.vue'
import MPlankStore from './component/m-paiban-store'
import {
  checkCurrentDragPlankDataIsLoad,
  genAwaitStoreData,
  getPlankImage,
} from './component/m-paiban-store/utlis'
import surPlusTemp from './component/surPlusTemp'
import { getPlankMaxIndexByFinalDrawData } from './util'

// 默认缩放比例
const defaultScale = 5
// 大板和大板边框的默认偏移值
const defaultDeviation = 5
// 下刀顺序距边的默认距离
const defaultTextWidth = 3
// 记录大板之间的间距
const defaultTopInfoHeight = 30
// 下刀点距边的默认距离
const defaultDotWidth = 5
// 用于防抖
let _debounceTime = null
const BODY = document.querySelector('body')
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subtleData: {
      type: [Array, Object],
    },
    isNCBtnLoading: {
      type: Boolean,
      default: false,
    },
    standardPlank: {
      type: Object,
      default: () => ({}),
    },
    baseMaterialData: {
      type: Object,
      default: () => ({}),
    },
    isSpecialShape: {
      type: Boolean,
      default: false,
    },
    nestWay: {
      type: Number,
      default: 1,
    },
  },
  components: {
    bigpartPriority,
    surPlusTemp,
    GBaseModal,
    MPlankStore,
  },
  data() {
    return {
      // 是否点击重新排版
      hasReformatFlag: false,
      isLoadDownLoadNc: false,
      lockHoverInfo: {
        isShow: false,
        top: 0,
        left: 0,
        isLocked: false,
      },
      UnLockedImgInstance: null,
      LockedImgInstance: null,
      recordBigpart: '',
      // 记录可视窗口缩放事件
      resizeEvent: null,
      // 记录canvas的宽度
      canvasWidth: 0,
      // 记录绘制上下文
      ctx: {},
      // 记录大板长宽
      plankWidth: 0,
      plankHeight: 0,
      // 记录缩放后的长宽
      scaleWidth: 0,
      scaleHeight: 0,
      // 记录大板边框和大板的差值
      deviation: 5,
      dotWidth: 5,
      textWidth: 3,
      // 记录大板顶部信息高度
      topInfoHeight: 30,
      // 记录选中的小板
      activePlank: null,
      // 记录缩放比例
      scalePercent: 1,
      scale: 5,
      // 初始大小
      oriScale: 5,
      // 缩放倍数
      zoomNum: 1,
      // 记录当前大板
      bigpart: {},
      // 记录是否在移动板件
      isMovingPlank: false,
      // 记录是否在拖动画布
      isMovingCavnas: false,
      // 记录鼠标按下时鼠标相对于小板的偏移值
      activePlankOff: null,
      // 记录鼠标按下时, 鼠标位置相对于大板的偏移值
      plankRealOff: null,
      // 记录鼠标按下时, 小板的起始坐标
      plankStartPoint: null,
      // 记录画布拖动时的偏移值
      canvasRightDrag: null,
      // 键盘按下事件
      keyDownEvent: null,
      // 鼠标滚轮事件
      mouseWheelEvent: null,
      // 鼠标松开事件
      mouseupEvent: null,
      // 默认展示暂存区
      plankActiveNav: 'zancun',
      // 记录所有的排版方案
      paibanWays: [],
      // 显示下刀点修改弹窗
      showPositionChange: false,
      // 记录选择的下刀点
      selectedCutorigin: '',
      // 记录是否在修改下刀顺序
      isChangingPriority: false,
      // 是否显示加载
      showLoading: false,
      // 记录深拷贝之后的排版数据
      drawData: [],
      // 是否处于预料裁剪状态
      isCuttingSurplus: false,
      // 是否显示余料模板
      showSurplusTemp: false,
      // 记录所有的余料点位
      surplusPoint: [],
      // 记录计算后的下一个余料点位
      nextPoint: null,
      leftDistance: 0,
      rightDistance: 0,
      upDistance: 0,
      downDistance: 0,
      // 记录当前绘制的余料, 下一条线段是横向还是竖向
      nextDirection: false,
      isActivePlankLine: false,
      // 余料绘制引线信息
      lineRecode: {
        left: 0,
        top: 0,
        maxLine: 0,
        current: 0,
        show: false,
        axle: '',
        e: {},
        recordPoint: {},
      },
      xAxleLeadData: {},
      yAxleLeadData: {},
      isAdsorb: false,
      // 记录玻璃扩张
      glassDilate: 0,
      // 记录板件空白位置距离
      emptyDistance: false,
      // 记录点击的位置
      mousePoint: null,
      // 当前大板序号
      currentIndex: 1,
      // 节流
      isMessage: true,
      messageTimer: null,
      // 保存孔槽详细信息
      hsDetailInfoList: [],
      // 绘制孔槽详细信息后是否再次绘制,防止过多的重绘
      isRenderHSDetail: false,
      // 是否不翻版
      isNoRoll: false,
      // 是否显示删除弹窗
      isShowDeleteDialog: false,
      // 操作板件
      plankControl: null,
      // 冲突板件
      conflictPart: null,
      //记录点击的偏移的起始值
      clickPointOffset: {},
      cutOriginObj: {
        leftTop: '左上角',
        rightTop: '右上角',
        leftBottom: '左下角',
        rightBottom: '右下角',
      },
      ableRewriteRemarks: false,
      plankRemarks: '',
      // 余料库容量不足弹窗显示
      showSurStoreTip: false,
      paibanStoreRef: null,
      // 待排版库本地数据进入精细排版记录的id
      awaitLocalPlankStoreIds: [],
      // 待排版库远程数据进入精细排版记录的显示的id
      awaitServePlankStoreIds: [],
      // 待排版库在页面上入库板件partuniqueId
      awaitParticipationPaibanPlank: [],
      // 是否批量修改下刀点
      isBatchPosition: false,
      positionPointsObj: [
        {
          label: 'cuttingDock.cuttingParams.topLeftD',
          value: 'leftTop',
        },
        {
          label: 'cuttingDock.cuttingParams.bottomLeftD',
          value: 'leftBottom',
        },
        {
          label: 'cuttingDock.cuttingParams.topRightD',
          value: 'rightTop',
        },
        {
          label: 'cuttingDock.cuttingParams.bottomRightD',
          value: 'rightBottom',
        },
      ],
      defaultPositionPoint: 'rightBottom',
      // 右侧设置下刀点状态
      isCutorigin: false,
      //已选择的小板数量
      activePartCount: 0,
    }
  },
  computed: {
    ...mapState([
      'finalDrawData',
      'ncSetting',
      'tempStorage',
      'finalStorage',
      'paibanData',
      'orderInfo',
      'userInfo',
      'supplusIndex',
      'specialPlankParams',
      'isDragingPlank',
      'beDraggedPlank',
      'surplusCommonSize',
      'surplusTailorWay',
      'surplusAutoTailor',
      'activePaibanWay',
      'plankLableId',
      'isSurplusNoRoll',
      'preLayoutData',
      'selectStandardPlank',
      'customPlankOrder',
      'plankOrderList',
    ]),
    paibanWayLimit() {
      let flag = false
      let isLoop = false
      const parts = this.bigpart.parts ?? []
      if (this.bigpart.isLocked) {
        flag = true
      } else {
        for (const part of parts) {
          if (isLoop || !part) break
          for (const v of part.slots) {
            if (specialSymbol.includes(v['symbol'])) {
              flag = true
              isLoop = true
              break
            }
          }
        }
      }
      return flag
    },
    showPlankNum() {
      let str = this.activePlank.oriPlankNum
      if (this.ncSetting.genSimpleLabelBarcode) {
        str = this.activePlank.simplePlankNum ?? ''
      }
      return str
    },
    showCutOrign() {
      return this.showPositionChange || (this.isCutorigin && this.activePlank)
    },

    finalBigPart() {
      const data = cloneDeep(this.bigpart)
      delete data.subLockStartX
      delete data.subLockStartY
      delete data.subLockWH
      return data
    },
    isHighPlank() {
      return this.bigpart?.parts[0]?.is_high_gloss_plank
    },
  },
  methods: {
    ...mapMutations([
      'setFinalDrawData',
      'setPaibanData',
      'deleteTempStorage',
      'changeDragingPlank',
      'addTempStorage',
      'setSupplusIndex',
      'changeTempStorage',
      'setTempImgPos',
      'recordBeDraggedPlank',
      'deleteFinalStorage',
      'addFinalStorage',
      'resetTempStorage',
      'setPlankLableId',
      'setIsPrintTag',
      'setChangePaiban',
      'setHistorySecondTrimValue',
      'setHistoryPlankEdgeOff',
      'setPreLayoutData',
    ]),
    translateLang(key) {
      return translate(key)
    },
    showPartTitle(item) {
      let title = `${item.thick} ${item.matCode} (${item.texture})`
      if (title.length > 18) {
        title = title.slice(0, 18) + '...'
      }
      return title
    },
    // 计算板件序号
    clacplankIndex(i) {
      let index = 0
      for (let j = 0; j < i; j++) {
        index += this.drawData[j].data.length
      }
      return index
    },
    // 返回排版页面
    goBackPaiban() {
      const finalBigPart = cloneDeep(this.finalBigPart)
      // finalBigPart.parts 删除意义不明的newRectInd字段 点击过重新排版的时候不删除
      if (!this.hasReformatFlag) {
        finalBigPart.parts.forEach((part) => {
          delete part.newRectInd
        })
      }
      const isChange =
        JSON.stringify(this.recordBigpart.parts) ===
        JSON.stringify(finalBigPart.parts)
      this.bigpart.isActive = true
      if (!isChange) {
        this.$confirm(
          translate('arrangedPage.subtleSaveTip'),
          translate('common.tip'),
          {
            confirmButtonText: translate('common.confirm'),
            cancelButtonText: translate('common.cancel'),
            type: 'warning',
          }
        )
          .then(() => {
            // 恢复暂存区数据
            this.recoverAwaitStoreData()
            this.isCuttingSurplus = false
            this.isAdsorb = false
            this.setChangePaiban(false)
            this.$emit('getBigPart', this.bigpart)
            this.$emit('update:visible', false)
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        this.isCuttingSurplus = false
        this.isAdsorb = false
        this.$emit('getBigPart', this.bigpart)
        this.$emit('update:visible', false)
      }
    },
    // 处理左侧目录栏数据
    dealCategory() {
      let arr = this.drawData
      for (let i = 0; i < arr.length; ++i) {
        arr[i].show = true
        for (let k = 0; k < arr[i].data.length; ++k) {
          arr[i].data[k].show = false
          if (
            arr[i].data[k].stockKey == this.subtleData.stockKey &&
            arr[i].data[k].canvasIndex == this.subtleData.canvasIndex
          ) {
            arr[i].data[k].show = true
          }
        }
      }
      this.$forceUpdate()
    },
    // 展开关闭左侧目录
    foldCategory(item) {
      let arr = this.drawData
      for (let i = 0; i < arr.length; ++i) {
        if (arr[i].title == item.title) {
          item.show = !item.show
        }
      }
      this.$forceUpdate()
    },
    // 切换大板
    changeBigPart(item, index, bigpartIndex, isNotClickCanvas) {
      // 在批量修改下刀点状态下不能切换大板
      if (this.isBatchPosition) return
      let arr = this.drawData
      this.currentIndex = index
      this.plankActiveNav = 'zancun'
      for (let i = 0; i < arr.length; ++i) {
        if (bigpartIndex && bigpartIndex === i) {
          arr[i].show = true
        }

        for (let k = 0; k < arr[i].data.length; ++k) {
          arr[i].data[k].show = false
        }
      }
      this.$forceUpdate()

      item.subtleStartX = 500
      item.subtleStartY = 100
      item.show = true
      this.bigpart = item
      this.activePlank = null

      let recordHeight = 0
      let recordWidth = 0
      if (this.bigpart.surplusInfo) {
        let surplusWidth = this.bigpart.surplusInfo.width
        let surplusHeight = this.bigpart.surplusInfo.height
        recordHeight =
          (Number(surplusHeight) / defaultScale) * this.scalePercent
        recordWidth = (Number(surplusWidth) / defaultScale) * this.scalePercent
      } else {
        this.plankWidth = this.bigpart.plankWidth
        this.plankHeight = this.bigpart.plankHeight
        recordHeight =
          (Number(this.plankHeight) / defaultScale) * this.scalePercent
        recordWidth =
          (Number(this.plankWidth) / defaultScale) * this.scalePercent
      }
      this.bigpart.subtleWidth = recordWidth
      this.bigpart.subtleHeight = recordHeight
      if (this.plankActiveNav == 'fangan') {
        this.paibanAgain(true, 'changeBigPart')
      }
      this.$emit('changeBigpart', this.bigpart)
      this.clickCanvas(event, isNotClickCanvas)
      this.renderAll()
    },
    // 切换上一个大板
    changeLastBigpart(e, isNotClickCanvas) {
      let arr = this.drawData
      let newBigpart = {}
      for (let i = 0; i < arr.length; ++i) {
        let data = arr[i].data
        let flag = false
        for (let k = 0; k < data.length; ++k) {
          let bigpart = data[k]
          if (bigpart.show) {
            bigpart.show = false
            if (k != 0) {
              data[k - 1].show = true
              newBigpart = data[k - 1]
              flag = true
              break
            } else {
              let newData = []
              if (i != 0) {
                newData = arr[i - 1].data
              } else {
                newData = arr[arr.length - 1].data
              }
              newBigpart = newData[newData.length - 1]
              newBigpart.show = true
              flag = true
              break
            }
          }
        }
        if (flag) break
      }

      let recordHeight = 0
      let recordWidth = 0
      if (newBigpart.surplusInfo) {
        let surplusWidth = newBigpart.surplusInfo.width
        let surplusHeight = newBigpart.surplusInfo.height
        recordHeight =
          (Number(surplusHeight) / defaultScale) * this.scalePercent
        recordWidth = (Number(surplusWidth) / defaultScale) * this.scalePercent
      } else {
        recordHeight =
          (Number(this.plankHeight) / defaultScale) * this.scalePercent
        recordWidth =
          (Number(this.plankWidth) / defaultScale) * this.scalePercent
      }
      newBigpart.subtleWidth = recordWidth
      newBigpart.subtleHeight = recordHeight

      newBigpart.subtleStartX = 500
      newBigpart.subtleStartY = 100
      this.bigpart = newBigpart
      this.$forceUpdate()
      if (this.plankActiveNav == 'fangan') {
        this.paibanAgain(true, 'changeBigPart')
      }
      this.$emit('changeBigpart', this.bigpart)
      this.clickCanvas(e, isNotClickCanvas)
      this.renderAll()
    },
    // 切换下一个大板
    changeNextBigpart(e, isNotClickCanvas) {
      let arr = this.drawData
      let newBigpart = {}
      for (let i = 0; i < arr.length; ++i) {
        let data = arr[i].data
        let flag = false
        for (let k = 0; k < data.length; ++k) {
          let bigpart = data[k]
          if (bigpart.show) {
            bigpart.show = false
            if (k != data.length - 1) {
              data[k + 1].show = true
              newBigpart = data[k + 1]
              flag = true
              break
            } else {
              let newData = []
              if (i != arr.length - 1) {
                newData = arr[i + 1].data
              } else {
                newData = arr[0].data
              }
              newBigpart = newData[0]
              newBigpart.show = true
              flag = true
              break
            }
          }
        }
        if (flag) break
      }

      let recordHeight = 0
      let recordWidth = 0
      if (newBigpart.surplusInfo) {
        let surplusWidth = newBigpart.surplusInfo.width
        let surplusHeight = newBigpart.surplusInfo.height
        recordHeight =
          (Number(surplusHeight) / defaultScale) * this.scalePercent
        recordWidth = (Number(surplusWidth) / defaultScale) * this.scalePercent
      } else {
        recordHeight =
          (Number(this.plankHeight) / defaultScale) * this.scalePercent
        recordWidth =
          (Number(this.plankWidth) / defaultScale) * this.scalePercent
      }
      newBigpart.subtleWidth = recordWidth
      newBigpart.subtleHeight = recordHeight

      newBigpart.subtleStartX = 500
      newBigpart.subtleStartY = 100
      this.bigpart = newBigpart
      this.$forceUpdate()
      this.clickCanvas(e, isNotClickCanvas)
      this.renderAll()
      if (this.plankActiveNav == 'fangan') {
        this.paibanAgain(true, 'changeBigPart')
      }
      this.$emit('changeBigpart', this.bigpart)
    },

    // 判断大板是否可以进行正反切换
    judgeAbleFanban(bigpart) {
      let toggleFlag = false
      for (let k = 0; k < bigpart.parts.length; ++k) {
        let plank = bigpart.parts[k]
        let slotBackFlag = plank.slots?.some((v) => v.side == -1)
        let holeBackFlag = plank.holes?.some((v) => v.side == -1)
        let curveHoles = plank.curveHoles?.some((v) => v.side == -1)
        let millInfo
        if (plank.millInfo) {
          millInfo = plank.millInfo?.some((v) => v.side == -1)
        }
        let handleSlope
        if (plank.handleSlopes) {
          handleSlope = plank.handleSlopes.some((v) => v.side == -1)
        }

        if (
          slotBackFlag ||
          holeBackFlag ||
          curveHoles ||
          millInfo ||
          handleSlope
        ) {
          toggleFlag = true
          break
        }
      }
      // 通孔通槽双面加工，大板显示正反
      const holeArr = bigpart.parts
        .filter((p) => p.specialType !== 'supplus')
        .map((part) => [
          ...part.holes,
          // ...(part.curveHoles ? part.curveHoles : []),
        ])
      const slotArr = bigpart.parts
        .filter((p) => p.specialType !== 'supplus')
        .map((part) => [
          ...part.slots,
          ...(part.millInfo ? part.millInfo : []),
          ...(part.handleSlopes ?? []),
        ])
      const holeToggle = holeArr
        .flat(1)
        .some((v) => v.deep * 1 + 0.001 >= bigpart.thick)
      const slotToggle = slotArr
        .flat(1)
        .some((v) => v.deep * 1 + 0.001 >= bigpart.thick)
      bigpart.ableToggle =
        toggleFlag ||
        (this.ncSetting.throughTowSideToCut && holeToggle) ||
        (this.ncSetting.through_slot_two_side_cut && slotToggle)
    },
    // 初始化点位数据用于预料吸附和引线
    initBigPlanDeal() {
      this.xAxleLeadData = {}
      this.yAxleLeadData = {}
      this.bigpart.parts.forEach((item) => {
        item.polyArr[0].forEach((item) => {
          // 将0-2400 0-1200 划成100个为一组的对象，方便查找，防止卡顿
          const xKey = `X${Math.ceil(item.X / 100)}`
          const yKey = `Y${Math.ceil(item.Y / 100)}`
          if (!this.xAxleLeadData[xKey]) {
            this.xAxleLeadData[xKey] = []
          }
          if (!this.yAxleLeadData[yKey]) {
            this.yAxleLeadData[yKey] = []
          }
          this.xAxleLeadData[xKey].push({ ...item })
          this.yAxleLeadData[yKey].push({ ...item })
        })
      })
    },
    // 切换暂存区和排版方案
    changeNav(type) {
      if (type == 2) {
        this.plankActiveNav = 'fangan'
        this.paibanAgain(true)
        buryPointApi('layout_sub', 'layout_plan')
      } else {
        this.plankActiveNav = 'zancun'
      }
    },
    // 修改切割顺序
    changeCutOrder() {
      buryPointApi('layout_sub', 'modify_cut_order')
      this.isChangingPriority = true
      this.isBatchPosition = false
    },
    changeBigPartPriotity(part, num) {
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        if (
          this.bigpart.parts[i].index == part.index &&
          this.bigpart.parts[i].plankID == part.plankID &&
          this.bigpart.parts[i].partUniqueId == part.partUniqueId
        ) {
          this.bigpart.parts[i].showPriority = num
          break
        }
      }
    },
    // 重置切割顺序的更改
    resetPriorityChange() {
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        if (this.bigpart.parts[i].showPriority) {
          delete this.bigpart.parts[i].showPriority
        }
      }
    },
    // 确认修改
    updatePriority() {
      const oriBigpart = JSON.parse(JSON.stringify(this.bigpart))
      this.isChangingPriority = false
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        this.bigpart.parts[i].priority = this.bigpart.parts[i].showPriority
        delete this.bigpart.parts[i].showPriority
      }
      this.renderAll()
      this.$emit('recordUpdatePriority', {
        key: `精细排版修改切割顺序-大板序号：${this.bigpart.canvasIndex}`,
        dataArr: this.bigpart.parts.map((part, index) => {
          return {
            type: 'form',
            current: {
              priority: part.priority,
            }, // 当前表单数据
            ori: {
              priority: oriBigpart.parts[index].priority,
            }, // 原始表单数据
            compareMsg: [
              {
                title: part.partName,
                keys: ['priority'],
              },
            ], // 要比较的内容
            formTextObj: {
              priority: '切割顺序',
            }, // key对应的意思
            formValueObj: {}, // value对应的意思
          }
        }),
      })
    },
    async paibanAgain(needMorePlan, changeBigPart) {
      if (!changeBigPart) {
        const hasChanged = await checkPlankEdgeHasChanged(this.baseMaterialData)
        if (!hasChanged) return
      }
      this.paibanWays = []
      buryPointApi('layout_sub', 'reload_layout')
      this.showLoading = true
      this.setChangePaiban(true)
      const cloneBigPart = cloneDeep(this.bigpart.parts)
      // 剔除余料
      let cloneBigPartData = cloneDeep(this.bigpart),
        oriData
      if (needMorePlan) {
        oriData = [cloneBigPartData]
      } else {
        // 标记点击过重新排版
        this.hasReformatFlag = true
        this.bigpart.parts = this.bigpart.parts.filter(
          (part) => part.specialType !== 'supplus'
        )
        oriData = [cloneDeep(this.bigpart)]
      }
      if (this.ncSetting.xyReverse) {
        oriData = viewDataToNCData(oriData, 'toView')
      }
      let rectInd = 1
      let arr = oriData[0].parts
      for (let i = 0; i < arr.length; ++i) {
        arr[i].rectInd = rectInd
        // 意义不明的代码
        this.bigpart.parts[i].newRectInd = rectInd
        rectInd++
      }
      let rectMap = []
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        let obj = {
          rect: {
            x1: arr[i].rect.x,
            y1: arr[i].rect.y,
            x2: arr[i].rect.width,
            y2: arr[i].rect.height,
          },
          needRoll: arr[i].needRoll,
          rotate: arr[i].texDir == 'notcare' ? true : false,
        }
        if (this.isSpecialShape && arr[i].path) {
          const curveHolesArr = arr[i].curveHoles?.map((it) => it.path)
          obj.cutCurve = [...arr[i].path]
          if (curveHolesArr && curveHolesArr.length && !!curveHolesArr[0]) {
            obj.cutCurve.push(...curveHolesArr)
          }
        }
        rectMap.push(obj)
      }
      if (!rectMap.length) {
        this.$message.error('已无可用小板（余料除外），不支持重新排版')
        this.showLoading = false
        this.bigpart.parts = cloneBigPart.filter(
          (item) => item.specialType == 'supplus'
        )
        return
      }
      let layoutRectMap = {}
      let title = `${this.bigpart.texture}:${this.bigpart.matCode}:${this.bigpart.thick}`
      layoutRectMap[title] = rectMap
      const diameter = +getPlateKnifeDiameter(
        this.bigpart.stockKey,
        this.ncSetting
      )
      let ncConfig = {
        changeKnifePosition: this.ncSetting.xyReverse ? true : false,
        decimal: this.ncSetting.decimal,
        gap: this.ncSetting.panelSize.layoutGap,
        knifeR: diameter / 2,
        outHeight: this.standardPlank.plankHeight,
        outWidth: this.standardPlank.plankWidth,
        startPosition: this.ncSetting.startPosition,
        margin: this.standardPlank.plankEdgeOff,
        xyReverse: this.ncSetting.xyReverse ? 1 : 0,
        surplus_no_roll: this.isSurplusNoRoll,
        nesting_version: this.nestWay,
      }
      if (Object.keys(this.specialPlankParams).length > 0) {
        ncConfig.otherPlateSize = this.specialPlankParams
        let outSizeMap = {}
        this.specialPlankParams.forEach((item) => {
          if (item.isPicked) {
            let key = `${item.color}:${item.matCode}:${item.thick}`
            outSizeMap[key] = {
              width: item.width,
              height: item.height,
              trim_edge: item.trim_edge,
            }
          }
        })
        ncConfig.outSizeMap = outSizeMap
      }
      ncConfig.surplus_position = this.ncSetting.surplusPosition
      ncConfig.trim_side = this.ncSetting.trimSide
      let layout_data = {
        layoutConfig: ncConfig,
        layoutHoleMap: {},
        layoutRectMap: layoutRectMap,
      }
      let surplusInfo = {}
      if (
        this.bigpart.surplusInfo &&
        Object.keys(this.bigpart.surplusInfo).length > 0
      ) {
        let title = `${this.bigpart.texture}:${this.bigpart.matCode}:${this.bigpart.thick}`
        surplusInfo[title] = [this.bigpart.surplusInfo]
      }
      let paramObj = {
        data: {
          count_list: [1],
          cutDirection: this.ncSetting.cutDirection,
          id: this.orderInfo.order_codes,
          layout_data: JSON.stringify(layout_data),
          oids: this.orderInfo.order_ids,
          surplus: JSON.stringify(surplusInfo),
          uid: this.userInfo.id,
        },
        status: 1,
      }
      const res = await dealPaibanData(
        [paramObj],
        arr,
        this.activePaibanWay[0],
        false,
        false,
        needMorePlan
      )
      if (!res || !res.length) {
        this.showLoading = false
        return
      }
      const resultParts = res[0].parts
      // 精细排版排除两块大板，就不进行后续的操作
      if (res.length > 1) {
        this.showLoading = false
        return this.$message.warning('没有更好的排版方式')
      }
      if (!needMorePlan) {
        this.replaceRectData(resultParts)
        this.tailorSurplus(this.bigpart)
      }
      this.dealMorePlan(arr)
      this.showLoading = false
      if (this.bigpart.sideType == -1) {
        this.bigpart.sideType = 1
        this.toggleBigpartData()
      }
      // 重新排版 锁定解锁的余料
      if (
        this.bigpart.surplusInfo &&
        Object.keys(this.bigpart.surplusInfo).length > 0
      ) {
        let params = {
          match_list: [
            {
              thick: Number(this.bigpart.thick),
              type: this.bigpart.matCode,
              color: this.bigpart.texture,
            },
          ],
          page: 1,
          limit: 9999,
        }
        // 获取最新余料数据
        this.$token('/search_surplus_depot', params, (res) => {
          if (res.status == 1) {
            let currentSurplus = res.data.data.find(
              (item) => item.id == this.bigpart.surplusInfo.id
            )
            if (currentSurplus && currentSurplus.lock_status != 1) {
              const lockParams = {
                surplus: [
                  {
                    ...currentSurplus,
                    lock_status: 1,
                    lock_order: this.orderInfo.order_address.replace(
                      /\(.*?\)|历史记录/g,
                      ''
                    ),
                    lock_num: 1,
                  },
                ],
                release_all: false,
              }
              surplusLock(lockParams)
            }
          }
        })
      }
    },
    tailorSurplus(bigpart, needCalculate = false) {
      if (this.surplusAutoTailor) {
        const data = [bigpart]
        if (this.surplusTailorWay == 'maxRect') {
          surplusMaxtailor(data[0], this.ncSetting, this.userInfo)
        }
        if (this.surplusTailorWay == 'commonRect') {
          surplusAutoFill(
            data,
            this.surplusCommonSize,
            this.ncSetting,
            this.userInfo
          )
        }
        // 重新设定下刀点和切割顺序  多种排版方案不算下刀点 因为弄不来
        !needCalculate && this.calcCutPositionOrder(bigpart)
        !needCalculate && calcBigpartArea(bigpart, this.ncSetting)
      }
    },
    // 旋转板件
    rotatePlank() {
      const oriActivePlank = JSON.parse(JSON.stringify(this.activePlank))
      buryPointApi('layout_sub', 'rotate_90')
      rotatePart(this.activePlank)
      dealPlankPoly(this.activePlank)
      dealBigPlank(this.bigpart)
      checkOneBigpart(this.bigpart)
      this.renderAll()
      this.$emit('recordRotatePlank', {
        key: '精细排版旋转小板',
        dataArr: [
          {
            type: 'form',
            current: {
              oRectWidth: this.activePlank.oRect.width,
            }, // 当前表单数据
            ori: {
              oRectWidth: oriActivePlank.oRect.width,
            }, // 原始表单数据
            compareMsg: [
              {
                title: `${this.activePlank.partName}-大板序号: ${this.bigpart.canvasIndex}`,
                keys: ['oRectWidth'],
              },
            ], // 要比较的内容
            formTextObj: {
              oRectWidth: '成品宽度',
            }, // key对应的意思
            formValueObj: {}, // value对应的意思
          },
        ],
      })
    },
    showPosition(able) {
      if (able) return
      buryPointApi('layout_sub', 'set_cut_point')
      this.showPositionChange = true
      this.selectedCutorigin = this.activePlank.cutOrigin
    },
    handleCloseCutOrign() {
      this.activePlank = null
      this.showPositionChange = false
      this.renderAll()
    },
    // 修改下刀位置
    changeCutOrigin(type) {
      this.selectedCutorigin = type
      if (this.isCutorigin) {
        this.confirmChangeCutOrigin()
        this.activePlank = null
        this.renderAll()
        this.defaultPositionPoint = 'rightBottom'
      }
    },
    confirmChangeCutOrigin() {
      const oriActivePlank = JSON.parse(JSON.stringify(this.activePlank))
      this.activePlank.cutOrigin = this.selectedCutorigin
      this.showPositionChange = false
      this.renderAll()
      this.$emit('recordChangeCutOrigin', {
        key: `精细排版设置下刀点-大板序号: ${this.bigpart.canvasIndex}`,
        dataArr: [
          {
            type: 'form',
            current: {
              cutOrigin: this.cutOriginObj[this.activePlank.cutOrigin],
            }, // 当前表单数据
            ori: {
              cutOrigin: this.cutOriginObj[oriActivePlank.cutOrigin],
            }, // 原始表单数据
            compareMsg: [
              {
                title: this.activePlank.partName,
                keys: ['cutOrigin'],
              },
            ], // 要比较的内容
            formTextObj: {
              cutOrigin: '下刀点',
            }, // key对应的意思
            formValueObj: {}, // value对应的意思
          },
        ],
      })
    },
    // 根据窗口缩放计算canvas宽度
    calcCanvasWidth() {
      return (e) => {
        let windowWidth = document.body.offsetWidth
        if (windowWidth >= 1440) {
          this.canvasWidth = windowWidth - 220 - 316 - 48
        }
      }
    },
    handleRewriteRemarks() {
      if (!this.plankRemarks) {
        this.plankRemarks = this.activePlank.plank_remarks
      }
      this.ableRewriteRemarks = true
    },
    handleChangeRemarksAble(val) {
      this.ableRewriteRemarks = val
      this.plankRemarks = ''
      if (!this.plankRemarks) {
        this.plankRemarks = this.activePlank.plank_remarks
      }
    },
    handleChangeRemarks() {
      if (this.activePlank) {
        this.ableRewriteRemarks = false
        this.bigpart.parts.forEach((nowPlank) => {
          if (
            this.activePlank.oriPlankNum == nowPlank.oriPlankNum &&
            this.activePlank.priority == nowPlank.priority
          ) {
            nowPlank.plank_remarks = this.plankRemarks
            this.$set(this.activePlank, 'plank_remarks', this.plankRemarks)
          }
        })
        this.plankRemarks = ''
      }
    },
    // 开始绘制前的准备
    startDraw() {
      this.$nextTick(() => {
        let dom = this.$refs.subtleCanvas
        this.ctx = dom.getContext('2d')
        let recordHeight = 0
        let recordWidth = 0
        // if (
        //   this.selectStandardPlank &&
        //   Object.keys(this.selectStandardPlank).length
        // ) {
        //   this.plankWidth = !this.ncSetting.xyReverse
        //     ? this.selectStandardPlank.plankWidth
        //     : this.selectStandardPlank.plankHeight
        //   this.plankHeight = !this.ncSetting.xyReverse
        //     ? this.selectStandardPlank.plankHeight
        //     : this.selectStandardPlank.plankWidth
        // } else
        if (!this.bigpart.otherPlate) {
          this.plankWidth = this.bigpart.plankWidth
          this.plankHeight = this.bigpart.plankHeight
        } else {
          this.plankWidth = this.ncSetting.drawPlankWidth
          this.plankHeight = this.ncSetting.drawPlankHeight
        }
        if (this.bigpart.surplusInfo) {
          let surplusWidth = this.bigpart.surplusInfo.width
          let surplusHeight = this.bigpart.surplusInfo.height
          recordHeight =
            (Number(surplusHeight) / defaultScale) * this.scalePercent
          recordWidth =
            (Number(surplusWidth) / defaultScale) * this.scalePercent
        } else {
          recordHeight =
            (Number(this.plankHeight) / defaultScale) * this.scalePercent
          recordWidth =
            (Number(this.plankWidth) / defaultScale) * this.scalePercent
        }
        this.bigpart.subtleWidth = recordWidth
        this.bigpart.subtleHeight = recordHeight
        this.initPlankLock(this.ctx)
        this.recordBigpart = cloneDeep(this.bigpart)
        this.drawOneBigpart()
        // 初始化板件控制
        this.plankControl = new PlankControl(this)
      })
    },
    // 绘制一个大板的所有东西
    drawOneBigpart() {
      let strokeColor = '#333'
      let strokeLine = 1
      this.ctx.fillStyle = '#eee'
      if (
        this.activePlank &&
        this.activePlank.stockKey == this.bigpart.stockKey
      ) {
        strokeColor = '#f00'
        strokeLine = 2
      } else {
        strokeColor = '#333'
        strokeLine = 1
      }
      let left = this.bigpart.subtleStartX
      let top = this.bigpart.subtleStartY
      if (this.canvasOffset) {
        left += this.canvasOffset.x
        top += this.canvasOffset.y
      }
      // 边框是比真正的大板要大一圈的圆角矩形, 而且顶部还有其他信息, 所以长宽多10, 起始点y要多30
      this.strokeRoundRect(
        this.ctx,
        left - strokeLine,
        top - strokeLine,
        this.bigpart.subtleWidth + 2 * this.deviation + 2 * strokeLine,
        this.bigpart.subtleHeight +
          2 * this.deviation +
          this.topInfoHeight +
          2 * strokeLine,
        0,
        strokeLine,
        strokeColor
      )
      if (
        this.bigpart.surplusInfo &&
        Object.keys(this.bigpart.surplusInfo).length > 0
      ) {
        let surplus = this.bigpart.surplusInfo
        let newLeft = left + this.deviation
        let newTop = top + this.deviation + this.topInfoHeight
        if (surplus.shape && surplus.shape == 'lshape') {
          let x3 = this.ncSetting.xyReverse
            ? Number(surplus.y5)
            : Number(surplus.x3)
          let y3 = this.ncSetting.xyReverse
            ? Number(surplus.x5)
            : Number(surplus.y3)
          let x4 = this.ncSetting.xyReverse
            ? Number(surplus.y4)
            : Number(surplus.x4)
          let y4 = this.ncSetting.xyReverse
            ? Number(surplus.x4)
            : Number(surplus.y4)
          let x5 = this.ncSetting.xyReverse
            ? Number(surplus.y3)
            : Number(surplus.x5)
          let y5 = this.ncSetting.xyReverse
            ? Number(surplus.x3)
            : Number(surplus.y5)
          let newWidth = surplus.width
          let newHeight = surplus.height
          if (
            this.ncSetting.xyReverse &&
            (this.ncSetting.startPosition == '右下角' ||
              this.ncSetting.startPosition == '左上角')
          ) {
            x3 = newWidth - x3
            x4 = newWidth - x4
            x5 = newWidth - x5

            this.ctx.beginPath()
            this.ctx.moveTo(newLeft, newTop)
            this.ctx.lineTo(
              newLeft + (newWidth / defaultScale) * this.scalePercent,
              newTop
            )
            this.ctx.lineTo(
              newLeft + (newWidth / defaultScale) * this.scalePercent,
              newTop + (newHeight / defaultScale) * this.scalePercent
            )

            this.ctx.lineTo(
              newLeft + (x3 / defaultScale) * this.scalePercent,
              newTop + (y3 / defaultScale) * this.scalePercent
            )
            this.ctx.lineTo(
              newLeft + (x4 / defaultScale) * this.scalePercent,
              newTop + (y4 / defaultScale) * this.scalePercent
            )
            this.ctx.lineTo(
              newLeft + (x5 / defaultScale) * this.scalePercent,
              newTop + (y5 / defaultScale) * this.scalePercent
            )
          } else {
            y3 = newHeight - y3
            y4 = newHeight - y4
            y5 = newHeight - y5

            this.ctx.beginPath()
            this.ctx.moveTo(newLeft, newTop)

            this.ctx.lineTo(
              newLeft + (x3 / defaultScale) * this.scalePercent,
              newTop + (y3 / defaultScale) * this.scalePercent
            )
            this.ctx.lineTo(
              newLeft + (x4 / defaultScale) * this.scalePercent,
              newTop + (y4 / defaultScale) * this.scalePercent
            )
            this.ctx.lineTo(
              newLeft + (x5 / defaultScale) * this.scalePercent,
              newTop + (y5 / defaultScale) * this.scalePercent
            )
            this.ctx.lineTo(
              newLeft + (newWidth / defaultScale) * this.scalePercent,
              newTop + (newHeight / defaultScale) * this.scalePercent
            )
            this.ctx.lineTo(
              newLeft,
              newTop + (newHeight / defaultScale) * this.scalePercent
            )
          }

          this.ctx.closePath()
          this.ctx.fill()
        } else {
          this.ctx.fillRect(
            newLeft,
            newTop,
            (surplus.width / defaultScale) * this.scalePercent,
            (surplus.height / defaultScale) * this.scalePercent
          )
        }
      } else {
        // 绘制真正的大板
        this.ctx.fillRect(
          left + this.deviation,
          top + this.topInfoHeight + this.deviation,
          this.bigpart.subtleWidth,
          this.bigpart.subtleHeight
        )
      }
      // 绘制顶部信息(正反切换和优化率)
      this.drawTopInfo()
      let { lockStartX, lockStartY, lockWidthHeight } = this.setLockInfo()
      if (this.bigpart.isLocked) {
        if (this.canvasOffset) {
          if (this.bigpart.isLocked) {
            this.loadLockedImg(
              this.ctx,
              lockStartX,
              lockStartY,
              this.bigpart.isLocked,
              lockWidthHeight
            )
          }
        } else {
          if (this.bigpart.isLocked) {
            this.loadLockedImg(
              this.ctx,
              lockStartX,
              lockStartY,
              this.bigpart.isLocked,
              lockWidthHeight
            )
          }
        }
      } else if (this.activePlank && !this.bigpart.isLocked) {
        if (this.canvasOffset) {
          if (this.bigpart.isLocked) {
            this.loadLockedImg(
              this.ctx,
              lockStartX,
              lockStartY,
              this.bigpart.isLocked,
              lockWidthHeight
            )
          }
        } else {
          if (this.bigpart.isLocked) {
            this.loadLockedImg(
              this.ctx,
              lockStartX,
              lockStartY,
              this.bigpart.isLocked,
              lockWidthHeight
            )
          }
        }
      }
      // 绘制小板
      // 记录是否有激活板件, 激活的板件最后绘制
      let finalPart = []
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        let part = this.bigpart.parts[i]
        if (this.activePlank && part.index == this.activePlank.index) {
          finalPart.push(part)
          continue
        }
        this.drawParts(
          this.ctx,
          part,
          left + this.deviation,
          top + this.topInfoHeight + this.deviation,
          this.bigpart
        )
      }
      if (finalPart && finalPart.length) {
        finalPart.forEach((item) => {
          this.drawParts(
            this.ctx,
            item,
            left + this.deviation,
            top + this.topInfoHeight + this.deviation,
            this.bigpart
          )
        })
      }
      if (!this.isAdsorb && !this.isCuttingSurplus) {
        // 绘制空白位置距离
        drawPlankEmptyDistance(this.ctx, this.emptyDistance, this.mousePoint)
        // 绘制孔槽详细信息
        drawPlankHSDetailInfo(this.ctx, this.hsDetailInfoList, this.mousePoint)
      }
    },

    /**
     * 绘制大板顶部信息包括正反切换和优化率
     * @param { Object } bigpart 大板信息
     */
    drawTopInfo() {
      let fontSize = 14 * this.scalePercent
      this.ctx.font = `bold ${fontSize}px 'PingFangSC-Regular, PingFang SC' `
      let startX = this.bigpart.subtleStartX
      let startY = this.bigpart.subtleStartY
      if (this.canvasOffset) {
        startX += this.canvasOffset.x
        startY += this.canvasOffset.y
      }
      let width = this.bigpart.subtleWidth + 2 * this.deviation
      const isLshape =
        this.bigpart.surplusInfo && this.bigpart.surplusInfo.shape == 'lshape'
      const plankSize = calcPlankSize(this.bigpart, isLshape)
      let usedRate =
        '【' +
        this.bigpart.orderIndex +
        '】' +
        (this.bigpart.usedRate * 100).toFixed(2) +
        '%' +
        (isLshape
          ? ` (${plankSize.longW}(${plankSize.shortW})X${plankSize.longH}(${plankSize.shortH}))`
          : `  (${this.bigpart.plankWidth}X${this.bigpart.plankHeight})`)
      if (this.bigpart.ableToggle) {
        if (this.isHighPlank) {
          // 绘制正
          this.ctx.fillStyle = '#18a8c7'
          this.ctx.fillRect(startX, startY, width / 2, this.topInfoHeight)
          // 绘制正字
          this.ctx.fillStyle = '#fff'
          let textWidth = this.calcTextSize(translate('common.front'))
          let topInfoHeight = (width / 2 - textWidth) / 2
          this.ctx.fillText(
            translate('common.front'),
            startX + topInfoHeight,
            startY + 22 * this.scalePercent
          )

          // 绘制反
          this.ctx.fillStyle = '#d7d7d7'
          this.ctx.fillRect(
            startX + width / 2,
            startY,
            width / 2,
            this.topInfoHeight
          )
          this.ctx.fillStyle = '#666'
          // 绘制反字
          this.ctx.fillText(
            translate('common.back'),
            startX + width / 2 + topInfoHeight,
            startY + 22 * this.scalePercent
          )

          // 绘制优化率
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX,
            startY - 33 * this.scalePercent,
            width,
            this.topInfoHeight / 2
          )
          this.ctx.fillStyle = '#333'
          textWidth = this.calcTextSize(usedRate)
          topInfoHeight = (width / 3 - textWidth) / 2
          // 绘制优化率文字
          this.ctx.fillText(usedRate, startX, startY - 6 * this.scalePercent)
          return
        }
        if (this.bigpart.sideType == 1) {
          // 绘制正
          this.ctx.fillStyle = '#18a8c7'
          this.ctx.fillRect(startX, startY, width / 2, this.topInfoHeight)
          // 绘制正字
          this.ctx.fillStyle = '#fff'
          let textWidth = this.calcTextSize(translate('common.front'))
          let topInfoHeight = (width / 2 - textWidth) / 2
          this.ctx.fillText(
            translate('common.front'),
            startX + topInfoHeight,
            startY + 22 * this.scalePercent
          )

          // 绘制反
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX + width / 2,
            startY,
            width / 2,
            this.topInfoHeight
          )
          this.ctx.fillStyle = '#333'
          // 绘制反字
          this.ctx.fillText(
            translate('common.back'),
            startX + width / 2 + topInfoHeight,
            startY + 22 * this.scalePercent
          )

          // 绘制优化率
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX,
            startY - 33 * this.scalePercent,
            width,
            this.topInfoHeight / 2
          )
          this.ctx.fillStyle = '#333'
          textWidth = this.calcTextSize(usedRate)
          topInfoHeight = (width / 3 - textWidth) / 2
          // 绘制优化率文字
          this.ctx.fillText(usedRate, startX, startY - 6 * this.scalePercent)
        } else {
          // 绘制正
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(startX, startY, width / 2, this.topInfoHeight)
          // 绘制正字
          this.ctx.fillStyle = '#333'
          let textWidth = this.calcTextSize(translate('common.front'))
          let topInfoHeight = (width / 2 - textWidth) / 2
          this.ctx.fillText(
            translate('common.front'),
            startX + topInfoHeight,
            startY + 22 * this.scalePercent
          )

          // 绘制反
          this.ctx.fillStyle = '#18a8c7'
          this.ctx.fillRect(
            startX + width / 2,
            startY,
            width / 2,
            this.topInfoHeight
          )
          this.ctx.fillStyle = '#fff'
          // 绘制反字
          this.ctx.fillText(
            translate('common.back'),
            startX + width / 2 + topInfoHeight,
            startY + 22 * this.scalePercent
          )

          // 绘制优化率
          this.ctx.fillStyle = '#fff'
          this.ctx.fillRect(
            startX + width / 2,
            startY - 33 * this.scalePercent,
            width / 2,
            this.topInfoHeight / 2
          )
          this.ctx.fillStyle = '#333'
          textWidth = this.calcTextSize(usedRate)
          topInfoHeight = (width / 3 - textWidth) / 2
          // 绘制优化率文字
          this.ctx.fillText(usedRate, startX, startY - 6 * this.scalePercent)
        }
      } else {
        // 绘制优化率
        this.ctx.fillStyle = '#cfcfcf'
        this.ctx.fillRect(startX, startY, width, this.topInfoHeight)
        this.ctx.fillStyle = '#333'
        let textWidth = this.calcTextSize(usedRate)
        let topInfoHeight = (width - textWidth) / 2
        // 绘制优化率文字
        this.ctx.fillText(
          usedRate,
          startX + topInfoHeight,
          startY + 22 * this.scalePercent
        )
      }
    },

    // 获取文字宽度, 用于中心绘制
    calcTextSize(text) {
      let span = document.createElement('span')
      let result = {}
      result.width = span.offsetWidth
      result.height = span.offsetHeight
      span.style.visibility = 'hidden'
      span.style.fontSize = '14px'
      span.style.fontFamily = 'PingFangSC-Regular, PingFang SC'
      span.style.display = 'inline-block'
      document.body.appendChild(span)
      if (typeof span.textContent != 'undefined') {
        span.textContent = text
      } else {
        span.innerText = text
      }
      result.width =
        parseFloat(window.getComputedStyle(span).width) - result.width
      result.height =
        parseFloat(window.getComputedStyle(span).height) - result.height
      document.body.removeChild(span)
      return result.width * this.scalePercent
    },

    /**
     * 绘制小板
     * @param { Object } part 小板数据
     * @param { Number } startX 大板的绘制起始横坐标
     * @param { Number } startY 大板的绘制起始纵坐标
     */
    drawParts(ctx, part, startX, startY, bigpart, onlyOnePart = false) {
      let rect = {
        x: startX + (part.startX / defaultScale) * this.scalePercent,
        y: startY + (part.startY / defaultScale) * this.scalePercent,
        width: (part.rect.width / defaultScale) * this.scalePercent,
        height: (part.rect.height / defaultScale) * this.scalePercent,
      }
      if (onlyOnePart) {
        rect.x = 5
        rect.y = 5
      }
      if (this.activePlankOff) {
        if (
          this.activePlank &&
          this.activePlank.partUniqueId == part.partUniqueId &&
          this.dragPosition
        ) {
          let newPlankStartX =
            (this.dragPosition.x / this.scalePercent) * defaultScale -
            this.activePlankOff.x
          let newPlankStartY =
            (this.dragPosition.y / this.scalePercent) * defaultScale -
            this.activePlankOff.y
          rect.x = startX + (newPlankStartX / defaultScale) * this.scalePercent
          rect.y = startY + (newPlankStartY / defaultScale) * this.scalePercent
        }
      }
      const plank = new Plank(
        ctx,
        part,
        rect.x,
        rect.y,
        defaultScale,
        this.scalePercent,
        { plankLineWidth: 1 }
      )
      // 激活板件或者入库时需要设置板件激活颜色
      if (this.isBatchPosition) {
        if (part.checked) {
          plank.setFillStyle(DrawPlankColorJs.active)
        }
      } else {
        if (this.activePlank && isSamePart(this.activePlank, part)) {
          this.selectedCutorigin = part.cutOrigin
          plank.setFillStyle(DrawPlankColorJs.active)
        }
      }
      if (part.plankMerge) {
        plank.setFillStyle(DrawPlankColorJs.plankMerge)
      }
      const draws = plankDrawFactory(
        ctx,
        part,
        rect.x,
        rect.y,
        defaultScale,
        this.scalePercent,
        {
          dotWidth: this.dotWidth,
          textWidth: this.textWidth,
          fontSize: 14,
        },
        ['plankSize']
      )
      // 添加其他绘制
      plank.addStrategy(Object.keys(draws).map((it) => draws[it]))
      plank.draw()
    },
    // 绘制异形孔
    drawAllCurveHole(ctx, part, rect, bigpart, isThumbnail = false) {
      if (part.curveHoles && part.curveHoles.length) {
        const thick = (bigpart && bigpart.thick) || 0
        // const finalCurveHoles = this.dealCurveHoles(part.curveHoles)
        const noThroughCurveHoles = getThroughPathObj(
          part.curveHoles,
          thick,
          false
        )
        noThroughCurveHoles.forEach((hole) => {
          if (hole.side == 1) {
            ctx.strokeStyle = '#f008'
          } else {
            ctx.strokeStyle = '#00f8'
          }
          ctx.beginPath()
          for (let i = 0; i < hole.path.length; i++) {
            let x, y
            if (isThumbnail) {
              x = rect.x + hole.path[i].x
              y = rect.y + hole.path[i].y
            } else {
              x = rect.x + (hole.path[i].x / defaultScale) * this.scalePercent
              y = rect.y + (hole.path[i].y / defaultScale) * this.scalePercent
            }

            if (i == 0) {
              ctx.moveTo(x, y)
            } else {
              ctx.lineTo(x, y)
            }
          }
          ctx.stroke()
          ctx.closePath()
        })
      }
    },
    /**
     * 绘制孔位
     * @param { Object } part 小板数据
     * @param { Object } rect 小板的绘制属性
     */
    drawAllHoles(ctx, part, rect, isThumbnail = false) {
      if (part.holes.length > 0) {
        for (let i = 0; i < part.holes.length; ++i) {
          let hole = part.holes[i]
          if (hole.side == 1) {
            ctx.strokeStyle = '#f008'
          } else {
            ctx.strokeStyle = '#00f8'
          }
          let circle
          if (isThumbnail) {
            circle = {
              x: rect.x + hole.center.x,
              y: rect.y + hole.center.y,
              r: hole.diameter / 2,
            }
          } else {
            circle = {
              x: rect.x + (hole.center.x / defaultScale) * this.scalePercent,
              y: rect.y + (hole.center.y / defaultScale) * this.scalePercent,
              r: (hole.diameter / 2 / defaultScale) * this.scalePercent,
            }
          }
          ctx.beginPath()
          ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, true)
          ctx.closePath()
          ctx.stroke()
        }
      }
    },
    /**
     * 绘制拉槽
     * @param { Object } part 小板数据
     * @param { Object } rect 小板的绘制属性
     */
    drawAllSlots(ctx, part, rect, isThumbnail = false) {
      let slotsArr = [part.slots, part.handleSlopes]
      slotsArr.forEach((item, index) => {
        if (item && item.length > 0) {
          for (let i = 0; i < item.length; ++i) {
            let slot = item[i]
            if (slot.side == 1) {
              ctx.strokeStyle = '#f008'
            } else {
              ctx.strokeStyle = '#00f8'
            }
            let slotInfo = {}
            // 拉槽两点pt1, pt2为拉槽平行边的中点, 所以计算起始点需要减去拉槽的宽度的一半
            // 如果拉槽两点的横坐标相等 则为竖拉槽
            slotInfo.width = isThumbnail
              ? slot.width
              : (slot.width / defaultScale) * this.scalePercent
            if (slot.pt1.x === slot.pt2.x) {
              // 找出更小的纵坐标, 因为canvas是从左上角绘制的
              // isThumbnail=true 是缩略图
              if (isThumbnail) {
                slotInfo.x = rect.x + slot.pt1.x - slot.width / 2
                slotInfo.long = Math.abs(slot.pt1.y - slot.pt2.y)
              } else {
                slotInfo.x =
                  rect.x +
                  (slot.pt1.x / defaultScale) * this.scalePercent -
                  ((slot.width / defaultScale) * this.scalePercent) / 2
                slotInfo.long = Math.abs(
                  ((slot.pt1.y - slot.pt2.y) / defaultScale) * this.scalePercent
                )
              }
              if (slot.pt1.y > slot.pt2.y) {
                if (isThumbnail) {
                  slotInfo.y = rect.y + slot.pt2.y
                } else {
                  slotInfo.y =
                    rect.y + (slot.pt2.y / defaultScale) * this.scalePercent
                }
              } else {
                if (isThumbnail) {
                  slotInfo.y = rect.y + slot.pt1.y
                } else {
                  slotInfo.y =
                    rect.y + (slot.pt1.y / defaultScale) * this.scalePercent
                }
              }
              const { x, y, width, long } = slotInfo
              if (x + width > rect.x + rect.width && index == 1) {
                ctx.strokeRect(x - width / 2, y, width, long)
              } else if (x < rect.x && index == 1) {
                ctx.strokeRect(x + width / 2, y, width, long)
              } else {
                ctx.strokeRect(x, y, width, long)
              }
            }
            // 如果拉槽两点的纵坐标相等, 则为横拉槽
            if (slot.pt1.y === slot.pt2.y) {
              if (isThumbnail) {
                slotInfo.y = rect.y + slot.pt1.y - slot.width / 2
                slotInfo.long = Math.abs(slot.pt1.x - slot.pt2.x)
              } else {
                // 找出更小的横坐标
                slotInfo.y =
                  rect.y +
                  (slot.pt1.y / defaultScale) * this.scalePercent -
                  ((slot.width / defaultScale) * this.scalePercent) / 2
                slotInfo.long = Math.abs(
                  ((slot.pt1.x - slot.pt2.x) / defaultScale) * this.scalePercent
                )
              }
              if (slot.pt1.x > slot.pt2.x) {
                if (isThumbnail) {
                  slotInfo.x = rect.x + slot.pt2.x
                } else {
                  slotInfo.x =
                    rect.x + (slot.pt2.x / defaultScale) * this.scalePercent
                }
              } else {
                if (isThumbnail) {
                  slotInfo.x = rect.x + slot.pt1.x
                } else {
                  slotInfo.x =
                    rect.x + (slot.pt1.x / defaultScale) * this.scalePercent
                }
              }
              const { x, y, width, long } = slotInfo
              if (y < rect.y && index == 1) {
                ctx.strokeRect(x, y + width / 2, long, width)
              } else if (y + width > rect.y + rect.height && index == 1) {
                ctx.strokeRect(x, y - width / 2, long, width)
              } else {
                ctx.strokeRect(x, y, long, width)
              }
            }
          }
        }
      })
    },

    /**
     * 该方法用来绘制一个有填充色的圆角矩形
     * @param cxt:canvas的上下文环境
     * @param x:左上角x轴坐标
     * @param y:左上角y轴坐标
     * @param width:矩形的宽度
     * @param height:矩形的高度
     * @param radius:圆的半径
     * @param fillColor:填充颜色
     **/
    fillRoundRect(cxt, x, y, width, height, radius, /*optional*/ fillColor) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false
      }

      cxt.save()
      cxt.translate(x, y)
      //绘制圆角矩形的各个边
      this.drawRoundRectPath(cxt, width, height, radius)
      cxt.fillStyle = fillColor || '#000' //若是给定了值就用给定的值否则给予默认值
      cxt.fill()
      cxt.restore()
    },

    /**
     * 该方法用来绘制圆角矩形
     * @param cxt:canvas的上下文环境
     * @param x:左上角x轴坐标
     * @param y:左上角y轴坐标
     * @param width:矩形的宽度
     * @param height:矩形的高度
     * @param radius:圆的半径
     * @param lineWidth:线条粗细
     * @param strokeColor:线条颜色
     **/
    strokeRoundRect(
      cxt,
      x,
      y,
      width,
      height,
      radius,
      /*optional*/ lineWidth,
      /*optional*/ strokeColor
    ) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false
      }

      cxt.save()
      cxt.translate(x, y)
      //绘制圆角矩形的各个边
      this.drawRoundRectPath(cxt, width, height, radius)
      cxt.lineWidth = lineWidth || 1 //若是给定了值就用给定的值否则给予默认值2
      cxt.strokeStyle = strokeColor || '#000'
      cxt.stroke()
      cxt.restore()
    },

    drawRoundRectPath(cxt, width, height, radius) {
      cxt.beginPath(0)
      //从右下角顺时针绘制，弧度从0到1/2PI
      cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2)
      //矩形下边线
      cxt.lineTo(radius, height)
      //左下角圆弧，弧度从1/2PI到PI
      cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI)
      //矩形左边线
      cxt.lineTo(0, radius)
      //左上角圆弧，弧度从PI到3/2PI
      cxt.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2)
      //上边线
      cxt.lineTo(width - radius, 0)
      //右上角圆弧
      cxt.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2)
      //右边线
      cxt.lineTo(width, height - radius)
      cxt.closePath()
    },

    /**
     * 设置激活板件
     * @param { Object } e 事件对象
     * @returns
     */
    setActivePlank(e, isNotClickCanvas) {
      let mousePoint = {
        x: e.offsetX,
        y: e.offsetY,
      }
      // 如果找到了, 则判断点击的位置
      if (
        mousePoint.x <=
          this.bigpart.subtleStartX +
            this.bigpart.subtleWidth +
            2 * this.deviation &&
        mousePoint.y <=
          this.bigpart.subtleStartY +
            this.bigpart.subtleHeight +
            this.topInfoHeight +
            2 * this.deviation
      ) {
        // 判断点击的是否为大板, 还是顶部信息
        if (mousePoint.y >= this.bigpart.subtleStartY + this.topInfoHeight) {
          this.bigpart.isActive = true
          for (let i = 0; i < this.bigpart.parts.length; ++i) {
            let part = this.bigpart.parts[i]
            if (!this.isAdsorb && !this.isCuttingSurplus) {
              this.emptyDistance = dealCurrentPointDistance(
                this.bigpart,
                mousePoint,
                this.deviation,
                this.topInfoHeight,
                this.scalePercent,
                defaultScale,
                'jingxi'
              )
              this.mousePoint = mousePoint
            }
            let rect = {
              x:
                this.bigpart.subtleStartX +
                this.deviation +
                (part.startX / defaultScale) * this.scalePercent,
              y:
                this.bigpart.subtleStartY +
                this.deviation +
                this.topInfoHeight +
                (part.startY / defaultScale) * this.scalePercent,
            }
            let newPos = {
              x: ((mousePoint.x - rect.x) / this.scalePercent) * defaultScale,
              y: ((mousePoint.y - rect.y) / this.scalePercent) * defaultScale,
            }
            let curvePath = []
            if (part.path) {
              curvePath = part.path[0]
            } else {
              curvePath = [
                { x: 0, y: 0 },
                { x: part.rect.width, y: 0 },
                { x: part.rect.width, y: part.rect.height },
                { x: 0, y: part.rect.height },
              ]
            }
            let isInpoly = this.isInPolygon(newPos, curvePath)
            if (isInpoly) {
              this.emptyDistance = false
              this.conflictPart = null
              // 给每块板子上添加一个是否被选中的状态  再次被选中的时候取消它的状态
              this.$set(part, 'checked', part.checked ? false : true)
              return part
            }
          }
        } else {
          this.plankRemarks = ''
          const holeArr = this.bigpart.parts
            .filter((p) => p.specialType !== 'supplus')
            .map((part) => [
              ...part.holes,
              // ...(part.curveHoles ? part.curveHoles : []),
            ])
          const slotArr = this.bigpart.parts
            .filter((p) => p.specialType !== 'supplus')
            .map((part) => [
              ...part.slots,
              ...(part.millInfo ? part.millInfo : []),
              ...(part.handleSlopes ?? []),
            ])
          const holeToggle = holeArr
            .flat(1)
            .some((v) => v.deep * 1 + 0.001 >= this.bigpart.thick)
          const slotToggle = slotArr
            .flat(1)
            .some((v) => v.deep * 1 + 0.001 >= this.bigpart.thick)
          const isReverePlank = this.bigpart.parts.some(
            (part) => part.reverePlank
          )
          if (this.bigpart && this.bigpart.ableToggle) {
            if (this.bigpart.parts[0].is_high_gloss_plank && !isNotClickCanvas)
              return this.$message.error(translate('arrangedPage.glossRollErr'))
            if (
              ((this.ncSetting.throughTowSideToCut && holeToggle) ||
                (this.ncSetting.through_slot_two_side_cut && slotToggle)) &&
              !isReverePlank
            )
              return this.$message.error(translate('arrangedPage.rollTip'))
            if (
              mousePoint.x >= this.bigpart.subtleStartX &&
              mousePoint.x <=
                this.bigpart.subtleStartX + this.bigpart.subtleWidth / 2
            ) {
              if (this.bigpart.sideType == -1) {
                this.bigpart.sideType = 1
                this.toggleBigpartData()
              }
            }
            if (
              mousePoint.x >=
                this.bigpart.subtleStartX + this.bigpart.subtleWidth / 2 &&
              mousePoint.x <=
                this.bigpart.subtleStartX + this.bigpart.subtleWidth
            ) {
              if (this.bigpart.sideType == 1) {
                this.bigpart.sideType = -1
                this.toggleBigpartData()
              }
            }
          }
          this.emptyDistance = false
          return null
        }
      } else {
        this.emptyDistance = false
        return null
      }
    },

    toggleBigpartData() {
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        let plank = this.bigpart.parts[i]
        rolloverPlank(plank)
        dealPlankPoly(plank)
        checkOneBigpart(this.bigpart)
        this.judgeAbleFanban(this.bigpart)
      }
    },
    isInBigPlank(point) {
      let bigpartPoly = []
      for (let i = 0; i < this.bigpart.bigPlank[0].length; ++i) {
        let point2 = this.bigpart.bigPlank[0][i]
        bigpartPoly.push({
          x:
            this.bigpart.subtleStartX +
            this.deviation +
            (point2.X / defaultScale) * this.scalePercent,
          y:
            this.bigpart.subtleStartY +
            this.deviation +
            this.topInfoHeight +
            (point2.Y / defaultScale) * this.scalePercent,
        })
      }
      return this.isInPolygon(point, bigpartPoly)
    },
    // 判断点是否在多边形内部
    isInPolygon(checkPoint, polygonPoints) {
      let counter = 0
      let i
      let xinters
      let p1, p2
      let pointCount = polygonPoints.length
      p1 = polygonPoints[0]
      for (i = 1; i <= pointCount; i++) {
        p2 = polygonPoints[i % pointCount]
        if (
          checkPoint.x >= Math.min(p1.x, p2.x) &&
          checkPoint.x <= Math.max(p1.x, p2.x)
        ) {
          if (checkPoint.y <= Math.max(p1.y, p2.y)) {
            if (p1.x != p2.x) {
              xinters =
                ((checkPoint.x - p1.x) * (p2.y - p1.y)) / (p2.x - p1.x) + p1.y
              if (p1.y == p2.y || checkPoint.y < xinters) {
                counter++
              }
            }
          }
        }
        p1 = p2
      }
      if (counter % 2 == 0) {
        return false
      } else {
        return true
      }
    },

    // 重新绘制
    renderAll() {
      if (this.ctx.clearRect) {
        this.ctx.clearRect(0, 0, 1289, 744)
        this.drawOneBigpart()
        this.initBigPlanDeal()
      }
    },
    // 鼠标按下事件
    clickCanvas(e, isNotClickCanvas) {
      // 保存起始offset信息
      this.clickPointOffset.x = e.offsetX
      this.clickPointOffset.y = e.offsetY

      // 鼠标左键
      if (e.button == 0) {
        const mousePoint = {
          x: e.offsetX,
          y: e.offsetY,
        }
        if (this.isCuttingSurplus) {
          if (this.isAdsorb && this.surplusPoint.length === 0) {
            this.surPlusAbsorb(e, 'down')
          } else {
            this.drawSurplus(e)
          }
          this.lineRecode.e = e
          return
        }
        if (this.isMovingCavnas) return
        // 选中板件时, 先清除所有分类的选中状态
        this.activePlank = this.setActivePlank(e, isNotClickCanvas)
        this.plankRemarks = ''
        this.ableRewriteRemarks = false
        this.renderAll()
        const plankPath = this.getPlankPath()
        const onPlank = this.jundgeLocation(mousePoint, plankPath)
        const { lockPath, lockStartX, lockStartY, lockWidthHeight } =
          this.setLockInfo()
        const onLock = this.jundgeLocation(mousePoint, lockPath)
        if (onPlank) {
          if (this.bigpart.isLocked) {
            this.loadLockedImg(
              this.ctx,
              lockStartX,
              lockStartY,
              this.bigpart.isLocked,
              lockWidthHeight
            )
          }
        }
        // 在锁上的点击
        if (onLock && (this.bigpart.isLocked || this.bigpart.isActive)) {
          this.bigpart.isLocked = this.lockHoverInfo.isLocked =
            !this.bigpart.isLocked
          if (!this.bigpart.isLocked) {
            this.$message.success(translate('lockModal.lockSeccess'))
            this.renderAll()
            this.lockHoverInfo.isShow = false
            this.bigpart.isActive = false
          } else {
            this.$message.success(translate('lockModal.lockSeccessTip'))
          }
          this.$emit('changeLockedStatus', this.bigpart)
          this.renderAll()
        }
        // 大板被激活过一次但是点击在大板和锁之外把激活状态置为false
        if (this.bigpart.isActive && !onLock && !onPlank) {
          this.bigpart.isActive = false
        }
        if (this.activePlank) {
          this.$nextTick(() => {
            if (this.activePlank.ggid) {
              this.$refs.qrcode.innerHTML = ''
              new QRCode('subtle-qrcode', {
                width: 80,
                height: 80,
                colorDark: '#000000', //前景色
                colorLight: '#ffffff', //背景色
                typeNumber: 4,
                correctLevel: QRCode.CorrectLevel.H,
                text: 'http://eggi.cn/÷' + this.activePlank.ggid,
              })
            }
          })
          // 如果激活了板件则记录当前的位置, 以便移动时的使用
          this.isMovingPlank = true
          // 记录当前板件的起始坐标
          this.plankStartPoint = {
            x: this.activePlank.startX,
            y: this.activePlank.startY,
          }
          // 记录选中板件的起始点和鼠标位置的差距
          this.activePlankOff = {
            x:
              (e.offsetX / this.scalePercent) * defaultScale -
              this.activePlank.startX,
            y:
              (e.offsetY / this.scalePercent) * defaultScale -
              this.activePlank.startY,
          }
          // 计算当前点击的位置, 鼠标位置相对于大板的偏移值
          this.plankRealOff = {
            x:
              ((e.offsetX - this.bigpart.subtleStartX - this.deviation) /
                this.scalePercent) *
                defaultScale -
              this.activePlank.startX,
            y:
              ((e.offsetY -
                this.bigpart.subtleStartY -
                this.topInfoHeight -
                this.deviation) /
                this.scalePercent) *
                defaultScale -
              this.activePlank.startY,
          }
          if (this.ncSetting.glass_setting || this.bigpart.isLocked) {
            this.isMovingPlank = false
            if (_debounceTime) {
              clearTimeout(_debounceTime)
            }
            _debounceTime = setTimeout(() => {
              if (this.ncSetting.glass_setting) {
                this.$message.error(translate('arrangedPage.glassEquipmentTip'))
              }
              if (this.bigpart.isLocked) {
                this.$message.error(translate('arrangedPage.lockPartEditTip'))
              }
            }, 100)
            return
          }
        }
      }
      // 鼠标右键
      if (e.button == 2) {
        if (this.isMovingPlank) return
        this.bigpart.isActive = false
        this.emptyDistance = false
        this.isMovingCavnas = true
        this.canvasRightDrag = {
          x: e.offsetX,
          y: e.offsetY,
        }
      }
    },
    // 鼠标松开事件
    clickUpCanvas(e) {
      const { offsetX, offsetY } = e
      if (this.isCuttingSurplus) return
      if (e.button == 0) {
        const mousePoint = {
          x: e.offsetX,
          y: e.offsetY,
        }
        if (this.isMovingCavnas) return
        this.isMovingPlank = false
        this.dragPosition = null
        this.activePlankOff = null
        if (this.activePlank) {
          const oriActivePlank = JSON.parse(JSON.stringify(this.activePlank))
          if (this.ncSetting.glass_setting || this.bigpart.isLocked) return
          // 如果鼠标位置在大板上, 则继续执行后面的逻辑, 否则弹回原位置重新绘制
          if (
            e.offsetX <=
              this.bigpart.subtleStartX +
                this.bigpart.subtleWidth +
                2 * this.deviation &&
            e.offsetY <=
              this.bigpart.subtleStartY +
                this.bigpart.subtleHeight +
                this.topInfoHeight +
                2 * this.deviation
          ) {
            // 计算选中板件相对于大板的位置
            this.activePlank.startX =
              ((e.offsetX - this.bigpart.subtleStartX - this.deviation) /
                this.scalePercent) *
                defaultScale -
              this.plankRealOff.x
            this.activePlank.startY =
              ((e.offsetY -
                this.bigpart.subtleStartY -
                this.topInfoHeight -
                this.deviation) /
                this.scalePercent) *
                defaultScale -
              this.plankRealOff.y
            this.plankRealOff = null
            buryPointApi('layout_sub', 'move_plate')
            // 重新处理选中板件的轮廓路径
            dealPlankPoly(this.activePlank)
            dealBigPlank(this.bigpart)
            let flag = false
            // 判断鼠标点击左键抬起和点击位置是否一样 一样就不需要吸附
            const { x, y } = this.clickPointOffset
            const t = !!(x === offsetX && y === offsetY)

            // 如果移动后的板件还是在当前选中的大板上, 则直接判断是否冲突
            const result = calcClosePlank(this.activePlank, this.bigpart)
            if (result && !t) {
              const { x, y } = result
              this.activePlank.startX = x
              this.activePlank.startY = y
              // 重新处理选中板件的轮廓路径
              dealPlankPoly(this.activePlank)
            }
            flag = checkOnePlank(this.activePlank, this.bigpart)

            if (!flag) {
              this.activePlank.startX = this.plankStartPoint.x
              this.activePlank.startY = this.plankStartPoint.y
              dealPlankPoly(this.activePlank)
            }
            checkOneBigpart(this.bigpart)
          } else {
            this.activePlank.startX = this.plankStartPoint.x
            this.activePlank.startY = this.plankStartPoint.y
            dealPlankPoly(this.activePlank)
          }

          // 判断完冲突后, 重新绘制
          this.renderAll()
          this.$emit('recordPlankMove', {
            key: '精细排版移动小板',
            dataArr: [
              {
                type: 'form',
                current: {
                  startX: this.activePlank.startX,
                  startY: this.activePlank.startY,
                }, // 当前表单数据
                ori: {
                  startX: oriActivePlank.startX,
                  startY: oriActivePlank.startY,
                }, // 原始表单数据
                compareMsg: [
                  {
                    title: `${this.activePlank.partName}-大板序号：${this.bigpart.canvasIndex}`,
                    keys: ['startX', 'startY'],
                  },
                ], // 要比较的内容
                formTextObj: {
                  startX: '横坐标',
                  startY: '纵坐标',
                }, // key对应的意思
                formValueObj: {}, // value对应的意思
              },
            ],
          })
          const path = this.getPlankPath()
          const result = this.jundgeLocation(mousePoint, path)
          // 未锁定状态
          if (result) {
            const { lockStartX, lockStartY, lockWidthHeight } =
              this.setLockInfo()
            if (this.bigpart.isLocked) {
              this.loadLockedImg(
                this.ctx,
                lockStartX,
                lockStartY,
                this.bigpart.isLocked,
                lockWidthHeight
              )
            }
          }
        }
      }
      if (e.button == 2) {
        if (this.isMovingPlank) return
        if (this.canvasOffset) {
          this.bigpart.subtleStartX += this.canvasOffset.x
          this.bigpart.subtleStartY += this.canvasOffset.y
        }
        this.isMovingCavnas = false
        this.canvasOffset = null
      }
    },
    drawDashLine(recordPoint, point, e) {
      const box = document.querySelector('#line_box')
      let firstPoint = this.surplusPoint[0]
      let diameter = +getPlateKnifeDiameter(
        this.bigpart.stockKey,
        this.ncSetting
      )
      if (this.ncSetting.glass_setting) {
        diameter = 0
      }
      if (this.nextDirection) {
        // 如果新的点在记录的点下方, 则长度不能超过计算的下方距离
        if (recordPoint.y > point.y) {
          let distance = Math.abs(recordPoint.y - point.y)
          if (distance > this.upDistance) {
            point.y = recordPoint.y - this.upDistance
          }
        } else {
          let distance = Math.abs(recordPoint.y - point.y)
          if (distance > this.downDistance) {
            point.y = recordPoint.y + this.downDistance
          }
        }
      } else {
        if (recordPoint.x > point.x) {
          let distance = Math.abs(recordPoint.x - point.x)
          if (distance > this.leftDistance) {
            point.x = recordPoint.x - this.leftDistance
          }
        } else {
          let distance = Math.abs(recordPoint.x - point.x)
          if (distance > this.rightDistance) {
            point.x = recordPoint.x + this.rightDistance
          }
        }
      }
      // 移动输入框位置
      if (point.x !== recordPoint.x || point.y !== recordPoint.y) {
        this.lineRecode.show = true
        this.lineRecode.point = { ...point }
        this.lineRecode.recordPoint = { ...recordPoint }
        this.lineRecode.left = point.x - box.offsetWidth / 2 + 'px'
        this.lineRecode.top = point.y + 30 + 'px'
        const { direction, axle, sym } = this.dealLineDirection(
          point,
          recordPoint
        )
        box.querySelector('input').focus()
        this.lineRecode.axle = axle
        this.lineRecode.sym = sym
        this.lineRecode.maxLine =
          (
            (this[`${direction}Distance`] / this.scalePercent) *
            defaultScale
          ).toFixed(2) -
          diameter -
          this.glassDilate
        this.lineRecode.current =
          (
            (Math.abs(point[axle] - recordPoint[axle]) * defaultScale) /
            this.scalePercent
          ).toFixed(2) -
          diameter -
          this.glassDilate
        this.drawSurplusLead(point, direction)
      } else {
        this.lineRecode.show = false
      }
      // 如果已经有三个点了, 且没有按住ctrl键, 则当第四个点与第一个点比较持平的时候, 会自动吸附到和第一个点持平的位置
      if (this.surplusPoint.length == 3 && !e.ctrlKey) {
        // 通过判断第四个点和第一个点是否相近, 相近则自动校正x, y, 不相近说明是L形
        // 获取第一个记录点
        // 如果最后两个点的y轴相等, 说明最后一条绘制的线段为横线, 则下一条只能为竖线
        // body.style.cursor = 'crosshair'
        if (this.nextDirection) {
          if (Math.abs(e.offsetY - firstPoint.y) <= 10) {
            point.y = firstPoint.y
            this.drawLine(point, firstPoint)
            BODY.style.cursor = 'crosshair'
            this.lineRecode.show = false
          }
        } else {
          if (Math.abs(e.offsetX - firstPoint.x) <= 10) {
            point.x = firstPoint.x
            this.drawLine(point, firstPoint)
            BODY.style.cursor = 'crosshair'
            this.lineRecode.show = false
          }
        }
        if (
          Math.abs(e.offsetY - firstPoint.y) >= 10 &&
          Math.abs(e.offsetX - firstPoint.x) >= 10
        ) {
          BODY.style.cursor = 'default'
        }
      }
      // 如果已经有5个点了, 则会直接确定第六个点
      if (this.surplusPoint.length == 5) {
        if (this.nextDirection) {
          point.y = firstPoint.y
          this.drawLine(point, firstPoint)
        } else {
          point.x = firstPoint.x
          this.drawLine(point, firstPoint)
        }
        this.lineRecode.show = false
      }
      if (this.surplusPoint.length != 3) {
        BODY.style.cursor = 'default'
      }
      this.ctx.beginPath()
      this.ctx.setLineDash([])
      this.ctx.moveTo(recordPoint.x, recordPoint.y)
      this.ctx.lineTo(point.x, point.y)
      this.ctx.stroke()
      this.ctx.setLineDash([])
      this.ctx.closePath()
    },
    /**绘制一条线段 */
    drawLine(head, tail) {
      this.ctx.save()
      this.ctx.beginPath()
      this.ctx.strokeStyle = '#9DCEFF'
      this.ctx.setLineDash([4, 2])
      this.ctx.moveTo(tail.x, tail.y)
      this.ctx.lineTo(head.x, head.y)
      this.ctx.stroke()
      this.ctx.restore()
    },
    /** 绘制余料引线 */
    drawSurplusLead(currentPoint, direction) {
      const startX = this.bigpart.subtleStartX
      const startY = this.bigpart.subtleStartY
      const axle = direction == 'up' || direction == 'down' ? 'Y' : 'X'
      let { x, y } = currentPoint
      const p = {
        X:
          ((x - this.bigpart.subtleStartX - this.deviation) /
            this.scalePercent) *
          defaultScale,
        Y:
          ((y -
            this.bigpart.subtleStartY -
            this.deviation -
            this.topInfoHeight) /
            this.scalePercent) *
          defaultScale,
        key: true,
      }

      const key = `${axle}${Math.ceil(p[axle] / 100)}`
      const result = this[`${axle.toLowerCase()}AxleLeadData`][key]
      if (!result) return
      // 很难找到和当前位置对应的点位 所以有上下5像素的误差
      let d = this[`${axle.toLowerCase()}AxleLeadData`][key].filter((item) => {
        return Math.abs(Math.ceil(item[`${axle}`]) - p[`${axle}`]) <= 5
      })
      if (d.length === 0) return
      d = [...d, p]
      // 在X轴上移动，找Y轴上在范围内的点位
      const leadAxle = axle == 'X' ? 'Y' : 'X'
      d = d.sort(compare(leadAxle, 'up', leadAxle))
      const index = d.findIndex((item) => item.key)
      const leadPoint = [d[index - 1], d[index + 1]]
      leadPoint.forEach((point) => {
        if (!point) return
        const sx =
          (p.X * this.scalePercent) / defaultScale + this.deviation + startX
        const sy =
          (p.Y * this.scalePercent) / defaultScale +
          this.topInfoHeight +
          this.deviation +
          startY
        const ex =
          (point.X * this.scalePercent) / defaultScale + this.deviation + startX
        const ey =
          (point.Y * this.scalePercent) / defaultScale +
          this.topInfoHeight +
          this.deviation +
          startY
        const w = 10,
          h = 10
        this.ctx.save()
        this.ctx.beginPath()
        this.ctx.strokeStyle = '#FF0000'
        this.ctx.setLineDash([4, 2])
        this.ctx.moveTo(ex, ey)
        // 根据方向的不同，保持和计算各个方向可延申的线段一样都最长距离上减去0.001，吸附过去但是点位不会和吸附的点位一摸一样
        // 会放置在点位旁边
        const boundaryV =
          direction == 'right' || direction == 'down' ? -0.001 : 0.001
        if (axle === 'X') {
          this.ctx.lineTo(ex, sy)
          this.nextPoint = {
            x: ex + boundaryV,
            y: sy,
          }
        } else {
          this.ctx.lineTo(sx, ey)
          this.nextPoint = {
            x: sx,
            y: ey + boundaryV,
          }
        }

        this.ctx.strokeRect(ex - w / 2, ey - h / 2, w, h)
        this.ctx.stroke()
        this.ctx.restore()
      })
    },
    // 余料吸附
    surPlusAbsorb(e, type = 'move') {
      const startX = this.bigpart.subtleStartX
      const startY = this.bigpart.subtleStartY
      const point = {
        x: e.offsetX,
        y: e.offsetY,
      }
      const p = {
        x:
          ((point.x - this.bigpart.subtleStartX - this.deviation) /
            this.scalePercent) *
          defaultScale,
        y:
          ((point.y -
            this.bigpart.subtleStartY -
            this.deviation -
            this.topInfoHeight) /
            this.scalePercent) *
          defaultScale,
        key: true,
      }
      // 找当前点位对应的key的值
      const xKey = `X${Math.ceil(p.x / 100)}`
      const yKey = `Y${Math.ceil(p.y / 100)}`
      const xData = this.xAxleLeadData[xKey] ? this.xAxleLeadData[xKey] : []
      const yData = this.yAxleLeadData[yKey] ? this.yAxleLeadData[yKey] : []
      const directionArr = [...xData, ...yData]
      // 找当当前鼠标点位周边xy相加之和在50之内所有点位
      const result = directionArr.filter((item) => {
        if (!item) return
        const total = p.x + p.y
        const itemTotal = item.X + item.Y
        return Math.abs(total - itemTotal) <= 50
      })
      if (type !== 'move' && (!result || result.length === 0)) {
        this.drawSurplus(e)
      }
      if (!result || result.length === 0) return
      let resultPoint = result[0]
      // 在找到的点位中找到，距离鼠标位置最近的一个点位
      let num = 50
      result.forEach((item) => {
        const total = p.x + p.y
        const itemTotal = item.X + item.Y
        if (Math.abs(total - itemTotal) <= num) {
          num = Math.abs(total - itemTotal)
          resultPoint = item
        }
      })
      // 过滤后的有效点位
      let filterPoint = null
      const ex =
        (resultPoint.X * this.scalePercent) / defaultScale +
        this.deviation +
        startX
      const ey =
        (resultPoint.Y * this.scalePercent) / defaultScale +
        this.topInfoHeight +
        this.deviation +
        startY
      // 将吸附点位放置在找到的点为周边，需先判断点位放置的位置是否在板件内部
      // 在各个方向上进行点位变化，找到和板件不冲突的点位
      // 0.001太小会导致某些不应该能够吸附的点位也能吸附
      const dealPoint = [
        { offsetX: ex - 0.1, offsetY: ey - 0.1, k: '上左--' },
        { offsetX: ex + 0.1, offsetY: ey + 0.1, k: '上右++' },
        { offsetX: ex - 0.1, offsetY: ey + 0.1, k: '下左-+' },
        { offsetX: ex + 0.1, offsetY: ey - 0.1, k: '下右+-' },
      ]
      // 将点位在小板中和小板外的分开
      const passPoint = [] //保存小板内的点位
      filterPoint = dealPoint.filter((item) => {
        if (!this.setActivePlank(item)) {
          return true
        } else {
          passPoint.push(item)
        }
      })
      if (!filterPoint.length) return
      // 排除不在大板内的点位
      const inBigPlankPoint = filterPoint.filter((item) => {
        const { offsetX: x, offsetY: y } = item
        return this.isInBigPlank({ x, y })
      })
      if (!inBigPlankPoint.length) return
      this.ctx.save()
      this.ctx.strokeStyle = '#0000FF'
      this.ctx.strokeRect(ex - 5, ey - 5, 10, 10)
      this.ctx.stroke()
      this.ctx.restore()
      if (type !== 'move') {
        let resPoint = {}
        /**
         * 排除小板内大板外的点位后，判断是否能找到和小板内点位xy都不相同的在大板内的点位
         * 找到则用这个点位，没找到则用被排除的第一个点位 也就是 d数组
         */
        if (passPoint.length === 1) {
          let p = passPoint[0]
          let d = []
          resPoint = inBigPlankPoint.filter((item) => {
            if (item.offsetX !== p.offsetX && item.offsetY !== p.offsetY) {
              return true
            } else {
              d.push(item)
            }
          })
          resPoint = resPoint.length ? resPoint[0] : d[0]
        } else {
          resPoint = filterPoint[0]
        }
        const ePoint = {
          offsetX: resPoint.offsetX,
          offsetY: resPoint.offsetY,
        }
        this.drawSurplus(ePoint)
      }
    },
    /**
     * sym true +号  false -号
     */
    dealLineDirection(point, recordPoint) {
      let direction = ''
      let axle = ''
      let sym = ''
      if (point.x === recordPoint.x) {
        if (point.y < recordPoint.y) {
          direction = 'up'
          sym = false
        } else {
          direction = 'down'
          sym = true
        }
        axle = 'y'
      } else {
        if (point.x < recordPoint.x) {
          direction = 'left'
          sym = false
        } else {
          direction = 'right'
          sym = true
        }
        axle = 'x'
      }
      return { direction, axle, sym }
    },
    // 输入生成引线
    dealCurrentLineLength() {
      if (
        this.lineRecode.current < 1 ||
        this.lineRecode.current > this.lineRecode.maxLine
      ) {
        this.$message.warning('输入数值有误,请重新输入!')
        return
      }
      let diameter = +getPlateKnifeDiameter(
        this.bigpart.stockKey,
        this.ncSetting
      )
      if (this.ncSetting.glass_setting) {
        diameter = 0
      }
      const long =
        ((+this.lineRecode.current + diameter + this.glassDilate) *
          this.scalePercent) /
        defaultScale
      this.lineRecode.recordPoint[this.lineRecode.axle] = this.lineRecode.sym
        ? this.lineRecode.recordPoint[this.lineRecode.axle] + long
        : this.lineRecode.recordPoint[this.lineRecode.axle] - long
      let point = {
        x: this.lineRecode.recordPoint.x,
        y: this.lineRecode.recordPoint.y,
      }
      if (this.lineRecode.current == this.lineRecode.maxLine) {
        point[this.lineRecode.axle] -= 0.001
      }
      this.nextPoint = point
      this.drawSurplus(this.lineRecode.e)
    },
    // 鼠标拖动生成模板
    dealSurPlusCut(surPlus, e) {
      const startX = this.bigpart.subtleStartX
      const startY = this.bigpart.subtleStartY
      surPlus.width = +surPlus.width
      surPlus.length = +surPlus.length
      const d = {
        x: surPlus.width / 2,
        y: surPlus.length / 2,
      }
      const point = this.windowToCanvas(e.offsetX, e.offsetY)
      if (!point) return
      const p = {
        x:
          ((point.x - this.bigpart.subtleStartX - this.deviation) /
            this.scalePercent) *
          defaultScale,
        y:
          ((point.y -
            this.bigpart.subtleStartY -
            this.deviation -
            this.topInfoHeight) /
            this.scalePercent) *
          defaultScale,
      }
      const plank = {
        polyArr: [
          [
            { X: p.x - d.x, Y: p.y - d.y },
            { X: surPlus.width + p.x - d.x, Y: p.y - d.y },
            { X: surPlus.width + p.x - d.x, Y: surPlus.length + p.y - d.y },
            { X: p.x - d.x, Y: surPlus.length + p.y - d.y },
          ],
        ],
        startX: p.x - d.x,
        startY: p.y - d.y,
        rect: {
          x: 0,
          y: 0,
          width: surPlus.width,
          height: surPlus.length,
        },
      }
      const isFlag = checkOnePlank(plank, this.bigpart)
      if (!isFlag) {
        return this.$message.error(translate('arrangedPage.tempErr'))
      }
      const next = plank.polyArr[0].shift()
      this.surplusPoint = plank.polyArr[0].map((item) => ({
        x:
          (item.X * this.scalePercent) / defaultScale + this.deviation + startX,
        y:
          (item.Y * this.scalePercent) / defaultScale +
          this.topInfoHeight +
          this.deviation +
          startY,
      }))
      this.nextPoint = {
        x:
          (next.X * this.scalePercent) / defaultScale + this.deviation + startX,
        y:
          (next.Y * this.scalePercent) / defaultScale +
          this.topInfoHeight +
          this.deviation +
          startY,
      }
      this.drawSurplus(point)
    },
    calcNextPoint(e) {
      let point1 = this.surplusPoint[this.surplusPoint.length - 2]
      let point2 = this.surplusPoint[this.surplusPoint.length - 1]
      // 判断下一条线段的方向, true为竖线, false为横线
      // 如果最后两个点的y轴相等, 说明最后一条绘制的线段为横线, 则下一条只能为竖线
      if (point1.y == point2.y) {
        this.nextDirection = true
      }
      if (point1.x == point2.x) {
        this.nextDirection = false
      }
      let point = {}
      if (this.nextDirection) {
        point = {
          x: point2.x,
          y: e.offsetY,
        }
      } else {
        point = {
          x: e.offsetX,
          y: point2.y,
        }
      }
      this.drawDashLine(point2, point, e)
      this.nextPoint = point
    },
    // 鼠标移动事件
    movePlank(e) {
      // 锁的Hover事件
      const mousePoint = {
        x: e.offsetX,
        y: e.offsetY,
      }
      const { lockPath } = this.setLockInfo()
      const result = this.jundgeLocation(mousePoint, lockPath)
      if (result && (this.bigpart.isLocked || this.bigpart.isActive)) {
        this.lockHoverInfo.isShow = true
        this.lockHoverInfo.top = e.pageY + 24 * this.scalePercent
        this.lockHoverInfo.left = e.pageX - 94
        this.lockHoverInfo.isLocked = this.bigpart.isLocked
      } else {
        this.lockHoverInfo.isShow = false
      }
      if (this.isCuttingSurplus) {
        if (this.isAdsorb) {
          this.renderAll()
          this.surPlusAbsorb(e)
        }
        if (this.surplusPoint.length > 0) {
          this.renderAll()
          this.ctx.beginPath()
          this.ctx.setLineDash([])
          this.ctx.strokeStyle = '#000'
          for (let i = 0; i < this.surplusPoint.length; ++i) {
            if (i == 0) {
              this.ctx.moveTo(this.surplusPoint[i].x, this.surplusPoint[i].y)
            } else {
              this.ctx.lineTo(this.surplusPoint[i].x, this.surplusPoint[i].y)
            }
          }
          this.ctx.stroke()
          this.ctx.closePath()

          // 如果只有一个点, 则可以任意选择是横向还是竖向
          if (this.surplusPoint.length == 1) {
            let point = {
              x: e.offsetX,
              y: e.offsetY,
            }
            let recordPoint = this.surplusPoint[0]
            let pointXOff = Math.abs(recordPoint.x - e.offsetX)
            let pointYOff = Math.abs(recordPoint.y - e.offsetY)
            if (pointXOff <= pointYOff) {
              point.x = recordPoint.x
              this.nextDirection = true
            } else {
              point.y = recordPoint.y
              this.nextDirection = false
            }
            this.nextPoint = point
            this.drawDashLine(recordPoint, point, e)
          } else {
            this.calcNextPoint(e)
          }
        }
        return
      }
      if (this.isMovingPlank) {
        if (this.activePlank) {
          this.dragPosition = {
            x: e.offsetX,
            y: e.offsetY,
          }
          this.renderAll()
        }
      }
      if (this.isMovingCavnas) {
        this.canvasOffset = {
          x: e.offsetX - this.canvasRightDrag.x,
          y: e.offsetY - this.canvasRightDrag.y,
        }
        this.renderAll()
      }
      if (!this.activePlank) {
        if (_debounceTime) {
          clearTimeout(_debounceTime)
        }
        _debounceTime = setTimeout(() => {
          const activePart = this.plankControl.gitDetailActivePart(
            e,
            this.bigpart,
            {
              deviation: this.deviation,
              topInfoHeight: this.topInfoHeight,
              scalePercent: this.scalePercent,
              defaultScale,
            }
          )
          this.conflictPart =
            partIsConflict(activePart) && !this.activePlank ? activePart : null
        }, 200)
      }

      if (this.activePlank && !this.isMovingPlank) {
        const point = { x: e.offsetX, y: e.offsetY }
        if (_debounceTime) {
          clearTimeout(_debounceTime)
        }
        _debounceTime = setTimeout(() => {
          // 绘制孔槽等的详细信息
          // 我知道你会疑惑上面不是判断了this.activePlank有值才会进入，为啥这里又要判断，这一步是因为这里是宏任务，这里的this.activePlank可能存在null的情况
          if (this.activePlank) {
            this.hsDetailInfoList = drawActivePlankOtherInfo(
              this.bigpart,
              point,
              this.activePlank,
              {
                deviation: this.deviation,
                padding: this.topInfoHeight,
                scalePercent: this.scalePercent,
                defaultScale: defaultScale,
              },
              'jingxi'
            )
          }
          if (this.isRenderHSDetail) {
            this.renderAll()
            this.isRenderHSDetail = false
          }
          if (this.hsDetailInfoList.length) {
            this.mousePoint = point
            this.renderAll()
            this.isRenderHSDetail = true
          }
        }, 50)
      } else {
        this.hsDetailInfoList = []
      }
    },
    // 滚轮缩放
    wheelScale(e) {
      if (e.ctrlKey) {
        this.emptyDistance = false
        this.scaleCanvas(e)
      }
    },
    /**
     * 滚轮缩放事件
     * @param { Object } event 事件对象
     */
    scaleCanvas(event, toolScale, isReset = false) {
      // 判断滚轮方向
      let direction = true
      if (isReset) {
        this.scale = this.oriScale = 5
        this.zoomNum = 1
      } else {
        if (event.wheelDelta > 0) {
          if (toolScale) {
            this.scale += this.oriScale * toolScale
          } else {
            this.scale += this.oriScale * 0.1
          }
          this.zoomNum += 0.1
        } else {
          if (this.scale - this.oriScale * 0.5 < 0.0001) return

          if (toolScale) {
            this.scale += this.oriScale * toolScale
          } else {
            this.scale -= this.oriScale * 0.1
          }
          this.zoomNum -= 0.1

          direction = false
        }
      }

      this.drawData.forEach((draw) => {
        draw.data.forEach((plank) => {
          if (plank.stockKey === this.bigpart.stockKey) {
            plank.zoomNum = this.zoomNum
          }
        })
      })
      this.$emit('wheelScale', this.zoomNum)
      this.scaleComputed()
    },
    scaleComputed() {
      // 记录上一次缩放的比例
      let lastScale = this.scalePercent
      // 计算缩放的比例
      this.scalePercent = this.scale / defaultScale
      // 计算各种间距和大板宽度缩放后的值
      this.topInfoHeight = defaultTopInfoHeight * this.scalePercent
      this.deviation = defaultDeviation * this.scalePercent
      this.dotWidth = defaultDotWidth * this.scalePercent
      this.textWidth = defaultTextWidth * this.scalePercent
      this.bigpart.subtleWidth =
        (this.bigpart.subtleWidth / lastScale) * this.scalePercent
      this.bigpart.subtleHeight =
        (this.bigpart.subtleHeight / lastScale) * this.scalePercent
      this.renderAll()
    },

    /**
     *
     * @param {String} info 提示信息
     */
    messageDebunce(info) {
      clearTimeout(this.messageTimer)
      if (this.isMessage) {
        this.$message({
          message: info,
          type: 'info',
          duration: 2000,
        })
        this.isMessage = false
        this.messageTimer = setTimeout(() => {
          this.isMessage = true
        }, 500)
      }
    },
    /*坐标转换*/
    windowToCanvas(x, y) {
      let canvasDom = this.$refs.subtleCanvas
      if (!canvasDom) return null
      let box = canvasDom.getBoundingClientRect() //这个方法返回一个矩形对象，包含四个属性：left、top、right和bottom。分别表示元素各边与页面上边和左边的距离
      return {
        x: x - box.left - (box.width - canvasDom.width) / 2,
        y: y - box.top - (box.height - canvasDom.height) / 2,
      }
    },

    // 判断哪些是需要进行距离计算的选段
    judgeDirectionLine(
      activeArr,
      partArr,
      activePath,
      activePlank,
      part,
      direction
    ) {
      // 记录板件间隙
      let distanceArr = []
      let gap = this.ncSetting.panelSize.layoutGap
      let { activeTop, activeBottom, activeLeft, activeRight } = activeArr
      let { partTop, partBottom, partLeft, partRight } = partArr
      let maxNum = 0
      let minNum = 0
      if (direction == 'up' || direction == 'down') {
        maxNum = Math.max(partLeft, activeLeft)
        minNum = Math.min(partRight, activeRight)
      } else {
        maxNum = Math.max(partTop, activeTop)
        minNum = Math.min(partBottom, activeBottom)
      }
      // 判断交集的方法, 取两个区间(a, b)(c, d), 如果a和c的最大值小于b和d的最小值, 说明存在交集
      // 注意精度问题
      // 当最大的坐标小于最小的坐标且不相等时, 才会进入判断
      if (maxNum < minNum && Math.abs(maxNum - minNum) > 0.0001) {
        // 如果记录板件与选中板件都为矩形, 则直接求两个板件之间的距离
        if (!activePlank.path && !part.path) {
          if (direction == 'up') {
            // 如果比较板件的顶部小于选中板件的底部, 则在按上的时候不需要继续计算距离
            if (activeBottom < partTop) return { msg: 'continue' }
            // 如果是已经贴合了, 则直接记录最小的板件间隙的值, 当前不可移动, 直接跳出循环
            if (Math.abs(activeTop - partBottom + gap) < 0.001) {
              distanceArr.push(gap)
              return {
                msg: 'break',
                data: distanceArr,
              }
            }
            let distance = activeTop - partBottom
            distanceArr.push(distance)
            return {
              msg: 'continue',
              data: distanceArr,
            }
          }
          if (direction == 'down') {
            // 如果比较板件的底部小于选中板件的顶部, 则在按下的时候不需要继续计算距离
            if (partBottom < activeTop) return 'continue'
            // 如果是已经贴合了, 则直接记录最小的板件间隙的值, 当前不可移动, 直接跳出循环
            if (Math.abs(partTop - activeBottom + gap) < 0.001) {
              distanceArr.push(gap)
              return {
                msg: 'break',
                data: distanceArr,
              }
            }
            let distance = partTop - activeBottom
            distanceArr.push(distance)
            return {
              msg: 'continue',
              data: distanceArr,
            }
          }
          if (direction == 'left') {
            // 如果比较板件的
            if (activeLeft < partRight) return { msg: 'continue' }
            if (Math.abs(activeLeft - partRight + gap) < 0.001) {
              distanceArr.push(gap)
              return {
                msg: 'break',
                data: distanceArr,
              }
            }
            let distance = activeLeft - partRight
            distanceArr.push(distance)
            return {
              msg: 'continue',
              data: distanceArr,
            }
          }
          if (direction == 'right') {
            if (activeRight > partLeft) return { msg: 'continue' }
            if (Math.abs(partLeft - activeRight + gap) < 0.001) {
              distanceArr.push(gap)
              return {
                msg: 'break',
                data: distanceArr,
              }
            }
            let distance = partLeft - activeRight
            distanceArr.push(distance)
            return {
              msg: 'continue',
              data: distanceArr,
            }
          }
        }
        // 记录交集
        let range = {}
        if (direction == 'up' || direction == 'down') {
          range.x1 = maxNum
          range.x2 = minNum
        } else {
          range.y1 = maxNum
          range.y2 = minNum
        }

        // 记录比较板件的边框点位
        let partPath = []
        if (part.path) {
          // 先判断小板是否处于比较板件的内部
          if (part.path.length > 1) {
            // 如果是在内部, 则判断是否存在挖洞, 则循环判断每一个选中的板件是否在某一个挖洞内部
            let flag = polyContain([part.polyArr[0]], [activePlank.polyArr[0]])
            if (flag) {
              for (let i = 0; i < part.path.length; ++i) {
                if (i == 0) continue
                let newFlag = polyContain(
                  [part.polyArr[i]],
                  [activePlank.polyArr[0]]
                )
                if (newFlag) {
                  partPath = part.path[i]
                  break
                }
              }
            } else {
              partPath = part.path[0]
            }
          } else {
            partPath = part.path[0]
          }
        } else {
          partPath = [
            { x: 0, y: 0 },
            { x: part.rect.width, y: 0 },
            { x: part.rect.width, y: part.rect.height },
            { x: 0, y: part.rect.height },
          ]
        }

        // 记录比较板件的所有在交集范围内的横线线段
        let partLineArr = this.getPlankLine(
          partPath,
          part.startX,
          part.startY,
          direction,
          range
        )
        // 根据方向获取选中板件的线段
        // 如果选中的板件是有挖洞的, 则需要重新计算选中板件的点位, 判断和其他板件是否有包含关系
        if (activePlank.path && activePlank.path.length > 1) {
          // 如果是在内部, 则判断是否存在挖洞, 则循环判断每一个选中的板件是否在某一个挖洞内部
          let flag = polyContain([activePlank.polyArr[0]], [part.polyArr[0]])
          if (flag) {
            for (let i = 0; i < activePlank.path.length; ++i) {
              if (i == 0) continue
              let newFlag = polyContain(
                [activePlank.polyArr[i]],
                [part.polyArr[0]]
              )
              if (newFlag) {
                activePath = activePlank.path[i]
                break
              }
            }
          }
        }
        this.isActivePlankLine = true
        let activeLineArr = this.getPlankLine(
          activePath,
          activePlank.startX,
          activePlank.startY,
          direction,
          range
        )
        this.isActivePlankLine = false
        distanceArr = this.calcDistance(
          activeLineArr,
          partLineArr,
          direction,
          true
        )
        return {
          msg: 'finish',
          data: distanceArr,
          activeLineArr: activeLineArr,
        }
      }
      return { msg: 'continue' }
    },

    // 键盘按下事件
    keyDownFuncs() {
      return (e) => {
        if (e.ctrlKey) {
          // e.preventDefault()
          this.isScaling = true
        }
        if (
          (e.code == 'ArrowUp' ||
            e.code == 'ArrowDown' ||
            e.code == 'ArrowLeft' ||
            e.code == 'ArrowRight') &&
          this.isMovingPlank
        ) {
          e.preventDefault()
          return
        }
        if (
          this.activePlank &&
          (e.code == 'ArrowUp' ||
            e.code == 'ArrowDown' ||
            e.code == 'ArrowLeft' ||
            e.code == 'ArrowRight')
        ) {
          e.preventDefault()
          if (this.ncSetting.glass_setting) {
            return this.$message.error(
              translate('arrangedPage.glassEquipmentTip')
            )
          }
          if (this.bigpart.isLocked) {
            return this.$message.error(
              translate('arrangedPage.lockPartEditTip')
            )
          }
          let direction = ''
          switch (e.code) {
            case 'ArrowUp':
              direction = 'up'
              break
            case 'ArrowDown':
              direction = 'down'
              break
            case 'ArrowLeft':
              direction = 'left'
              break
            case 'ArrowRight':
              direction = 'right'
              break
          }
          // 记录所有距离
          let distanceArr = []
          // 记录移动前的位置
          const { startX, startY } = this.activePlank
          let bigpart = this.bigpart

          // 计算当前选中板件上下左右四个方向的坐标
          let activeArr = {
            activeLeft: this.activePlank.startX,
            activeRight: this.activePlank.startX + this.activePlank.rect.width,
            activeTop: this.activePlank.startY,
            activeBottom:
              this.activePlank.startY + this.activePlank.rect.height,
          }

          // 获取选中板件的边框点位
          let activePath = []
          if (this.activePlank.path) {
            activePath = this.activePlank.path[0]
          } else {
            activePath = [
              { x: 0, y: 0 },
              { x: this.activePlank.rect.width, y: 0 },
              {
                x: this.activePlank.rect.width,
                y: this.activePlank.rect.height,
              },
              { x: 0, y: this.activePlank.rect.height },
            ]
          }

          // 循环判断和当前大板中其余板件的距离
          for (let i = 0; i < bigpart.parts.length; ++i) {
            // 不会和自己进行比较
            if (bigpart.parts[i].partUniqueId == this.activePlank.partUniqueId)
              continue
            let part = bigpart.parts[i]
            // 计算出当前做比较的板件的上下左右四个方向的坐标
            let partArr = {
              partLeft: part.startX,
              partRight: part.startX + part.rect.width,
              partTop: part.startY,
              partBottom: part.startY + part.rect.height,
            }
            let result = this.judgeDirectionLine(
              activeArr,
              partArr,
              activePath,
              this.activePlank,
              part,
              direction
            )
            if (result.data) {
              distanceArr = [...distanceArr, ...result.data]
            }
            if (result.msg == 'continue') continue
            if (result.msg == 'break') break
          }

          let distanceArr2 = []
          let bigPartLineArr = this.dealBigPlankLine(bigpart.bigPlank[0])
          this.isActivePlankLine = true
          let activeLineArr = this.getPlankLine(
            activePath,
            this.activePlank.startX,
            this.activePlank.startY,
            direction
          )
          this.isActivePlankLine = false
          distanceArr2 = this.calcBigPlankDistance(
            activeLineArr,
            bigPartLineArr,
            direction
          )

          // 取数组中的最小值
          let distance = 99999
          let distance2 = Math.min(...distanceArr2)
          if (compareTowNum(distance2, 20, '>=')) distance2 = 20

          if (distanceArr.length > 0) {
            let plankDistance = Math.min(...distanceArr)
            if (compareTowNum(plankDistance, 0, '>=')) {
              distance = plankDistance
              // 如果是和其他板件进行比较, 则需要考虑板件间隙
              let gap = this.ncSetting.panelSize.layoutGap
              gap = gap == 0 ? 0.01 : gap
              // 如果距离超过了板件间隙, 则判断减去板件间隙之后, 还可以移动多少
              if (compareTowNum(distance, gap, '>=')) {
                distance = distance - gap
                if (distance < 0) distance = 0
                if (distance > 0) {
                  if (compareTowNum(distance, 20, '>=')) {
                    distance = 20
                  }
                }
              } else {
                distance = 0
              }
            }
          }

          distance = compareTowNum(distance, distance2, '<=')
            ? distance
            : distance2

          if (direction == 'up') {
            this.activePlank.startY -= distance
          }
          if (direction == 'down') {
            this.activePlank.startY += distance
          }
          if (direction == 'left') {
            this.activePlank.startX -= distance
          }
          if (direction == 'right') {
            this.activePlank.startX += distance
          }
          dealPlankPoly(this.activePlank)
          // 每一次移动都重新判断一次板件冲突情况
          const isConflict = checkOneBigpart(bigpart)
          // 检查是否出现冲突
          if (isConflict) {
            this.activePlank.startX = startX
            this.activePlank.startY = startY
            // 清除所有冲突状态
            bigpart.parts.forEach((part) => {
              part.plankMerge = false
            })
            dealPlankPoly(this.activePlank)
            checkOneBigpart(bigpart)
          }
          this.renderAll()
        }
        if (
          this.activePlank &&
          this.activePlank.specialType &&
          e.code == 'Delete'
        ) {
          if (this.bigpart.isLocked) {
            return this.$message.error(translate('arrangedPage.lockDeleteErr'))
          }
          e.preventDefault()
          let isSavedSurplus =
            this.activePlank.specialType && this.activePlank.hasSaved
          if (isSavedSurplus || this.activePlank.specialType === 'supplus') {
            const h = this.$createElement
            let dom = h('div', {}, [
              // 进入待排版库的提示
              h('span', {}, translate('common.confirmDeleteTip')),
            ])
            this.$antdConfirm({
              title: translate('arrangedPage.deleteConfirm'),
              content: dom,
              okText: translate('common.confirm'),
              cancelText: translate('common.cancel'),
              centered: true,
              onOk: () => {
                let arr = []
                for (let i = 0; i < this.drawData.length; ++i) {
                  arr = [...arr, ...this.drawData[i].data]
                }
                this.setPaibanData(arr)
                this.setFinalDrawData(this.drawData)
                this.$emit('updatePaibanData')
                this.confirmDeleteSurplusPlank()
              },
              onCancel: () => {},
            })
          } else {
            this.confirmDeleteSurplusPlank()
          }
        } else if (this.activePlank && e.code == 'Delete') {
          if (this.bigpart.isLocked) {
            return this.$message.error(translate('arrangedPage.lockDeleteErr'))
          }
          this.isShowDeleteDialog = true
        } else if (!this.activePlank && e.code === 'Delete') {
          this.$message({
            message: translate('arrangedPage.selectPlankTip'),
            type: 'info',
          })
        }
      }
    },
    // 删除精细排版item
    handleConfirmDelete() {
      this.isShowDeleteDialog = false
      let oriBigpart
      this.drawData.forEach((draw, index) => {
        draw.data.forEach((bigPlank) => {
          if (bigPlank.stockKey === this.bigpart.stockKey) {
            oriBigpart = JSON.parse(JSON.stringify(bigPlank))
            // 删除小板
            this.handleAddPlankToAwaitStore(this.activePlank, bigPlank)
          }
          this.$emit('judgeAbleFanban', bigPlank, index)
          // 重新计算优化率
          calcBigpartArea(bigPlank, this.ncSetting)
        })
      })
      // 触发父组件的判断方法
      this.renderAll()
      this.$emit('recordDeletePlank', {
        key: '精细排版删除小板',
        dataArr: [
          {
            type: 'form',
            current: {
              partsNum: this.bigpart.parts.length,
            }, // 当前表单数据
            ori: {
              partsNum: oriBigpart.parts.length,
            }, // 原始表单数据
            compareMsg: [
              {
                title: `${this.activePlank.partName}-大板序号: ${this.bigpart.canvasIndex}`,
                keys: ['partsNum'],
              },
            ], // 要比较的内容
            formTextObj: {
              partsNum: '小板数量',
            }, // key对应的意思
            formValueObj: {}, // value对应的意思
          },
        ],
      })
      // 如果删除板件是待排版服务器上的数据则需要将板件再显示出来
      this.$store.commit(
        'awaitPaibanStore/showPlank',
        this.activePlank.partUniqueId
      )
    },
    confirmDeleteSurplusPlank() {
      this.handleAddPlankToAwaitStore(this.activePlank, this.bigpart)
      this.activePlank = null
      this.ableRewriteRemarks = false
      if (this.bigpart.parts.length > 0) {
        this.calcCutPositionOrder(this.bigpart)
      }
      calcBigpartArea(this.bigpart, this.ncSetting)
      this.renderAll()
    },
    dealBigPlankLine(path) {
      let arr = []
      for (let i = 0; i < path.length; ++i) {
        let point = path[i]
        let nextPoint = i == path.length - 1 ? path[0] : path[i + 1]
        if (point.X == nextPoint.X && point.Y == nextPoint.Y) continue
        let pathLine = {
          x1: point.X,
          y1: point.Y,
          x2: nextPoint.X,
          y2: nextPoint.Y,
        }
        arr.push(pathLine)
      }
      return arr
    },
    // 计算板件和大板的距离
    calcBigPlankDistance(activeLineArr, bigPartLineArr, direction) {
      let distanceArr = []
      for (let i = 0; i < activeLineArr.length; ++i) {
        let line1 = activeLineArr[i]
        for (let k = 0; k < bigPartLineArr.length; ++k) {
          let line2 = bigPartLineArr[k]
          if (this.checkRangeIntersect(line1, line2, direction)) {
            if (direction == 'up') {
              if (compareTowNum(line1.y1, line1.y2, '>=')) {
                let distance = line1.y2 - line2.y1
                if (compareTowNum(distance, 0, '>=')) {
                  distanceArr.push(distance)
                }
              } else {
                let distance = line1.y1 - line2.y1
                if (compareTowNum(distance, 0, '>=')) {
                  distanceArr.push(distance)
                }
              }
            }
            if (direction == 'down') {
              if (compareTowNum(line1.y1, line1.y2, '>=')) {
                let distance = line1.y1 - line2.y1
                if (compareTowNum(distance, 0, '<=')) {
                  distanceArr.push(-distance)
                }
              } else {
                let distance = line1.y2 - line2.y1
                if (compareTowNum(distance, 0, '<=')) {
                  distanceArr.push(-distance)
                }
              }
            }
            if (direction == 'left') {
              if (compareTowNum(line1.x1, line1.x2, '>=')) {
                let distance = line1.x2 - line2.x1
                if (compareTowNum(distance, 0, '>=')) {
                  distanceArr.push(distance)
                }
              } else {
                let distance = line1.x1 - line2.x1
                if (compareTowNum(distance, 0, '>=')) {
                  distanceArr.push(distance)
                }
              }
            }
            if (direction == 'right') {
              if (compareTowNum(line1.x1, line1.x2, '>=')) {
                let distance = line1.x1 - line2.x1
                if (compareTowNum(distance, 0, '<=')) {
                  distanceArr.push(-distance)
                }
              } else {
                let distance = line1.x2 - line2.x1
                if (compareTowNum(distance, 0, '<=')) {
                  distanceArr.push(-distance)
                }
              }
            }
          }
        }
      }
      return distanceArr
    },
    // 获取大板边框
    getBigPlankPath(bigpart) {
      const diameter = +getPlateKnifeDiameter(bigpart.stockKey, this.ncSetting)
      // 计算大板修边
      let plankOffset = Math.abs(
        diameter / 2 - this.ncSetting.panelSize.plankEdgeOff
      )
      let bigPartsPath = []
      let plankWidth = 0
      let plankHeight = 0
      if (bigpart.surplusInfo && Object.keys(bigpart.surplusInfo).length > 0) {
        let surplusInfo = bigpart.surplusInfo
        // 如果为L形余料
        if (surplusInfo.shape && surplusInfo.shape == 'lshape') {
          let x3 = this.ncSetting.xyReverse
            ? Number(surplusInfo.y5)
            : Number(surplusInfo.x3)
          let y3 = this.ncSetting.xyReverse
            ? Number(surplusInfo.x5)
            : Number(surplusInfo.y3)
          let x4 = this.ncSetting.xyReverse
            ? Number(surplusInfo.y4)
            : Number(surplusInfo.x4)
          let y4 = this.ncSetting.xyReverse
            ? Number(surplusInfo.x4)
            : Number(surplusInfo.y4)
          let x5 = this.ncSetting.xyReverse
            ? Number(surplusInfo.y3)
            : Number(surplusInfo.x5)
          let y5 = this.ncSetting.xyReverse
            ? Number(surplusInfo.x3)
            : Number(surplusInfo.y5)
          let newWidth = surplusInfo.width
          let newHeight = surplusInfo.height
          plankWidth = newWidth
          plankHeight = newHeight
          if (
            this.ncSetting.xyReverse &&
            (this.ncSetting.startPosition == '右下角' ||
              this.ncSetting.startPosition == '左上角')
          ) {
            x3 = newWidth - x3
            x4 = newWidth - x4
            x5 = newWidth - x5

            bigPartsPath = [
              { x: -plankOffset, y: -plankOffset },
              { x: newWidth + plankOffset, y: -plankOffset },
              { x: newWidth + plankOffset, y: newHeight + plankOffset },
              { x: x3 - plankOffset, y: y3 + plankOffset },
              { x: x4 - plankOffset, y: y4 + plankOffset },
              { x: x5 - plankOffset, y: y5 + plankOffset },
            ]
          } else {
            bigPartsPath = [
              { x: -plankOffset, y: -plankOffset },
              { x: x3 + plankOffset, y: y3 - plankOffset },
              { x: x4 + plankOffset, y: y4 - plankOffset },
              { x: x5 + plankOffset, y: y5 + plankOffset },
              { x: newWidth + plankOffset, y: newHeight + plankOffset },
              { x: -plankOffset, y: newHeight + plankOffset },
            ]
          }
        } else {
          bigPartsPath = [
            { x: -plankOffset, y: -plankOffset },
            { x: Number(surplusInfo.width) + plankOffset, y: -plankOffset },
            {
              x: Number(surplusInfo.width) + plankOffset,
              y: Number(surplusInfo.height) + plankOffset,
            },
            { x: -plankOffset, y: Number(surplusInfo.height) + plankOffset },
          ]
          plankWidth = surplusInfo.width
          plankHeight = surplusInfo.height
        }
      } else {
        bigPartsPath = [
          { x: -plankOffset, y: -plankOffset },
          { x: this.plankWidth + plankOffset, y: -plankOffset },
          {
            x: this.plankWidth + plankOffset,
            y: this.plankHeight + plankOffset,
          },
          { x: -plankOffset, y: this.plankHeight + plankOffset },
        ]
        plankWidth = this.plankWidth
        plankHeight = this.plankHeight
      }
      return {
        bigPartsPath,
        bigPartArr: {
          bigpartTop: -plankOffset,
          bigpartLeft: -plankOffset,
          bigpartRight: plankOffset + plankWidth,
          bigpartBottom: plankOffset + plankHeight,
        },
      }
    },
    // 获取一个板件的所有线段
    getPlankLine(path, startX = 0, startY = 0, direction, range) {
      // 将点位处理成顺时针点位, 然后求相邻两点的垂直向量
      // 判断点位是否是顺时针点位, 通过clipper判断面积, 面积为正数则为逆时针点位, 但是由于canvas原点在左上角, 所以要反过来判断顺逆时针
      let area = ClipperLib.JS.AreaOfPolygon(path)
      if (area < 0) {
        path.reverse()
      }
      // 求垂直的单位向量
      let lineArr = []
      let pathLen = path.length
      for (let k = 0; k < pathLen; ++k) {
        let pathLine = {}
        let point = path[k]
        let nextPoint = k == pathLen - 1 ? path[0] : path[k + 1]
        if (point.x == nextPoint.x && point.y == nextPoint.y) continue
        let a = {
          x: point.x + startX,
          y: point.y + startY,
        }
        let b = {
          x: nextPoint.x + startX,
          y: nextPoint.y + startY,
        }
        // 计算当前线段向量的垂直单位向量
        let c = {
          x: b.x - a.x,
          y: b.y - a.y,
        }
        // 计算方式, 已知向量(x, y) 设要求的单位垂直向量为(a, b)单位向量的摸长为1, 也就是a² + b² = 1, 然后和已知向量垂直就有x * a + y * b = 0, 解二元方程
        // 则a = Math.sqrt( Math.pow(y, 2) / ( Math.pow(x, 2) + Math.pow(y, 2) ) )
        // b = Math.sqrt( 1 - Math.pow(a, 2) )
        let aa = Math.sqrt(
          Math.pow(c.y, 2) / (Math.pow(c.x, 2) + Math.pow(c.y, 2))
        )
        let bb = Math.sqrt(1 - Math.pow(aa, 2))
        if (c.x > 0 && c.y < 0) {
          aa = -aa
          bb = -bb
        }
        if (c.x < 0 && c.y < 0) {
          aa = -aa
        }
        if (c.x > 0 && c.y > 0) {
          bb = -bb
        }
        if (c.x > 0 && c.y == 0) {
          bb = -bb
        }
        if (c.x == 0 && c.y < 0) {
          aa = -aa
        }
        let cd = {}
        if (direction == 'up') {
          cd = { x: 0, y: -1 }
        }
        if (direction == 'down') {
          cd = { x: 0, y: 1 }
        }
        if (direction == 'left') {
          cd = { x: -1, y: 0 }
        }
        if (direction == 'right') {
          cd = { x: 1, y: 0 }
        }
        let cos =
          (aa * cd.x + bb * cd.y) /
          (Math.sqrt(aa * aa + bb * bb) * Math.sqrt(cd.x * cd.x + cd.y * cd.y))
        let angle = (180 * Math.acos(cos)) / Math.PI
        if (
          (compareTowNum(angle, 0, '>=') && compareTowNum(angle, 90, '<=')) ||
          !this.isActivePlankLine
        ) {
          pathLine = {
            x1: a.x,
            y1: a.y,
            x2: b.x,
            y2: b.y,
          }
          // 将pathLine的值按照区间从小到大正常排列之后再进行判断
          if (direction == 'up' || direction == 'down') {
            if (range) {
              if (this.checkRangeIntersect(pathLine, range, direction)) {
                if (pathLine.x1 != pathLine.x2) lineArr.push(pathLine)
              }
            } else {
              if (pathLine.x1 != pathLine.x2) lineArr.push(pathLine)
            }
          }
          if (direction == 'left' || direction == 'right') {
            if (range) {
              if (this.checkRangeIntersect(pathLine, range, direction)) {
                if (pathLine.y1 != pathLine.y2) lineArr.push(pathLine)
              }
            } else {
              if (pathLine.y1 != pathLine.y2) lineArr.push(pathLine)
            }
          }
        }
      }
      return lineArr
    },
    // 计算两条线段的距离
    calcDistance(activeLineArr, partLineArr, direction) {
      let distanceArr = []
      for (let i = 0; i < activeLineArr.length; ++i) {
        let line1 = activeLineArr[i]

        for (let k = 0; k < partLineArr.length; ++k) {
          let line2 = partLineArr[k]
          if (direction == 'up' || direction == 'down') {
            // 先判断点是否在另外一条线段上的区间内, 如果再, 才去求距离
            let a,
              b,
              c,
              d = {}
            let startY = 0
            // 判断line1和line2是否平行
            let isPingxing =
              (line1.x2 - line1.x1) * (line2.y2 - line2.y1) -
              (line2.x2 - line2.x1) * (line1.y2 - line1.y1)
            // 如果两条线段平行, 则直接求任意一点到另一条线段的距离
            if (isPingxing == 0) {
              // 计算直线斜率, 由于平行, 计算任意一条线段的斜率即可, 以line1为准
              let k = (line1.y2 - line1.y1) / (line1.x2 - line1.x1)
              // line1对应的直线则为 y - line1.y1 = k * (x - line1.x1)
              // line2对应的直线则为 y - line2.y1 = k * (x - line2.x1)
              // 化为一般式则是 -kx + y - line1.y1 + k * line1.x1 = 0和-kx + y - line2.y1 + k * line2.x1 = 0
              // 平行线之间的距离则为
              let distance =
                Math.abs(
                  -line1.y1 + k * line1.x1 - (-line2.y1 + k * line2.x1)
                ) / Math.sqrt(Math.pow(k, 2) + 1)
              distanceArr.push(distance)
              continue
            }
            // point.x1在另一条point2内部
            let isInRange = false
            // 将两条线段的横坐标从小到大排列
            let newLine1 = JSON.parse(JSON.stringify(line1))
            if (line1.x1 > line1.x2) {
              newLine1.y1 = line1.y2
              newLine1.y2 = line1.y1
              newLine1.x1 = line1.x2
              newLine1.x2 = line1.x1
            }
            let newLine2 = JSON.parse(JSON.stringify(line2))
            if (line2.x1 > line2.x2) {
              newLine2.y1 = line2.y2
              newLine2.y2 = line2.y1
              newLine2.x1 = line2.x2
              newLine2.x2 = line2.x1
            }
            if (newLine1.x1 > newLine2.x1 && newLine1.x1 < newLine2.x2) {
              // 由该点延伸出一条直线, 求与另一条线段的交点, 然后纵坐标相减求出距离
              // 该点为(x1, y1), 上下移动时延伸出向量, 也就是(0, y1), 和另一条线段的向量(x2 - x1, y2 - y1), 求得交点(x, y), 距离则为|y - y1|
              a = { x: newLine1.x1, y: newLine1.y1 }
              b = { x: newLine1.x1, y: 0 }
              c = { x: newLine2.x1, y: newLine2.y1 }
              d = { x: newLine2.x2, y: newLine2.y2 }
              startY = newLine1.y1
              isInRange = true
            }
            // newLine1.x2在另一条point2内部
            if (newLine1.x2 > newLine2.x1 && newLine1.x2 < newLine2.x2) {
              a = { x: newLine1.x2, y: newLine1.y2 }
              b = { x: newLine1.x2, y: 0 }
              c = { x: newLine2.x1, y: newLine2.y1 }
              d = { x: newLine2.x2, y: newLine2.y2 }
              startY = newLine1.y2
              isInRange = true
            }
            // newLine2.x1在point1内部
            if (newLine2.x1 > newLine1.x1 && newLine2.x1 < newLine1.x2) {
              a = { x: newLine2.x1, y: newLine2.y1 }
              b = { x: newLine2.x1, y: 0 }
              c = { x: newLine1.x1, y: newLine1.y1 }
              d = { x: newLine1.x2, y: newLine1.y2 }
              startY = newLine2.y1
              isInRange = true
            }
            if (newLine2.x2 > newLine1.x1 && newLine2.x2 < newLine1.x2) {
              a = { x: newLine2.x2, y: newLine2.y2 }
              b = { x: newLine2.x2, y: 0 }
              c = { x: newLine1.x1, y: newLine1.y1 }
              d = { x: newLine1.x2, y: newLine1.y2 }
              startY = newLine2.y2
              isInRange = true
            }
            if (!isInRange) continue
            let denominator =
              (b.x - a.x) * (d.y - c.y) - (d.x - c.x) * (b.y - a.y)
            if (denominator == 0) continue
            // 如果平行, 则直接求两条线段的纵坐标距离
            let b1 = (b.y - a.y) * a.x + (a.x - b.x) * a.y
            let b2 = (d.y - c.y) * c.x + (c.x - d.x) * c.y
            let y = (b2 * (b.y - a.y) - b1 * (d.y - c.y)) / denominator
            let distance = Math.abs(y - startY)
            distanceArr.push(distance)
          }
          if (direction == 'left' || direction == 'right') {
            // 先判断点是否在另外一条线段上的区间内, 如果再, 才去求距离
            let a,
              b,
              c,
              d = {}
            let startX = 0
            // 判断line1和line2是否平行
            let isPingxing =
              (line1.x2 - line1.x1) * (line2.y2 - line2.y1) -
              (line2.x2 - line2.x1) * (line1.y2 - line1.y1)
            // 如果两条线段平行, 则直接求任意一点到另一条线段的距离
            if (isPingxing == 0) {
              // 如果为垂直的竖线, 则直接计算横坐标的差值
              if (line1.x1 == line1.x2) {
                let distance = Math.abs(line1.x1 - line2.x1)
                distanceArr.push(distance)
                continue
              } else {
                // 计算直线斜率, 由于平行, 计算任意一条线段的斜率即可, 以line1为准
                let k = (line1.y2 - line1.y1) / (line1.x2 - line1.x1)
                // line1对应的直线则为 y - line1.y1 = k * (x - line1.x1)
                // line2对应的直线则为 y - line2.y1 = k * (x - line2.x1)
                // 化为一般式则是 -kx + y - line1.y1 + k * line1.x1 = 0和-kx + y - line2.y1 + k * line2.x1 = 0
                // 平行线之间的距离则为
                let distance =
                  Math.abs(
                    -line1.y1 + k * line1.x1 - (-line2.y1 + k * line2.x1)
                  ) / Math.sqrt(Math.pow(k, 2) + 1)
                distanceArr.push(distance)
                continue
              }
            }
            // point.x1在另一条point2内部
            let isInRange = false
            // 将两条线段的纵坐标从小到大排列
            let newLine1 = JSON.parse(JSON.stringify(line1))
            if (line1.y1 > line1.y2) {
              newLine1.y1 = line1.y2
              newLine1.y2 = line1.y1
              newLine1.x1 = line1.x2
              newLine1.x2 = line1.x1
            }
            let newLine2 = JSON.parse(JSON.stringify(line2))
            if (line2.y1 > line2.y2) {
              newLine2.y1 = line2.y2
              newLine2.y2 = line2.y1
              newLine2.x1 = line2.x2
              newLine2.x2 = line2.x1
            }
            if (newLine1.y1 > newLine2.y1 && newLine1.y1 < newLine2.y2) {
              // 由该点延伸出一条直线, 求与另一条线段的交点, 然后纵坐标相减求出距离
              // 该点为(x1, y1), 上下移动时延伸出向量, 也就是(0, y1), 和另一条线段的向量(x2 - x1, y2 - y1), 求得交点(x, y), 距离则为|y - y1|
              a = { x: newLine1.x1, y: newLine1.y1 }
              b = { x: 0, y: newLine1.y1 }
              c = { x: newLine2.x1, y: newLine2.y1 }
              d = { x: newLine2.x2, y: newLine2.y2 }
              startX = newLine1.x1
              isInRange = true
            }
            // newLine1.x2在另一条point2内部
            if (newLine1.y2 > newLine2.y1 && newLine1.y2 < newLine2.y2) {
              a = { x: newLine1.x2, y: newLine1.y2 }
              b = { x: 0, y: newLine1.y2 }
              c = { x: newLine2.x1, y: newLine2.y1 }
              d = { x: newLine2.x2, y: newLine2.y2 }
              startX = newLine1.x2
              isInRange = true
            }
            // newLine2.x1在point1内部
            if (newLine2.y1 > newLine1.y1 && newLine2.y1 < newLine1.y2) {
              a = { x: newLine2.x1, y: newLine2.y1 }
              b = { x: 0, y: newLine2.y1 }
              c = { x: newLine1.x1, y: newLine1.y1 }
              d = { x: newLine1.x2, y: newLine1.y2 }
              startX = newLine2.x1
              isInRange = true
            }
            if (newLine2.y2 > newLine1.y1 && newLine2.y2 < newLine1.y2) {
              a = { x: newLine2.x2, y: newLine2.y2 }
              b = { x: 0, y: newLine2.y2 }
              c = { x: newLine1.x1, y: newLine1.y1 }
              d = { x: newLine1.x2, y: newLine1.y2 }
              startX = newLine2.x2
              isInRange = true
            }
            if (!isInRange) continue
            let denominator =
              (b.x - a.x) * (d.y - c.y) - (d.x - c.x) * (b.y - a.y)
            if (denominator == 0) continue
            let b1 = (b.y - a.y) * a.x + (a.x - b.x) * a.y
            let b2 = (d.y - c.y) * c.x + (c.x - d.x) * c.y
            let x = (b2 * (b.x - a.x) - b1 * (d.x - c.x)) / denominator
            let distance = Math.abs(x - startX)
            distanceArr.push(distance)
          }
        }
      }
      return distanceArr
    },
    // 判断两个线段是否横坐标是否有交叉位置, 或者纵坐标有交叉位置
    checkRangeIntersect(line1, line2, direction) {
      // direction为up或者down时, 判断横坐标是否有交集
      if (direction == 'up' || direction == 'down') {
        let newLine1 = {}
        let newLine2 = {}
        if (line1.x1 > line1.x2) {
          newLine1.x1 = line1.x2
          newLine1.x2 = line1.x1
        } else {
          newLine1.x1 = line1.x1
          newLine1.x2 = line1.x2
        }
        if (line2.x1 > line2.x2) {
          newLine2.x1 = line2.x2
          newLine2.x2 = line2.x1
        } else {
          newLine2.x1 = line2.x1
          newLine2.x2 = line2.x2
        }
        if (
          Math.max(newLine1.x1, newLine2.x1) <
          Math.min(newLine1.x2, newLine2.x2)
        ) {
          return true
        } else {
          return false
        }
      } else {
        // direction为left或者right时, 判断纵坐标是否有交集
        let newLine1 = {}
        let newLine2 = {}
        if (line1.y1 > line1.y2) {
          newLine1.y1 = line1.y2
          newLine1.y2 = line1.y1
        } else {
          newLine1.y1 = line1.y1
          newLine1.y2 = line1.y2
        }
        if (line2.y1 > line2.y2) {
          newLine2.y1 = line2.y2
          newLine2.y2 = line2.y1
        } else {
          newLine2.y1 = line2.y1
          newLine2.y2 = line2.y2
        }
        if (
          Math.max(newLine1.y1, newLine2.y1) <
          Math.min(newLine1.y2, newLine2.y2)
        ) {
          return true
        } else {
          return false
        }
      }
    },
    // 键盘松开事件
    keyUpFuncs() {
      return (e) => {
        this.isScaling = false
      }
    },
    mouseWheelFuncs() {
      return (e) => {
        if (e.ctrlKey) {
          e.preventDefault()
        }
      }
    },
    /** 添加板件到暂存库 */
    async handleAddPlankToAwaitStore(activePlank, activeBigPlank) {
      if (this.isCutorigin) return
      // 清除大板上的指定小板
      const flag = clearPlankToBigPlank(activePlank, activeBigPlank)
      if (!flag) return
      const imgUrl = getPlankImage(activePlank, false, {
        defaultScale,
        scale: 3,
        plankLineWidth: 1,
      })
      //生成本地暂存库数据并将数据添加到本地暂存库中
      const data = await genAwaitStoreData(
        activePlank,
        this.preLayoutData,
        imgUrl
      )
      this.$store.commit('awaitPaibanStore/setJinxiPlankToStore', data)
      // 进行板件处理后的固定操作
      buryPointApi('layout', 'plate_staged')
      this.$emit('recordActivePlank', null)
      calcBigpartArea(activeBigPlank, this.ncSetting)
      this.judgeAbleFanban(activeBigPlank)
      this.activePlank = null
      this.activeBigpart = null
      this.renderAll()
    },
    // 鼠标松开事件
    mouseUpFuncs() {
      return async (e) => {
        // 是否是拖动板件
        if (this.activePlank && this.isMovingPlank) {
          if (mousePointISInElement(e, 'jinxi-paiban-await-store', 'class')) {
            this.handleAddPlankToAwaitStore(this.activePlank, this.bigpart)
          }
        }
        if (this.isDragingPlank) {
          let pos = this.windowToCanvas(e.clientX, e.clientY)
          if (!pos) return
          const dragData = await checkCurrentDragPlankDataIsLoad(
            this.$store.state.awaitPaibanStore.currentDragPlank
          )
          if (!dragData) return
          let plank = dragData.plank
          let bigpart = this.bigpart
          const dragTarget =
            this.$refs['paibanStoreRef']?.getCurrentDragTarget()
          if (!dragTarget) return
          // 获取暂存区拖出图片的信息，获取偏移量并计算出板件的起始位置
          const { offsetX, offsetY } = dragTarget
          const startX = pos.x - offsetX
          const startY = pos.y - offsetY
          // 如果找到了大板则继续执行后面的逻辑, 否则弹回原位置重新绘制
          if (bigpart) {
            // 如果鼠标位置在大板上, 则继续执行后面的逻辑, 否则弹回原位置重新绘制
            if (
              startX <=
                this.bigpart.subtleStartX +
                  this.bigpart.subtleWidth +
                  2 * this.deviation &&
              startY <=
                this.bigpart.subtleStartY +
                  this.bigpart.subtleHeight +
                  this.topInfoHeight +
                  2 * this.deviation
            ) {
              if (bigpart.isLocked) {
                this.$message.error(translate('arrangedPage.plankLockErr'))
                this.changeDragingPlank(false)
                return
              }
              if (
                bigpart.matCode == plank.matCode &&
                bigpart.thick == plank.thick &&
                bigpart.texture == plank.texture
              ) {
                const realPos = {
                  x:
                    ((startX - this.bigpart.subtleStartX - this.deviation) /
                      this.scalePercent) *
                    defaultScale,
                  y:
                    ((startY -
                      this.bigpart.subtleStartY -
                      this.topInfoHeight -
                      this.deviation) /
                      this.scalePercent) *
                    defaultScale,
                }
                // 判断当前板件是否能够放入大板件
                const flag = judgePlankPushBigPlank(realPos, plank, bigpart)
                if (flag) {
                  this.activePlank = plank
                  plank.stockKey = bigpart.stockKey
                  plank.stockNum = bigpart.stockNum
                  bigpart.parts.push(plank)
                  this.activePlank.index = this.getPlankNextIndex()
                  calcBigpartArea(this.bigpart, this.ncSetting)
                  this.judgeAbleFanban(this.bigpart)
                  this.activePlankOff = null
                  this.isMovingPlank = false
                  this.renderAll()
                  this.changeDragingPlank(false)
                  if (!isExistPriority(plank)) {
                    // 重新计算下刀点
                    this.calcCutPositionOrder(bigpart)
                  }
                  this.$store.commit(
                    'awaitPaibanStore/deleteJinxiStorePlank',
                    this.$store.state.awaitPaibanStore.currentDragPlank.plank
                      .partUniqueId
                  )
                  this.$store.commit(
                    'awaitPaibanStore/setCurrentDragPlank',
                    null
                  )
                  this.changeDragingPlank(false)
                } else {
                  this.$message.info('无法放置板件,留意空余空间大小')
                }
              } else {
                this.$message({
                  message: translate('arrangedPage.dragErrTip'),
                  type: 'error',
                })
                this.changeDragingPlank(false)
              }
            } else {
              this.$message({
                message: translate('arrangedPage.dragWarning'),
                type: 'info',
              })
              this.changeDragingPlank(false)
            }
          } else {
            return
          }
        }
      }
    },
    // 获取当前的index 临时处理后期需要优化(todo)
    getPlankNextIndex() {
      const exists = this.bigpart.parts.map((part) => +part.index)
      const index = getPlankMaxIndexByFinalDrawData(exists)
      return index
    },
    // 截取板件生成图片
    cuttingPlank() {
      // 计算当前激活板件的位置
      let width =
        (this.activePlank.rect.width / defaultScale) * this.scalePercent
      let height =
        (this.activePlank.rect.height / defaultScale) * this.scalePercent
      let oCanvas = document.createElement('canvas')
      oCanvas.width = width + 10
      oCanvas.height = height + 10
      let oCtx = oCanvas.getContext('2d')
      this.activePlankOff = null
      let newPlank = JSON.parse(JSON.stringify(this.activePlank))
      newPlank.startX = 0
      newPlank.startY = 0
      this.drawParts(oCtx, newPlank, 5, 5, true)
      let imgUrl = oCanvas.toDataURL()

      let title = `${this.activePlank.thick}${this.activePlank.matCode}(${this.activePlank.texture})`
      let obj = {
        imgUrl: imgUrl,
        title: title,
        data: this.activePlank,
      }
      let img = new Image()
      img.src = imgUrl
      let that = this
      img.onload = function () {
        let scaleW = Number(this.width) / 138
        let scaleH = Number(this.height) / 140
        if (this.width <= 138 && this.height <= 140) {
          if (scaleW >= scaleH) {
            obj.width = '100%'
          } else {
            obj.height = '100%'
          }
        }
        if (this.width > 138 && this.height > 140) {
          if (scaleW >= scaleH) {
            obj.width = Number(this.width) / scaleW + 'px'
            obj.height = Number(this.height) / scaleW + 'px'
          } else {
            obj.width = Number(this.width) / scaleH + 'px'
            obj.height = Number(this.height) / scaleH + 'px'
          }
        }
        if (this.width <= 138 && this.height > 140) {
          obj.width = Number(this.width) / scaleH + 'px'
          obj.height = Number(this.height) / scaleH + 'px'
        }
        if (this.width > 138 && this.height <= 140) {
          obj.width = Number(this.width) / scaleW + 'px'
          obj.height = Number(this.height) / scaleW + 'px'
        }
        // 从精细排版返回排版时如果没有保存, 在返回排版时会把暂存区板件释放出来
        that.addFinalStorage(obj)
      }
      if (document.body.contains(oCanvas)) {
        document.body.removeChild(oCanvas)
      }
    },

    // 从暂存区拖拽出来时
    dragImg(e, item, index) {
      this.changeDragingPlank(true)
      window.addEventListener('mouseup', this.mouseupEvent)
      this.$nextTick(() => {
        e.preventDefault()
        let imgDom = this.$refs[`tempImg${index}`][0]
        let imgBox = imgDom.getBoundingClientRect()
        // 计算点击的图片位置, 相对于图片的位置, 并用于后续拖动板件到大板时的位置判断
        this.imgPosition = {
          x: e.offsetX / imgBox.width,
          y: e.offsetY / imgBox.height,
        }
        this.setTempImgPos(this.imgPosition)
        // 记录现在鼠标相对于浏览器的位置
        let screenPos = {
          x: e.clientX,
          y: e.clientY,
        }
        // 点击的时候, 在鼠标位置生成原始大小的板件图片
        let img = null
        if (this.tempImg) {
          img = this.tempImg
        } else {
          img = new Image()
        }
        img.src = item.imgUrl
        new Promise((resolve) => {
          img.onload = function () {
            resolve({
              x: this.width,
              y: this.height,
            })
          }
        }).then((res) => {
          // 计算原始图片大小, 相当于从鼠标位置进行缩放
          let x = this.imgPosition.x * res.x
          let y = this.imgPosition.y * res.y
          this.imgRealSize = res
          let left = screenPos.x - x
          let top = screenPos.y - y
          // 设置其位置
          img.style.position = 'fixed'
          img.style.opacity = '0.7'
          img.style.left = left + 'px'
          img.style.top = top + 'px'
          img.style.display = 'block'
          // 挂载在页面上
          if (!this.tempImg) {
            document.body.appendChild(img)
          }
          this.tempImg = img
          this.mouseMoveEvent = this.mouseMoveFunc()
          window.addEventListener('mousemove', this.mouseMoveEvent)
          let that = this
          this.tempImg.onmouseup = function () {
            that.tempImg.style.display = 'none'
            window.removeEventListener('mousemove', that.mouseMoveEvent)
          }
        })

        this.recordBeDraggedPlank(item)
      })
    },
    mouseMoveFunc() {
      return (e) => {
        if (this.tempImg) {
          e.preventDefault()
          let x = this.imgPosition.x * this.imgRealSize.x
          let y = this.imgPosition.y * this.imgRealSize.y
          let left = e.clientX - x
          let top = e.clientY - y

          this.tempImg.style.left = left + 'px'
          this.tempImg.style.top = top + 'px'
        }
      }
    },
    handlePositionChange() {
      // 进入选择模式
      this.isBatchPosition = true
      this.bigpart.parts.forEach((part) => (part.checked = false))
      this.activePartCount = 0
      this.activePlank = null
      this.isCutorigin = false
      this.renderAll()
    },
    handleBatchPositionChange() {
      // 将已选中的数组中的下刀点改成defaultPositionPoint
      this.bigpart.parts.forEach((part) => {
        if (part.checked) {
          part.cutOrigin = this.defaultPositionPoint
        }
      })
      this.handleBatchPositionCancel()
    },
    handleBatchPositionCancel() {
      this.bigpart.parts.forEach((part) => (part.checked = false))
      this.activePartCount = 0
      this.activePlank = null
      this.renderAll()
      this.isBatchPosition = false
      this.defaultPositionPoint = 'rightBottom'
    },
    // 保存精细排版的修改
    saveSubtleChange() {
      buryPointApi('layout_sub', 'save_layout')
      this.changeDrawPlank()
      // 保存暂存区数据
      this.exitButSave()
      this.resetTempStorage()
      this.$emit('savePlan')
      this.$emit('update:visible', false)
    },
    changeDrawPlank() {
      let arr = []
      for (let i = 0; i < this.drawData.length; ++i) {
        arr = [...arr, ...this.drawData[i].data]
      }
      // this.setHistoryPlankEdgeOff(this.ncSetting.panelSize.plankEdgeOff)
      // this.setHistorySecondTrimValue(this.ncSetting.secondTrimValue)
      let drawDataPartsArr = []
      arr.map((item) => drawDataPartsArr.push(...item.parts))
      let layoutData = [...this.preLayoutData]
      layoutData.forEach((layout) => {
        let nowPlank = drawDataPartsArr.filter(
          (draw) => draw.oriPlankNum == layout.oriPlankNum
        )
        if (nowPlank[0]) {
          layout.plank_remarks = nowPlank[0].plank_remarks
        }
      })
      this.setPreLayoutData(layoutData)
      this.setPaibanData(arr)
      this.setFinalDrawData(this.drawData)
    },

    // 余料入库
    saveSurplus(isSaveStorage) {
      buryPointApi('layout_sub', 'sup_warehousing')
      if (!this.activePlank) {
        this.$message({
          message: translate('arrangedPage.subtlePage.saveErr'),
          type: 'info',
        })
        return
      }
      if (!this.activePlank.hasOwnProperty('specialType')) {
        this.$message({
          message: translate('arrangedPage.subtlePage.selectTip'),
          type: 'info',
        })
        return
      }
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        if (this.bigpart.parts[i].plankMerge) {
          this.$message({
            type: 'error',
            message: translate('arrangedPage.storeFailTip'),
          })
          return
        }
      }
      if (this.activePlank.hasSaved) {
        this.$message({
          message: translate('arrangedPage.repeatStoreErr'),
          type: 'error',
        })
        return
      }
      let newSurplus = {
        amount: 1,
        thick: +this.activePlank.thick,
        type: this.activePlank.matCode,
        color: this.activePlank.texture,
        long: this.activePlank.realRect.height,
        width: this.activePlank.realRect.width,
        remark: '',
      }
      if (this.activePlank.surplusPath[0].length == 4) {
        newSurplus.area =
          (this.activePlank.realRect.width * this.activePlank.realRect.height) /
          1000 /
          1000
        newSurplus.shape = 0
      } else {
        const { min_width, min_long, width, long } = dealSurplusSize(
          this.activePlank
        )
        const area =
          min_long && min_width
            ? width * long - (width - min_width) * (long - min_long)
            : width * long
        newSurplus.long = long
        newSurplus.width = width
        newSurplus.area = area / 1000 / 1000
        newSurplus.min_long = min_long
        newSurplus.min_width = min_width
        newSurplus.shape = 1
      }

      // 临时方案, 获取余料名称
      this.$getByToken('/get_user_plank_setting', {}, (res) => {
        if (res.status == 1) {
          newSurplus.name = res.data.next_surplus_name
          // 调用创建余料的接口进行入库
          this.$token(
            '/create_surplus_depot',
            {
              surplus_data: [newSurplus],
              designated: isSaveStorage ? true : false,
            },
            (res) => {
              if (res.status == 1) {
                this.$message({
                  message: translate('arrangedPage.surplusStoreSuccess'),
                  type: 'success',
                })
                this.activePlank.hasSaved = true
                this.activePlank.ggid = res.data.ggid
                this.activePlank.surplus_id = res.data.id
                if (res.data.length) {
                  this.activePlank.branch_name = res.data[0].branch_name
                  this.activePlank.branch_no = res.data[0].branch_no
                }
                let arr = []
                for (let i = 0; i < this.drawData.length; ++i) {
                  arr = [...arr, ...this.drawData[i].data]
                }
                this.setPaibanData(arr)
                this.setFinalDrawData(this.drawData)
                this.$emit('updatePaibanData')
                this.$refs.qrcode.innerHTML = ''
                new QRCode('subtle-qrcode', {
                  width: 80,
                  height: 80,
                  colorDark: '#000000', //前景色
                  colorLight: '#ffffff', //背景色
                  typeNumber: 4,
                  correctLevel: QRCode.CorrectLevel.H,
                  text: 'http://eggi.cn/÷' + this.activePlank.ggid,
                })
                if (isSaveStorage) {
                  this.showSurStoreTip = false
                }
              } else {
                if (res.data?.error_code == '0') {
                  this.showSurStoreTip = true
                  return
                }
                this.$message({
                  message: res.msg,
                  // message: translate('arrangedPage.surplusStoreFail'),
                  type: 'error',
                })
                if (isSaveStorage) {
                  this.showSurStoreTip = false
                }
              }
            }
          )
        }
      })
    },

    // 余料入库增加防抖处理
    debounceSaveSurplus: debounce(function () {
      this.saveSurplus()
    }, 1000),

    // 进入裁剪余料状态
    cutSurplus() {
      if (!this.isCuttingSurplus) {
        buryPointApi('layout_sub', 'sup_cropping')
      }
      this.activePlank = null
      this.bigpart.parts.forEach((part) => (part.checked = false))
      this.activePartCount = 0
      this.ableRewriteRemarks = false
      this.renderAll()
      this.isBatchPosition = false
      this.surplusPoint = []
      this.nextPoint = null
      this.isCuttingSurplus = !this.isCuttingSurplus
      this.showSurplusTemp = !this.showSurplusTemp
      if (!this.isCuttingSurplus) {
        this.lineRecode.show = false
        this.isAdsorb = false
        this.showSurplusTemp = false
      }
    },

    // 计算当前点位到各个板件的距离
    calcPointDistance(pt) {
      const diameter = +getPlateKnifeDiameter(
        this.bigpart.stockKey,
        this.ncSetting
      )
      let plankOffset = Math.abs(diameter / 2 - this.standardPlank.plankEdgeOff)
      let point = {
        x:
          ((pt.x - this.bigpart.subtleStartX - this.deviation) /
            this.scalePercent) *
          defaultScale,
        y:
          ((pt.y -
            this.bigpart.subtleStartY -
            this.topInfoHeight -
            this.deviation) /
            this.scalePercent) *
          defaultScale,
      }

      let lineArr = []
      let bigPlank = []
      if (
        this.bigpart.surplusInfo &&
        Object.keys(this.bigpart.surplusInfo).length > 0
      ) {
        let surplus = this.bigpart.surplusInfo
        if (surplus.shape == 'lshape') {
          bigPlank = [
            {
              x1: -plankOffset,
              y1: surplus.height - plankOffset,
              x2: surplus.width + plankOffset,
              y2: surplus.height - plankOffset,
            },
            {
              x1: surplus.width + plankOffset,
              y1: surplus.height - plankOffset,
              x2: surplus.x5 + plankOffset,
              y2: surplus.height - surplus.y5 - plankOffset,
            },
            {
              x1: surplus.x5 + plankOffset,
              y1: surplus.height - surplus.y5 - plankOffset,
              x2: surplus.x4 + plankOffset,
              y2: surplus.height - surplus.y4 - plankOffset,
            },
            {
              x1: surplus.x4 + plankOffset,
              y1: surplus.height - surplus.y4 - plankOffset,
              x2: surplus.x3 + plankOffset,
              y2: surplus.height - surplus.y3 - plankOffset,
            },
            {
              x1: surplus.x3 + plankOffset,
              y1: surplus.height - surplus.y3 - plankOffset,
              x2: -plankOffset,
              y2: surplus.height - surplus.y3 - plankOffset,
            },
            {
              x1: -plankOffset,
              y1: surplus.height - surplus.y3 - plankOffset,
              x2: -plankOffset,
              y2: surplus.height - plankOffset,
            },
          ]
          // xy轴互换
          if (this.$store.state.ncSetting.xyReverse) {
            let surplus = JSON.parse(JSON.stringify(this.bigpart.surplusInfo))
            surplus = {
              ...surplus,
              width: surplus.height,
              height: surplus.width,
            }
            bigPlank = [
              {
                x1: -plankOffset,
                y1: plankOffset,
                x2: surplus.width + plankOffset,
                y2: plankOffset,
              },
              {
                x1: surplus.width + plankOffset,
                y1: plankOffset,
                x2: surplus.x5 + plankOffset,
                y2: surplus.y5 - plankOffset,
              },
              {
                x1: surplus.x5 + plankOffset,
                y1: surplus.y5 - plankOffset,
                x2: surplus.x4 + plankOffset,
                y2: surplus.y4 - plankOffset,
              },
              {
                x1: surplus.x4 + plankOffset,
                y1: surplus.y4 - plankOffset,
                x2: surplus.x3 + plankOffset,
                y2: surplus.y3 - plankOffset,
              },
              {
                x1: surplus.x3 + plankOffset,
                y1: surplus.y3 - plankOffset,
                x2: -plankOffset,
                y2: surplus.y3 - plankOffset,
              },
              {
                x1: -plankOffset,
                y1: surplus.y3 - plankOffset,
                x2: -plankOffset,
                y2: plankOffset,
              },
            ]
            // xy轴互换时需要进行一次翻面
            bigPlank = bigPlank.map((item) => ({
              x1: item.y1,
              y1: surplus.width - item.x1,
              y2: surplus.width - item.x2,
              x2: item.y2,
            }))
          }
        } else {
          bigPlank = [
            {
              x1: -plankOffset,
              y1: -plankOffset,
              x2: surplus.width + plankOffset,
              y2: -plankOffset,
            },
            {
              x1: surplus.width + plankOffset,
              y1: -plankOffset,
              x2: surplus.width + plankOffset,
              y2: surplus.height + plankOffset,
            },
            {
              x1: surplus.width + plankOffset,
              y1: surplus.height + plankOffset,
              x2: -plankOffset,
              y2: surplus.height + plankOffset,
            },
            {
              x1: -plankOffset,
              y1: surplus.height + plankOffset,
              x2: -plankOffset,
              y2: -plankOffset,
            },
          ]
        }
      } else {
        let plankWidth = this.ncSetting.drawPlankWidth
        let plankHeight = this.ncSetting.drawPlankHeight
        bigPlank = [
          {
            x1: -plankOffset,
            y1: -plankOffset,
            x2: plankWidth + plankOffset,
            y2: -plankOffset,
          },
          {
            x1: plankWidth + plankOffset,
            y1: -plankOffset,
            x2: plankWidth + plankOffset,
            y2: plankHeight + plankOffset,
          },
          {
            x1: plankWidth + plankOffset,
            y1: plankHeight + plankOffset,
            x2: -plankOffset,
            y2: plankHeight + plankOffset,
          },
          {
            x1: -plankOffset,
            y1: plankHeight + plankOffset,
            x2: -plankOffset,
            y2: -plankOffset,
          },
        ]
      }
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        let plank = this.bigpart.parts[i]
        // 计算大板边框线段
        if (plank.path) {
          for (let k = 0; k < plank.path[0].length; ++k) {
            let pathPoint = plank.path[0][k]
            let nextPoint =
              k == plank.path[0].length - 1
                ? plank.path[0][0]
                : plank.path[0][k + 1]
            let pathLine = {
              x1: toDecimal(pathPoint.x + plank.startX, 2),
              y1: toDecimal(pathPoint.y + plank.startY, 2),
              x2: toDecimal(nextPoint.x + plank.startX, 2),
              y2: toDecimal(nextPoint.y + plank.startY, 2),
            }
            lineArr.push(pathLine)
          }
        } else {
          let arr = [
            {
              x1: plank.startX,
              y1: plank.startY,
              x2: plank.rect.width + plank.startX,
              y2: plank.startY,
            },
            {
              x1: plank.rect.width + plank.startX,
              y1: plank.startY,
              x2: plank.rect.width + plank.startX,
              y2: plank.rect.height + plank.startY,
            },
            {
              x1: plank.rect.width + plank.startX,
              y1: plank.rect.height + plank.startY,
              x2: plank.startX,
              y2: plank.rect.height + plank.startY,
            },
            {
              x1: plank.startX,
              y1: plank.rect.height + plank.startY,
              x2: plank.startX,
              y2: plank.startY,
            },
          ]
          lineArr = [...lineArr, ...arr]
        }
      }
      let finalLineArr = [...lineArr, ...bigPlank]
      let upArr = []
      let downArr = []
      let leftArr = []
      let rightArr = []
      let surplusLine = null
      // 计算出上一条线段
      if (this.surplusPoint.length > 1) {
        let point1 = this.surplusPoint[this.surplusPoint.length - 2]
        let point2 = this.surplusPoint[this.surplusPoint.length - 1]
        surplusLine = {
          x1:
            ((point1.x - this.bigpart.subtleStartX - this.deviation) /
              this.scalePercent) *
            defaultScale,
          y1:
            ((point1.y -
              this.bigpart.subtleStartY -
              this.topInfoHeight -
              this.deviation) /
              this.scalePercent) *
            defaultScale,
          x2:
            ((point2.x - this.bigpart.subtleStartX - this.deviation) /
              this.scalePercent) *
            defaultScale,
          y2:
            ((point2.y -
              this.bigpart.subtleStartY -
              this.topInfoHeight -
              this.deviation) /
              this.scalePercent) *
            defaultScale,
        }
      }
      // 计算点到大板和小板的距离
      for (let i = 0; i < finalLineArr.length; ++i) {
        let line = finalLineArr[i]
        let b = { x: point.x, y: point.y }
        let c = { x: line.x1, y: line.y1 }
        let d = { x: line.x2, y: line.y2 }
        let upFlag = false
        // 计算竖直方向的距离
        // y坐标相等时, 线段为横线, 判断记录的点是否在线段两点的横坐标之间
        if (line.x1 < line.x2) {
          upFlag =
            compareTowNum(point.x, line.x1, '>=') &&
            compareTowNum(point.x, line.x2, '<=')
        } else if (line.x1 > line.x2) {
          upFlag =
            compareTowNum(point.x, line.x2, '>=') &&
            compareTowNum(point.x, line.x1, '<=')
        }
        if (upFlag) {
          // 判断上一条线段是否和比较的线段有交集, 如果有则求最小值
          if (surplusLine && !this.checkRangeIntersect(surplusLine, line, 'up'))
            continue
          let a = { x: point.x, y: 0 }
          let calcResult = this.calcIntersectPoint(a, b, c, d)
          if (!calcResult) continue
          let distance = point.y - calcResult.y
          // 如果小于0, 说明在该点下方, 大于0, 说明在该点上方
          if (distance < 0) {
            downArr.push(-distance)
          } else {
            upArr.push(distance)
          }
        }

        let leftFlag = false
        if (line.y1 < line.y2) {
          leftFlag =
            compareTowNum(point.y, line.y1, '>=') &&
            compareTowNum(point.y, line.y2, '<=')
        } else if (line.y2 < line.y1) {
          leftFlag =
            compareTowNum(point.y, line.y2, '>=') &&
            compareTowNum(point.y, line.y1, '<=')
        }
        if (leftFlag) {
          if (
            surplusLine &&
            !this.checkRangeIntersect(surplusLine, line, 'left')
          )
            continue
          let a = { x: 0, y: point.y }
          let calcResult = this.calcIntersectPoint(a, b, c, d)
          if (!calcResult) continue
          let distance = point.x - calcResult.x
          // 如果大于0, 说明在该点左侧
          if (distance < 0) {
            rightArr.push(-distance)
          } else {
            leftArr.push(distance)
          }
        }
      }
      // 计算各个方向的最小距离
      this.leftDistance =
        (Math.min(...leftArr) / defaultScale) * this.scalePercent
      this.rightDistance =
        (Math.min(...rightArr) / defaultScale) * this.scalePercent
      this.upDistance = (Math.min(...upArr) / defaultScale) * this.scalePercent
      this.downDistance =
        (Math.min(...downArr) / defaultScale) * this.scalePercent
      // 最后的长度都不能小于1，小于1的线段没有意义
      this.leftDistance = this.leftDistance <= 1 ? 0 : this.leftDistance - 0.001
      this.rightDistance =
        this.rightDistance <= 1 ? 0 : this.rightDistance - 0.001
      this.upDistance = this.upDistance <= 1 ? 0 : this.upDistance - 0.001
      this.downDistance = this.downDistance <= 1 ? 0 : this.downDistance - 0.001
    },

    calcIntersectPoint(a, b, c, d) {
      let denominator = (b.x - a.x) * (d.y - c.y) - (d.x - c.x) * (b.y - a.y)
      if (denominator == 0) return false
      let b1 = (b.y - a.y) * a.x + (a.x - b.x) * a.y
      let b2 = (d.y - c.y) * c.x + (c.x - d.x) * c.y
      let x = (b2 * (b.x - a.x) - b1 * (d.x - c.x)) / denominator
      let y = (b2 * (b.y - a.y) - b1 * (d.y - c.y)) / denominator
      return {
        x: x,
        y: y,
      }
    },

    // 绘制余料
    drawSurplus(e) {
      BODY.style.cursor = 'default'
      let point = {
        x: e.offsetX,
        y: e.offsetY,
      }
      if (this.surplusPoint.length == 0) {
        let bigpartPoly = []
        for (let i = 0; i < this.bigpart.bigPlank[0].length; ++i) {
          let point2 = this.bigpart.bigPlank[0][i]
          bigpartPoly.push({
            x:
              this.bigpart.subtleStartX +
              this.deviation +
              (point2.X / defaultScale) * this.scalePercent,
            y:
              this.bigpart.subtleStartY +
              this.deviation +
              this.topInfoHeight +
              (point2.Y / defaultScale) * this.scalePercent,
          })
        }
        let isInBigPlank = this.isInPolygon(point, bigpartPoly)
        if (!isInBigPlank) {
          this.$message({
            message: translate('arrangedPage.subtlePage.cutErr2'),
            type: 'error',
          })
          return
        }
        let isInPart = this.setActivePlank(e)
        if (isInPart && this.surplusPoint.length == 0) {
          this.$message({
            message: translate('arrangedPage.subtlePage.cutErr'),
            type: 'error',
          })
          return
        }
      }
      if (this.surplusPoint.length == 0) {
        this.surplusPoint.push(point)
        this.calcPointDistance({ x: e.offsetX, y: e.offsetY })
      } else {
        // 如果是矩形, 则形成闭合, 退出余料裁剪状态
        let finalPoint = this.surplusPoint[this.surplusPoint.length - 1]
        // 如果当前点击的位置和记录的点位数组最后一个相同, 则不会记录该点
        if (
          this.nextPoint.x == finalPoint.x &&
          this.nextPoint.y == finalPoint.y
        )
          return
        this.surplusPoint.push(this.nextPoint)
        // 当余料的点位有5个时进入判断
        if (this.surplusPoint.length === 5) {
          // 判断余料板件是否自相交
          const isShapeIntersect = this.dealShapeIntersect()
          if (!isShapeIntersect) return
          // 判断余料生成的线段是否合大板上已存在的板件冲突
          const isPartConflict = this.dealLinePartConflict(e)
          if (!isPartConflict) return
        }
        this.calcPointDistance(this.nextPoint)
        finalPoint = this.surplusPoint[this.surplusPoint.length - 1]
        if (this.surplusPoint.length == 4) {
          let firstPoint = this.surplusPoint[0]

          if (firstPoint.x == finalPoint.x || firstPoint.y == finalPoint.y) {
            // 闭合之前需要检查是否可以闭合
            const flag = this.dealLinePartConflict(e)
            if (!flag) return
            this.addNewSurplus()
            return
          }
        }
        if (this.surplusPoint.length == 6) {
          this.addNewSurplus()
          return
        }

        this.renderAll()
        this.ctx.beginPath()
        this.ctx.setLineDash([])
        this.ctx.strokeStyle = '#000'

        for (let i = 0; i < this.surplusPoint.length; ++i) {
          if (i == 0) {
            this.ctx.moveTo(this.surplusPoint[i].x, this.surplusPoint[i].y)
          } else {
            this.ctx.lineTo(this.surplusPoint[i].x, this.surplusPoint[i].y)
          }
        }
        this.ctx.stroke()
        this.ctx.closePath()
        this.calcNextPoint(e)
      }
    },

    // 添加余料
    addNewSurplus() {
      let pathArr = []
      let diameter = +getPlateKnifeDiameter(
        this.bigpart.stockKey,
        this.ncSetting
      )
      if (this.ncSetting.glass_setting) {
        diameter = 0
      }
      for (let i = 0; i < this.surplusPoint.length; ++i) {
        let point = this.surplusPoint[i]
        let newPoint = {
          x:
            ((point.x - this.bigpart.subtleStartX - this.deviation) /
              this.scalePercent) *
            defaultScale,
          y:
            ((point.y -
              this.bigpart.subtleStartY -
              this.deviation -
              this.topInfoHeight) /
              this.scalePercent) *
            defaultScale,
        }
        pathArr.push(newPoint)
      }

      let min_x = []
      let min_y = []
      for (let i = 0; i < pathArr.length; ++i) {
        min_x.push(pathArr[i].x)
        min_y.push(pathArr[i].y)
      }
      let startX = Math.min(...min_x)
      let startY = Math.min(...min_y)
      let width = Math.max(...min_x) - startX
      let height = Math.max(...min_y) - startY

      for (let i = 0; i < pathArr.length; ++i) {
        pathArr[i].x -= startX
        pathArr[i].y -= startY
      }
      // 余料闭合路径
      if (pathArr.length === 6) {
        pathArr.push({ ...pathArr[0] })
      }
      let newPlank = {
        startX: startX,
        startY: startY,
        rect: {
          x: 0,
          y: 0,
          width: width,
          height: height,
        },
        realRect: {
          x: 0,
          y: 0,
          width: width - diameter - this.glassDilate,
          height: height - diameter - this.glassDilate,
        },
        oRect: {
          x: 0,
          y: 0,
          width: width - diameter - this.glassDilate,
          height: height - diameter - this.glassDilate,
        },
        fullSize: {
          x: 0,
          y: 0,
          width: width - diameter - this.glassDilate,
          height: height - diameter - this.glassDilate,
        },
        realCurve: {},
        edgeInfo: '←0↓0→0↑0',
        holes: [],
        slots: [],
        sholes: [],
        sslots: [],
        specialType: 'supplus',
        matCode: this.bigpart.matCode,
        thick: this.bigpart.thick,
        texture: this.bigpart.texture,
        address: '',
        name: '余料板件',
        stockNum: this.bigpart.stockNum,
        stockKey: this.bigpart.stockKey,
        plankWidth: this.ncSetting.drawPlankWidth,
        plankHeight: this.ncSetting.drawPlankHeight,
      }
      let maxPlankId = Math.max(
        ...this.bigpart.parts.map((item) =>
          +item.plankID ? item.plankID : -999
        )
      )
      if (maxPlankId == -999 || maxPlankId == '-Infinity') maxPlankId = 0
      newPlank.plankID = maxPlankId + 1
      // 柜门跳转需要添加doorsize和siez
      const guimenKey = this.$store.state.thinkerxMaterialKeys
      guimenKey && mapGuimenToYPB(newPlank, guimenKey)
      if (pathArr.length > 4) {
        newPlank.path = [pathArr]
      }

      // 用于标签绘制中余料的绘制
      newPlank.surplusPath = [pathArr]

      newPlank.realCurve = [
        { x: 0, y: 0 },
        { x: newPlank.oRect.width, y: 0 },
        { x: newPlank.oRect.width, y: newPlank.oRect.height },
        { x: 0, y: newPlank.oRect.height },
      ]

      dealPlankPoly(newPlank)

      newPlank.index = this.supplusIndex
      newPlank.surplusIndex = this.supplusIndex
      this.setPlankLableId(this.plankLableId + 1)
      newPlank.labelId = dealNumber(this.$store.state.plankLableId)
      this.setSupplusIndex()

      let date = new Date()
      let time = date.getTime()
      let plankNum = ('' + time).slice(-12)
      let strArr = plankNum.split('')
      let num1 = 0
      let num2 = 0
      for (let i = 0; i < strArr.length; ++i) {
        if (i == 0) {
          if (strArr[i] == '0') {
            strArr[i] = Math.floor(Math.random() * 9 + 1)
          }
        }
        if ((i + 1) % 2) {
          num2 += Number(strArr[i])
        } else {
          num1 += Number(strArr[i])
        }
      }
      let finalNum = (10 - ((num1 * 3 + num2) % 10)) % 10
      strArr.push(finalNum)
      newPlank.oriPlankNum = strArr.join('')
      newPlank.plankNum = newPlank.oriPlankNum
      newPlank.checklistID = dealChecklistID(this.bigpart.parts)
      newPlank.partUniqueId = nanoid()
      generateSimplePlankNum(newPlank)
      this.bigpart.parts.push(newPlank)

      this.surplusPoint = []
      this.nextPoint = null
      this.calcCutPositionOrder(this.bigpart)
      calcBigpartArea(this.bigpart, this.ncSetting)
      this.renderAll()
    },
    // 绘制完余料后, 重新设置下刀点和下刀顺序
    calcCutPositionOrder(bigpart) {
      let plankWidth = this.standardPlank.plankWidth
      let plankHeight = this.standardPlank.plankHeight
      let { startPosition, xyReverse, cutDirection, movePlankSetting } =
        this.ncSetting
      let rectMap = {}
      const diameter = +getPlateKnifeDiameter(bigpart.stockKey, this.ncSetting)
      let cutKnifeR = diameter / 2
      let title = `${this.bigpart.texture}:${this.bigpart.matCode}:${this.bigpart.thick}`
      const bigPartLen = this.bigpart.parts.length
      for (let k = 0; k < bigPartLen; ++k) {
        let _set = []
        this.bigpart.parts.forEach((item) => {
          _set.push(item.index)
        })
        const maxNum = _set.sort((a, b) => a - b).pop() + 1
        let part = this.bigpart.parts[k]
        this.bigpart.parts[bigPartLen - 1].index = maxNum
        let partRectWidth = 0
        let partRectHeight = 0
        partRectWidth = part.rect.width
        partRectHeight = part.rect.height
        let partInfo = {
          width: partRectWidth,
          height: partRectHeight,
          startX: part.startX + cutKnifeR,
          startY: plankHeight - partRectHeight - part.startY + cutKnifeR,
          isRotated: part.isRotated ? part.isRotated : false,
          partIndex: part.index,
          stockNum: part.stockNum,
        }
        if (part.surplusInfo) {
          partInfo.surplusInfo = part.surplusInfo
        }
        if (rectMap[title]) {
          rectMap[title].push(partInfo)
        } else {
          rectMap[title] = [partInfo]
        }
      }

      let obj = {
        ncConfig: {
          plankWidth: plankWidth ?? this.ncSetting.panelSize.plankWidth,
          plankHeight: plankHeight ?? this.ncSetting.panelSize.plankHeight,
          startPosition: startPosition,
          xyReverse: xyReverse,
          cutDirection: cutDirection ?? '逆时针',
          new_cut_sequence: Boolean(movePlankSetting?.newCutSequence),
        },
        layoutRectMap: rectMap,
        uid: this.userInfo.id,
      }
      this.showLoading = true
      if (sessionStorage.getItem('thinkerx_material')) {
        obj['from'] = '门窗erp'
      }
      this.$token('/get_cal_priority', obj, (res) => {
        if (res.status == 1) {
          let newPointArr = res.result.data[title]
          for (let i = 0; i < newPointArr.length; ++i) {
            for (let k = 0; k < bigpart.parts.length; ++k) {
              let plank = bigpart.parts[k]
              if (plank.index === newPointArr[i].index) {
                plank.priority = newPointArr[i].priority
                plank.cutOrigin = newPointArr[i].cutOrigin
              }
            }
          }
          this.renderAll()
          this.showLoading = false
        } else {
          this.$message({
            type: 'error',
            message: '计算下刀点失败!',
          })
          this.showLoading = false
        }
      })
    },
    showPartSize(field, needShowField) {
      const rect = getOriginRect(this.activePlank, field)
      return (rect?.[needShowField] ?? 0).toFixed(2)
    },
    // 判断板件是否和余料线段冲突
    dealLinePartConflict() {
      const point = this.getNextPoint()
      const lastPoint = this.surplusPoint[this.surplusPoint.length - 1]
      const { x, y } = point
      let bigpartPoly = []
      let left = this.bigpart.subtleStartX + this.deviation
      let top = this.bigpart.subtleStartY + this.deviation + this.topInfoHeight
      for (let i = 0; i < this.bigpart.parts.length; ++i) {
        const part = this.bigpart.parts[i]
        let shapePath = []
        if (part.path) {
          shapePath = part.path[0]
        } else {
          const { width, height } = part.rect
          shapePath = [
            { x: 0, y: 0 },
            { x: width, y: 0 },
            { x: width, y: height },
            { x: 0, y: height },
            { x: 0, y: 0 },
          ]
        }
        shapePath.forEach((it) => {
          bigpartPoly.push({
            X: left + ((part.startX + it.x) / defaultScale) * this.scalePercent,
            Y: top + ((part.startY + it.y) / defaultScale) * this.scalePercent,
          })
        })
        const lineArr = [[lastPoint, { x, y }]]
        if (this.surplusPoint.length === 5) {
          lineArr[1] = [{ x, y }, this.surplusPoint[0]]
        }
        const len = bigpartPoly.length - 1
        let flag = false
        for (let i = 0; i < len; i++) {
          const cut = bigpartPoly[i]
          const next = bigpartPoly[(i + 1) % len]
          const line2 = [
            { x: cut.X, y: cut.Y },
            { x: next.X, y: next.Y },
          ]
          for (let j = 0; j < lineArr.length; j++) {
            flag = dealLineSegmentIntersect(lineArr[j], line2)
            if (flag) break
          }
          if (flag) break
        }
        bigpartPoly.length = 0
        if (flag) {
          this.surplusPoint.pop()
          this.calcPointDistance(
            this.surplusPoint[this.surplusPoint.length - 1]
          )
          this.$message.error(translate('arrangedPage.plankConfilictErr'))
          return false
        }
      }
      return true
    },
    // 获取余料的下一点位
    getNextPoint() {
      const firstPoint = this.surplusPoint[0]
      let point1 = this.surplusPoint[this.surplusPoint.length - 2]
      let point2 = this.surplusPoint[this.surplusPoint.length - 1]
      let nextDirection
      if (point1.y == point2.y) {
        nextDirection = true
      }
      if (point1.x == point2.x) {
        nextDirection = false
      }
      let point = {}
      if (nextDirection) {
        point = {
          x: point2.x,
          y: firstPoint.y,
        }
      } else {
        point = {
          x: firstPoint.x,
          y: point2.y,
        }
      }
      return point
    },
    // 判断余料是否自相交
    dealShapeIntersect() {
      const last = this.surplusPoint[this.surplusPoint.length - 1]
      // 获取余料的下一个点位
      const nextPoint = this.getNextPoint()
      /**
       * 只需要判断余料的第一条线段和最后一条线段 || 第二条线段和最后一个点位和余料的下一点位组成的线段是否冲突
       */
      const lineArr = [
        [
          [last, nextPoint],
          [this.surplusPoint[1], this.surplusPoint[2]],
        ],
        [
          [this.surplusPoint[this.surplusPoint.length - 2], last],
          [this.surplusPoint[0], this.surplusPoint[1]],
        ],
      ]
      let flag
      for (const line of lineArr) {
        flag = dealLineSegmentIntersect(line[0], line[1])
        if (flag) break
      }
      if (flag) {
        this.surplusPoint.pop()
        const lastPoint = this.surplusPoint[this.surplusPoint.length - 1]
        this.calcPointDistance(lastPoint)
        this.$message.error(translate('arrangedPage.surplusErrTip'))
        return false
      }
      return true
    },
    downloadNC() {
      if (this.beforeCommonCheck()) {
        return
      }
      buryPointApi('layout_sub', 'download_nc')
      this.$emit('downloadNC', this.bigpart)
    },
    // 部分公用的预检查预检查
    beforeCommonCheck() {
      let isReturn = false
      if (!this.checkAwaitLocalStore()) {
        isReturn = true
      }
      return isReturn
    },
    // 加载loading按钮
    changeLoading(flag) {
      this.isLoadDownLoadNc = flag
    },
    printTag() {
      if (this.beforeCommonCheck()) {
        return
      }
      this.setIsPrintTag(true)
      buryPointApi('layout_sub', 'print_tag')
      this.$emit('printTag', this.bigpart)
    },
    // 绘制更多排版方案缩略图
    drawMorePlan(way) {
      // 绘制大板轮廓
      const startX = 120
      const startY = 120
      const endX = way.plankWidth + startX
      const endY = way.plankHeight + startY
      way.el.height = way.plankHeight + 240
      way.el.width = way.plankWidth + 240
      const ctx = way.ctx
      ctx.beginPath()
      ctx.moveTo(startX, startY)
      ctx.lineTo(endX, startY)
      ctx.lineTo(endX, endY)
      ctx.lineTo(startX, endY)
      ctx.closePath()
      ctx.stroke()
      // 绘制小板
      way.parts.forEach((part) => {
        this.drawPartsParmas(way.ctx, part, startX, startY, way)
      })
    },
    /**
     * 绘制更多排版方案的小板
     *孔位 | 拉槽 | 下刀点 | 下刀顺序 | 异形孔位
     * */
    drawPartsParmas(ctx, part, startX, startY, bigpart) {
      if (part.holeSlotMerge) {
        ctx.fillStyle = '#F808'
      } else {
        ctx.fillStyle = 'rgba(238, 238, 161, 0.8)'
      }
      ctx.strokeStyle = '#0008'
      ctx.lineWidth = 15
      if (part.plankMerge) {
        ctx.fillStyle = '#f008'
      }
      if (part.specialType) {
        ctx.fillStyle = 'rgba(255, 200, 200, 0.8)'
      }
      let rect = {
        x: startX + part.startX,
        y: startY + part.startY,
        width: part.rect.width,
        height: part.rect.height,
      }
      if (part.path) {
        ctx.beginPath()
        let pArr = []
        if (part.curveHoles) {
          part.curveHoles.forEach((item) => {
            let p = item.path
            p.kkey = item.deep ? item.deep : item.depth
            p.kkey = Math.abs(p.kkey)
            pArr.push(p)
          })
        }
        let newArr = part.curveHoles ? [...part.path, ...pArr] : part.path
        for (let i = 0; i < newArr.length; ++i) {
          let current = newArr[i]
          if (i == 0) {
            if (part.holeSlotMerge) {
              ctx.fillStyle = '#F808'
            } else {
              ctx.fillStyle = 'rgba(238, 238, 161, 0.8)'
            }
            if (part.plankMerge) {
              ctx.fillStyle = '#f008'
            }
            if (part.specialType) {
              ctx.fillStyle = 'rgba(255, 200, 200, 0.8)'
            }
          }
          for (let k = 0; k < current.length; ++k) {
            let x = rect.x + current[k].x
            let y = rect.y + current[k].y
            if (k == 0) {
              ctx.moveTo(x, y)
            } else {
              ctx.lineTo(x, y)
            }
          }
          ctx.closePath()
          if (!current.kkey || current.kkey >= bigpart.thick) {
            ctx.stroke()
          }
          if (current.kkey) {
            Reflect.deleteProperty(current, 'kkey')
          }
        }
        ctx.fill('evenodd')
      } else {
        ctx.fillRect(rect.x, rect.y, rect.width, rect.height)
        ctx.strokeRect(rect.x, rect.y, rect.width, rect.height)
      }
      this.drawAllHoles(ctx, part, rect, true)
      this.drawAllSlots(ctx, part, rect, true)
      this.drawAllCurveHole(ctx, part, rect, bigpart, true)
    },
    // 改变排版方案
    handleChangePaibanWay(data) {
      this.replaceRectData(data.parts)
      const hasSurplus = this.bigpart.parts.some(
        (item) => item.surplusPath && item.surplusPath.length
      )
      // 有余料时需要进行余料裁剪
      if (hasSurplus) {
        this.bigpart.parts = this.bigpart.parts.filter(
          (item) => !item.surplusPath
        )
        this.tailorSurplus(this.bigpart)
      }
      this.hasReformatFlag = true
      this.isBatchPosition = false
      this.$message.success(translate('arrangedPage.changeArrageSuccess'))
    },
    // 将rect和rectind相匹配的值, 将他们的startX和startY进行替换
    replaceRectData(data) {
      for (let i = 0; i < data.length; ++i) {
        let dstpart = data[i]
        for (let k = 0; k < this.bigpart.parts.length; ++k) {
          let srcpart = this.bigpart.parts[k]
          if (srcpart.partUniqueId == dstpart.partUniqueId) {
            let recordIndex = srcpart.index
            const stock_num = srcpart.stockNum
            Object.assign(srcpart, dstpart)
            srcpart.index = recordIndex
            srcpart.stockNum = stock_num
            // 保持原有的stockKey
            srcpart.stockKey = this.subtleData.stockKey
            // 重新处理边框点位
            dealPlankPoly(this.bigpart.parts[k])
            break
          }
        }
      }
      this.renderAll()
    },
    // 处理更多排版方案数据
    async dealMorePlan(preData) {
      const allSubPaibanData =
        this.$store.state.paibanStore.allSubPaibanDataCollectList
      for (let index = 0; index < allSubPaibanData.length; index++) {
        const subPaibanData = allSubPaibanData[index]
        const res = await paibanDataToView(
          preData,
          cloneDeep(subPaibanData.data),
          false,
          false,
          true
        )
        if (res.length == 1) {
          this.paibanWays.push(res[0])
          this.tailorSurplus(res[0], true)
          this.paibanWays.forEach((way, index) => {
            way.canvasIndex = this.bigpart.canvasIndex
            this.$nextTick(() => {
              let el = this.$refs[`planCanvasRef${way.canvasIndex}-${index}`][0]
              let ctx = el.getContext('2d')
              way.el = el
              way.ctx = ctx
              this.drawMorePlan(way)
            })
          })
        }
      }
    },
    // 加载读取锁定大板图片
    loadLockedImg(ctx, x, y, isLocked, lockWidthHeight) {
      let lockedImgInstance
      lockedImgInstance = isLocked
        ? this.UnLockedImgInstance
        : this.LockedImgInstance
      ctx.drawImage(lockedImgInstance, x, y, lockWidthHeight, lockWidthHeight)
    },
    // 添加大板锁定icon的信息
    setLockInfo() {
      let lockStartX = (this.bigpart.subLockStartX =
        this.bigpart.subtleStartX +
        this.deviation +
        this.bigpart.subtleWidth +
        20 * this.scalePercent)
      let lockStartY = (this.bigpart.subLockStartY =
        this.bigpart.subtleStartY + this.topInfoHeight - 30 * this.scalePercent)
      let lockWidthHeight = (this.bigpart.subLockWH = 24 * this.scalePercent)
      let lockEndY = lockStartY + this.bigpart.subLockWH
      let lockEndX = lockStartX + this.bigpart.subLockWH
      const lockPath = [
        { X: lockStartX, Y: lockEndY },
        { X: lockEndX, Y: lockEndY },
        { X: lockEndX, Y: lockStartY },
        { X: lockStartX, Y: lockStartY },
      ]
      return { lockStartX, lockStartY, lockWidthHeight, lockPath }
    },
    // 判断位置
    jundgeLocation(mousePoint, path) {
      if (!this.bigpart.isLocked) {
        return false
      }
      let point = new ClipperLib.IntPoint(mousePoint.x, mousePoint.y)
      const result = ClipperLib.Clipper.PointInPolygon(point, path)
      return result
    },
    // 初始化锁
    initPlankLock(ctx) {
      this.UnLockedImgInstance = new Image()
      this.UnLockedImgInstance.src = UnLockedImg
      this.UnLockedImgInstance.onload = () => {
        ctx.drawImage(this.UnLockedImgInstance, 0, 0, 1, 1)
      }
      this.LockedImgInstance = new Image()
      this.LockedImgInstance.src = LockedImg
      this.LockedImgInstance.onload = () => {
        ctx.drawImage(this.LockedImgInstance, 0, 0, 1, 1)
      }
    },
    // 获取大板path
    getPlankPath() {
      const plankStartX = this.bigpart.subtleStartX
      const plankStartY = this.bigpart.subtleStartY
      const plankEndX =
        this.bigpart.subtleStartX +
        this.bigpart.subtleWidth +
        2 * this.deviation
      const plankEndY =
        this.bigpart.subtleStartY +
        this.bigpart.subtleHeight +
        2 * this.deviation +
        this.topInfoHeight

      const path = [
        { X: plankStartX, Y: plankEndY },
        { X: plankEndX, Y: plankEndY },
        { X: plankEndX, Y: plankStartY },
        { X: plankStartX, Y: plankStartY },
      ]
      return path
    },
    // 存入余料清单
    handleSaveSurStorage() {
      this.saveSurplus(true)
    },
    /** 记录进入精细排版时待排版库当前板件所拥有或正在显示的id, 可用于退出恢复之前的数据 */
    recordAwaitStoreIds() {
      const { localPlankStore, servePlankStore, participationPaibanPlank } =
        this.$store.state.awaitPaibanStore
      this.awaitLocalPlankStoreIds = localPlankStore.map((it) => ({
        id: it.partUniqueId,
        isShow: it.isShow,
      }))
      this.awaitServePlankStoreIds = servePlankStore.map((it) => ({
        id: it.partUniqueId,
        isShow: it.isShow,
      }))
      this.awaitParticipationPaibanPlank = [...participationPaibanPlank]
    },
    /** 退出但未保存的情况下需要重新恢复待排版库的数据 */
    recoverAwaitStoreData() {
      const { localPlankStore, servePlankStore } =
        this.$store.state.awaitPaibanStore
      const loaclIds = this.awaitLocalPlankStoreIds
      const serveIds = this.awaitServePlankStoreIds
      const newLocalPlankStore = localPlankStore.filter((item) => {
        const target = loaclIds.find((it) => it.id === item.partUniqueId)
        if (target) {
          item.isShow = target.isShow
        }
        return target?.isShow
      })
      servePlankStore.forEach((item) => {
        const target = serveIds.find((it) => it.id === item.partUniqueId)
        if (target) {
          item.isShow = target.isShow
        }
      })
      this.$store.commit(
        'awaitPaibanStore/reSetLocalPlankStore',
        newLocalPlankStore
      )
      this.$store.commit('awaitPaibanStore/setServePlankStore', servePlankStore)
      this.$store.commit('awaitPaibanStore/setParticipationPaibanPlank', [
        ...this.awaitParticipationPaibanPlank,
      ])
    },
    /** 退出但是保存 */
    exitButSave() {
      const { localPlankStore } = this.$store.state.awaitPaibanStore
      const newLocalPlankStore = localPlankStore.filter((it) => it.isShow)
      this.$store.commit(
        'awaitPaibanStore/reSetLocalPlankStore',
        newLocalPlankStore
      )
    },
    /** 待排版入库完成 */
    handleAwaitStoreDone() {
      // 删除本地数据中的板件数据
      this.changeDrawPlank()
      this.$emit('savePlan')
    },
    /** 检查进行下载或标签相关操作前需要先检查待排版库是否存在板件 */
    checkAwaitLocalStore() {
      return this.$refs['paibanStoreRef']?.checkLocalPlankStore()
    },
  },
  created() {
    // 第一次进入时计算canvas宽度, 220为左侧宽度, 316位右侧宽度, 48位topInfoHeight
    this.canvasWidth = document.body.offsetWidth - 220 - 316 - 48
    this.changeTempStorage()
  },
  mounted() {
    this.resizeEvent = this.calcCanvasWidth()
    window.addEventListener('resize', this.resizeEvent)
    this.drawData = JSON.parse(JSON.stringify(this.finalDrawData))
    for (let i = 0; i < this.drawData.length; ++i) {
      for (let k = 0; k < this.drawData[i].data.length; ++k) {
        this.drawData[i].data[k].orderIndex = this.clacplankIndex(i) + k + 1
        if (
          this.subtleData.stockKey == this.drawData[i].data[k].stockKey &&
          this.subtleData.canvasIndex == this.drawData[i].data[k].canvasIndex
        ) {
          this.bigpart = this.drawData[i].data[k]
        }
      }
    }
    this.bigpart.subtleStartX = 500
    this.bigpart.subtleStartY = 100
    this.dealCategory()
    this.startDraw()

    this.keyDownEvent = this.keyDownFuncs()
    window.addEventListener('keydown', this.keyDownEvent)
    // 监听键盘按键松开事件
    this.keyUpEvent = this.keyUpFuncs()
    window.addEventListener('keyup', this.keyUpEvent)
    // 监听鼠标滚轮事件
    this.mouseWheelEvent = this.mouseWheelFuncs()
    window.addEventListener('mousewheel', this.mouseWheelEvent, {
      passive: false,
    })
    this.mouseupEvent = this.mouseUpFuncs()
    // 监听鼠标松开事件
    window.addEventListener('mouseup', this.mouseupEvent)
    this.initBigPlanDeal()
    if (
      this.ncSetting.glass_setting &&
      this.ncSetting.glass_setting.contour_size_recoup
    ) {
      this.glassDilate = this.ncSetting.glass_setting.contour_size_recoup * 2
    }
    // 记录暂存区的id用于后续恢复
    this.recordAwaitStoreIds()
  },
  watch: {
    subtleData: {
      deep: true,
      handler(val) {
        this.zoomNum = val.zoomNum ? val.zoomNum : 1
      },
    },
    bigpart: {
      handler(val) {
        if (val.zoomNum) {
          this.scale = this.oriScale * val.zoomNum
          this.zoomNum = val.zoomNum
        } else {
          this.scale = this.oriScale
          this.zoomNum = 1
        }
        this.scaleComputed()
        this.activePartCount = val.parts?.filter((e) => e.checked).length
      },
      deep: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEvent)
    window.removeEventListener('keydown', this.keyDownEvent)
    window.removeEventListener('keyup', this.keyUpEvent)
    window.removeEventListener('mousewheel', this.mouseWheelEvent)
    this.setChangePaiban(false)
  },
}
</script>

<style scoped lang="less">
.subtle-page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 16px;
  background: #eeeeee;
  .left-category {
    flex-shrink: 0;
    width: 220px;
    height: calc(100vh - 90px);
    overflow: auto;
    background: #fff;
    /* 改造滚动条样式 */
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.05);
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
    &::-webkit-scrollbar-track {
      background-color: #eee;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #eee;
    }
    .category-title {
      cursor: pointer;
    }
    .overflow-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .big-part-info {
      height: 40px;
      margin: 0 8px;
      padding: 0 25px;
      line-height: 40px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        color: #18a8c7;
      }
      &.active {
        color: #fff;
        background: rgba(24, 168, 199, 1);
      }
    }
    .big-part-disable {
      cursor: not-allowed;
    }
    .active-bigpart {
      background: #18a8c7;
      color: #fff !important;
      &:hover {
        background: #18a8c7;
      }
    }
  }
  .center-canvas {
    flex-grow: 1;
    box-sizing: border-box;
    margin: 0 24px;
    padding: 16px;
    overflow-x: scroll;
    background: #fff;
    /* 改造滚动条样式 */
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.05);
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
    &::-webkit-scrollbar-track {
      background-color: #eee;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #eee;
    }
    .top-operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .surplus-adsorb {
        margin: 0 15px 0 8px;
      }
    }
    .top-left-operation {
      display: flex;
      align-items: center;
      .ant-btn {
        margin-right: 8px;
      }
    }
    .top-right-operation {
      color: #fff;
      background: #18a8c7;
    }
    .batch-position {
      background: #e7e7e7;
    }
    .canvas-box {
      position: relative;
      #line_box {
        position: absolute;
        display: flex;
        align-items: center;
        height: 30px;
        user-select: none;
        .el-input {
          width: 80px;
          opacity: 0.5;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
  .right-operation {
    width: 331px;
    background: #fff;
    overflow-y: auto;
    height: 100%;
    flex-shrink: 0;
    .tag-btn-box {
      height: 400px;
      padding: 16px;
      background: #fff;
      .tag {
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        height: 190px;
        margin-bottom: 16px;
        text-align: center;
        border: 1px dashed #999999;
        .no-active {
          span {
            display: block;
            color: #999999;
            font-size: 14px;
          }
        }
      }
      .plank-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 250px;
        margin-bottom: 16px;
        padding: 10px 8px;
        border: 1px solid #d9d9d9;
        position: relative;
        .left-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          span {
            display: block;
            width: 180px;
            overflow: hidden;
            color: #333;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .right-qrcode {
          position: absolute;
          right: 10px;
          bottom: 15px;
        }
      }
      .btn {
        .title {
          color: #999999;
          font-size: 14px;
        }
        .operation-btn {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 12px;
          .ant-btn {
            width: 138px;
            height: 32px;
            margin: 4px 0;
          }
          .cutorigin {
            width: 138px;
            height: 32px;
            margin: 4px 0;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
          }
          .cutorigin:hover {
            border: 1px solid #3bbdd4;
            color: #3bbdd4;
            cursor: pointer;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          .cutorigin-active {
            color: #3bbdd4;
            background-color: #fff;
            border-color: #3bbdd4;
          }
          .cursor-not-allowed {
            cursor: not-allowed !important;
          }
          .cutorigin-disable {
            color: rgba(0, 0, 0, 0.25);
            background-color: #f5f5f5;
            border-color: #dcdcdc;
          }
        }
      }
    }
    .modify-plank {
      width: 316px;
      margin-top: 90px;
      background: #fff;
      .nav {
        display: flex;
        width: 100%;
        height: 40px;
        span {
          display: inline-block;
          width: 50%;
          color: #333;
          font-size: 14px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          &.active {
            position: relative;
            color: #18a8c7;
            &::after {
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 56px;
              height: 2px;
              background: #18a8c7;
              transform: translateX(-50%);
              content: '';
            }
          }
        }
      }
      .content-zancun {
        box-sizing: border-box;
        width: 100%;
        padding: 8px;
        overflow-y: scroll;
        background: #fff;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.05);
        }
        &:hover::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track:hover {
          background-color: #fff;
        }
        .plank-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          float: left;
          box-sizing: border-box;
          width: 50%;
          height: 180px;
          padding: 8px 0;
          text-align: center;
          border: 1px solid rgba(229, 229, 229, 1);
          border-top: none;
          // border-right: 1px solid rgba(229, 229, 229, 1);
          &:first-child {
            border-top: 1px solid rgba(229, 229, 229, 1);
          }
          &:nth-child(2) {
            border-top: 1px solid rgba(229, 229, 229, 1);
          }
          &:nth-child(2n) {
            border-left: none;
          }
          img {
            display: block;
            margin: 0 auto;
          }
          .plank-title {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: default;
          }
        }
      }
      .plan-group {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        box-sizing: border-box;
        width: 100%;
        height: calc(100vh - 32px - 60px - 346px);
        max-height: calc(100vh - 32px - 60px - 346px - 70px);
        padding: 16px;
        overflow-y: scroll;
        background: #fff;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.05);
        }
        &:hover::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track:hover {
          background-color: #fff;
        }
        .plan-item {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
  .origin-dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #0008;
  }
  .origin-dialog {
    position: fixed;
    top: 135px;
    right: 75px;
    width: 333px;
    height: 228px;
    margin: auto;
    background: #fff;
    .origin-dialog-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      height: 56px;
      padding: 0 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      span {
        color: #333;
        font-size: 16px;
        cursor: default;
      }
      .icon-close {
        cursor: pointer;
      }
    }
    .origin-btn {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      padding: 24px 24px 16px 24px;
      color: #333;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .ant-btn {
        width: 138px;
        height: 32px;
        margin-bottom: 8px;
        border-radius: 2px;
      }
      .ant-btn.active {
        color: #18a8c7;
        border-color: #18a8c7;
      }
    }
    .origin-dialog-btns {
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 10px;
      > div {
        .ant-btn {
          width: 65px;
          height: 32px;
          &:last-child {
            color: #fff;
            background: #18a8c7;
            border: none;
          }
        }
      }
    }
  }
  .loading-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000000000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #0008;
  }
}
.locked-btn {
  background-color: #ccc !important;
  color: #fff !important;
}

.is-end {
  color: #f00 !important;
  border-color: #f00 !important;
}
.warning {
  color: #f00;
}
</style>

<style lang="less">
.sur-store-modal {
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-footer {
    border-top: none;
    .ant-btn:first-child {
      display: none;
    }
  }
}
</style>
